import { Outlet } from "react-router-dom";
import { LinesProvider } from "../../../context/LinesProvider";

const Lines: React.FC = () => {
  return (
    <LinesProvider>
      <Outlet />
    </LinesProvider>
  );
};

export default Lines;
