/* eslint-disable max-lines */
import { format, parse } from "date-fns";
import { useEffect } from "react";
import useDashboardDatePicker from "../../hooks/useDashboardDatePicker";
import useDashboardSelectLine from "../../hooks/useDashboardSelectLine";
import useDashboardSelectOperator from "../../hooks/useDashboardSelectOperator";
import useFetch from "../../hooks/useFetch";
import { OperatorsSelectList } from "../../interfaces/endpoints/IOperators";
import { LineSelectList } from "../../interfaces/endpoints/lines/ILines";
import { getFilteredAnalyticsPerLine } from "../../services/Analytics.service";
import { getLinesSelectList } from "../../services/Lines.service";
import { getAllSelectOperators } from "../../services/Operators.service";
import Animation from "../ui/Animation";
import Box from "../ui/Box";
import Select from "../ui/select/Select";
import SuperAdmin from "../users/permissions/SuperAdmin";
import useSuperAdmin from "../users/permissions/useSuperAdmin";
import DashboardCard from "./DashboardCard";
import FilteredAnalyticsPerLineGraph from "./FilteredAnalyticsPerLineGraph";

const FilteredAnalyticsPerLineAdmin = () => {
  const { from, to } = useDashboardDatePicker();
  const { line, setLocalLine, defaultLocation } = useDashboardSelectLine();
  const { operator, setLocalOperator, defaultOperatorLocation } =
    useDashboardSelectOperator();
  const { loading, response, handleFunc } = useFetch(
    getFilteredAnalyticsPerLine,
    {
      initRequest: false,
      loading: true,
      resetResponseOnError: true
    }
  );
  const isSuperAdmin = useSuperAdmin();

  useEffect(() => {
    if (from && to) {
      void handleFunc({
        startDate: format(parse(from, "dd/MM/yyyy", new Date()), "MM-dd-yyyy"),
        endDate: format(parse(to, "dd/MM/yyyy", new Date()), "MM-dd-yyyy"),
        lineId: line?.value === 0 ? "" : line?.value.toString(),
        ...(isSuperAdmin?.id
          ? { operatorId: operator?.id === 0 ? "" : operator?.id.toString() }
          : {})
      });
    }
  }, [from, to, line, operator]);

  const onChangeLine = (e: LineSelectList) => setLocalLine(e);
  const onChangeOperator = (e: OperatorsSelectList) => {
    setLocalLine(defaultLocation);
    setLocalOperator(e);
  };

  return (
    <Animation show={true} type="fade-in" delay={100} duration={400}>
      <div className="mt-6">
        <Box shadow={false} padding="p-6">
          <div className="flex xl:justify-between xl:items-center xl:flex-row flex-col">
            <h1 className="text-black text-base leading-6 font-medium">
              Shitjet sipas linjave
            </h1>
            <SuperAdmin>
              <div className="flex items-center xl:justify-center xl:mt-0 mt-4">
                <span className="mr-2 text-neutral text-sm leading-[18px]">
                  Filtro per Operatoret:
                </span>
                <div
                  className="h-[36px] w-[280px]
           text-xs flex justify-start items-center"
                >
                  <Select
                    placeholder="Filtro per Operatoret:"
                    paddingType="fill"
                    onSelectedItemChange={onChangeOperator}
                    selectedItem={operator}
                    emptyItem={defaultOperatorLocation}
                    getOptionLabel={(o) => o.operatorName}
                    itemToString={(o) => o?.operatorName || ""}
                    asyncFunc={getAllSelectOperators}
                  />
                </div>
              </div>
            </SuperAdmin>
            <div className="flex items-center xl:justify-center xl:mt-0 mt-4">
              <span className="mr-2 text-neutral text-sm leading-[18px]">
                Filtro linjen:
              </span>
              <div
                className="h-[36px] w-[280px]
           text-xs flex justify-start items-center"
              >
                <Select
                  paddingType="fill"
                  placeholder="Filtro linjen:"
                  onSelectedItemChange={onChangeLine}
                  selectedItem={line}
                  emptyItem={defaultLocation}
                  asyncFunc={getLinesSelectList}
                  {...(isSuperAdmin?.id
                    ? {
                      disabled: !operator?.id,
                      extraParams: { operatorId: operator?.id }
                    }
                    : {})}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col xl:flex-row mt-6">
            <div className="flex xl:flex-col flex-row">
              <DashboardCard
                delay={100}
                className="xl:pb-6 pr-6 xl:pr-0"
                title="Shitjet për linja"
                currentValue={response?.data.salesPerLine?.currentValue || "0"}
                ratePercentage={
                  response?.data.salesPerLine?.ratePercentage || "0"
                }
                ratePercentageDesc="prej periudhës së kaluar"
                border="xl:border-b xl:border-r-0 border-r"
                padding={false}
                animation={false}
                rounded={false}
                loading={loading}
              />
              <DashboardCard
                delay={200}
                className="xl:mt-6 ml-6 xl:ml-0"
                title="Numri i rezervimeve për linja"
                currentValue={
                  response?.data.reservationsPerLine?.currentValue || "0"
                }
                ratePercentage={
                  response?.data.reservationsPerLine?.ratePercentage || "0"
                }
                ratePercentageDesc="prej periudhës së kaluar"
                border={false}
                padding={false}
                animation={false}
                rounded={false}
                loading={loading}
                currency={false}
              />
            </div>
            <div className="xl:ml-24 xl:mt-0 mt-6 flex-1">
              <FilteredAnalyticsPerLineGraph
                loading={loading}
                data={response?.data?.salesPerLinePerDay.map((el, index) => ({
                  ...el,
                  nrOfReservations:
                    response?.data?.reservationsPerLinePerDay[index]
                      .nrOfReservations
                }))}
              />
            </div>
          </div>
        </Box>
      </div>
    </Animation>
  );
};
export default FilteredAnalyticsPerLineAdmin;
