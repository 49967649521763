import { forwardRef, ReactNode } from "react";
import { cx } from "../../../utils/classnames";
import { InputProps } from "./Input.types";
import InputWrapper from "./InputWrapper";

enum paddingInputEnum {
  "hug" = "p-3",
  "fill" = "py-[9px] px-3",
  "small" = "py-[7.5px] px-3"
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      inputClassName,
      border,
      label,
      className,
      error,
      before,
      after,
      paddingType = "hug",
      labelClassName,
      disabled,
      beforeClassName,
      afterClassName,
      ...props
    },
    ref
  ) => (
    <InputWrapper
      id={props.id}
      label={label}
      name={props.name}
      className={cx([className, (!!after || !!before) && "relative"])}
      labelClassName={labelClassName}
      error={error}
    >
      <div className={cx([(!!after || !!before) && "relative"])}>
        {before &&
          beforeORafter(before, cx(["left-0 pl-3 pr-2 z-10", beforeClassName]))}
        <input
          {...props}
          id={props.id || props.name}
          ref={ref}
          autoComplete="off"
          disabled={disabled}
          className={cx([
            inputClassName,
            `text-sm leading-[18px] outline-none focus:ring-primary
            appearance-none w-full rounded-lg ${error && "ring-warning"}`,
            typeof border === "string"
              ? border
              : `ring-1 ${
                  !error ? "ring-blue-stroke " : ""
                }transition-all duration-300 hover:ring-gray-500`,
            paddingInputEnum[paddingType],
            disabled && "bg-gray-200 cursor-not-allowed",
            !!after && "pr-9 relative",
            !!before && "pl-9 relative"
          ])}
          {...(props.type === "number" && {
            onKeyPress: (e) => !/[0-9.]/.test(e.key) && e.preventDefault(),
            onWheel: (e) => e.currentTarget.blur()
          })}
        />
        {after &&
          beforeORafter(after, cx(["right-0 pr-3 pl-2 z-10", afterClassName]))}
      </div>
    </InputWrapper>
  )
);

export default Input;

export const beforeORafter = (icon: ReactNode, className: string) => (
  <div
    className={cx([
      "absolute inset-y-0 flex items-center pointer-events-none",
      className
    ])}
  >
    {icon}
  </div>
);
