import { ArrowRightIcon } from "@heroicons/react/20/solid";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import LoadingSection from "../../../components/auth/LoadingSection";
import Button from "../../../components/ui/Button";
import Table, { Row } from "../../../components/ui/table";
import withProtected from "../../../hoc/withProtected";
import useFetch from "../../../hooks/useFetch";
import { getOperatorTripSalesPerDate } from "../../../services/Analytics.service";
import _404 from "../../404";

const colums = ["Lloji", "Emri", "Mbiemri", "Passport ID", "Ulëse Shkuese"];

const TripsSales = () => {
  const [showButton, setShowButton] = useState(true);
  const { tripId } = useParams();
  const [searchParams] = useSearchParams();
  const { response, loading, error } = useFetch(getOperatorTripSalesPerDate, {
    initRequest: true,
    params: {
      tripId,
      dateFrom: searchParams.get("df") || "",
      dateTo: searchParams.get("dt") || ""
    }
  });

  useEffect(() => {
    window.onbeforeprint = () => setShowButton(false);
    window.onafterprint = () => setShowButton(true);
  }, []);
  const onPrint = () => {
    setShowButton(false);
    setTimeout(() => {
      print();
    }, 0);
  };

  if (loading) return <LoadingSection />;
  if (error) return <_404 title="Kjo linje nuk u gjete!" />;
  return (
    <div className="container mx-auto my-8">
      <style>{" @page { margin: 0cm !important; }"}</style>
      <div className="border-b-2 border-gray-200 pb-3 flex justify-between">
        {response?.data.operator.pictureUrl ? (
          <img
            src={response?.data.operator.pictureUrl}
            alt=""
            className="w-24 h-10 object-contain object-left"
          />
        ) : (
          <div className="font-medium text-neutral text-2xl">
            {response?.data.operator.name}
          </div>
        )}
        {showButton ? (
          <Button
            btnType="custom"
            className=" bg-gradient-to-bl from-primary to-pink text-white text-sm
            rounded-lg shadow-bottom"
            rounded={false}
            padding="px-8 py-2.5"
            onClick={onPrint}
          >
            Printo
          </Button>
        ) : null}
      </div>
      <div className="mt-6">
        <h1 className="text-base font-bold mb-5">Detajet e Pasagjerëve</h1>
        <div className="flex gap-8 mb-6">
          <div className="flex flex-col gap-1">
            <p className="text-xs text-neutral">Linja</p>
            <div className="flex gap-2 items-center text-sm font-bold">
              <p className="font-bold ">
                {response?.data.trip.departureCity}
                <span className="text-xs text-neutral font-normal ml-0.5">
                  ({response?.data.trip.departureStation})
                </span>
              </p>
              <ArrowRightIcon className="h-4 w-4" />
              <p className="font-bold ">
                {response?.data.trip.arrivalCity}
                <span className="text-xs text-neutral font-normal ml-0.5">
                  ({response?.data.trip.arrivalStation})
                </span>
              </p>
            </div>
          </div>
          <div className="border-r-2 border-gray-200 my-1" />
          <div className="flex flex-col gap-1">
            <p className="text-xs text-neutral">Orari</p>
            <p className="flex gap-2 items-center  text-base font-bold">
              <span>{response?.data.trip.departureTime} </span>
              <ArrowRightIcon className="h-4 w-4" />
              <span> {response?.data.trip.arrivalTime}</span>
            </p>
          </div>
        </div>
        {response?.data.journeys.map((j) => (
          <div className="mb-8">
            <div className="flex gap-1 flex-col mb-3">
              <p className="text-xs text-neutral">Data</p>
              <p className="font-bold text-sm">
                {dayjs(j.journeyDate).format("DD MMMM YYYY")}
              </p>
            </div>
            <Table
              titles={colums}
              className="bg-gray-50"
              thClassName="bg-gray-50"
              style="border"
            >
              {j.clients.map((c) => (
                <Row
                  passagersType={c.passengerType || "--"}
                  name={c.name}
                  lastname={c.lastName}
                  passportId={c.passportId || "--"}
                  seat={c.seatNumber || "--"}
                />
              ))}
            </Table>
          </div>
        ))}
      </div>
    </div>
  );
};
export default withProtected(TripsSales, true);
