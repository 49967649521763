export enum PaymentMethodEnum {
  Raiffeisen = 1,
  Stripe = 2,
  Transfer = 3
}
export enum TicketStatusEnum {
  Confirmed = 1,
  Pending = 2,
  Canceled = 3,
  Refund = 4
}
