import { useAuth } from "oidc-react";
import { useEffect } from "react";
import Box from "../ui/Box";
import Modal from "../ui/Modal";
import LoadingSection from "./LoadingSection";

const Logout = () => {
  const auth = useAuth();
  useEffect(() => {
    void auth?.signOutRedirect();
  }, []);

  return (
    <Modal show={true} onClose={() => 0} hasHeader={false} position="top" modalWidthTouch="">
      <Box className="w-[300px] h-[450px] flex flex-col justify-center items-center"
        shadow={false}
        rounded={false}>
        <LoadingSection />
      </Box>
    </Modal>
  );
};

export default Logout;
