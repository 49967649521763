import Departure from "../../../components/office/lines/DepartureCol";
import ITrip from "../../../interfaces/endpoints/officeSales/ITrip";

interface SelectedTripProps {
  departureTripRes?: ITrip;
  returnTripRes?: ITrip;
}
const SelectedTrip: React.FC<SelectedTripProps> = ({ departureTripRes, returnTripRes }) => {
  return (
    <div className="flex gap-4">
      <div>
        <span className="text-gray">Nisja</span>
        <div className="bg-gray-100 border rounded-md py-4 px-10">
          <Departure
            fromLocation={{
              station: departureTripRes?.trip.line.departureCityName || "",
              time: departureTripRes?.trip.departureTime || ""
            }}
            toLocation={{
              station: departureTripRes?.trip.line.arrivalCityName || "",
              time: departureTripRes?.trip.arrivalTime || ""
            }}
          />
        </div>
      </div>
      {returnTripRes ? (
        <div>
          <span className="text-gray">Kthimi</span>
          <div className="bg-gray-100 border rounded-md py-4 px-10">
            <Departure
              fromLocation={{
                station: returnTripRes?.trip.line.departureCityName || "",
                time: returnTripRes?.trip.departureTime || ""
              }}
              toLocation={{
                station: returnTripRes?.trip.line.arrivalCityName || "",
                time: returnTripRes?.trip.arrivalTime || ""
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SelectedTrip;
