import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Select from "react-select";
import Box from "../../components/ui/Box";
import Button from "../../components/ui/Button";
import Spinner from "../../components/ui/Spinner";
import InputWrapper from "../../components/ui/input/InputWrapper";

import { useNavigate } from "react-router-dom";
import { useRequest } from "../../hooks/useRequest";

type operatorTypeForm = {
  operator: { id: number; operatorName: string };
  username: string;
  password: string;
};

const ChangeOperator = () => {
  const navigate = useNavigate();
  const {
    requestState: { response }
  } = useRequest("v1/account/operators", "get");

  const {
    handleRequest,
    requestState: { loading }
  } = useRequest("", "post");

  const {
    handleSubmit,
    control
  } = useForm<operatorTypeForm>();

  const onSubmit = async ({ operator, ...rest }: operatorTypeForm) => {
    await handleRequest(
      { ...rest, operatorId: operator?.id || 1 },
      () => {
        toast.success("Operatori është ndryshuar me sukses.");
        navigate("/");
        window.location.reload();
      },
      () => toast.error("Ndodhi një gabim gjatë ndryshimit."),
      `v1/Account/changeoperator?operatorId=${operator?.id || 1}`
    );
  };

  return (
    <Box className="max-w-xl">
      <h1 className="mb-6 border-b font-medium text-black">
        Ndrysho Operatorin
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className="max-w-lg mx-auto">
        <Controller
          name="operator"
          control={control}
          rules={{ required: "Ploteso operatorin" }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <InputWrapper
              label="Selekto operatorin:"
              error={error?.message}
            >
              <Select
                value={value}
                onChange={onChange}
                placeholder="Zgjedh"
                options={response?.data}
                getOptionValue={(o: operatorTypeForm["operator"]) => `${o.id}`}
                getOptionLabel={(o: operatorTypeForm["operator"]) =>
                  o.operatorName
                }
              />
            </InputWrapper>
          )}
        />
        <div className="flex justify-end mt-6">
          <Button
            padding="px-4 py-3"
            className="flex items-center"
            disabled={loading}
            type="submit"
          >
            {loading && (
              <span className="mr-2">
                <Spinner size={16} />
              </span>
            )}
            Ndrysho Operatorin
          </Button>
        </div>
      </form>
    </Box>
  );
};

export default ChangeOperator;
