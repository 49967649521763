import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Controller, useFormContext } from "react-hook-form";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { IBundleSchema } from "../../../../interfaces/bundles/Bundles.types";
import { getPagedBundleIncludes } from "../../../../services/Bundles.service";
import Select from "../../../ui/select/Select";
import MultipleLangFields from "./MultipleLangFields";

const BasicDetails = () => {
  const { control } = useFormContext<IBundleSchema>();

  return (
    <>
      <MultipleLangFields />
      <Controller
        control={control}
        name="packages"
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <Select
              className="mt-6 "
              label={
                <>
                  Pakoja përfshin*
                  <InformationCircleIcon
                    className="w-4 h-4 ml-1.5"
                    strokeWidth={2}
                    data-tooltip-id="packages-tooltip"
                    data-tooltip-content="Specifikat që përshihen në këtë ofertë."
                    data-tooltip-variant="dark"
                    data-tooltip-float={true}
                  />
                  <Tooltip id="packages-tooltip" />
                </>
              }
              labelClassName="flex items-center"
              placeholder="Zgjedh"
              onSelectedListChange={(selected) => onChange(selected)}
              beforeClassName="z-50"
              selectedItems={value}
              selectType="multiple"
              getOptionLabel={(o) => (
                <span className="flex items-center">
                  {o.svgUrl ? (
                    <img src={o.svgUrl} className="w-4 h-4 mr-2" />
                  ) : null}{" "}
                  <span>{o.name}</span>
                </span>
              )}
              asyncFunc={getPagedBundleIncludes}
              error={error?.message}
            />
          );
        }}
      />
    </>
  );
};

export default BasicDetails;
