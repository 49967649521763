/* eslint-disable max-lines */

import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { format, parse } from "date-fns";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import useDashboards from "../../hooks/useDashboards";
import useFetch from "../../hooks/useFetch";
import { TripSalesType } from "../../interfaces/endpoints/IAnalytics.types";
import { getOperatorTripsSalesAnalytics } from "../../services/Analytics.service";
import { cx } from "../../utils/classnames";
import { serialize } from "../../utils/serializeToQueryString";
import PdfIcon from "../shared/svgs/PdfIcon";
import SpinnerLoader from "../ui/SpinnerLoader";

const TripsSalesAnalytics = () => {
  const { line, from, to } = useDashboards();

  const { response, loading, handleFunc } = useFetch(
    getOperatorTripsSalesAnalytics,
    { loading: true, resetResponseOnError: true }
  );

  useEffect(() => {
    if (from && to) {
      void handleFunc({
        startDate: format(parse(from, "dd/MM/yyyy", new Date()), "MM-dd-yyyy"),
        endDate: format(parse(to, "dd/MM/yyyy", new Date()), "MM-dd-yyyy"),
        lineId: line?.value || undefined
      });
    }
  }, [from, to, line]);

  const navigate = useNavigate();

  const onClick = (trip: TripSalesType) => {
    if (from && to && !!trip.nrOfPassengers)
      navigate(
        `/trips-sales/${trip.tripId}${serialize({
          df: format(parse(from, "dd/MM/yyyy", new Date()), "yyyy-MM-dd"),
          dt: format(parse(to, "dd/MM/yyyy", new Date()), "yyyy-MM-dd")
        })}`
      );
  };

  return (
    <div className="rounded-lg border border-gray-200 py-4 relative">
      <table className="max-w-[370px]">
        <thead
          className="table w-full overflow-hidden "
          style={{ tableLayout: "fixed" }}
        >
          <tr className="">
            <th className="px-5 pb-2 text-left text-2xs uppercase font-medium text-neutral w-[160px]">
              Oraret
            </th>
            <th className="px-5 pb-2 text-left text-2xs uppercase font-medium text-neutral ">
              Pasagjerët
            </th>
            <th className="px-5 pb-2 text-left text-2xs uppercase font-medium text-neutral ">
              Qarkullimi
            </th>
          </tr>
        </thead>
        {response?.data.length && !loading ? (
          <tbody className="block h-[310px] overflow-auto tableDiv">
            {response.data.map((el) =>
              el.tripSales.map((e) => (
                <tr
                  className={cx([
                    "odd:bg-gray-100 table w-full hover:bg-gray-200",
                    !!e.nrOfPassengers && "cursor-pointer"
                  ])}
                  style={{ tableLayout: "fixed" }}
                  onClick={() => onClick(e)}
                >
                  <td className="pl-5 py-2.5 text-left text-sm  whitespace-nowrap w-[170px]">
                    <div className="flex items-center gap-1 uppercase">
                      {e.departureTime.slice(0, 5)}{" "}
                      <ArrowRightIcon className="text-primary w-4 h-4" />{" "}
                      {e.arrivalTime.slice(0, 5)}
                    </div>
                    <div
                      className="text-2xs text-neutral w-full truncate"
                      data-tooltip-content={el.lineName}
                      data-tooltip-id={`lineName_${el.lineId}`}
                      data-tooltip-variant="dark"
                      data-tooltip-float={true}
                      data-tooltip-place="bottom"
                    >
                      {el.lineName}
                      <Tooltip id={`lineName_${el.lineId}`} />
                    </div>
                  </td>
                  <td className="px-5 py-2.5 text-left text-sm uppercase">
                    <div className="flex justify-between items-center w-10">
                      {e.nrOfPassengers}{" "}
                      <PdfIcon className="text-primary w-5 h-5" />{" "}
                    </div>
                  </td>
                  <td className="px-5 py-2.5 text-left text-sm uppercase whitespace-nowrap">
                    <div className="">{e.totalSales} €</div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        ) : null}
      </table>
      {!response?.data.length && !loading ? (
        <div className=" mx-6 mb-2 flex flex-col items-center mt-6">
          <p className="text-neutral text-sm">Nuk keni asnje te dhene!</p>
          <p className="text-neutral text-xs">
            Per linjen e caktuar dhe data e zgjedhuar.
          </p>
        </div>
      ) : null}
      {loading ? (
        <SpinnerLoader className="text-primary absolute right-0 left-0 top-16" />
      ) : null}
    </div>
  );
};

export default TripsSalesAnalytics;
