/* eslint-disable max-lines */
import { FC } from "react";
import { IIcon } from "./IIcon";

const BusChange: FC<IIcon> = ({ color, size, className }) => {
  const currentColor = color || "currentColor";
  const currentSize = size || 10;
  return (
    <span className={className}>
      <svg
        width={currentSize}
        height={currentSize}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_8738_48806)">
          <path
            d="M8.52134 2.25178L10.0423 3.18302C10.2277 3.29652 10.4811 3.23825 10.6083 3.05288C10.7356
            2.86751
           10.6884 2.62524 10.5031 2.51174L8.98212 1.5805C8.79675 1.467 8.54334 1.52527 8.41609
            1.71064C8.28885 1.89601 8.33597 2.13828 8.52134 2.25178Z"
            fill={currentColor}
          />
          <path
            d="M9.56289 4.57228L10.6069 3.05133C10.7341 2.86596 10.687 2.62369 10.5017 2.51019C10.3163
           2.39669 10.0629 2.45496 9.93562 2.64033L8.89161 4.16128C8.76437 4.34665 8.81149 4.58892 8.99686
            4.70242C9.18223 4.81592 9.43565 4.75765 9.56289 4.57228Z"
            fill={currentColor}
          />
          <path
            d="M2.78411 14.1015C2.33495 11.7921 2.84503 9.35451 4.26358 7.28793C5.60811 5.32918 7.60828
          3.93636 9.87821 3.32303C10.0974 3.26379 10.2347 3.04625 10.1848 2.83711C10.1349 2.62797 9.91669
           2.50647 9.69745 2.56571C7.22989 3.23245 5.05381 4.74776 3.5923 6.87693C2.0503 9.12335 1.49538
           11.7752 1.98362 14.2856C2.02473 14.4969 2.23724 14.627 2.45829 14.5762C2.67934 14.5254 2.82521
           14.3128 2.78411 14.1015ZM15.4675 21.7276L13.9466 20.7963C13.7612 20.6828 13.5078 20.7411 13.3805
           20.9265C13.2533 21.1119 13.3004 21.3541 13.4858 21.4676L15.0067 22.3989C15.1921 22.5124 15.4455
            22.4541 15.5728 22.2687C15.7 22.0834 15.6529 21.8411 15.4675 21.7276Z"
            fill={currentColor}
          />
          <path
            d="M14.4259 19.406L13.3818 20.9269C13.2546 21.1123 13.3017 21.3545 13.4871 21.468C13.6725 21.5815
          13.9259 21.5233 14.0531 21.3379L15.0971 19.817C15.2244 19.6316 15.1773 19.3893 14.9919
          19.2758C14.8065 19.1623 14.5531 19.2206 14.4259 19.406Z"
            fill={currentColor}
          />
          <path
            d="M21.2031 9.87535C21.6522 12.1847 21.1421 14.6224 19.7236 16.6889C18.379 18.6477 16.3789 20.0405
          14.109 20.6538C13.8897 20.7131 13.7525 20.9306 13.8024 21.1398C13.8523 21.3489 14.0705 21.4704
          14.2897 21.4112C16.7573 20.7444 18.9334 19.2291 20.3949 17.0999C21.9369 14.8535 22.4918 12.2016
          22.0035 9.69131C21.9624 9.47998 21.7499 9.34986 21.5289 9.40069C21.3078 9.45151 21.162 9.66402
          21.2031 9.87535Z"
            fill={currentColor}
          />
          <path
            d="M8.82445 16.1719C9.10527 16.1719 9.37458 16.0665 9.57315 15.879C9.77172 15.6914 9.88327
          15.4371 9.88327 15.1719C9.88327 14.9067 9.77172 14.6523 9.57315 14.4648C9.37458 14.2772 9.10527
          14.1719 8.82445 14.1719C8.54363 14.1719 8.27432 14.2772 8.07575 14.4648C7.87718 14.6523 7.76563
          14.9067 7.76562 15.1719C7.76563 15.4371 7.87718 15.6914 8.07575 15.879C8.27432 16.0665 8.54363
          16.1719 8.82445 16.1719Z"
            stroke="#F15B2D"
            strokeWidth="0.705882"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={currentColor}
          />
          <path
            d="M15.178 16.1719C15.4588 16.1719 15.7281 16.0665 15.9267 15.879C16.1252 15.6914 16.2368 15.4371
          16.2368 15.1719C16.2368 14.9067 16.1252 14.6523 15.9267 14.4648C15.7281 14.2772 15.4588 14.1719
          15.178 14.1719C14.8971 14.1719 14.6278 14.2772 14.4293 14.4648C14.2307 14.6523 14.1191 14.9067
          14.1191 15.1719C14.1191 15.4371 14.2307 15.6914 14.4293 15.879C14.6278 16.0665 14.8971 16.1719
          15.178 16.1719Z"
            stroke="#F15B2D"
            strokeWidth="0.705882"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={currentColor}
          />
          <path
            d="M14.1201 15.1719H9.88477"
            stroke="#F15B2D"
            strokeWidth="0.705882"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill={currentColor}
          />
          <path
            d="M7.76585 15.1719H6.70703V9.67188C6.70703 9.53927 6.76281 9.41209 6.86209 9.31832C6.96138
          9.22455 7.09603 9.17188 7.23644 9.17188H14.6482C15.3502 9.17188 16.0236 9.54038 16.5197
          10.1969C17.0163 10.8534 17.2953 11.7434 17.2953 12.6719V15.1719H16.2364"
            stroke="#F15B2D"
            strokeWidth="0.705882"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="#fff"
          />
          <path
            d="M14.1191 9.17188L14.9133 12.6719H17.2956"
            stroke="#F15B2D"
            strokeWidth="0.705882"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="#fff"
          />
          <path
            d="M6.70703 11.6719H14.6482"
            stroke="#F15B2D"
            strokeWidth="0.705882"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.35352 9.17188V11.6719"
            stroke="#F15B2D"
            strokeWidth="0.705882"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.002 9.17188V11.6719"
            stroke="#F15B2D"
            strokeWidth="0.705882"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_8738_48806">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};
export default BusChange;
