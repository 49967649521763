import { TrashIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { useFormContext } from "react-hook-form";
import { ITripType } from "../../../../interfaces/endpoints/trips/Trips.types";
import Input from "../../../ui/input/Input";
import Select from "../../../ui/input/Select";
import useTimeList from "../../../ui/input/TimePicker";
interface EmptyLineTripProps {
  editMode?: boolean;
  options?: { name: string; id: number }[];
  index: number;
  handleDelete: (id: number) => void;
  tripIndex?: number;
}

const EmptyLineTrip: React.FC<EmptyLineTripProps> = ({
  editMode,
  options,
  index,
  handleDelete,
  tripIndex
}) => {
  const {
    register, getValues, setValue, formState: { errors }
  } = useFormContext<ITripType>();

  const updateDuration = () => {
    const dt = dayjs(
      `2000-01-01 ${getValues(`schedules.${index}.departureTimeString`)}`
    );
    const at = dayjs(
      `2000-01-01 ${getValues(`schedules.${index}.arrivalTimeString`)}`
    );
    const durationSchedules: number = Number(dt.diff(at, "minutes", true));
    setValue(`schedules.${index}.duration`, durationSchedules);
  };
  const times = useTimeList();

  return (
    <div className="flex-row flex items-center justify-between">
      <div className="mb-6 px-2 w-full md:w-2/3">
        {!editMode ? (
          <Select
            options={options || []}
            label="Zgjedh ndalesën"
            placeholder="Zgjedh"
            {...register(`schedules.${index}.stationId`, {
              valueAsNumber: true
            })}
            error={errors?.schedules?.[index]?.stationId?.message}
          />
        ) : (
          <Input
            {...register(`schedules.${index}.stationId`)}
            label="Ndalesa e zgjedhur"
            className="pointer-events-none"
            inputClassName="h-11"
          />
        )}
      </div>
      <div className="w-full md:w-1/2 flex items-center">
        <div className="mb-6 w-5/6 md:w-full px-3">
          <Select
            {...register(`schedules.${index}.departureTimeString`)}
            label="Ora e nisjes"
            error={errors?.schedules?.[index]?.departureTimeString?.message}
            onBlur={() => {
              const setDeparture = getValues(
                `schedules.${index}.departureTimeString`
              );
              setValue(`schedules.${index}.arrivalTimeString`, setDeparture);
              updateDuration();
            }}
            options={times}
          />
        </div>
        <div className="hidden mb-6 w-1/2 pr-3">
          <Input
            type="text"
            {...register(`schedules.${index}.arrivalTimeString`)}
            label="Ora e arritjes"
            step="300"
            inputClassName="h-11 rounded-lg"
            onBlur={updateDuration}
          />
        </div>
        <input
          hidden
          value={0}
          {...register(`schedules.${index}.duration`, { valueAsNumber: true })}
        />
        <input
          value={tripIndex}
          {...register(`schedules.${index}.tripId`)}
          hidden
        />
        <span
          className="transition-colors hover:bg-gray-200 cursor-pointer rounded-full flex
        bg-gray-200 md:bg-transparent
        items-center justify-center w-10 h-9"
          onClick={() => handleDelete(index)}
        >
          <TrashIcon className="w-4 h-4 text-neutral" />
        </span>
      </div>
    </div>
  );
};

export default EmptyLineTrip;
