import { ButtonHTMLAttributes, FC } from "react";
import { cx } from "../../utils/classnames";

interface IButton extends ButtonHTMLAttributes<HTMLElement> {
  type?: "button" | "submit";
  rounded?: boolean;
  padding?: string;
  btnType?: "primary" | "custom";
  fontWeight?: false | string;
}

const Button: FC<IButton> = ({
  className,
  padding = "px-4 py-2",
  type = "button",
  children,
  rounded = true,
  btnType = "primary",
  fontWeight = "font-bold",
  ...props
}) => {
  return (
    <button
      {...props}
      type={type}
      className={cx([
        className,
        fontWeight,
        `${
          rounded && "rounded-lg"
        }  ${padding} text-sm leading-[18px]  hover:bg-opacity-90 duration-300`,
        btnType === "primary" &&
          `bg-primary  text-white
          `,
        props?.disabled && "opacity-70"
      ])}
    >
      {children}
    </button>
  );
};

export default Button;
