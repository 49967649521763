import { axios } from "../config/axios";
import {
  BundleListType,
  BundleType,
  SelectBundleIncludeType
} from "../interfaces/bundles/Bundles.types";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import { IPagination } from "../interfaces/IPagination";
import { SelectParamsType } from "../interfaces/ISelectParams";
import { serialize } from "../utils/serializeToQueryString";

export const getPagedBundleIncludes = async (
  params: SelectParamsType
): Promise<IAxiosResponse<IPagination<SelectBundleIncludeType>>> => {
  return axios.get(`TravelBundle/TravelBundleIncludes${serialize(params)}`);
};

export const getPagedBundles = async (
  params: SelectParamsType & { bundleStatus: number }
): Promise<IAxiosResponse<IPagination<BundleListType>>> => {
  return axios.get(`TravelBundle/TravelBundles${serialize(params)}`);
};

export const getBundleById = async ({
  id
}: {
  id: string;
}): Promise<IAxiosResponse<BundleType>> => {
  return axios.get(`/TravelBundle/${id}`);
};

export const deleteBundleById = async (
  id: number
): Promise<IAxiosResponse<BundleType>> => {
  return axios.delete(`/TravelBundle/${id}`);
};

export const createBundle = async ({
  data
}: {
  data: Partial<BundleType>;
}): Promise<IAxiosResponse<BundleType>> => {
  return axios.post("/TravelBundle", data);
};
export const editBundle = async ({
  data
}: {
  data: Partial<BundleType>;
}): Promise<IAxiosResponse<BundleType>> => {
  return axios.put("/TravelBundle", data);
};
