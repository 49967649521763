import NotfoundTable from "../../../components/ui/NotfoundTable";
import Table from "../../../components/ui/table";
import Pagination from "../../../components/ui/table/Pagination";
import UserRow from "../../../components/users/UserRow";
import usePageIndex from "../../../hooks/usePageIndex";
import { useSearch } from "../../../hooks/useSearch";
import { getPagedUsers } from "../../../services/Users.service";

const columns: (string | number)[] = [
  "Emri Mbiemri",
  "Email",
  "Operator Name",
  "Role",
  "Data e krijimit",
  "Aktiv",
  "Veprimet"
];

const Users = () => {
  const { response, loading, handlePageChange, page, setResponse } = useSearch(
    getPagedUsers,
    {
      withQuerySearch: true,
      withFilter: true,
      filterSelector: "userRole",
      resetResponseOnError: true
    }
  );
  const { state }: any = usePageIndex();

  return (
    <div className="flex flex-col" style={{ height: "calc(100vh - 14.1rem)" }}>
      <Table
        titles={columns}
        loading={loading}
        className="overflow-x-auto"
        style="hover"
      >
        {response?.data.result?.length ? (
          response?.data.result?.map((row, i: number) => {
            const highlighted = +state?.params?.id === row.id;

            return (
            <UserRow
              key={`${row.id}_${i}`}
              className={`${highlighted && "highlighted"}`}
              row={row}
              pageIndex={page}
              onDeleteSuccess={() => {
                setResponse((prev) =>
                  prev
                    ? {
                        ...prev,
                        data: {
                          ...prev.data,
                          result: prev.data.result.filter(
                            (el) => el.id !== row.id
                          )
                        }
                      }
                    : null
                );
              }}
            />
          );
})
        ) : (
          <></>
        )}
      </Table>
      {!response?.data.result?.length && !loading ? (
        <NotfoundTable
          header="Nuk ka user në list"
          paragraph="Shto user duke klikuar butonin lartë"
        />
      ) : null}
      <Pagination
        onPageChange={handlePageChange}
        totalCount={response?.data?.totalCount || 0}
        length={response?.data?.result.length}
        page={page}
        pageSize={10}
      />
    </div>
  );
};

export default Users;
