import format from "date-fns/format";
import { ChangeEvent, FC } from "react";
import useWindowSize from "../../../../hooks/useWindowResize";
import { cx } from "../../../../utils/classnames";
import { IInputs } from "../Datepicker.types";
import InputPicker from "../InputPicker";
import InputPickerWrapper from "../InputPickerWrapper";

const Inputs: FC<IInputs> = ({
  range,
  setShow,
  show,
  onChangeRange,
  inputRange,
  setInputRange,
  paddingType,
  error,
  placeholder,
  onlyModal,
  disabled,
  ...labelProps
}) => {
  // const [error, setError] = useState(false);
  // useEffect(() => {
  //   if (range?.from && range?.to) {
  //     setError(false);
  //   } else setError(true);
  // }, [range]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const mainRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/\d{4}$/;
    let value = e.target.value;
    if (mainRegex.test(e.target.value)) {
      const date = e.target.value.split("/");
      const newDate = new Date(
        parseInt(date[2]),
        parseInt(date[1]) - 1,
        parseInt(date[0])
      );
      value = format(newDate, "dd/MM/yyyy");
      onChangeRange({
        from: range?.from,
        to: range?.to,
        [e.target.id]: newDate
      });
    } else {
      onChangeRange({
        from: range?.from,
        to: range?.to,
        [e.target.id]: undefined
      });
    }
    setInputRange((prev) => ({ ...prev, [e.target.id]: value }));
  };
  const size = useWindowSize();

  return (
    <InputPickerWrapper
      setShow={setShow}
      disabled={disabled}
      show={show}
      inputClassName={cx([
        size["2xl"] && !onlyModal ? "cursor-text" : "cursor-pointer",
        !!error && "ring-warning"
      ])}
      {...labelProps}
      error={error}
    >
      <div className="flex justify-start items-center w-full">
        <InputPicker
          onlyModal={onlyModal}
          value={inputRange.from}
          id="from"
          autoComplete="off"
          disabled={disabled}
          maxLength={10}
          onChange={onChange}
          className={cx([
            placeholder
              ? inputRange.from || inputRange.to
                ? "w-[80px]"
                : "w-full"
              : "w-[80px]"
          ])}
          {...(inputRange.to || !placeholder
            ? {
              placeholder: "dd/mm/yyyy"
            }
            : { placeholder })}
          paddingType={paddingType}
        />
        {placeholder ? (
          <>
            {inputRange.from || inputRange.to ? (
              <span className="mx-1 text-neutral">-</span>
            ) : null}
          </>
        ) : (
          <span className="mx-1 text-neutral">-</span>
        )}
        <InputPicker
          value={inputRange.to}
          onlyModal={onlyModal}
          id="to"
          maxLength={10}
          autoComplete="off"
          disabled={disabled}
          className={cx([
            placeholder
              ? inputRange.from || inputRange.to
                ? "w-[80px]"
                : "w-full"
              : "w-[80px]"
          ])}
          {...((inputRange.from || !placeholder) && {
            placeholder: "dd/mm/yyyy"
          })}
          onChange={onChange}
          paddingType={paddingType}
        />
      </div>
    </InputPickerWrapper>
  );
};

export default Inputs;
