import { FC } from "react";
import Day, { DayProps, DaysShortEnum } from "./Day";

interface DaysProps {
  days: [boolean, boolean, boolean, boolean, boolean, boolean, boolean];
}

const Days: FC<DaysProps> = ({ days }) => {
  const _days: DayProps[] = [
    { day: DaysShortEnum.monday, enable: days[0] },
    { day: DaysShortEnum.tuesday, enable: days[1] },
    { day: DaysShortEnum.wednesday, enable: days[2] },
    { day: DaysShortEnum.thursday, enable: days[3] },
    { day: DaysShortEnum.friday, enable: days[4] },
    { day: DaysShortEnum.saturday, enable: days[5] },
    { day: DaysShortEnum.sunday, enable: days[6] }
  ];
  return (
    <>
      {_days.map((e, key) => (
        <Day key={key} {...e} />
      ))}
    </>
  );
};

export default Days;
