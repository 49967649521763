import { XMarkIcon } from "@heroicons/react/24/outline";
import { useCombobox } from "downshift";
import { cx } from "../../../../utils/classnames";
import { IPill } from "../Select.types";
import { instanceOfBaseType } from "../utils";

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const Pill = <T, D extends unknown>({
  item,
  getOptionLabel,
  disabled,
  onSelectedItemChange
}: IPill<T, D>) => {
  const _getOptionLabel = (o: T) => {
    if (getOptionLabel) return getOptionLabel(o);
    if (instanceOfBaseType(o, "label")) return o.label;
  };
  return (
    <div
      className="py-1 ring-1 ring-gray-300 rounded-full text-sm leading-[18px] whitespace-nowrap px-2
      flex justify-center items-center group bg-white"
    >
      {_getOptionLabel(item)}
      <span
        className={cx([
          "rounded-full cursor-pointer ml-1 p-0.5",
          "hover:rotate-90 transition-all duration-300 hover:shadow-lg",
          "hover:ring-1 ring-gray-200",
          disabled ? "" : "bg-gray-100"
        ])}
        onClick={() =>
          onSelectedItemChange &&
          !disabled &&
          onSelectedItemChange({
            selectedItem: item,
            type: useCombobox.stateChangeTypes.ItemClick
          })
        }
      >
        <XMarkIcon className="w-4 h-4 scale-110" />
      </span>
    </div>
  );
};

export default Pill;
