import { FC, InputHTMLAttributes } from "react";
import useWindowSize from "../../../hooks/useWindowResize";
import { cx } from "../../../utils/classnames";

enum paddingInputEnum {
  "hug" = "py-[12.5px]",
  "fill" = "py-[9px]"
}
interface IInputPicker extends InputHTMLAttributes<HTMLInputElement> {
  paddingType?: "hug" | "fill";
  className?: string;
  onlyModal?: boolean;
}

const InputPicker: FC<IInputPicker> = ({
  paddingType = "hug",
  className,
  onlyModal,
  ...props
}) => {
  const size = useWindowSize();

  if (size["2xl"] && !onlyModal)
    return (
      <input
        className={cx([
          "h-full appearance-none focus:outline-none",
          paddingInputEnum[paddingType],
          className
        ])}
        {...props}
      />
    );
  else
    return (
      <span
        className={cx([
          "h-full appearance-none focus:outline-none",
          paddingInputEnum[paddingType],
          className
        ])}
      >
        {props.value || (
          <span className="text-[#cdcdcd] text-sm whitespace-nowrap">
            {props.placeholder}
          </span>
        )}
      </span>
    );
};

export default InputPicker;
