import { format } from "date-fns";
import { FC } from "react";
import { cx } from "../../../../utils/classnames";
import { IInput } from "../Datepicker.types";
import InputPickerWrapper from "../InputPickerWrapper";

enum paddingInputEnum {
  "hug" = "py-[12.5px]",
  "fill" = "py-[9px]"
}

const Input: FC<IInput> = ({
  setShow,
  show,
  date,
  error,
  paddingType = "hug",
  className,
  ...labelProps
}) => {
  return (
    <InputPickerWrapper
      setShow={setShow}
      show={show}
      inputClassName="cursor-pointer bg-white"
      {...labelProps}
      error={error}
    >
      <span
        className={cx([
          "h-full appearance-none focus:outline-none",
          paddingInputEnum[paddingType],
          className
        ])}
      >
        {date ? (
          format(date, "dd/MM/yyyy")
        ) : (
          <span className="text-[#cdcdcd] text-sm">dd/mm/yyyy</span>
        )}
      </span>
    </InputPickerWrapper>
  );
};

export default Input;
