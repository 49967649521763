import { axios } from "../config/axios";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import { IPagination } from "../interfaces/IPagination";
import { SelectParamsType } from "../interfaces/ISelectParams";
import {
  BundleReservationDetailType,
  BundleReservationEditType,
  BundleReservationListType
} from "../interfaces/bundles/BundleReservations.types";
import { serialize } from "../utils/serializeToQueryString";

export const getPagedBundleReservations = async (
  params: SelectParamsType & { paymentStatus: number }
): Promise<IAxiosResponse<IPagination<BundleReservationListType>>> => {
  return axios.get(
    `/TravelBundle/TravelBundleReservations${serialize(params)}`
  );
};

export const getBundleReservationById = async ({
  id
}: {
  id: string;
}): Promise<IAxiosResponse<BundleReservationDetailType>> => {
  return axios.get(`/TravelBundle/TravelBundleReservation/${id}`);
};

export const editBundleReservation = async (
  data: BundleReservationEditType
): Promise<IAxiosResponse<{}>> => {
  return axios.put("/TravelBundle/TravelBundleReservation", data);
};
