import { axios } from "../config/axios";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import { IPagination } from "../interfaces/IPagination";
import { IOperator, IOperators, ISingleOperator, OperatorsSelectList } from "../interfaces/endpoints/IOperators";
import { serialize } from "../utils/serializeToQueryString";

export type getAllOperatorsType = {
  pageIndex: number;
  pageSize: number;
};

// export type SelectedOperators = Pick<IOperators, "operatorName" | "id">;

export const getAllSelectOperators = async (
  att: getAllOperatorsType
): Promise<IAxiosResponse<IPagination<OperatorsSelectList>>> => {
  return axios.get<IPagination<OperatorsSelectList>>(
    `Operator/Operators${serialize(att)}`
  );
};

export const getAllOperators = async (
  att: getAllOperatorsType
): Promise<IAxiosResponse<IPagination<IOperators>>> => {
  return axios.get<IPagination<IOperators>>(
    `Operator/Operators${serialize(att)}`
  );
};

export const getCurrentOperators = async (
  id: number
): Promise<IAxiosResponse<ISingleOperator>> => {
  return axios.get<ISingleOperator>(`Operator/operator/${id}`);
};

export const createOperators = async (body: IOperator): Promise<number> => {
  return axios.post("Operator", body);
};

export const editOperators = async (body: IOperator): Promise<boolean> => {
  return axios.put("Operator", body);
};

export const deleteOperatorsById = async (
  id: number
): Promise<IAxiosResponse<IOperator>> => {
  return axios.delete(`Operator/${id}`);
};
