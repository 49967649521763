import NotfoundTable from "../../components/ui/NotfoundTable";
import Table from "../../components/ui/table";
import Pagination from "../../components/ui/table/Pagination";
import InvitedUserRow from "../../components/users/InvitedUserRow";
import useInvitedUsers from "../../hooks/useInvitedUsers";

const columns: (string | number)[] = [
  "Email",
  "Role",
  "Operator Name",
  "Data e krijimit",
  "Statusi",
  "Data e skadimit",
  "Veprimet"
];

const InvitedUsersList = () => {
  const { loading, response, onDelete, handlePageChange, page } =
    useInvitedUsers();
  return (
    <div className="flex flex-col" style={{ height: "calc(100vh - 14.1rem)" }}>
      <Table
        titles={columns}
        loading={loading}
        className="overflow-x-auto"
        style="hover"
      >
        {response?.data.result?.length ? (
          response?.data.result?.map((row, i: number) => (
            <InvitedUserRow
              key={`${row.id}_${i}`}
              row={row}
              onDeleteSuccess={onDelete}
            />
          ))
        ) : (
          <></>
        )}
      </Table>
      {!response?.data.result?.length && !loading ? (
        <NotfoundTable
          header="Nuk ka user në list"
          paragraph="Shto user duke klikuar butonin lartë"
        />
      ) : null}
      <Pagination
        onPageChange={handlePageChange}
        totalCount={response?.data?.totalCount || 0}
        length={response?.data?.result.length}
        page={page}
        pageSize={10}
      />

    </div>
  );
};

export default InvitedUsersList;
