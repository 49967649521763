import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import FormWrapper from "../../../components/bundles/FormWrapper";
import BundleReservationDetails from "../../../components/bundles/reservations/BundleReservationDetails";
import BundleReservationInfo from "../../../components/bundles/reservations/BundleReservationInfo";
import { paymentStatus } from "../../../components/bundles/reservations/utils/PaymentsOptions";
import useFetch from "../../../hooks/useFetch";
import { IBundleReservationSchema } from "../../../interfaces/bundles/BundleReservations.types";
import { getBundleReservationById } from "../../../services/BundleReservations.service";
import { BundleReservationSchema } from "../../../utils/validations/BundleReservation.schema";

const BundleReservationForm = () => {
  const methods = useForm<IBundleReservationSchema>({
    resolver: yupResolver(BundleReservationSchema),
    defaultValues: {
      id: 0,
      paymentStatus: { label: "test", value: 1 },
      travelBundleReservationUserInfos: []
    }
  });

  const { id } = useParams();

  const { loading, response, error } = useFetch(getBundleReservationById, {
    initRequest: true,
    params: { id: id || "" }
  });

  useEffect(() => {
    if (response?.data) {
      methods.reset({
        id: response.data.id,
        paymentMethodId: response?.data.paymentMethodId,
        paymentStatus: paymentStatus.find(
          (el) => el.value === response.data.paymentStatus
        ),
        travelBundleReservationUserInfos: [
          ...response.data.travelBundleReservationUserInfos.map((el) => ({
            firstName: el.firstName,
            lastName: el.lastName,
            id: el.id
          }))
        ]
      });
    }
  }, [response]);

  return (
    <>
      <FormWrapper
        showPrompt={
          !methods.formState.isSubmitSuccessful &&
          !!Object.keys(methods.formState.dirtyFields).length
        }
        backTitle="Kthehu tek Rezervimet e ofertave"
        backTo={"/bundles/reservations"}
        backState={["Menaxhimi i ofertave", "Rezervimet e ofertave"]}
        error={!!error}
        mode="edit"
        response={response}
        loading={loading}
        notFoundMessage="Pakoja nuk u gjet"
        title="Modifiko rezervimin"
      >
        {response?.data ? (
          <BundleReservationInfo
            ticketCount={response?.data.ticketCount}
            travelBundleDetail={response?.data.travelBundleDetail}
            travelBundleReservationUserInfos={
              response?.data.travelBundleReservationUserInfos
            }
          />
        ) : null}
        <FormProvider {...methods}>
          <BundleReservationDetails />
        </FormProvider>
      </FormWrapper>
    </>
  );
};

export default BundleReservationForm;
