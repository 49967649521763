import { FC } from "react";

interface ISkeletonTicket {
  loading: boolean;
  length?: number;
}

const SkeletonTicket: FC<ISkeletonTicket> = ({ loading, length = 3 }) => {
  if (!loading) return null;
  return (
    <>
      {Array.from({ length }).map(() => (
        <div className="ticket w-full min-h-[158px] relative duration-500
        transition-all bg-gray-300 animate-pulse group" />
      ))}
    </>
  );
};

export default SkeletonTicket;
