import { useContext } from "react";
import { SideBarContext } from "../../../context/SideBarContext";
import Body from "./Body";

const SideBar: React.FC = () => {
  const { isSideBarOpen, setSideBar } = useContext(SideBarContext);
  return (
    <>
      <div
        className={`border-r border-gray-200 flex flex-col justify-between
         top-16 md:sticky flex-none pt-5 z-20 duration-300
        h-[calc(100%-4rem)] md:h-[calc(100vh-4rem)] transition-all transform-gpu fixed
        bg-white md:bg-transparent shadow md:shadow-none
      ${
        isSideBarOpen
          ? "w-80 translate-x-0 overflow-y-auto"
          : "md:w-20 md:translate-x-0 -translate-x-80"
      }`}
      >
        <Body />
      </div>
      <span
        onClick={() => setSideBar(false)}
        className={`fixed inset-0 bg-black-900 z-10 transition-opacity duration-500 block md:hidden
        ${
          isSideBarOpen
            ? "opacity-25 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        }`}
      />
    </>
  );
};

export default SideBar;
