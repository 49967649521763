import { format } from "date-fns";
import { useEffect, useState } from "react";

const defaultDates = {
  from: format(
    new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000),
    "dd/MM/yyyy"
  ),
  to: format(new Date(), "dd/MM/yyyy")
};

type DatesType = {
  from: string;
  to: string;
};

const useDashboardDatePicker = () => {
  const [dates, setDates] = useState<DatesType>();

  useEffect(() => {
    const storageDashboad = () => {
      const localDates = getLocalDates();
      if (localDates === null) setLocalDates(defaultDates);
      else setDates(localDates);
    };
    window.addEventListener("storage:dashboard", storageDashboad);
    window.dispatchEvent(new Event("storage:dashboard"));
    return () => {
      window.removeEventListener("storage:dashboard", storageDashboad);
    };
  }, []);

  const getLocalDates = () => {
    const localDates = sessionStorage.getItem("dashboard:dates");
    try {
      const mainRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/\d{4}$/;
      const _dates: DatesType = JSON.parse(localDates || "");
      if (mainRegex.test(_dates.from) && mainRegex.test(_dates.to))
        return _dates;
      else return null;
    } catch (error) {
      return null;
    }
  };

  const setLocalDates = (d: DatesType) => {
    sessionStorage.setItem("dashboard:dates", JSON.stringify(d));
    window.dispatchEvent(new Event("storage:dashboard"));
  };

  return { ...dates, setLocalDates };
};

export default useDashboardDatePicker;
