import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  IReservationFormSchemaV2,
  IReservationV2
} from "../../../interfaces/reservations/Reservations.types";
import {
  paymentMethods,
  paymentStatus
} from "../../bundles/reservations/utils/PaymentsOptions";
import { TicketStatusEnum } from "../../reservations/res.enum";
import Input from "../../ui/input/Input";
import Select from "../../ui/select/Select";

const GeneralInfo: FC<{
  initialPaymentStatus: IReservationV2["paymentStatus"];
}> = ({ initialPaymentStatus }) => {
  const { register, control } = useFormContext<IReservationFormSchemaV2>();

  const filteredOptions = paymentStatus.filter((option) => {
    if (
      (initialPaymentStatus === TicketStatusEnum.Confirmed && (option.value === 1 || option.value === 3)) ||
      (initialPaymentStatus === TicketStatusEnum.Canceled && (option.value === 3 || option.value === 4))
    ) {
      return true;
    }
    return false;
  });

  return (
    <div className="border-t border-gray-200 py-6">
      <div className="flex justify-between gap-4 px-5">
        <Controller
          control={control}
          name="paymentMethod"
          render={({ field: { onChange, value } }) => (
            <Select
              label="Mënyra e pageses"
              selectedItem={value}
              options={paymentMethods}
              disabled
              onSelectedItemChange={(item) => onChange(item)}
            />
          )}
        />
        <Input label="Email" className="w-full" {...register("email")} />
        <Controller
          control={control}
          name="paymentStatus"
          render={({ field: { onChange, value } }) => (
            <Select
              label="Statusi"
              selectedItem={value}
              disabled={initialPaymentStatus !== TicketStatusEnum.Confirmed &&
                initialPaymentStatus !== TicketStatusEnum.Canceled}
              options={filteredOptions}
              onSelectedItemChange={(item) => onChange(item)}
            />
          )}
        />
      </div>
    </div>
  );
};

export default GeneralInfo;
