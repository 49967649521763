import { useAuth } from "oidc-react";
import React from "react";
import { Navigate } from "react-router-dom";
import LoadingSection from "../components/auth/LoadingSection";
import { getToken } from "../utils/auth/getToken";
// import { getToken } from "../utils/auth/getToken";

export function withProtected<P>(
  WrappedComponent: React.ComponentType<any & P>,
  isProtected: boolean
) {
  if (isProtected)
    return (props: P) => {
      const auth = useAuth();
      if (auth.isLoading) return <LoadingSection />;
      if (!auth.userData) return <Navigate to={"/login"} replace />;
      return <WrappedComponent {...props} />;
    };
  else
    return (props: P) => {
      const token = getToken();
      if (token?.access_token) return <Navigate to={"/"} replace />;
      return <WrappedComponent {...props} />;
    };
}

export default withProtected;
