import { axios } from "../config/axios";
import { IBlog, IBlogSchema } from "../interfaces/endpoints/IBlogs";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import { IPagination } from "../interfaces/IPagination";
import { serialize } from "../utils/serializeToQueryString";

export type getAllBlogsType = {
  pageIndex: number;
  pageSize: number;
};

export const getAllBlogs = async (
  att: getAllBlogsType
): Promise<IAxiosResponse<IPagination<IBlogSchema>>> => {
  return axios.get<IPagination<IBlogSchema>>(`Blog/Blogs${serialize(att)}`);
};

export const getCurrentBlog = async (
  id: number
): Promise<IAxiosResponse<IBlogSchema>> => {
  return axios.get<IBlogSchema>(`Blog/${id}`);
};

export const postBlog = async (body: IBlog): Promise<number> => {
  return axios.post("Blog", body);
};

export const editBlog = async (body: IBlog, id: number): Promise<number> => {
  return axios.put(`Blog/Blogs?id=${id}`, body);
};

export const deleteBlogById = async (
  id: number
): Promise<IAxiosResponse<IBlog>> => {
  return axios.delete(`Blog/Blogs/${id}`);
};
