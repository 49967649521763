import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { apiFuncType } from "../../interfaces/hooks/IFetch";
import Button from "../ui/Button";
import IconBtn from "../ui/IconBtn";
import Modal from "../ui/Modal";

interface IDeleteModal<T, D> {
  params: D;
  handleDelete: apiFuncType<T, D>;
  title?: string;
  paragraph?: string;
  successMessage?: string;
  errorMessage?: string;
  loadingMessage?: string;
  onSuccess?: (params: D) => void | string;
  onError?: (params: D) => void | string;
  indicator?: React.ReactNode;
  node?: React.ReactNode;
  mini?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const DeleteModal = <T, D extends unknown>({
  handleDelete,
  params,
  title = "Konfirmo fshirjen",
  paragraph = "Ky veprim nuk mund të kthehet mbrapa.",
  successMessage = "Success",
  errorMessage = "Error",
  loadingMessage = "Deleting...",
  indicator = <IconBtn className="w-6 h-6 text-neutral select-none"
  ><TrashIcon className="w-4 h-4 " />
  </IconBtn>,
  onError,
  onSuccess,
  node,
  mini = true
}: IDeleteModal<T, D>) => {
  const [show, setShow] = useState<boolean>(false);

  const onDelete = () => {
    setShow(false);
    void toast.promise(
      handleDelete(params),
      {
        loading: loadingMessage,
        success: () => {
          if (onSuccess) {
            const success = onSuccess(params);
            if (typeof success === "string") return success;
          }
          return successMessage;
        },
        error: () => {
          if (onError) {
            const error = onError(params);
            if (typeof error === "string") return error;
          }
          return errorMessage;
        }
      },
      {
        position: "top-right",
        duration: 1000
      }
    );
  };

  return (
    <>
      <span onClick={() => setShow(true)}>
        {indicator}
      </span>
      <Modal
        position="center"
        show={show}
        onClose={() => setShow(false)}
        mini={mini}
        hasHeader={false}
        calcHeight={false}
        buttonElement={
          <Button onClick={onDelete}>
            Konfirmo
          </Button>
        }
      >
        <div className="flex justify-center mt-6">
          {title}
          <IconBtn
            className="absolute right-2 top-2"
            onClick={() => setShow(false)}
          >
            <XMarkIcon className="w-4 h-4 text-neutral" strokeWidth={2} />
          </IconBtn>
        </div>
        <p className="justify-center w-full flex mt-6 text-gray-600 text-sm">
          {paragraph}
        </p>
        {node ? node : null}
      </Modal>
    </>
  );
};

export default DeleteModal;
