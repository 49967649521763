import { Link } from "react-router-dom";
import NotfoundSvg from "../components/shared/svgs/NotfoundSvg";

const _404 = ({ title }: { title?: string }) => {
  return (
    <div className="container mx-auto flex items-center flex-col mt-16 md:mt-4">
      <NotfoundSvg />
      <div className="my-12">
        <div className="text-center w-full left-0">
          <p className="text-xl mb-6 mt-0">
            {title || "Kjo faqe nuk ka rrugë"}
          </p>
          <Link
            to="/"
            className="bg-gradient-to-bl from-primary to-pink text-white text-sm p-3 rounded-lg shadow-bottom"
          >
            Kthehuni
          </Link>
        </div>
      </div>
    </div>
  );
};

export default _404;
