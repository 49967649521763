/* eslint-disable max-lines */
import { yupResolver } from "@hookform/resolvers/yup";
import { FC, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import FormWrapper from "../../../components/bundles/FormWrapper";
import BundleDetails from "../../../components/bundles/manage/form/BundleDetails";
import Preview from "../../../components/bundles/manage/form/Preview";
import useFetch from "../../../hooks/useFetch";
import {
    IBundleForm,
    IBundleSchema
} from "../../../interfaces/bundles/Bundles.types";
import { getBundleById } from "../../../services/Bundles.service";
import { BundleSchema } from "../../../utils/validations/Bundle.schema";

const BundleForm: FC<IBundleForm> = ({ mode }) => {
  const methods = useForm<IBundleSchema>({
    resolver: yupResolver(BundleSchema),
    shouldFocusError: false,
    defaultValues: {
      packages: [],
      journeys:
        mode === "create"
          ? [
              {
                range: undefined,
                maxReservationCount: undefined,
                travelBundlePrice: undefined
              }
            ]
          : [],
      fromLocation: undefined,
      photos: [],
      locationIncludes: undefined,
      toLocation: undefined,
      locales: [
        { languageId: 1, title: "", description: "" },
        { languageId: 2, title: "", description: "" }
      ]
    }
  });
  const { id } = useParams();
  const { loading, response, error, handleFunc } = useFetch(getBundleById, {
    loading: mode === "edit"
  });
  useEffect(() => {
    if (mode === "edit" && id) {
      const _getBundleById = async () => {
        const res = await handleFunc({ id });
        if (res?.data)
          methods.reset({
            locales:
              res.data.locales?.length === 2
                ? res.data.locales
                : [
                    { languageId: 1, title: "", description: "" },
                    { languageId: 2, title: "", description: "" }
                  ],
            fromLocation: {
              cityId: res.data.fromLocation.id,
              name: res.data.fromLocation.locationName
            },
            toLocation: {
              cityId: res.data.toLocation.id,
              name: res.data.toLocation.locationName
            },
            packages: res.data.travelBundleIncludeMappings.map(
              (el) => el.travelBundleInclude
            ),
            locationIncludes: res.data.travelBundleLocationMappings.map(
              (el) => ({
                id: el.location?.id,
                locationName: el.location?.locationName
              })
            ),
            journeys: res.data.travelBundleDetails.map((el) => ({
              ...el,
              range: {
                from: new Date(el.fromDate),
                to: new Date(el.toDate)
              }
            })),
            photos: res.data.travelBundlePictureMappings.map((el, index) => ({
              index,
              completed: true,
              dataURL: el.pictureUrl,
              displayOrder: el.displayOrder,
              error: false,
              isCoverPicture: el.isCoverPicture,
              pending: false,
              picture: el.picture,
              pictureId: el.picture?.id,
              state: "finish"
            }))
          });
      };
      void _getBundleById();
    }
  }, []);

  return (
    <FormProvider {...methods}>
      <FormWrapper
        showPrompt={
          !methods.formState.isSubmitSuccessful &&
          !!Object.keys(methods.formState.dirtyFields).length
        }
        backTitle="Kthehu tek ofertat"
        backTo={"/bundles/manage"}
        backState={["Menaxhimi i ofertave", "Ofertat e udhëtimeve"]}
        error={!!error}
        mode={mode}
        response={response}
        loading={loading}
        notFoundMessage="Pakoja nuk u gjet"
        title={
          mode === "create"
            ? "Oferte e Re"
            : methods.watch("locales")[0].title || "Edit oferten"
        }
        rightHeaderContent={<Preview />}
      >
        <BundleDetails mode={mode} />
      </FormWrapper>
    </FormProvider>
  );
};
export default BundleForm;
