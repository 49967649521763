import { array, object, string } from "yup";

export const BundleReservationSchema = object().shape({
  // name: string().required("Duhet te kete nje emer"),
  svgUrl: string().required("Duhet te kete nje imazh URL"),
  locales: array().of(
    object().shape({
      name: string()
        .required("Duhet te kete nje emer")
        .typeError("Vendos titullin!")
    })
  )
});
