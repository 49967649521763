import React from "react";
import { cx } from "../../../utils/classnames";
import Body from "./Body";
import Head from "./Head";
import { RowsProps, THeadProps } from "./interfaces";
export * from "./Row";
export * from "./Td";
interface TableProps extends RowsProps, THeadProps {
  className?: string;
  loading?: boolean;
  rounded?: string | false;
}

const Table: React.FC<TableProps> = ({
  titles,
  children,
  className,
  style,
  rounded = "rounded-t-md",
  thClassName
}) => {
  // const isTouch = checkDevice();
  return (
    <div className={cx(["w-full overflow-auto border tableDiv", rounded])}>
      <table
        className={`${className} overflow-auto whitespace-nowrap p-0 relative w-full
      rounded-t-md `}
      >
        {/* <span className={Styles.loading}>
        <span /> */}
        {/* </span> */}
        <Head titles={titles} thClassName={thClassName} />
        <Body style={style && style}>{children}</Body>
      </table>
    </div>
  );
};

export default React.memo(Table);
