/* eslint-disable max-lines */
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import UploadBlogPhoto from "../../components/blogs/create/BlogImage";
import MultipleLangFields from "../../components/blogs/create/MultipleLangFields";
import FormWrapper from "../../components/ui/FormWrapper";
import useFetch from "../../hooks/useFetch";
import useNavigateState from "../../hooks/useNavigateState";
import { IBlog } from "../../interfaces/endpoints/IBlogs";
import {
  editBlog,
  getCurrentBlog,
  postBlog
} from "../../services/Blogs.service";
import { BlogSchema } from "../../utils/validations/Blog.schema";

interface AddBlogProps {
  mode: "create" | "edit";
}

const AddBlog: React.FC<AddBlogProps> = ({ mode }) => {
  const { id } = useParams();
  const stateCrumbs = useNavigateState(["Blogu i udhëtimeve"]);
  const { state }: any = useLocation();
  const methods = useForm<IBlog>({
    resolver: yupResolver(BlogSchema),
    defaultValues: {
      title: "",
      description: "",
      blogPictureMappings: [],
      locales: [
        { languageId: 1, title: "", description: "" },
        { languageId: 2, title: "", description: "" }
      ]
    }
  });

  const { handleSubmit } = methods;
  const navigate = useNavigate();

  const { response } = useFetch(getCurrentBlog, {
    initRequest: mode === "edit",
    params: Number(id)
  });

  useEffect(() => {
    if (mode === "edit" && response && response.data) {
      const { data } = response;
      response.data.blogPictureMappings.map((p) => p.pictureUrl);
      methods.reset({
        id: data.id,
        // slug: data.slug,
        title: data.locales[1].title,
        description: data.locales[1].description,
        inserDate: data.inserDate,
        blogPictureMappings: data.blogPictureMappings.map((el) => ({
          id: el.id,
          pictureId: el.picture?.id,
          isCoverPicture: el.isCoverPicture,
          picture: el.picture
        })),
        locales:
          data.locales?.length === 2
            ? data.locales
            : [
                { languageId: 1, title: "", description: "" },
                { languageId: 2, title: "", description: "" }
              ]
      });
    }
  }, [response, id]);

  const onSubmit: SubmitHandler<IBlog> = async (data) => {
    if (mode === "edit") {
      await editBlog(
        {
          ...data,
          title: data.locales[1].title,
          description: data.locales[1].description
        },
        Number(id)
      )
        .then(() => {
          toast.success("Artikulli është ndryshuar me sukses.", {
            position: "top-right"
          });
          setTimeout(() => {
            navigate(`/blogs?pageindex=${state?.page ? state?.page : 1}`, {
              state: stateCrumbs
            });
          }, 500);
        })
        .catch(() => toast.error("Ndodhi një gabim gjatë ndryshimit."));
    } else {
      await postBlog({
        ...data,
        title: data.locales[1].title,
        description: data.locales[1].description
      })
        .then(() => {
          toast.success("Artikulli është publikuar me sukses.", {
            position: "top-right"
          });
          setTimeout(() => {
            navigate("/blogs", { state: ["Blogu i udhëtimeve"] });
          }, 500);
        })
        .catch(() =>
          toast.error("Ndodhi një gabim gjatë krijimit.", {
            position: "top-right"
          })
        );
    }
  };

  return (
    <FormProvider {...methods}>
      <Link
        className="flex items-center mb-4 text-sm font-semibold text-primary"
        to={"/blogs"}
        state={["Blogu"]}
      >
        <ChevronLeftIcon className="w-4 h-4 mr-2" />
        <span>Kthehu tek lista</span>
      </Link>
      <FormWrapper
        showPrompt={
          !methods.formState.isSubmitSuccessful &&
          !!Object.keys(methods.formState.dirtyFields).length
        }
        onSubmit={handleSubmit(onSubmit)}
        title={mode === "edit" ? "Modifiko blogun" : "Shto blog të ri"}
      >
        <UploadBlogPhoto mode={mode} />
        {/* <BlogTextEditor /> */}
        <MultipleLangFields />
      </FormWrapper>
    </FormProvider>
  );
};

export default AddBlog;
