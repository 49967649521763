/* eslint-disable max-len */
import { FC } from "react";
import { IIcon } from "./IIcon";

const AccountProfileUsersIcon: FC<IIcon> = ({ color, className, size }) => {
  const currentColor = color || "currentColor";
  const _size = size || 24;
  return (
    <span className={className}>
      <svg
        width={_size}
        height={_size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="account-profile-users-group">
          <g id="Union">
            <path
              d="M15.5175 4.91404C14.6409 3.92152 13.1257 3.82753 12.1332 4.7041C11.8228 4.9783 11.3488 4.94889 11.0746 4.63843C10.8004 4.32796 10.8298 3.854 11.1403 3.5798C12.7537 2.15484 15.2168 2.30764 16.6418 3.92108C18.0668 5.53453 17.914 7.99765 16.3005 9.42261C15.9901 9.69681 15.5161 9.66741 15.2419 9.35694C14.9677 9.04647 14.9971 8.57251 15.3076 8.29832C16.3001 7.42175 16.3941 5.90655 15.5175 4.91404Z"
              fill={currentColor}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.6367 10.0583C13.6367 12.2674 11.8458 14.0583 9.6367 14.0583C7.42757 14.0583 5.6367 12.2674 5.6367 10.0583C5.6367 7.84913 7.42757 6.05827 9.6367 6.05827C11.8458 6.05827 13.6367 7.84913 13.6367 10.0583ZM12.1367 10.0583C12.1367 11.439 11.0174 12.5583 9.6367 12.5583C8.25599 12.5583 7.1367 11.439 7.1367 10.0583C7.1367 8.67756 8.25599 7.55827 9.6367 7.55827C11.0174 7.55827 12.1367 8.67756 12.1367 10.0583Z"
              fill={currentColor}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.6367 15.3083C6.88675 15.3083 4.98428 16.2626 3.74563 17.4916C3.31011 17.9237 3.05567 18.4353 3.00811 18.9798C2.961 19.5192 3.12255 20.0257 3.40332 20.4447C3.95652 21.2705 4.99583 21.8083 6.13945 21.8083H13.134C14.2776 21.8083 15.3169 21.2705 15.8701 20.4447C16.1509 20.0257 16.3124 19.5192 16.2653 18.9798C16.2177 18.4353 15.9633 17.9237 15.5278 17.4916C14.2891 16.2626 12.3867 15.3083 9.6367 15.3083ZM4.8021 18.5564C5.74772 17.6182 7.26518 16.8083 9.6367 16.8083C12.0082 16.8083 13.5257 17.6182 14.4713 18.5564C14.6891 18.7725 14.7582 18.9643 14.771 19.1103C14.7842 19.2614 14.7428 19.4324 14.6239 19.6098C14.3778 19.9772 13.831 20.3083 13.134 20.3083H6.13945C5.4424 20.3083 4.89558 19.9772 4.64949 19.6098C4.53062 19.4324 4.48923 19.2614 4.50242 19.1103C4.51517 18.9643 4.58429 18.7725 4.8021 18.5564Z"
              fill={currentColor}
            />
            <path
              d="M15.2428 11.3161C14.8328 11.2575 14.4529 11.5424 14.3943 11.9525C14.3357 12.3625 14.6206 12.7424 15.0307 12.801C16.6295 13.0294 18.0576 13.6726 19.1149 14.444C19.3802 14.6376 19.4683 14.808 19.4952 14.911C19.5209 15.0089 19.5102 15.1143 19.4423 15.2331C19.2951 15.4904 18.8535 15.809 18.134 15.809H17.1367C16.7225 15.809 16.3867 16.1448 16.3867 16.559C16.3867 16.9732 16.7225 17.309 17.1367 17.309H18.134C19.2552 17.309 20.2685 16.8097 20.7444 15.9779C20.988 15.552 21.0809 15.045 20.9463 14.5311C20.8131 14.0222 20.4772 13.5812 19.9991 13.2323C18.7554 12.3249 17.0987 11.5812 15.2428 11.3161Z"
              fill={currentColor}
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

export default AccountProfileUsersIcon;
