import { ReactElement, ReactNode, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { SideBarContext } from "../../../context/SideBarContext";
import checkDevice from "../../../utils/isTouch";
import Animation from "../../ui/Animation";
import Styles from "./SideBar.module.css";
import Text from "./Text";
export interface SingleItem {
  className?: string;
  href?: string;
  name: string;
  icon?: ReactNode;
  widget?: ReactNode;
  crumbs: string[];
}

interface ItemProps extends SingleItem {
  children?: ReactElement<SingleItem>[] | ReactElement<SingleItem>;
}

const Item: React.FC<ItemProps> = ({ children, className, href, name, icon, crumbs, widget }) => {
  const [showChildren, setShowChildren] = useState<boolean>(false);
  const isTouch = checkDevice();
  const { isSideBarOpen, setSideBar } = useContext(SideBarContext);

  const closeMobileSideBar = () => {
    if (isTouch) setSideBar(false);
  };
  return (
    <>
      {children ?
        <li className={`${className}`}>
          <div onClick={() => setShowChildren(!showChildren)} >
            <Text name={name} widget={widget} icon={icon} withArrow />
          </div>
          <Animation show={showChildren && isSideBarOpen} type="height">
            <ul className="pl-10">
              {children}
            </ul>
          </Animation>
        </li>
        :
        <li onClick={closeMobileSideBar} >
          <NavLink
            state={crumbs}
            className={(navData) => (navData.isActive ?
              `text-primary relative ${Styles.activeItem} flex ` :
              "text-gray-700 relative group")}
            to={href || ""}
          >
            <Text name={name} widget={widget} icon={icon} />
          </NavLink>
        </li>
      }
    </>
  );
};

export default Item;
