import { createContext } from "react";
import useFetch from "../hooks/useFetch";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import {
  BundleOrderPut,
  BundlesOrderListType
} from "../interfaces/bundles/BundlesOrder.types";
import {
  errorType,
  loadingType,
  responseType
} from "../interfaces/hooks/IFetch";
import { getPagedBundlesOrder } from "../services/BundlesOrder.service";

export type BundlesOrderContextType = {
  response: responseType<IAxiosResponse<BundlesOrderListType[]>>;
  loading: loadingType;
  error: errorType;
  onDelete: (id: number) => void;
  onCreate: (data: BundlesOrderListType) => void;
  onEdit: (data: BundleOrderPut) => void;
};

interface IBundlesOrderProvider {
  children: React.ReactNode;
}

export const BundlesOrderContext = createContext<BundlesOrderContextType>({
  response: {} as responseType<IAxiosResponse<BundlesOrderListType[]>>,
  loading: false,
  error: {} as errorType,
  onDelete: () => undefined,
  onCreate: () => undefined,
  onEdit: () => undefined
});

export const BundlesOrderProvider: React.FC<IBundlesOrderProvider> = ({
  children
}) => {
  const { response, loading, error, setResponse } = useFetch(
    getPagedBundlesOrder,
    {
      initRequest: true
    }
  );

  const sortByDisplayOrder = (data?: BundlesOrderListType[]) => {
    const _data = data || [];
    _data.sort((a, b) => {
      if (a.displayOrder < b.displayOrder) return -1;
      if (a.displayOrder > b.displayOrder) return 1;
      return 0;
    });
    return _data;
  };

  const onDelete = (id: number) => {
    setResponse((prev) =>
      prev
        ? {
          ...prev,
          data: prev.data.filter((el) => el.id !== id)
        }
        : null
    );
  };

  const onEdit = (data: BundleOrderPut) => {
    setResponse((prev) => {
      const _data = prev?.data.map((el) =>
        el.id === data.id ? { ...el, displayOrder: data.displayOrder } : el
      );
      return prev
        ? {
          ...prev,
          data: sortByDisplayOrder(_data)
        }
        : null;
    });
  };

  const onCreate = (data: BundlesOrderListType) =>
    setResponse((prev) => {
      const _data = [...(prev?.data || []), data];
      return prev
        ? {
          ...prev,
          data: sortByDisplayOrder(_data)
        }
        : null;
    });

  return (
    <BundlesOrderContext.Provider
      value={{ response, loading, error, onDelete, onCreate, onEdit }}
    >
      {children}
    </BundlesOrderContext.Provider>
  );
};
