/* eslint-disable max-lines */
import { ChevronLeftIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
// import { useEffect, useState } from "react";
import { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm
} from "react-hook-form";
import toast from "react-hot-toast";
import "react-quill/dist/quill.snow.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Gmaps from "../../../components/Gmaps";
import CreateCity from "../../../components/locations/create/CreateCity";
import CreateStation from "../../../components/locations/create/CreateStation";
import MultpileLangFields from "../../../components/locations/create/MultipleLangFieldsCountry";
import { stationRank } from "../../../components/locations/create/Stations";
import Button from "../../../components/ui/Button";
import FormWrapper from "../../../components/ui/FormWrapper";
import Modal from "../../../components/ui/Modal";
import Select from "../../../components/ui/select/Select";
import useCan from "../../../components/users/permissions/useCan";
import useFetch from "../../../hooks/useFetch";
import { ICountry } from "../../../interfaces/endpoints/ILocations";
import {
  ISingleCity,
  IStations
} from "../../../interfaces/endpoints/IStations";
import {
  createStation,
  editStation,
  getAllCountries,
  getCitiesTemp,
  getCurrentLocation,
  getCurrentStation
} from "../../../services/Locations.service";
import { LocationsSchema } from "../../../utils/validations/Locations.schema";

const Locations: React.FC<{ editMode?: boolean }> = ({ editMode }) => {
  const { id } = useParams();
  const methods = useForm<IStations>({
    resolver: yupResolver(LocationsSchema),
    defaultValues: {
      city: {
        locales: [
          { languageId: 1, name: "" },
          { languageId: 2, name: "" }
        ]
      },
      cityId: {},
      country: {
        locales: [
          { languageId: 1, name: "" },
          { languageId: 2, name: "" }
        ]
      },
      countryId: {},
      station: {
        locales: [
          { languageId: 1, name: "" },
          { languageId: 2, name: "" }
        ]
      }
    }
  });
  const [showSelect, setShowSelect] = useState(true);
  const [showCity, setShowCity] = useState(true);
  const toggleSelectInput = () => setShowSelect(!showSelect);
  const toggleCityInput = () => setShowCity(!showCity);
  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors }
  } = methods;
  const navigate = useNavigate();
  const { response, loading } = useFetch(getCurrentStation, {
    initRequest: editMode,
    params: Number(id)
  });

  useEffect(() => {
    if (editMode && response && response.data && !loading) {
      getCurrentLocation(response.data.locationId)
        .then((city) => {
          const cityData: ISingleCity = {
            id: response.data.locationId,
            locationName: city.data.locationName,
            postalCode: city.data.postalCode,
            defaultStationRank: city.data.defaultStationRank,
            locationCoordinate: city.data.locationCoordinate,
            locales: city.data.locales
          };
          methods.reset({
            city: city.data,
            cityId: cityData,
            country: {
              locales: [
                { languageId: 1, name: "" },
                { languageId: 2, name: "" }
              ]
            },
            countryId: {},
            station: {
              id: response.data.id,
              bizSlug: response.data?.bizSlug || "",
              isDefault: response.data.isDefault,
              locales: response.data.locales,
              latitude: response.data.stationCoordinate.latitude,
              longitude: response.data.stationCoordinate.longitude,
              placeId: response.data.stationCoordinate.placeId,
              stationName: response.data.stationName,
              stationRank: Object.values(stationRank).filter(
                (x) => x.value === response.data.stationRank
              )[0],
              stationType: response.data.stationType
            }
          });

          setValue("cityId", cityData);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [response]);

  const onSubmit: SubmitHandler<IStations> = async (data) => {
    if (editMode) {
      await editStation({
        id: data.station.id,
        locationId: data.cityId.id,
        bizSlug: data.station?.bizSlug || "",
        isDefault: data.station.isDefault,
        locales: data.station.locales,
        stationCoordinate: {
          latitude: data.station.latitude,
        longitude: data.station.longitude,
        placeId: data.station.placeId
        },
        stationName: data.station.stationName,
        stationRank: data.station.stationRank.value,
        stationType: data.station.stationType
      })
        .then(() => {
          toast.success("Stacioni është ndryshuar me sukses.", {
            position: "top-right"
          });
          setTimeout(() => {
            navigate("/locations", { state: ["Stacionet"] });
          }, 500);
        })
        .catch(() =>
          toast.error("Ndodhi një gabim gjatë ndryshimit.", {
            position: "top-right"
          })
        );
    } else {
      {
      await createStation({
        ...data,
        city: {
          cityName: data.city?.locales?.[1].name,
          postalCode: data.city?.postalCode,
          longitude: data.city?.locationCoordinate?.longitude,
          latitude: data.city?.locationCoordinate?.latitude,
          locales: data.city?.locales
        },
        cityId: data.cityId.id || null,
        country: {
          countryName: data.country?.locales?.[1].name,
          locales: data.country?.locales
        },
        countryId: data.countryId.id || null,
        station: {
          stationRank: data.station.stationRank.value,
          stationName: data.station?.locales?.[1].name,
          bizSlug: data.station.bizSlug || "",
          placeId: data.station.placeId || "",
          isDefault: data.station.isDefault,
          stationType: data.station.stationType,
          latitude: data.station?.latitude,
          longitude: data.station?.longitude,
          locales: data.station?.locales
        }
      })
        .then(() => {
          toast.success("Lokacioni është shtuar me sukses.", {
            position: "top-right"
          });
          setTimeout(() => {
            navigate("/locations", { state: ["Stacionet"] });
          }, 500);
        })
        .catch(() =>
          toast.error("Ndodhi një gabim gjatë krijimit.", {
            position: "top-right"
          })
        );
      }
    }
  };

  const canCountry = useCan("locations.country.create");
  const canCity = useCan("locations.city.create");

  return (
    <FormProvider {...methods}>
      <Link
        className="flex items-center mb-4 text-sm font-semibold text-primary"
        to={"/locations"}
        state={["Stacionet"]}
      >
        <ChevronLeftIcon className="w-4 h-4 mr-2" />
        <span>Kthehu tek lista</span>
      </Link>
      <FormWrapper
        showPrompt={
          !methods.formState.isSubmitSuccessful &&
          !!Object.keys(methods.formState.dirtyFields).length
        }
        onSubmit={handleSubmit(onSubmit)}
        title={`${editMode ? "Edito stacionin" : "Shto stacion të ri"}`}
        className="mx-0 max-w-full"
      >
        <div className="flex gap-10 mx-5">
          <div className="w-1/2">
            <Modal
              position="side"
              modalWidth="400px"
              title="Shto qytet"
              show={!showCity}
              onClose={() => setShowCity(true)}
            >
              <div className="h-full flex flex-col justify-between">
                <div className="p-5">
                  <CreateCity />
                </div>
                <div className="p-5">
                  <Button onClick={() => setShowCity(true)} className="w-full">
                    Konfirmo
                  </Button>
                </div>
              </div>
            </Modal>
            {!editMode && (
              <div className="flex flex-row mb-6 relative z-20">
                {showSelect ? (
                  <div className="relative w-full">
                    <Controller
                      control={control}
                      name="countryId"
                      rules={{ required: "Vendos shtetin!" }}
                      render={({ field: { value, onChange } }) => (
                        <Select
                          className="transition"
                          label="Shteti*"
                          placeholder="Zgjedh shtetit e vendndodhjes"
                          onSelectedItemChange={onChange}
                          selectedItem={value}
                          getOptionLabel={(o) => o.countryName}
                          itemToString={(o) => o?.countryName || ""}
                          asyncFunc={getAllCountries}
                          error={errors?.countryId?.id?.message}
                        />
                      )}
                    />
                  </div>
                ) : (
                  <div className="w-full transition-all duration-300 origin-center">
                    <MultpileLangFields />
                  </div>
                )}
                {canCountry && (
                  <button
                    type="button"
                    onClick={() => {
                      toggleSelectInput();
                      setValue("countryId", {} as ICountry);
                    }}
                    className="flex flex-none items-center h-[42px] ml-4 mt-6"
                  >
                    <div
                      className="flex items-center font-semibold cursor-pointer
                     hover:bg-gray-100 p-2 rounded-md transition-colors"
                    >
                      <PlusCircleIcon className="w-6 h-6 text-primary mr-2" />
                      <span>Shto shtet te ri</span>
                    </div>
                  </button>
                )}
              </div>
            )}
            <div className="flex flex-row mb-6 relative z-10">
              <div className="relative w-full h-16">
                <Controller
                  control={control}
                  name="cityId"
                  rules={{
                    required: "Vendos qytetin e vendndodhjes së operatorit!"
                  }}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      className="absolute transition"
                      label="Qyteti*"
                      placeholder="Zgjedh qytetin e vendndodhjes"
                      onSelectedItemChange={onChange}
                      selectedItem={value}
                      getOptionLabel={(o) => o.locationName}
                      itemToString={(o) => o?.locationName || ""}
                      disabled={!editMode && !watch("countryId").id}
                      extraParams={{
                        countryId: watch("countryId").id
                      }}
                      asyncFunc={getCitiesTemp}
                      error={errors?.cityId?.id?.message}
                    />
                  )}
                />
              </div>
              {canCity && !editMode && (
                <button
                  type="button"
                  onClick={() => {
                    toggleCityInput();
                    setValue("cityId", {} as ISingleCity);
                  }}
                  className="flex-none ml-5 pt-5"
                >
                  <div
                    className="flex items-center font-semibold cursor-pointer
                   hover:bg-gray-100 p-2 rounded-md transition-colors"
                  >
                    <PlusCircleIcon className="w-6 h-6 text-primary mr-2" />
                    <span>Shto qytet te ri</span>
                  </div>
                </button>
              )}
            </div>
            <CreateStation />
          </div>
          <div className="w-1/2">
            <Gmaps
              names={{ lat: "station.latitude", lng: "station.longitude" }}
              coordinates={{
                lat: editMode ? watch("station.latitude")
                : watch("cityId").locationCoordinate?.latitude,
                lng: editMode ? watch("station.longitude")
                 : watch("cityId").locationCoordinate?.longitude
              }}
              className="max-w-full mx-0"
            />
          </div>
        </div>
      </FormWrapper>
    </FormProvider>
  );
};

export default Locations;
