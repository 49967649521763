import { FC } from "react";
import useWindowSize from "../../../../hooks/useWindowResize";
import Animation from "../../Animation";
import ClickAwayListener from "../../Clickaway";
import Modal from "../../Modal";
import { IRangeWrapper } from "../Datepicker.types";
import Inputs from "./Inputs";

const RangeWrapper: FC<IRangeWrapper> = ({
  children,
  show,
  setShow,
  wrapperClassName,
  inputRange,
  onChangeRange,
  range,
  setInputRange,
  paddingType,
  onlyModal,
  disabled,
  ...labelProps
}) => {
  const size = useWindowSize();

  if (size["2xl"] && !onlyModal)
    return (
      <ClickAwayListener onClickAway={() => setShow(false)}>
        <div className={wrapperClassName}>
          <Inputs
            onChangeRange={onChangeRange}
            range={range}
            inputRange={inputRange}
            setInputRange={setInputRange}
            setShow={setShow}
            show={show}
            paddingType={paddingType}
            disabled={disabled}
            {...labelProps}
          />
          <Animation
            show={show}
            type="fade-top"
            // defaultHeight={420}
            // leaveInDom
          >
            <div className="xl:z-50 xl:absolute xl:right-0 xl:top-[calc(100%+0.5rem)]
             xl:bg-white xl:shadow-calendar  xl:rounded-lg">
              {children}
            </div>
          </Animation>
        </div>
      </ClickAwayListener>
    );
  else
    return (
      <div className={wrapperClassName}>
        <Inputs
          onChangeRange={onChangeRange}
          range={range}
          inputRange={inputRange}
          setInputRange={setInputRange}
          setShow={setShow}
          show={show}
          paddingType={paddingType}
          onlyModal={onlyModal}
          disabled={disabled}
          {...labelProps}
        />
        <Modal
          position="top"
          show={show}
          onClose={() => setShow(false)}
          modalWidthTouch=""
          hasHeader={false}
        >
          <div>{children}</div>
        </Modal>
      </div>
    );
};

export default RangeWrapper;
