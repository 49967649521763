import dayjs from "dayjs";
import { useFormContext } from "react-hook-form";
import { IBundleSchema } from "../../../../interfaces/bundles/Bundles.types";

const PreviewDetails = () => {
  const { watch } = useFormContext<IBundleSchema>();
  return (
    <div className="mx-6 mt-7 lg:w-[800px] md:w-[600px] w-[300px]  max-h-[600px]">
      {watch("photos").length && watch("photos")[0]?.dataURL ? (
        <img
          src={watch("photos")[0]?.dataURL}
          className="w-full h-[348px] bg-gray rounded-lg object-cover"
          width={752}
          height={348}
        />
      ) : (
        <div className="w-full h-[348px] bg-gray-200 rounded-lg" />
      )}
      <div className="flex lg:flex-row-reverse flex-col mt-6 gap-12 ">
        <div className="border border-gray-200 rounded-lg p-4 h-max">
          <p className="text-neutral text-sm">Çmimi (për person)</p>
          <p className="flex items-center text-primary text-3xl font-bold mt-2 mb-6">
            <span
              className="inline-flex empty:w-11 empty:h-7 empty:bg-gray-200 empty:rounded-lg">
              {watch("journeys")[0]?.travelBundlePrice}
            </span>
            <span className="ml-0.5">€</span>
          </p>
          <p className="text-neutral text-sm mb-1.5">Datat e udhëtimit</p>
          <div className="flex items-center bg-gray-50 rounded-lg py-4 px-3 empty:h-14 empty:bg-gray-200">
            {watch("journeys")[0]?.range?.from ? (
              <p className="text-sm mr-1">
                {dayjs(watch("journeys")[0]?.range?.from).format(
                  "DD MMMM YYYY"
                )}
              </p>
            ) : null}
            {watch("journeys")[0]?.range?.from ||
              watch("journeys")[0]?.range?.to
              ? "-"
              : ""}
            {watch("journeys")[0]?.range?.to ? (
              <p className="text-sm ml-1">
                {dayjs(watch("journeys")[0]?.range?.to).format("DD MMMM YYYY")}
              </p>
            ) : null}
          </div>
          <div
            className="bg-primary rounded-lg text-sm text-white py-3 mt-6 lg:px-[103px] shadow-btn flex
              justify-center items-center"
          >
            Rezervo Pakon
          </div>
        </div>
        <div className="flex-1">
          <h1
            className="empty:h-9 text-2xl font-semibold empty:bg-gray-200 empty:rounded-lg
              "
          >
            {watch("locales")[0].title}
          </h1>
          <p className="text-lg text-neutral mt-2 mb-3">Emri i Operatorit</p>
          <div className="bg-gray-50 rounded-lg p-4 flex justify-between items-center">
            <div className="">
              <p className="text-lg flex items-center">
                Nisja:{" "}
                <span
                  className="ml-1 inline-flex empty:bg-gray-200 empty:h-5 empty:w-20
                     empty:rounded-md"
                >
                  {watch("fromLocation")?.name.split(",").length > 1
                    ? watch("fromLocation")?.name.split(",")[1]
                    : watch("fromLocation")?.name}
                </span>
              </p>
              <p className="text-lg flex items-center">
                Arritja:{" "}
                <span
                  className="ml-1 inline-flex empty:bg-gray-200 empty:h-5 empty:w-20
                     empty:rounded-md"
                >
                  {watch("toLocation")?.name.split(",").length > 1
                    ? watch("toLocation")?.name.split(",")[1]
                    : watch("toLocation")?.name}
                </span>
              </p>
            </div>
            <div className="bg-white px-6 py-2 text-sm rounded-lg border border-gray-300">
              Ndalesat
            </div>
          </div>
          <div className="mt-4">
            <p className="text-lg font-medium mb-3">Agjenda:</p>
            <p
              className="whitespace-pre-line pb-4 border-b border-gray-200 empty:bg-gray-200 empty:h-24
            empty:w-full empty:rounded-md"
            >
              {watch("locales")[0].description}
            </p>
          </div>
          <div className="my-4">
            <p className="text-lg font-medium mb-3">Pakoja përfshin:</p>
            <div className="flex gap-1.5 flex-wrap">
              {watch("packages").length ? (
                watch("packages").map((el) => (
                  <div className="text-sm py-2 px-3 border rounded-full">
                    {el.name}
                  </div>
                ))
              ) : (
                <>
                  <div className="text-sm border rounded-full w-28 h-[34px] bg-gray-200" />
                  <div className="text-sm border rounded-full w-36 h-[34px] bg-gray-200" />
                  <div className="text-sm border rounded-full w-32 h-[34px] bg-gray-200" />
                  <div className="text-sm border rounded-full w-40 h-[34px] bg-gray-200" />
                  <div className="text-sm border rounded-full w-28 h-[34px] bg-gray-200" />
                  <div className="text-sm border rounded-full w-20 h-[34px] bg-gray-200" />
                  <div className="text-sm border rounded-full w-44 h-[34px] bg-gray-200" />
                  <div className="text-sm border rounded-full w-36 h-[34px] bg-gray-200" />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PreviewDetails;
