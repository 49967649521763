import { PencilIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { FC } from "react";
import { Link } from "react-router-dom";
import usePageIndex from "../../../hooks/usePageIndex";
import { BundleReservationListType } from "../../../interfaces/bundles/BundleReservations.types";
import IconBtn from "../../ui/IconBtn";
import Status from "../../ui/Status";
import { Row } from "../../ui/table";
import Can from "../../users/permissions/Can";

interface IBundleReservationRow {
  row: BundleReservationListType;
}

const BundleReservationRow: FC<IBundleReservationRow> = ({ row }) => {
  const { pageIndex, state }: any = usePageIndex();
  const highlighted = +state?.params?.id === row.id;
  return (
    <Row
      className={`${highlighted && "highlighted"} group`}
      name={
        <>
          {row.firstName} {row.lastName}
        </>
      }
      email={row.email}
      ticketCount={row.ticketCount}
      travelBundleTitle={row.travelBundleTitle}
      travelBundlePrice={<>{row.totalPrice}€</>}
      reservationDate={dayjs.utc(row.reservationDate).tz().format("DD/MM/YY HH:MM")}
      bundleStatus={
        <>
          {row.paymentStatus === 1 ? (
            <Status name="I konfirmuar" type="green" />
          ) : null}
          {row.paymentStatus === 2 ? (
            <Status name="I pa përfunduar" type="orange" />
          ) : null}
          {row.paymentStatus === 3 ? (
            <Status name="I anuluar" type="red" />
          ) : null}
          {row.paymentStatus === 4 ? (
            <Status name="I rimbursuar" type="blue" />
          ) : null}
        </>
      }
      actions={
        <span className="flex items-center">
          <Can permissions="travelbundles.travelbundle.reservation.update">
            <Link
              to={`/bundles/reservations/edit/${row.id}`}
              state={{
                crumbs: [
                  "Menaxhimi i ofertave",
                  "Rezervimet e ofertave",
                  `${row.firstName} ${row.lastName}`
                ],
                page: pageIndex
              }}
            >
              <IconBtn className="w-6 h-6">
                <PencilIcon className="w-4 h-4 text-neutral" />
              </IconBtn>
            </Link>
          </Can>
        </span>
      }
    />
  );
};

export default BundleReservationRow;
