/* eslint-disable max-lines */
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import FormWrapper from "../../components/ui/FormWrapper";
import Input from "../../components/ui/input/Input";
import Select from "../../components/ui/input/Select";
import Textarea from "../../components/ui/input/Textarea";
import useFetch from "../../hooks/useFetch";
import useNavigateState from "../../hooks/useNavigateState";
import {
  IPromotionCode,
  PromotionCodeTypeEnum
} from "../../interfaces/endpoints/IPromotionCodes";
import {
  editPromotionCode,
  getCurrentPromotionCode,
  postPromotionCode
} from "../../services/PromoCodes.service";
import PromotionCodesSchema from "../../utils/validations/PromotionCodes.schema";
import PromotionCodeInput from "./PromotionCodeInput";

const AddPromotionCode: React.FC<{ editMode?: boolean }> = ({ editMode }) => {
  const { id } = useParams();
  const stateCrumbs = useNavigateState(["Kodet Promocionale"]);
  const { state }: any = useLocation();

  const methods = useForm<IPromotionCode>({
    resolver: yupResolver(PromotionCodesSchema)
  });
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = methods;
  const navigate = useNavigate();

  const { response } = useFetch(getCurrentPromotionCode, {
    initRequest: editMode,
    params: Number(id)
  });

  useEffect(() => {
    if (editMode && response && response.data) {
      response.data.expirationDate = String(
        dayjs(response?.data.expirationDate).format("YYYY-MM-DD")
      );
      Object.entries(response.data).forEach(([key, value]) => {
        const promotionCodeKey = key as keyof IPromotionCode;
        setValue(promotionCodeKey, value);
      });
    }
  }, [response, id]);

  const onSubmit: SubmitHandler<IPromotionCode> = async (data) => {
    if (editMode) {
      await editPromotionCode(data, Number(id))
        .then(() => {
          toast.success("Kodi është ndryshuar me sukses.", {
            position: "top-right"
          });
          setTimeout(() => {
            navigate(
              `/promotioncodes?pageindex=${state?.page ? state?.page : 1}`,
              {
                state: stateCrumbs
              }
            );
          }, 500);
        })
        .catch(() => toast.error("Ndodhi një gabim gjatë ndryshimit."));
    } else {
      await postPromotionCode(data)
        .then(() => {
          toast.success("Kodi është krijuar me sukses.", {
            position: "top-right"
          });
          setTimeout(() => {
            navigate("/promotioncodes", { state: ["Kodet promocionale"] });
          }, 500);
        })
        .catch(() =>
          toast.error("Ndodhi një gabim gjatë krijimit.", {
            position: "top-right"
          })
        );
    }
  };

  const today = dayjs();

  return (
    <FormProvider {...methods}>
      <Link
        className="flex items-center mb-4 text-sm font-semibold text-primary"
        to={"/promotioncodes"}
        state={["Kodet promocionale"]}
      >
        <ChevronLeftIcon className="w-4 h-4 mr-2" />
        <span>Kthehu tek lista</span>
      </Link>
      <FormWrapper
        showPrompt={
          !methods.formState.isSubmitSuccessful &&
          !!Object.keys(methods.formState.dirtyFields).length
        }
        onSubmit={handleSubmit(onSubmit)}
        title={editMode ? "Modifiko kodin" : "Shto kod të ri"}
      >
        <Select
          label="Lloji"
          {...register("promotionCodeType")}
          options={[
            {
              id: PromotionCodeTypeEnum.student,
              name: "Student"
            },
            {
              id: PromotionCodeTypeEnum.reseller,
              name: "Reseller"
            }
          ]}
          className={`${
            editMode && "opacity-60 pointer-events-none cursor-not-allowed"
          } mb-6`}
          error={errors.promotionCodeType?.message}
        />
        <Input
          {...register("name")}
          label="Emri"
          className="mb-6"
          placeholder="Shkruaj emrin këtu..."
          error={errors.name?.message}
        />
        <PromotionCodeInput editMode={editMode} />
        <Input
          {...register("email")}
          label="Email"
          className="mb-6"
          placeholder="Shkruaj email këtu..."
          error={errors.email?.message}
        />
        <Input
          {...register("expirationDate")}
          type={"date"}
          className="mb-6 cursor-pointer"
          placeholder="Zgjedh datën e skadimit"
          label="Data e skadimit"
          error={errors.expirationDate?.message}
          min={today.format("YYYY-MM-DD") && errors.expirationDate?.message}
        />
        <Textarea
          className="mb-6 text-sm"
          placeholder="Shkruaj përshkrimin për këtë kod promocional (opsionale)..."
          label="Përshkrimi (Opsionale)"
          {...register("description")}
        />
      </FormWrapper>
    </FormProvider>
  );
};

export default AddPromotionCode;
