import { InboxIcon } from "@heroicons/react/24/outline";
import { FC, useState } from "react";
import toast from "react-hot-toast";
import { notifyPromotionCode } from "../../services/PromoCodes.service";
import Button from "../ui/Button";
import IconBtn from "../ui/IconBtn";
import Modal from "../ui/Modal";

interface SendMailProps {
  promotionCodeType: number;
  promoCode: string;
  email: string;
  id: number;
  // handleSend: () => void;
}

const SendMail: FC<SendMailProps> = ({
  promoCode,
  // promotionCodeType,
  email,
  id
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const submit = async () => {
    setModalOpen(false);
    try {
      await notifyPromotionCode({ id });
      toast.success("Email është dërguar me sukses.", {
        position: "top-right"
      });
    } catch (error) {
      toast.error("Ndodhi një gabim gjatë krijimit.", {
        position: "top-right"
      });
    }
  };

  return (
    <>
      <IconBtn className="p-1 w-6 h-6" onClick={() => setModalOpen(true)}>
        <InboxIcon className="w-4 h-4" />
      </IconBtn>
      <Modal
        position="center"
        show={modalOpen}
        onClose={() => setModalOpen(false)}
        mini
        title="Konfirmo dërgimin e email."
        buttonElement={<Button onClick={submit}>Konfirmo</Button>}
      >
        <p className="justify-center w-full flex mt-3 text-gray-600">
          Ky veprim nuk mund të kthehet mbrapa.
        </p>
        {/* <p className="text-center text-xs text-gray-700 mt-1">
          Lloji: {promotionCodeType}
        </p> */}
        <p className="text-center text-xs text-gray-700 mt-1">
          Kodi: {promoCode}
        </p>
        <p className="text-center text-xs text-gray-700 mt-1">Email: {email}</p>
      </Modal>
    </>
  );
};

export default SendMail;
