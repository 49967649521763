import { useAuth } from "oidc-react";
import React, { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSection from "../components/auth/LoadingSection";
import Logout from "../components/auth/Logout";
import useFetch from "../hooks/useFetch";
import { IUser, SystemNameType } from "../interfaces/endpoints/IUser.types";
import { getCurrentUser } from "../services/User.service";

export type AuthCustomContextType = {
  logOut: () => void;
  user?: IUser;
  permissions: SystemNameType[];
};

export const AuthCustomContext = createContext<AuthCustomContextType>({
  logOut: () => undefined,
  user: undefined,
  permissions: []
});

export const AuthCustomProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { isLoading, userData, signOut } = useAuth();

  const [isLogOut, setIsLogout] = useState<boolean>(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { response, loading, handleFunc, setLoading } =
    useFetch(getCurrentUser);

  useEffect(() => {
    setLoading(isLoading);
    if (
      !isLoading &&
      userData?.access_token &&
      !response?.data &&
      !pathname.includes("/invitation/")
    ) {
      void handleFunc(undefined).then((res) => {
        if (!res?.data.id) {
          void signOut();
          navigate("/login?error=401");
        }
      });
    }
  }, [isLoading, userData,pathname]);

  const logOut = () => setIsLogout(true);

  return (
    <AuthCustomContext.Provider
      value={{
        logOut,
        user: response?.data,
        permissions: response?.data.permissions.map((el) => el.systemName) || []
      }}
    >
      {isLogOut ? <Logout /> : null}
      {(!loading && !isLoading) || pathname.includes("/invitation/") ? (
        children
      ) : (
        <LoadingSection />
      )}
    </AuthCustomContext.Provider>
  );
};
