/* eslint-disable max-lines */
import {
  ArrowsUpDownIcon,
  PencilSquareIcon
} from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { FC, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import {
  IReservationFormSchemaV2,
  ITripInfo
} from "../../../interfaces/reservations/Reservations.types";
import BusChange from "../../shared/svgs/BusChange";
import Radio from "../../shared/svgs/Radio";
import Button from "../../ui/Button";
import DayPicker from "../../ui/datepicker/day/DayPicker";
import Input from "../../ui/input/Input";

const TripInfo: FC<ITripInfo> = ({ ticketDetailsFor, showReturn }) => {
  const [enableTripIds, setEnableTripIds] = useState<number[]>([]);

  const {
    register,
    formState: { errors },
    setFocus,
    control
  } = useFormContext<IReservationFormSchemaV2>();

  const { fields } = useFieldArray({
    name: ticketDetailsFor,
    control
  });

  return (
    <>
      {fields.length && showReturn ? (
        <div
          className="bg-white rounded-md px-1 py-0.5 text-xs items-center flex
  mx-auto w-max my-4"
        >
          <span className="text-xs uppercase text-gray-600 mr-2">Vajtje</span>
          <ArrowsUpDownIcon className="w-5 h-5 text-primary" />
          <span className="text-xs uppercase text-gray-600 ml-2">Ardhje</span>
        </div>
      ) : null}
      <div className="bg-gray-100 rounded-lg">
        {fields.map((field, index) => (
          <div
            key={field.id}
            className="first:pb-0 last:pb-4 px-4 pt-4"
          >
            <div className="flex justify-between flex-wrap items-center">
              <div className="flex items-center w-3/5">
                <span className="mr-4">
                  {field.departureStationName.replace("Stacioni", "")}
                </span>
                <Radio className="w-3 h-3 text-primary" />
                <span className="h-[1px] bg-gray flex-1 mx-0.5 relative">
                  <span className="absolute text-sm justify-center flex w-full -top-5">
                    {field.operatorName}
                  </span>
                </span>
                <Radio className="w-3 h-3 text-gray" />
                <span className="ml-4">
                  {field.arrivalStationName.replace("Stacioni", "")}
                </span>
              </div>
              <div className="flex justify-between flex-wrap">
                <div className="text-sm flex items-center mr-6">
                  <div className="relative">
                    <Input
                      paddingType="small"
                      className="w-max"
                      {...register(`${ticketDetailsFor}.${index}.tripId`)}
                      error={!!errors[ticketDetailsFor]?.[index]?.tripId}
                      disabled={!enableTripIds.includes(field.tripId)}
                    />
                    {!enableTripIds.includes(field.tripId) && (
                      <Button
                        btnType="custom"
                        className="absolute right-0 top-0"
                        onClick={() => {
                          setEnableTripIds((prev) => [...prev, field.tripId]);
                          setTimeout(() => {
                            setFocus(`${ticketDetailsFor}.${index}.tripId`);
                          }, 0);
                        }}
                      >
                        <PencilSquareIcon className="w-4 h-4" />
                      </Button>
                    )}
                  </div>
                </div>
                <div className="text-sm flex items-center">
                  <Controller
                    control={control}
                    name={`${ticketDetailsFor}.${index}.journeyDate`}
                    render={({ field: { value, onChange } }) => (
                      <DayPicker
                        numberOfMonths={1}
                        date={dayjs(value).toDate()}
                        onChangeDate={(date) => {
                          onChange(dayjs(date).format("YYYY-MM-DD"));
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            {fields.length - 1 > index ? (
              <div
                className="bg-white shadow-md rounded-md px-2 py-1 mt-4 text-xs items-center flex
  w-max mx-auto z-10 relative"
              >
                <BusChange className="w-5 h-5 text-primary bg-white mr-1" />{" "}
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </>
  );
};

export default TripInfo;
