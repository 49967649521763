import dayjs from "dayjs";
import { FC } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import {
  DistributionPerDayType,
  OperatorFilteredAnalyticsListModel
} from "../../interfaces/endpoints/IAnalytics.types";
import SpinnerLoader from "../ui/SpinnerLoader";

const LinearGraph: FC<{
  data?: DistributionPerDayType[];
  loading: boolean;
  type: keyof OperatorFilteredAnalyticsListModel;
}> = ({ loading, data, type }) => {
  const longestLabelLength =
    data
      ?.map((c) => c.amount)
      .reduce(
        (acc, cur) =>
          cur.toFixed(0)?.toString().length > acc
            ? cur.toFixed(0)?.toString().length
            : acc,
        0
      ) || 1;

  return (
    <>
      <div className="w-full h-full relative">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={data}>
            <defs>
              <linearGradient id="amount" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="0%"
                  stopColor="#3A81F6"
                  stopOpacity={0.5}
                  gradientTransform="180deg"
                />
                <stop offset="100%" stopColor="#3A81F6" stopOpacity={0} />
              </linearGradient>
            </defs>
            {data !== undefined && !loading ? (
              <>
                <XAxis
                  dataKey="date"
                  axisLine={false}
                  tickSize={0}
                  tickFormatter={(date) => dayjs(date).format("DD/MM/YYYY")}
                  interval="preserveStartEnd"
                  fontSize={8}
                  dy={24}
                  color="#6E6E6E"
                />
                <YAxis
                  axisLine={false}
                  allowDecimals={false}
                  tickLine={false}
                  fontSize={8}
                  color="#6E6E6E"
                  width={longestLabelLength * 8}
                />
              </>
            ) : null}
            <CartesianGrid
              strokeDasharray="4"
              strokeWidth={1}
              stroke="#EAEAEA"
              vertical={false}
              horizontalPoints={[8, 35, 64, 93, 123]}
            />
            <Tooltip
              cursor={{
                stroke: "#2C2C2C",
                fill: "#2C2C2C",
                opacity: 0.5,
                strokeWidth: 1
              }}
              content={(d) => {
                if (d.active && d.payload && d.payload.length)
                  return (
                    <div className="text-white font-bold text-2xs bg-black rounded-full px-1.5 py-1">
                      {type === "sales"
                        ? `${d.payload[0].payload.amount.toFixed(2)} €`
                        : d.payload[0].payload.amount}
                    </div>
                  );
                return null;
              }}
              wrapperStyle={{ outline: "none" }}
            />
            {data !== undefined && !loading ? (
              <>
                <Area
                  type="monotone"
                  dataKey="amount"
                  stroke="#3A81F6"
                  strokeWidth={2}
                  fillOpacity={1}
                  fill="url(#amount)"
                />
              </>
            ) : null}
          </AreaChart>
        </ResponsiveContainer>
        {loading ? (
          <SpinnerLoader className="absolute top-0 w-full h-[120px] flex justify-center items-center" />
        ) : null}
      </div>
    </>
  );
};
export default LinearGraph;
