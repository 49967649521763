import { FC, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import AddIcon from "../../../../components/shared/svgs/Add";
import { ILocation } from "../../../../interfaces/endpoints/ILocations";
import { ITripType } from "../../../../interfaces/endpoints/trips/Trips.types";
import CreateTripWrapper from "./CreateTripWrapper";
import EmptyLineTrip from "./EmptyLineTrip";

interface SchedulesProps {
  locations: ILocation[];
  schedulesRes?: ITripType["schedules"];
  tripIndex?: number;
}

interface IPathStop {
  id: number;
  newTrip: React.ReactNode;
}

const Schedules: FC<SchedulesProps> = ({
  locations,
  schedulesRes,
  tripIndex
}) => {
  const [pathStops, setPathStops] = useState<IPathStop[]>([]);
  const { unregister } = useFormContext<ITripType>();
  useEffect(() => {
    setPathStops(
      schedulesRes?.map((el, i) => {
        return {
          id: i,
          newTrip: (
            <EmptyLineTrip
              key={el.id}
              handleDelete={handleDeletePathStop}
              options={locations?.map((lc) => {
                return {
                  id: lc.id, name: lc.name + `
               - ${lc.stationtype === 1 ? "Bus" : "Tren"}`
                };
              })}
              index={i}
              tripIndex={tripIndex}
            />
          )
        };
      }) || []
    );
  }, [locations]);

  const handleNewPathStop = () => {
    setPathStops((prev) => {
      const index = prev.length;
      return [
        ...prev,
        {
          id: index,
          newTrip: (
            <EmptyLineTrip
              handleDelete={handleDeletePathStop}
              options={locations?.map((lc) => {
                return {
                  id: lc.id, name: lc.name + `
               - ${lc.stationtype === 1 ? "Bus" : "Tren"}`
                };
              })}
              index={index}
              tripIndex={tripIndex}
            />
          )
        }
      ];
    });
  };

  const handleDeletePathStop = (id: number) => {
    unregister(`schedules.${id}`);
    setPathStops((prev) => prev.filter((x) => x.id !== id));
  };

  return (
    <CreateTripWrapper title="Ndalesat" className="py-5">
      <button
        type="button"
        className="absolute right-0 top-0 p-5 text-primary flex items-center
        transitions hover:font-semibold cursor-pointer transition-all duration-75"
        onClick={handleNewPathStop}
      >
        <span className="pr-2">
          <AddIcon size={16} />
        </span>
        Shto ndalese të re
      </button>
      {pathStops.length === 0 ? (
        <div
          className="w-full bg-gray-100 h-14
  rounded-md justify-center"
        >
          <p className="text-gray-500 text-center p-4">
            Ende nuk keni shtuar ndalesa...
          </p>
        </div>
      ) : (
        <ol className="pl-4 text-sm list-decimal">
          {pathStops.map((el) => {
            return <li key={el.id} className="px-2">{el.newTrip}</li>;
          })}
        </ol>
      )}
    </CreateTripWrapper>
  );
};

export default Schedules;
