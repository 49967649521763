import useUser from "../../../hooks/useUser";
import { ICan } from "../../../interfaces/components/Can.types";
import { checkPermission } from "./checkPermission";

const useCan = (permissions: ICan["permissions"]) => {
  const { permissions: userPermissions } = useUser();
  return checkPermission(userPermissions, permissions);
};

export default useCan;
