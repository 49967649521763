import { RowsProps } from "./interfaces";
import Styles from "./table.module.css";

const Body: React.FunctionComponent<RowsProps> = ({ children, style }) => {
  let borderStyle;
  switch (style) {
    case "hover":
      borderStyle = Styles.hover;
      break;
    case "strip":
      borderStyle = Styles.strip;
      break;
    case "border":
      borderStyle = Styles.border;
      break;
    case "hoverStrip":
      borderStyle = `${Styles.hover} ${Styles.strip}`;
      break;
    case "hoverBorder":
      borderStyle = `${Styles.hover} ${Styles.border}`;
  }

  return <tbody className={`${borderStyle} overflow-x-auto`}>{children}</tbody>;
};

export default Body;
