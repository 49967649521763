/* eslint-disable max-len */

import { FC } from "react";
import { IIcon } from "./IIcon";

const Service: FC<IIcon> = ({ color, size }) => {
  const currentColor = color || "currentColor";
  const currentSize = size || 16;
  return (
    <span>
      <svg
        width={currentSize}
        height={currentSize}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-auto h-auto"
      >
        <path
          d="M9 11.0625C7.86 11.0625 6.9375 10.14 6.9375 9C6.9375 7.86 7.86 6.9375 9 6.9375C10.14 6.9375 11.0625 7.86 11.0625 9C11.0625 10.14 10.14 11.0625 9 11.0625ZM9 8.0625C8.4825 8.0625 8.0625 8.4825 8.0625 9C8.0625 9.5175 8.4825 9.9375 9 9.9375C9.5175 9.9375 9.9375 9.5175 9.9375 9C9.9375 8.4825 9.5175 8.0625 9 8.0625Z"
          fill={currentColor}
        />
        <path
          d="M15.0002 14.0627C14.8802 14.0627 14.7677 14.0252 14.6627 13.9502C14.4152 13.7627 14.3627 13.4102 14.5502 13.1627C15.4577 11.9552 15.9377 10.5152 15.9377 9.00021C15.9377 7.48521 15.4577 6.04522 14.5502 4.83772C14.3627 4.59022 14.4152 4.23771 14.6627 4.05021C14.9102 3.86271 15.2627 3.9152 15.4502 4.1627C16.5077 5.5652 17.0627 7.23771 17.0627 9.00021C17.0627 10.7627 16.5077 12.4352 15.4502 13.8377C15.3377 13.9877 15.1727 14.0627 15.0002 14.0627Z"
          fill={currentColor}
        />
        <path
          d="M3 14.0627C2.8275 14.0627 2.6625 13.9877 2.55 13.8377C1.4925 12.4352 0.9375 10.7627 0.9375 9.00021C0.9375 7.23771 1.4925 5.5652 2.55 4.1627C2.7375 3.9152 3.09001 3.86271 3.33751 4.05021C3.58501 4.23771 3.6375 4.59022 3.45 4.83772C2.5425 6.04522 2.0625 7.48521 2.0625 9.00021C2.0625 10.5152 2.5425 11.9552 3.45 13.1627C3.6375 13.4102 3.58501 13.7627 3.33751 13.9502C3.24001 14.0252 3.12 14.0627 3 14.0627Z"
          fill={currentColor}
        />
        <path
          d="M12.5998 12.2615C12.4798 12.2615 12.3673 12.224 12.2623 12.149C12.0148 11.9615 11.9623 11.609 12.1498 11.3615C12.6673 10.679 12.9373 9.86154 12.9373 8.99904C12.9373 8.13654 12.6673 7.31904 12.1498 6.63654C11.9623 6.38904 12.0148 6.03653 12.2623 5.84903C12.5098 5.66153 12.8623 5.71404 13.0498 5.96154C13.7098 6.84654 14.0623 7.89654 14.0623 8.99904C14.0623 10.1015 13.7098 11.159 13.0498 12.0365C12.9373 12.1865 12.7723 12.2615 12.5998 12.2615Z"
          fill={currentColor}
        />
        <path
          d="M5.40001 12.2615C5.22751 12.2615 5.0625 12.1865 4.95 12.0365C4.29 11.1515 3.9375 10.1015 3.9375 8.99904C3.9375 7.89654 4.29 6.83904 4.95 5.96154C5.1375 5.71404 5.49 5.66153 5.7375 5.84903C5.985 6.03653 6.03749 6.38904 5.84999 6.63654C5.33249 7.31904 5.0625 8.13654 5.0625 8.99904C5.0625 9.86154 5.33249 10.679 5.84999 11.3615C6.03749 11.609 5.985 11.9615 5.7375 12.149C5.64 12.224 5.52001 12.2615 5.40001 12.2615Z"
          fill={currentColor}
        />
      </svg>
    </span>
  );
};

export default Service;
