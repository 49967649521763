import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import useNavigateState from "../../../hooks/useNavigateState";
import { IBundleReservationSchema } from "../../../interfaces/bundles/BundleReservations.types";
import { editBundleReservation } from "../../../services/BundleReservations.service";
import { TicketStatusEnum } from "../../reservations/res.enum";
import Input from "../../ui/input/Input";
import Select from "../../ui/select/Select";
import Actions from "../Actions";
import { paymentMethods, paymentStatus } from "./utils/PaymentsOptions";

const BundleReservationDetails = () => {
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const stateCrumbs = useNavigateState(["Menaxhimi i ofertave", "Menaxhimi i shitjeve"]);

  const { handleSubmit, control, register, watch } =
    useFormContext<IBundleReservationSchema>();

  const { loading, handleFunc } = useFetch(editBundleReservation);

  const { fields } = useFieldArray({
    control,
    name: "travelBundleReservationUserInfos"
  });
  const onSubmit = async (data: IBundleReservationSchema) => {
    const res = await handleFunc({
      id: data.id,
      paymentStatus: data.paymentStatus.value,
      travelBundleReservationUserInfoDtos: data.travelBundleReservationUserInfos
    });
    if (res?.status === 200) {
      toast.success("Rezervimi është ndryshuar me sukses.");
      navigate(`/bundles/reservations?pageindex=${state?.page ? state?.page : 1}`, {
        state: stateCrumbs
      });
    } else toast.error("Ndodhi një gabim gjatë ndryshimit.");
  };

  const initialPaymentStatus = watch("paymentStatus.value");
    const filteredOptions = () => {
      if (initialPaymentStatus === 1) {
        return paymentStatus.filter(
          (option) => option.value === 1 || option.value === 3
        );
      } else if (initialPaymentStatus === 3) {
        return paymentStatus.filter(
          (option) => option.value === 3 || option.value === 4
        );
      } else {
        return paymentStatus;
      }
    };

  return (
    <form className="mt-16" onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-6 px-5 flex gap-4 flex-wrap">
        {fields.map((el, index) =>
          index > 0 ? (
            <div key={el.id} className="">
              <p className="mb-1.5 text-sm text-neutral">
                Pasagjeri {index + 1}
              </p>
              <div className="flex gap-4">
                <Input
                  {...register(
                    `travelBundleReservationUserInfos.${index}.firstName`
                  )}
                  placeholder="Emri"
                />
                <Input
                  {...register(
                    `travelBundleReservationUserInfos.${index}.lastName`
                  )}
                  placeholder="Mbiemri"
                />
              </div>
            </div>
          ) : null
        )}
      </div>
      <div className="border-t border-gray-200 py-6">
        <div className="flex justify-between gap-4 px-5">
          <Select
            label="Mënyra e pageses"
            selectedItem={paymentMethods.find(
              (el) => el.value === watch("paymentMethodId")
            )}
            disabled
            options={[]}
            inputClassName="text-neutral"
            onSelectedItemChange={() => {
              console.log("first");
            }}
          />
          <Input
            label="ID e pakos"
            disabled
            className="w-full text-neutral"
            value={watch("id")}
          />
          <Controller
            control={control}
            name="paymentStatus"
            render={({ field: { onChange, value } }) => (
              <Select
                label="Statusi"
                selectedItem={value}
                disabled={initialPaymentStatus !== TicketStatusEnum.Confirmed &&
                  initialPaymentStatus !== TicketStatusEnum.Canceled}
                options={filteredOptions()}
                onSelectedItemChange={(item) => onChange(item)}
              />
            )}
          />
        </div>
      </div>
      <Actions loading={loading} buttonTitle="Modifiko Pakon" />
    </form>
  );
};

export default BundleReservationDetails;
