import { PlayCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import Modal from "./Modal";

interface VideoPopupProps {
  link: string;
  title: string;
}

const VideoPopup: React.FC<VideoPopupProps> = ({ link, title }) => {
  const [show, setShow] = useState<boolean>(false);
  return (
    <>
      <div
        onClick={() => setShow(true)}
        className="flex items-center bg-gray-200 rounded-md px-4 py-2
        text-sm ml-3 hover:bg-gray-50 transition cursor-pointer"
      >
        {title}
        <PlayCircleIcon className="w-5 h-5 ml-2 text-primary" />
      </div>
      <Modal
        position="center"
        show={show}
        title={title}
        onClose={() => setShow(false)}
      >
        <div
          style={{ paddingTop: "56.25%", position: "relative", width: "100%" }}
        >
          <iframe
            className="no-margin"
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%"
            }}
            src={link}
            frameBorder="0"
            allow="autoplay"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>
    </>
  );
};

export default VideoPopup;
