import { ICan } from "../../../interfaces/components/Can.types";
import { SystemNameType } from "../../../interfaces/endpoints/IUser.types";

export const checkPermission = (
  userPermissions: SystemNameType[],
  permissions: ICan["permissions"]
) => {
  const _permissions = Array.isArray(permissions) ? permissions : [permissions];
  return _permissions.some((p) => userPermissions.includes(p));
};
