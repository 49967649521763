import dayjs from "dayjs";
import { FC } from "react";
import { Tooltip } from "react-tooltip";
import { InvitedUserType } from "../../interfaces/endpoints/IUser.types";
import { deleteInvitedUserById } from "../../services/Users.service";
import DeleteModal from "../ui/DeleteModal";
import Status from "../ui/Status";
import { Row } from "../ui/table";
import Can from "./permissions/Can";

interface IUserRow {
  row: InvitedUserType;
  onDeleteSuccess: (id: string) => void;
}

const InvitedUserRow: FC<IUserRow> = ({ row, onDeleteSuccess }) => {
  return (
    <Row
      className="group"
      key={`${row.id}`}
      email={row.email}
      role={
        <>
          <span
            data-tooltip-id={`${row.id}`}
            data-tooltip-content={row.invitedUserRoleMappings
              .map((el) => el.role.roleName)
              .join(",")}
            data-tooltip-variant="dark"
            data-tooltip-float={true}
          >
            {row.invitedUserRoleMappings[0]?.role?.roleName}
            {row.invitedUserRoleMappings.length > 1 && (
              <span className="ml-2 rounded-full py-1 px-2 mr-1.5 text-primary ring-1 ring-gray-200 bg-white">
                +{row.invitedUserRoleMappings.length - 1}
              </span>
            )}
          </span>
          <Tooltip id={`${row.id}`} />
        </>
      }
      opertorName={row.operator?.operatorName}
      insertDate={dayjs.utc(row.insertDate).tz().format("DD/MM/YYYY")}
      statusi={
        <>
          {row.status === 1 ? <Status name="Në pritje" type="orange" /> : null}
          {row.status === 2 ? <Status name="Pranuar" type="green" /> : null}
          {row.status === 3 ? <Status name="I anuluar" type="red" /> : null}
        </>
      }
      expiredDate={dayjs(row.expiryDate).format("DD/MM/YYYY")}
      actions={
        <span className="flex items-center">
          <Can permissions="users.user.delete">
            <DeleteModal
              params={row.invitationGuid}
              title="Konfirmoni fshirjen e këtij invite."
              handleDelete={deleteInvitedUserById}
              successMessage="Invite eshte fshire me sukses"
              errorMessage="Invite nuk është fshire me sukses."
              loadingMessage="Invite është duke u fshire..."
              onSuccess={onDeleteSuccess}
            />
          </Can>
        </span>
      }
    />
  );
};

export default InvitedUserRow;
