import { useAuth } from "oidc-react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import AuthWrapper from "../../components/auth/AuthWrapper";
import Button from "../../components/ui/Button";
import { SpinLoader } from "../../components/ui/SpinLoader";
import { parseQueryString } from "../../utils/parseQueryString";

const Login = () => {
  const [logginIn, setLogginIn] = useState<boolean>(false);
  const { search } = useLocation();
  const { signIn } = useAuth();
  const logIn = () => {
    setLogginIn((prev) => {
      if (!prev) {
        const { error } = parseQueryString(search);
        if (error === "401") void signIn({ prompt: "login" });
        else void signIn();
      }
      return true;
    });
  };
  return (
    <div className="flex flex-col justify-between p-10">
      <img src="logo.svg" className="w-36" />
      <AuthWrapper
        heading={
          <span>
            Planifiko udhëtimin
            <br /> e ardhshëm!
          </span>
        }
        paragraph="Zbuloni mundësitë tuaja të udhëtimit me ne!"
        span=""
        linkContent=""
        linkTo=""
      >
        {/* <LoginForm /> */}
        <Button padding="px-11 py-3" className="flex" onClick={logIn}>
          {logginIn ? (
            <>
              Redirecting to Porta
              <SpinLoader className="ml-2" formatClassName="h-4 w-4" />
            </>
          ) : (
            "Vazhdo Kyçu"
          )}
        </Button>
      </AuthWrapper>
      <div className="text-gray gap-6 text-sm flex">
        <a href="#">Rreth nesh</a>
        <a href="#">Privatësia</a>
        <a href="#">Ndihmë</a>
      </div>
    </div>
  );
};

export default Login;
