/* eslint-disable max-len */

import { FC } from "react";
import { IIcon } from "./IIcon";

const CloudCross: FC<IIcon> = ({ color, size }) => {
  const currentColor = color || "currentColor";
  const currentSize = size || 16;
  return (
    <span>
      <svg
        width={currentSize}
        height={currentSize}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-auto h-auto"
      >
        <path
          d="M12.4888 15.5589C12.4813 15.5589 12.4663 15.5589 12.4588 15.5589C12.1513 15.5589 11.8963 15.3039 11.9038 14.9889C11.9038 14.6814 12.1588 14.4339 12.4663 14.4339C12.4738 14.4339 12.4888 14.4339 12.4963 14.4339C13.3513 14.4339 14.1688 14.1189 14.7988 13.5339C15.9613 12.5139 16.0438 11.0589 15.7288 10.0314C15.4138 9.00391 14.5438 7.84141 13.0213 7.64641C12.7738 7.61641 12.5788 7.42891 12.5338 7.18141C12.2338 5.38891 11.2663 4.15141 9.81126 3.70141C8.29626 3.23641 6.54876 3.69391 5.45376 4.84141C4.38876 5.95891 4.14126 7.51141 4.75626 9.22891C4.86126 9.52141 4.71126 9.84391 4.41876 9.94891C4.12626 10.0539 3.80376 9.90391 3.69876 9.61141C2.94126 7.50391 3.28626 5.48641 4.64376 4.06891C6.02376 2.62141 8.23626 2.04391 10.1488 2.63641C11.8963 3.17641 13.1338 4.61641 13.5763 6.61141C15.1063 6.95641 16.3288 8.11141 16.8163 9.70891C17.3488 11.4489 16.8613 13.2339 15.5638 14.3739C14.7088 15.1389 13.6213 15.5589 12.4888 15.5589Z"
          fill={currentColor}
        />
        <path
          d="M4.17512 15.5647C4.16012 15.5647 4.14512 15.5647 4.13762 15.5647C1.97762 15.4147 0.995117 13.7197 0.995117 12.2122C0.995117 10.7047 1.97762 9.01719 4.13762 8.85969C4.70012 8.85219 5.24012 8.97969 5.72012 9.21969C5.99762 9.36219 6.10262 9.69969 5.96762 9.97719C5.82512 10.2547 5.48762 10.3597 5.21012 10.2247C4.89512 10.0597 4.52762 9.99219 4.19012 9.98469C2.77262 10.0897 2.12762 11.1772 2.12762 12.2122C2.12762 13.2472 2.77262 14.3347 4.22762 14.4397C4.53512 14.4622 4.76762 14.7322 4.74512 15.0397C4.71512 15.3397 4.46762 15.5647 4.17512 15.5647Z"
          fill={currentColor}
        />
        <path
          d="M11.862 7.98297C11.6595 7.98297 11.457 7.87047 11.3595 7.66797C11.217 7.39047 11.3295 7.05297 11.6145 6.91047C12.072 6.67797 12.5895 6.55047 13.0995 6.54297C13.392 6.55797 13.6695 6.78297 13.6695 7.09797C13.677 7.40547 13.4295 7.66797 13.1145 7.66797C12.7695 7.67547 12.4245 7.75797 12.1095 7.91547C12.0345 7.96797 11.9445 7.98297 11.862 7.98297Z"
          fill={currentColor}
        />
        <path
          d="M7.17 16.6206C7.0275 16.6206 6.885 16.5681 6.7725 16.4556C6.555 16.2381 6.555 15.8781 6.7725 15.6606L8.895 13.5381C9.1125 13.3206 9.4725 13.3206 9.69 13.5381C9.9075 13.7556 9.9075 14.1156 9.69 14.3331L7.5675 16.4556C7.455 16.5681 7.3125 16.6206 7.17 16.6206Z"
          fill={currentColor}
        />
        <path
          d="M9.2925 16.6206C9.15 16.6206 9.0075 16.5681 8.895 16.4556L6.7725 14.3331C6.555 14.1156 6.555 13.7556 6.7725 13.5381C6.99 13.3206 7.35 13.3206 7.5675 13.5381L9.69 15.6606C9.9075 15.8781 9.9075 16.2381 9.69 16.4556C9.5775 16.5681 9.435 16.6206 9.2925 16.6206Z"
          fill={currentColor}
        />
      </svg>
    </span>
  );
};

export default CloudCross;
