/* eslint-disable max-lines */
import { InformationCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Tooltip } from "react-tooltip";
import { IBundleSchema } from "../../../../interfaces/bundles/Bundles.types";
import { cx } from "../../../../utils/classnames";
import Button from "../../../ui/Button";
import IconBtn from "../../../ui/IconBtn";
import RangePicker from "../../../ui/datepicker/range/RangePicker";
import Input from "../../../ui/input/Input";

const JourneyDetails = () => {
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext<IBundleSchema>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "journeys",
    keyName: "uuid"
  });

  return (
    <>
      {fields.map((field, index) => {
        return (
          <div
            key={field.uuid}
            className="flex lg:flex-row flex-col items-start justify-between mt-4"
          >
            <Controller
              control={control}
              name={`journeys.${index}.range`}
              render={({
                field: { value, onChange }
              }) => {
                return (
                  <RangePicker
                    onlyModal
                    onChange={(r) => onChange(r)}
                    showApply
                    disabled={!!field.id}
                    range={value}
                    label="Datat e udhëtimit* "
                    iconColor="secondary"
                    iconSide="right"
                    pickerProps={{ fromDate: new Date() }}
                    placeholder="Zgjedh datën e pakos"
                    error={
                      errors?.journeys &&
                      (errors?.journeys[index]?.range?.message ||
                        errors?.journeys[index]?.range?.message ||
                        errors?.journeys[index]?.range?.from?.message ||
                        errors?.journeys[index]?.range?.to?.message)
                    }
                  />
                );
              }}
            />
            <Input
              {...register(`journeys.${index}.maxReservationCount`)}
              {...field?.id && { disabled: true, readOnly: true }}
              type="number"
              label={
                <>
                  Limiti i rezervimeve*
                  <InformationCircleIcon
                    className="w-4 h-4 ml-1.5"
                    strokeWidth={2}
                    data-tooltip-id={`journeys.${index}.maxReservationCount`}
                    data-tooltip-content="Numri total i rezervimeve që mund të bëhen."
                    data-tooltip-variant="dark"
                    data-tooltip-float={true}
                  />
                  <Tooltip id={`journeys.${index}.maxReservationCount`} />
                </>
              }
              labelClassName="flex items-center"
              className="lg:mx-6 lg:my-0 my-6 w-full"
              placeholder="Shkruaj numrin e rezervimeve"
              error={
                errors?.journeys &&
                errors?.journeys[index]?.maxReservationCount?.message
              }
            />
            <Input
              {...register(`journeys.${index}.travelBundlePrice`)}
              {...field?.id && { disabled: true, readOnly: true }}
              type="number"
              label="Çmimi (për person)*"
              className="w-full"
              placeholder="Shkruaj cmimin e pakos për person"
              after={<span className="text-neutral">€</span>}
              error={
                errors?.journeys &&
                errors?.journeys[index]?.travelBundlePrice?.message
              }
            />
            <div className="ml-4 mb-1 self-end">
              <IconBtn
                className={cx([
                  fields.length === 1 || field.id ? "cursor-not-allowed" : ""
                ])}
                onClick={() =>
                  fields.length === 1 || field.id ? undefined : remove(index)
                }
              >
                <TrashIcon className="w-4 h-4 text-neutral" />
              </IconBtn>
            </div>
          </div>
        );
      })}
      <Button
        btnType="custom"
        className="flex justify-center items-center text-primary group mt-4 mb-6"
        fontWeight="font-medium"
        padding="p-0"
        onClick={() => {
          append(
            {
              maxReservationCount: undefined,
              range: undefined,
              travelBundlePrice: undefined
            },
            { shouldFocus: false }
          );
        }}
      >
        <PlusCircleIcon className="w-5 h-5 mr-2" />
        <span
          className="duration-300 text-opacity-90 group-hover:text-opacity-100
           relative justify-center items-center
before:bg-primary before:absolute before:w-full before:h-[1px] before:rounded-sm
before:bottom-0 before:left-0 before:origin-left before:scale-x-0
before:duration-300 before:ease-in-out before:transform group-hover:before:origin-left
group-hover:before:scale-x-100 transition-all"
        >
          Shto datë të re
        </span>
      </Button>
    </>
  );
};

export default JourneyDetails;
