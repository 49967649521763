import SpinnerLoader from "../ui/SpinnerLoader";

const LoadingSection = () => (
  <div className="mx-auto mt-36 flex flex-col justify-center items-center">
    <SpinnerLoader />
    <p className="mt-6 text-gray-500">Ju lutem prisni...</p>
  </div>
);

export default LoadingSection;
