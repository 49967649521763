import dayjs from "dayjs";
import { FC } from "react";
import { IReservationV2 } from "../../interfaces/reservations/Reservations.types";

interface InsertDateProps {
  insertDate?: IReservationV2["insertDate"];
}

const InsertDate: FC<InsertDateProps> = ({ insertDate }) => {
  return (
    <div className="flex justify-end">
      <div className="rounded-lg w-max">
        {/* <span className="text-neutral text-2xs mr-1"> Data insertimit:</span> */}
        <span className="text-sm">
          {dayjs(insertDate).format("DD, MMMM YYYY")}
          <span className="text-sm ml-1.5">
            {dayjs(insertDate).format("HH:mm")}
          </span>
        </span>
      </div>
    </div>
  );
};

export default InsertDate;
