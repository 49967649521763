import { axios } from "../config/axios";
import { IUser } from "../interfaces/endpoints/IUser.types";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";

export const getCurrentUser = async (): Promise<IAxiosResponse<IUser>> => {
  return axios.get<IUser>("User");
};

export const getCurrentUserV1 = async (): Promise<IAxiosResponse<IUser>> => {
  return axios.get<IUser>("v1/User");
};
