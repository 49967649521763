import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useKeys } from "rooks";
import Actions from "../../../components/bundles/Actions";
import NotFoundForm from "../../../components/bundles/NotFoundForm";
import SpinnerLoader from "../../../components/ui/SpinnerLoader";
import Table from "../../../components/ui/table";
import Can from "../../../components/users/permissions/Can";
import useCan from "../../../components/users/permissions/useCan";
import useFetch from "../../../hooks/useFetch";
import { EditPermissionFormType } from "../../../interfaces/endpoints/IPermission.types";
import {
  editPermissions,
  getAllPermissions
} from "../../../services/Permission.serivce";
import AccessControlRow from "./AccessControlRow";
const AccessControl = () => {
  const [displaySystemName, setDisplaySystemName] = useState(false);
  useKeys(["ControlLeft", "KeyK"], () =>
    setDisplaySystemName(!displaySystemName)
  );
  const { response, loading, error } = useFetch(getAllPermissions, {
    initRequest: true
  });
  const { handleFunc, loading: editLoading } = useFetch(editPermissions, {
    initRequest: false
  });
  const methods = useForm<EditPermissionFormType>();
  useEffect(() => {
    if (response?.data?.groupedPermissions) {
      let _temp = {};
      response?.data.groupedPermissions
        .map((el) => el.permissions)
        .flat()
        .forEach((p) => {
          p.permissionRoles.map((r) => {
            _temp = {
              ..._temp,
              [`role_${p.id}_${r.userRoleId}`]: {
                name: `role_${p.id}_${r.userRoleId}`,
                permissionRecordId: p.id,
                userRoleId: r.userRoleId,
                selected: r.selected,
                isSystemRole:
                  response.data.userRoles.find((el) => el.id === r.userRoleId)
                    ?.isSystemRole || false
              }
            };
          });
        });
      methods.reset(_temp);
    }
  }, [response]);
  const onSubmit: SubmitHandler<EditPermissionFormType> = (data) => {
    void handleFunc(
      Object.values(data)
        .filter((e) => e.selected)
        .map((el) => ({
          permissionRecordId: el.permissionRecordId,
          userRoleId: el.userRoleId
        }))
    )
      .then(() => {
        toast.success("Ndryshimet janë bërë me sukses.", {
          duration: 2000
        });
      })
      .catch(() => {
        toast.error("Ndodhi një gabim gjatë ndryshimit.");
      });
  };
  const can = useCan("permissions.permission.update");
  if (loading) return <SpinnerLoader className="pt-10" />;
  if (error) return <NotFoundForm message="Not found" />;
  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="relative h-full flex flex-col justify-between"
      >
        <div className="flex flex-col gap-6">
          {response?.data.groupedPermissions.map((el) => {
            return (
              <div>
                <p className="mb-6 leading-7">{el.name}</p>
                <Table
                  titles={[
                    "Permisson name",
                    ...response.data.userRoles.map((uR) => uR.roleName)
                  ]}
                  loading={false}
                  className="overflow-x-auto"
                  style="border"
                  rounded="rounded-md"
                  thClassName="text-center first:text-left"
                >
                  {el.permissions ? (
                    el.permissions.map((row, i: number) => {
                      return (
                        <AccessControlRow
                          key={`${row.id}_${row.name}_${i}`}
                          disabled={!can}
                          displaySystemName={displaySystemName}
                          row={row}
                          userRoles={response.data.userRoles}
                        />
                      );
                    })
                  ) : (
                    <></>
                  )}
                </Table>
              </div>
            );
          })}
        </div>
        <Can permissions="permissions.permission.update">
          <Actions
            className="md:-mx-7 mx-3 sticky bottom-0 md:-mb-7 -mb-3 bg-white md:mt-7 mt-3 z-10"
            loading={editLoading}
            buttonTitle="Ruaj Ndyshimet"
          />
        </Can>
      </form>
    </FormProvider>
  );
};

export default AccessControl;
