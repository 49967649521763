import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ITripNoticeForm } from "../../../interfaces/endpoints/ITripsNotices";
import { getAllTrips } from "../../../services/Trips.service";
import Airpod from "../../shared/svgs/Airpod";
import Briefcase from "../../shared/svgs/Briefcase";
import Bus from "../../shared/svgs/Bus";
import CloudCross from "../../shared/svgs/CloudCross";
import Danger from "../../shared/svgs/Danger";
import Health from "../../shared/svgs/Health";
import Info from "../../shared/svgs/Info";
import Location from "../../shared/svgs/Location";
import Other from "../../shared/svgs/Other";
import Seating from "../../shared/svgs/Seating";
import Service from "../../shared/svgs/Service";
import Star from "../../shared/svgs/Star";
import Ticket from "../../shared/svgs/Ticket";
import Select from "../../ui/select/Select";
import { noticePriority, noticeType } from "./Notices";

interface INoticesSelectDropdowns {
  mode: "create" | "edit";
}

const NoticesSelectDropdowns: FC<INoticesSelectDropdowns> = ({ mode }) => {
  const { control } = useFormContext<ITripNoticeForm>();
  return (
    <div className="flex gap-4">
      <Controller
        control={control}
        name="tripId"
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <Select
              className="mb-6 w-1/3"
              label="Zgjedh orarin*"
              disabled={mode === "edit"}
              placeholder="Zgjedh orarin"
              onSelectedItemChange={(item) => onChange(item)}
              selectedItem={value}
              getOptionLabel={(o) => (
                <span>
                  {o.routeName}, {o.trip.departureTimeString} -{" "}
                  {o.trip.arrivalTimeString}
                </span>
              )}
              itemToString={(o) =>
                o !== undefined && o !== null
                  ? `${o?.routeName}, ${o?.trip.departureTimeString} - ${o?.trip.arrivalTimeString}`
                  : ""
              }
              asyncFunc={getAllTrips}
              error={error?.message}
            />
          );
        }}
      />
      <Controller
        control={control}
        name="tripNoticePriority"
        render={({ field: { onChange, value }, fieldState: { error }  }) => (
          <Select
            label="Prioriteti i informatës*"
            placeholder="Zgjedh prioritetin e informatës"
            className="w-1/3"
            selectedItem={value}
            options={noticePriority}
            onSelectedItemChange={(item) => onChange(item)}
            error={error?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="tripNoticeType"
        render={({ field: { onChange, value }, fieldState: { error }  }) => (
          <Select
            label="Lloji i informatës*"
            placeholder="Zgjedh llojin e informatës"
            className="w-1/3"
            selectedItem={value}
            options={noticeType}
            error={error?.message}
            getOptionLabel={(o) => (
              <span className="flex items-center">
                {(o.value === 1 && <Service />) ||
                  (o.value === 2 && <Location />) ||
                  (o.value === 3 && <Star />) ||
                  (o.value === 4 && <Seating />) ||
                  (o.value === 5 && <Briefcase />) ||
                  (o.value === 6 && <Health />) ||
                  (o.value === 7 && <Ticket />) ||
                  (o.value === 8 && <Airpod />) ||
                  (o.value === 9 && <CloudCross />) ||
                  (o.value === 10 && <Danger />) ||
                  (o.value === 11 && <Info />) ||
                  (o.value === 12 && <Bus />) ||
                  (o.value === 13 && <Other />)}{" "}
                <span className="ml-2">{o.label}</span>{" "}
              </span>
            )}
            onSelectedItemChange={(item) => onChange(item)}
          />
        )}
      />
    </div>
  );
};

export default NoticesSelectDropdowns;
