import dayjs from "dayjs";
import { AuthProvider } from "oidc-react";
import { Toaster } from "react-hot-toast";
import { Outlet } from "react-router-dom";
import { oidc as oidcConfig } from "./config/oidc-auth";
import { AuthCustomProvider } from "./context/AuthContext";
import { SideBarProvider } from "./context/SideBarContext";

const App = () => {
  dayjs.locale("sq");
  return (
  <AuthProvider {...oidcConfig}>
    <AuthCustomProvider>
      <SideBarProvider>
        <Toaster position="top-right" />
        <Outlet />
      </SideBarProvider>
    </AuthCustomProvider>
  </AuthProvider>
);
};

export default App;
