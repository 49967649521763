import axios from "axios";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import { IPagination } from "../interfaces/IPagination";
import { SelectParamsType } from "../interfaces/ISelectParams";
import {
  CreateInviteUserType,
  InvitedUserType,
  UserType
} from "../interfaces/endpoints/IUser.types";
import { serialize } from "../utils/serializeToQueryString";

export const getPagedUsers = async (
  params: SelectParamsType & { userRole: number }
): Promise<IAxiosResponse<IPagination<UserType>>> => {
  return axios.get(`User/Users${serialize(params)}`);
};

export const getPagedInvitedUsers = async (
  params: SelectParamsType & { userRole: number }
): Promise<IAxiosResponse<IPagination<InvitedUserType>>> => {
  return axios.get(`User/InvitedUsers${serialize(params)}`);
};

export const deleteUserById = async (
  id: number
): Promise<IAxiosResponse<{}>> => {
  return axios.delete(`User/${id}`);
};

export const deleteInvitedUserById = async (
  invitationGuid: string
): Promise<IAxiosResponse<{}>> => {
  return axios.delete(`User/Invitation/${invitationGuid}`);
};

export const createInvitedUser = async (
  data: CreateInviteUserType
): Promise<IAxiosResponse<InvitedUserType>> => {
  return axios.post("User/InviteUser", data);
};

export const editInvitedUser = async ({
  id,
  roles
}: {
  id: number;
  roles: number[];
}): Promise<IAxiosResponse<unknown>> => {
  return axios.put(`User/${id}`, roles);
};

export const getUserById = async (
  id: number
): Promise<IAxiosResponse<UserType>> => {
  return axios.get(`User/${id}`);
};

export const invitationUser = async (
  invitationGuid: string
): Promise<IAxiosResponse<UserType>> => {
  return axios.get(`User/Invitation/${invitationGuid}`);
};
