import { axios } from "../config/axios";
import IPricing, { IPostPriceSchema } from "../interfaces/endpoints/IPricing";
import { ILines } from "../interfaces/endpoints/lines/ILines";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";

export interface getPriceParams {
  tripId: number;
  ageGroupId: number;
  isTwoWayTrip: boolean;
  lineId: number;
}
export const getPrice = async (
  pageIndex: number = 1
): Promise<IAxiosResponse<ILines>> => {
  return axios.get<ILines>(`route/lines/?pageIndex=${pageIndex}`);
};
export const getAllPrices = async (
  getPriceParams: getPriceParams
): Promise<IAxiosResponse<IPricing>> => {
  return axios.get(
    `Pricing/Price/${getPriceParams.tripId}/${getPriceParams.ageGroupId}/${getPriceParams.isTwoWayTrip}`
  );
};
export const postPrice = async (
  body: IPostPriceSchema
): Promise<IAxiosResponse<IPostPriceSchema>> => {
  return axios.post("Pricing/PostPrice", body);
};
export const editPrice = async (
  body: IPricing
): Promise<IAxiosResponse<IPricing>> => {
  return axios.post("Pricing/EditPrice", body);
};
export const deletePrice = async (id: number): Promise<boolean> => {
  return axios.delete(`Route/DeletePrice/${id}`);
};
