import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { isEqual } from "lodash";
import { cx } from "../../../../utils/classnames";
import Animation from "../../Animation";
import Input from "../../input/Input";
import { IList } from "../Select.types";
import Styles from "../style.module.css";
import ListItem from "./ListItem";

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const List = <T extends unknown>({
  items,
  selectedItem,
  selectedItems,
  isOpen,
  getMenuProps,
  handleScroll,
  maxHeightList = "max-h-80",
  isAbsolute = true,
  selectType,
  getInputProps,
  getOptionValue:_getOptionValue,
  ...props
}: IList<T>) => {
  const getOptionValue = (value?: T | null) => _getOptionValue ? _getOptionValue(value) : value;
  return (
    <Animation
      type="fade-top"
      show={isOpen && !!items.length}
      duration={300}
      leaveInDom
    >
      <div
        className={cx([
          isAbsolute && "absolute z-50",
          isOpen && !!items.length && "mt-2 py-1",
          "w-full bg-white rounded-lg ring-1 ring-blue-stroke "
        ])}
      >
        <ul
          className={cx([
            "overflow-y-scroll",
            Styles.scrollThin,
            isOpen && !!items.length ? maxHeightList : "h-0 max-h-0"
          ])}
          {...getMenuProps(
            { onScroll: handleScroll },
            { suppressRefError: true }
          )}
        >
          {selectType === "multiple" ? (
            <li className="px-3 py-2">
              <Input
                onChange={getInputProps().onChange}
                paddingType="fill"
                placeholder="Search"
                inputClassName="rounded-[4px]"
                before={<MagnifyingGlassIcon className="w-4 h-4" />}
              />
            </li>
          ) : null}
          {items.map((item, index) => (
            <ListItem
              key={`${index}`}
              active={
                !!(
                  isEqual(getOptionValue(item), getOptionValue(selectedItem as T)) ||
                  selectedItems?.some((el) => isEqual(getOptionValue(el), getOptionValue(item)))
                )
              }
              index={index}
              item={item}
              {...props}
            />
          ))}
        </ul>
      </div>
    </Animation>
  );
};

export default List;
