/* eslint-disable max-lines */
import {
  ArrowDownTrayIcon,
  CheckIcon,
  EllipsisVerticalIcon,
  PencilIcon,
  ReceiptRefundIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { useState } from "react";
import { Link } from "react-router-dom";
import HeaderPage from "../../components/lines-managements/header/HeaderPage";
import { TicketStatusEnum } from "../../components/reservations/res.enum";
import DeleteModal from "../../components/ui/DeleteModal";
import DropDown from "../../components/ui/DropDown";
import IconBtn from "../../components/ui/IconBtn";
import Input from "../../components/ui/input/Input";
import Table, { Row } from "../../components/ui/table";
import Pagination from "../../components/ui/table/Pagination";
import useCan from "../../components/users/permissions/useCan";
import { useSearch } from "../../hooks/useSearch";
import { OfficePaymentMethodEnum } from "../../interfaces/endpoints/officeSales/ICreateReservationSchema";
import {
  editJourneyStatus,
  getJourneys
} from "../../services/OfficeSales.service";

const Office = () => {
  // const [searchParams, setSearchParams] = useSearchParams();
  const { response, page, handlePageChange, setResponse } = useSearch(
    getJourneys,
    {
      withQuerySearch: true,
      withDateFrom: true,
      withDateTo: true,
      otherQuery: {
        pageSize: "10"
      }
    }
  );
  console.log(response?.data.result);
  const [refundVal, setRefundVal] = useState<number>();
  const tableCols = [
    "BookingId",
    "Pasagjeri",
    "Totali",
    "Udhetimi",
    "Data e insertimit",
    "Data e nisjes",
    "Data e kthimit",
    "Pagesa",
    "Statusi",
    ""
  ];
  console.log(response?.data.result, "response?.data.result?");
  const canCreate = useCan("reservation.ticket.create");
  const canUpdate = useCan("reservation.ticket.update");
  return (
    <div>
      <HeaderPage
        videoTutorial={{
          link: "https://video.gjirafa.com/embed/do-beni-nje-rezervim-nga-zyrja-juaj?autoplay=true&am=true",
          title: "Si të shtoni një rezervim?"
        }}
        {...(canCreate && {
          link: {
            to: "chooseLine",
            children: "Shto rezervim të ri",
            state: ["Menaxhimi i Rezervimeve", "Shto rezervim të ri"]
          }
        })}
      />
      <div className="flex flex-col" style={{ height: "calc(100vh - 11rem)" }}>

      <Table titles={tableCols}>
        {response?.data.result?.length ? (
          response?.data.result?.map((row) => (
            <Row
              key={row.bookingId}
              id={row.bookingId}
              user={
                <Col
                  first={row.clients[0].firstName}
                  second={row.clients[0].email}
                />
              }
              total={
                <Col
                  first={`${row.totalPrice}€`}
                  second={`${row.clients.length} pasagjer`}
                />
              }
              trip={
                <Col
                  first={`${row.oneWayTicketDetails[0].departureStationName}
              > ${row.oneWayTicketDetails[0].arrivalStationName}`}
                  second={`${row.roundWayTicketDetails.length ? "Kthyse" : ""}`}
                />
              }
              insertDate={dayjs(row.insertDate).format("DD/MM/YYYY")}
              departureTime={
                <Col
                  first={dayjs(row.oneWayTicketDetails[0].journeyDate).format(
                    "DD MMM"
                  )}
                  second={dayjs(row.oneWayTicketDetails[0].journeyDate).format(
                    "HH:mm"
                  )}
                />
              }
              arrivalTime={
                row.roundWayTicketDetails.length ? (
                  <Col
                    first={dayjs(
                      row.roundWayTicketDetails[0].journeyDate
                    ).format("DD MMM")}
                    second={dayjs(
                      row.roundWayTicketDetails[0].journeyDate
                    ).format("HH:mm")}
                  />
                ) : (
                  "-"
                )
              }
              payment={OfficePaymentMethodEnum[row.paymentMethod]}
              status={
                <span className={TicketStatusEnum[row.paymentStatus]}>
                  {TicketStatusEnum[row.paymentStatus]}
                </span>
              }
              more={
                !canUpdate ? null : (
                  <>
                    <DropDown
                      boxPadding={false}
                      name={
                        <IconBtn>
                          <EllipsisVerticalIcon className="w-6 h-6" />
                        </IconBtn>
                      }
                      withArrow={false}
                    >
                      <div className="text-gray-700">
                        {row.pdfLink && (
                          <a
                            className="flex items-center gap-3 py-3 px-5  hover:bg-gray-100"
                            href={row.pdfLink}
                            target="_blank"
                          >
                            <ArrowDownTrayIcon className="w-5 h-5" />
                            <span>Gjenero biletën/listën</span>
                          </a>
                        )}
                        {row.paymentMethod !== 1 && row.paymentMethod !== 2 && (
                          <Link
                            to={`edit/${row.bookingId}`}
                            className="flex items-center gap-3 py-3  px-5 cursor-pointer hover:bg-gray-100"
                          >
                            <PencilIcon className="w-5 h-5" />
                            <span>Ndrysho</span>
                          </Link>
                        )}

                        <div className="hover:bg-gray-100">
                          {row.paymentStatus === TicketStatusEnum.Confirmed && (
                            <DeleteModal
                              onSuccess={(item) => {
                                setResponse((prev) =>
                                  prev
                                    ? {
                                        ...prev,
                                        data: {
                                          ...prev.data,
                                          result: prev.data.result.map(
                                            (el) => ({
                                              ...el,
                                              paymentStatus:
                                                el.bookingId ===
                                                item.uniqueJourneyId
                                                  ? TicketStatusEnum.Canceled
                                                  : el.paymentStatus
                                            })
                                          )
                                        }
                                      }
                                    : null
                                );
                              }}
                              indicator={
                                <div className="flex items-center gap-3 py-3 px-5 border-t cursor-pointer">
                                  <XMarkIcon className="w-5 h-5" />
                                  <span>Anulo</span>
                                </div>
                              }
                              title="Konfirmoni anulimin e këtij rezervimi"
                              paragraph="Klienti do të njoftohet pasi te bëhët anulimi"
                              successMessage="Statusi u ndryshua me sukses"
                              handleDelete={editJourneyStatus}
                              params={{
                                paymentMethodId: row.paymentMethod,
                                ticketStatus: 3,
                                uniqueJourneyId: row.bookingId,
                                shouldNotifyUser: true
                              }}
                            />
                          )}
                          {row.paymentStatus === TicketStatusEnum.Pending && (
                            <DeleteModal
                              onSuccess={(item) => {
                                setResponse((prev) =>
                                  prev
                                    ? {
                                        ...prev,
                                        data: {
                                          ...prev.data,
                                          result: prev.data.result.map(
                                            (el) => ({
                                              ...el,
                                              paymentStatus:
                                                el.bookingId ===
                                                item.uniqueJourneyId
                                                  ? TicketStatusEnum.Canceled
                                                  : el.paymentStatus
                                            })
                                          )
                                        }
                                      }
                                    : null
                                );
                              }}
                              indicator={
                                <div className="flex items-center gap-3 py-3 px-5 border-t cursor-pointer">
                                  <CheckIcon className="w-5 h-5" />
                                  <span>Konfirmo</span>
                                </div>
                              }
                              title="Konfirmoni rezervimin"
                              paragraph="Klienti do të njoftohet pasi te bëhët konfirmimi"
                              successMessage="Statusi u ndryshua me sukses"
                              handleDelete={editJourneyStatus}
                              params={{
                                paymentMethodId: row.paymentMethod,
                                ticketStatus: 1,
                                uniqueJourneyId: row.bookingId,
                                shouldNotifyUser: true
                              }}
                            />
                          )}
                          {row.paymentStatus === TicketStatusEnum.Canceled && (
                            <DeleteModal
                              mini={false}
                              onSuccess={(item) => {
                                setResponse((prev) =>
                                  prev
                                    ? {
                                        ...prev,
                                        data: {
                                          ...prev.data,
                                          result: prev.data.result.map(
                                            (el) => ({
                                              ...el,
                                              paymentStatus:
                                                el.bookingId ===
                                                item.uniqueJourneyId
                                                  ? TicketStatusEnum.Canceled
                                                  : el.paymentStatus
                                            })
                                          )
                                        }
                                      }
                                    : null
                                );
                              }}
                              indicator={
                                <div className="flex items-center gap-3 py-3 px-5 border-t cursor-pointer">
                                  <ReceiptRefundIcon className="w-5 h-5" />
                                  <span>Rimburso</span>
                                </div>
                              }
                              title="Rimbursim i biletës"
                              paragraph="Klienti do të njoftohet pasi te bëhët rumbursimi"
                              successMessage="Statusi u ndryshua me sukses"
                              handleDelete={editJourneyStatus}
                              // eslint-disable-next-line max-len
                              node={
                                <div className="p-5">
                                  <Input
                                    label="Shuma"
                                    value={refundVal}
                                    onChange={(e: any) =>
                                      setRefundVal(e.target.value)
                                    }
                                  />
                                </div>
                              }
                              params={{
                                paymentMethodId: row.paymentMethod,
                                ticketStatus: 4,
                                uniqueJourneyId: row.bookingId,
                                refundAmount: refundVal,
                                shouldNotifyUser: true
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </DropDown>
                  </>
                )
              }
            />
          ))
        ) : (
          <></>
        )}
      </Table>
      <Pagination
        onPageChange={handlePageChange}
        totalCount={response?.data?.totalCount || 0}
        length={response?.data?.result.length}
        page={page}
        pageSize={10}
      />
    </div>
    </div>
  );
};
const Col: React.FC<{ first: string; second: string }> = ({
  first,
  second
}) => (
  <div>
    <p className="text-sm">{first}</p>
    <p className="text-xs text-gray-600 text-light">{second}</p>
  </div>
);

export default Office;
