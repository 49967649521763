import HeaderPage from "../../../components/lines-managements/header/HeaderPage";
import RolesTable from "../../../components/user-roles/roles";
import useCan from "../../../components/users/permissions/useCan";

const Roles: React.FC = () => {
  const can = useCan("userroles.userrole.create");
  return (
    <>
      <HeaderPage
        hasNoInput
        {...(can && {
          link: {
            to: "add",
            children: "Shto rol të ri",
            state: ["Menaxhimi i roleve", "Krijo rol të ri"]
          }
        })}
      />
      <RolesTable />
    </>
  );
};

export default Roles;
