import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Button from "../../../components/ui/Button";
import Modal from "../../../components/ui/Modal";
import { SpinLoader } from "../../../components/ui/SpinLoader";
import Input from "../../../components/ui/input/Input";
import useBundlesOrder from "../../../hooks/useBundlesOrder";
import useFetch from "../../../hooks/useFetch";
import { BundleOrderEditForm } from "../../../interfaces/bundles/BundlesOrder.types";
import { editBundleOrder } from "../../../services/BundlesOrder.service";
import { BundleOrderEditSchema } from "../../../utils/validations/BundleOrderCreate.schema";

const BundlesOrderEdit = () => {
  const navigator = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { onEdit } = useBundlesOrder();

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<BundleOrderEditForm>({
    resolver: yupResolver(BundleOrderEditSchema),
    defaultValues: { displayOrder: Number(searchParams.get("dpO")) }
  });

  const { handleFunc, loading } = useFetch(editBundleOrder);

  const onClose = () => !loading && navigator(-1);

  const onSubmit = async (data: BundleOrderEditForm) => {
    const res = await handleFunc({
      displayOrder: data.displayOrder,
      id: Number(id)
    });
    if (res?.status === 200 && res?.data) {
      toast.success("Renditja është ndryshuar me sukses.");
      onClose();
      onEdit({
        displayOrder: data.displayOrder,
        id: Number(id)
      });
    } else toast.error("Ndodhi një gabim gjatë ndryshimit.");
  };

  return (
    <Modal
      show={true}
      position="center"
      onClose={onClose}
      buttonElement={
        <Button
          form="bundleorder-edit"
          className="flex justify-center items-center"
          type="submit"
          disabled={loading}
        >
          {loading ? <SpinLoader formatClassName="w-4 h-4 mr-2" /> : null}
          Konfirmo
        </Button>
      }
      title="Shto oferten në renditje"
    >
      <form
        id="bundleorder-edit"
        onSubmit={handleSubmit(onSubmit)}
        className="px-6 pt-8 pb-6"
      >
        <Input
          {...register("displayOrder")}
          label="Renditja e ofertes"
          placeholder="Shkruaj renditjen e pakos"
          error={errors.displayOrder?.message}
          type="number"
        />
      </form>
    </Modal>
  );
};

export default BundlesOrderEdit;
