import dayjs from "dayjs";
import { FC, useEffect, useMemo, useState } from "react";
import { IDayPicker } from "../Datepicker.types";
import Picker from "../Picker";
import DayWrapper from "./DayWrapper";

const DayPicker: FC<IDayPicker> = ({
  resetDateToggled,
  date,
  onChangeDate,
  onApply,
  paddingType,
  pickerProps,
  onlyModal = false,
  showApply,
  disabled,
  hasHelpers,
  disabledDays,
  modifiers,
  numberOfMonths,
  ...labelProps
}) => {
  const [show, setShow] = useState(false);
  const [selectedDay, setSelectedDay] = useState<Date | undefined>(date);
  const toYear = useMemo(() => dayjs(new Date()).add(1, "year").year(), []);
  useEffect(() => {
    if (resetDateToggled !== undefined) {
      onChangeDate && onChangeDate(undefined);
      setSelectedDay(undefined);
    }
  }, [resetDateToggled]);

  const onButtonApply = () => {
    onApply && onApply(date);
    setShow(false);
  };

  const _onChangeRange = (r: Date | undefined) => {
    onChangeDate && onChangeDate(r);
    setSelectedDay(r);
  };

  return (
    <DayWrapper
      show={show}
      setShow={setShow}
      date={selectedDay}
      wrapperClassName="relative w-full"
      paddingType={paddingType}
      onlyModal={onlyModal}
      disabled={disabled}
      onChangeDate={_onChangeRange}
      {...labelProps}
    >
      <Picker
        numberOfMonths={numberOfMonths}
        weekStartsOn={1}
        modifiers={modifiers}
        toYear={toYear}
        {...pickerProps}
        mode="single"
        defaultMonth={date}
        selected={selectedDay}
        hasHelpers={hasHelpers}
        onSelect={(r: Date | undefined) => {
          onChangeDate && onChangeDate(r);
          setSelectedDay(r);
        }}
        onApply={onButtonApply}
        onCancel={() => setShow(false)}
        showApply={showApply}
        disabledDays={disabledDays}
      />
    </DayWrapper>
  );
};

export default DayPicker;
