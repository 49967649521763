import { createContext } from "react";
import { useSearch } from "../hooks/useSearch";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import { IPagination } from "../interfaces/IPagination";
import { InvitedUserType } from "../interfaces/endpoints/IUser.types";
import {
  errorType,
  loadingType,
  responseType
} from "../interfaces/hooks/IFetch";
import { getPagedInvitedUsers } from "../services/Users.service";

export type InvitedUsersContextType = {
  response: responseType<IAxiosResponse<IPagination<InvitedUserType>>>;
  loading: loadingType;
  error: errorType;
  onDelete: (invitationGuid: string) => void;
  onCreate: (data: InvitedUserType) => void;
  handlePageChange: ({ selected }: { selected: number }) => void;
  page: number;
};

interface IInvitedUsersProvider {
  children: React.ReactNode;
}

export const InvitedUsersContex = createContext<InvitedUsersContextType>({
  response: {} as responseType<IAxiosResponse<IPagination<InvitedUserType>>>,
  loading: false,
  error: {} as errorType,
  onDelete: () => undefined,
  onCreate: () => undefined,
  handlePageChange: () => undefined,
  page: 1
});

export const InvitedUsersProvider: React.FC<IInvitedUsersProvider> = ({
  children
}) => {
  const { response, loading, error, setResponse, handlePageChange, page } =
    useSearch(getPagedInvitedUsers, {
      withQuerySearch: true,
      withFilter: true,
      filterSelector: "userRole",
      resetResponseOnError: true
    });

  const onDelete = (invitationGuid: string) => {
    setResponse((prev) =>
      prev
        ? {
            ...prev,
            data: {
              ...prev.data,
              result: prev.data.result.filter(
                (el) => el.invitationGuid !== invitationGuid
              )
            }
          }
        : null
    );
  };

  const onCreate = (data: InvitedUserType) => {
    setResponse((prev) => {
      return prev
        ? {
            ...prev,
            data: {
              ...prev.data,
              totalCount: (prev.data.totalCount || 0) + 1,
              result: [data, ...prev.data.result]
            }
          }
        : {
            status: 200,
            statusText: "200",
            data: {
              totalPages: 1,
              pageNumber: 1,
              pageSize: 12,
              totalCount: 1,
              result: [data]
            }
          };
    });
  };

  return (
    <InvitedUsersContex.Provider
      value={{
        response,
        loading,
        error,
        onDelete,
        onCreate,
        handlePageChange,
        page
      }}
    >
      {children}
    </InvitedUsersContex.Provider>
  );
};
