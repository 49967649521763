/* eslint-disable max-len */
import sq from "date-fns/locale/sq";
import { FC } from "react";
import {
  DayPicker,
  DayPickerBase,
  DayPickerDefaultProps,
  DayPickerMultipleProps,
  DayPickerRangeProps,
  DayPickerSingleProps,
  Months
} from "react-day-picker";
import useWindowSize from "../../../hooks/useWindowResize";
import Button from "../Button";
import CustomCaption from "./CustomCaption";
import Helper from "./Helper";
import styles from "./style.module.css";

type pickerType = DayPickerDefaultProps
  | DayPickerSingleProps
  | DayPickerMultipleProps
  | DayPickerRangeProps;

type IPicker = {
  onApply?: (data: DayPickerBase["selected"]) => void;
  onCancel?: () => void;
  hasHelpers?: boolean;
  showApply?: boolean;
  disabledDays?: Date[];
} & pickerType;

const Picker: FC<IPicker
> = ({ onCancel, onApply, hasHelpers,showApply=true, disabledDays, ...props }) => {
  const size = useWindowSize();

  return (
    <div className="">
      <DayPicker
        {...props}
        locale={sq}
        classNames={{
          ...styles
        }}
        {...((size["2xl"] || size.xl || size.lg) && {
          numberOfMonths: props.numberOfMonths || 2,
          pagedNavigation: props.pagedNavigation || true
        })}
        // showOutsideDays={true}
        components={{
          Caption: (captionProps) => (
            <CustomCaption
              {...captionProps}
              mode={props.mode}
              numberOfMonths={props.numberOfMonths || 2}
              hasHelpers={hasHelpers}
            />
          ),
          Months: (monthsProps) => {
            return (
              <div className="flex">
                <Months {...monthsProps} />
                {props.mode === "range" && hasHelpers ? (
                  <Helper selected={props.selected} onSelect={props.onSelect} />
                ) : null}
              </div>
            );
          }
        }}
        disabled={disabledDays}
      />
      {showApply &&(onApply || onCancel) ? <div className="mx-6 mb-4 flex justify-end gap-2">
        {onCancel ? <Button
          btnType="custom"
          padding="px-3 py-2"
          className="border border-gray-200 rounded"
          fontWeight="font-medium"
          rounded={false}
          onClick={onCancel}
        >
          Cancel
        </Button> : null}
        {onApply ? <Button
          fontWeight="font-medium"
          padding="px-5 py-2"
          className="rounded"
          rounded={false}
          onClick={() => onApply(props.selected)}
        >
          Apliko
        </Button> : null}
      </div> : null}
    </div>
  );
};

export default Picker;
