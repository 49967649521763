export interface IAnnouncements {
  result: IAnnouncement[];
  totalPages: number;
}

export interface IAnnouncement {
  id: number;
  title: string;
  description: string;
  stationFrom: number;
  stationNameFrom: string;
  stationTo: number;
  stationNameTo: string;
  languageId: number;
  announcementDate: string;
  url: string;
}

export interface IAnnouncementSchema {
  id: number;
  title: string;
  description: string;
  stationFrom: number;
  stationTo: number;
  languageId: number;
  announcementDate: string;
  url?: string;
}

export enum EnumAnnouncementLanguage {
  Anglisht = 1,
  Shqip = 2
}
