import { ReactNode } from "react";
import { cx } from "../../../utils/classnames";
// import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

export interface InputWrapperProps {
  className?: string;
  error?: boolean | string;
  label?: ReactNode;
  labelClassName?: string;
  name?: string;
  id?: string;
  children?: ReactNode;
}

const InputWrapper: React.FC<InputWrapperProps> = ({
  className,
  error,
  label,
  name,
  id,
  children,
  labelClassName
}) => {
  return (
    <div className={className}>
      {label && (
        <label
          htmlFor={id || name}
          className={cx([
            "text-neutral block mb-1.5 text-sm leading-[17px] whitespace-nowrap",
            labelClassName
          ])}
        >
          {label}
        </label>
      )}
      {children}
      {typeof error === "string" && (
        <div className="text-red-500 text-xs mt-0.5">{error}</div>
      )}
    </div>
  );
};

export default InputWrapper;
