import { axios } from "../config/axios";
import { IGuid, IPhoto } from "../interfaces/endpoints/IMedia.types";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";

type UploadPhotoParams = {
  guid?: string;
  body: FormData;
};

export const getGuid = async (): Promise<IAxiosResponse<IGuid>> => {
  return axios.get("media/GenerateGuid");
};

export const uploadPhoto = async ({
  guid,
  body
}: UploadPhotoParams): Promise<IAxiosResponse<IPhoto>> => {
  return axios.post(`media/UploadFile?guid=${guid}`, body, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const uploadBlogPhoto = async ({
  guid,
  body
}: UploadPhotoParams): Promise<IAxiosResponse<IPhoto>> => {
  return axios.post(`media/UploadBlogFile?guid=${guid}`, body, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const uploadOperatorPhoto = async ({
  guid,
  body
}: UploadPhotoParams): Promise<IAxiosResponse<IPhoto>> => {
  return axios.post(
    `media/UploadOperatorProfilePictureFile?guid=${guid}`,
    body,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
};
