import { FC } from "react";
import { useLocation } from "react-router-dom";
import { IHeaderPage } from "../../../interfaces/components/HeaderPage.types";
import VideoPopup from "../../ui/OpenVideoTutModal";
import HeaderActions from "./HeaderActions";
import HeaderDateRange from "./HeaderDateRange";
import Filters from "./HeaderFilters";

const PageHeader: FC<IHeaderPage> = ({
  link,
  hasFilter,
  hasNoInput,
  filterName,
  options,
  filterSelctor,
  videoTutorial,
  querySelector,
  dateFromSelector,
  dateToSelector
}) => {
  const { pathname } = useLocation();

  return (
    <div className="mb-4 flex justify-between items-start">
      {link && <HeaderActions {...link} />}
      {videoTutorial ? (
        <VideoPopup link={videoTutorial?.link} title={videoTutorial?.title} />
      ) : null}
      <div className=" flex flex-[2] justify-end">
        {pathname === "/office" && (
          <HeaderDateRange
            dateFromSelector={dateFromSelector}
            dateToSelector={dateToSelector}
          />
        )}
        <Filters
          hasNoInput={hasNoInput}
          hasFilter={hasFilter}
          filterName={filterName}
          options={options}
          filterSelctor={filterSelctor}
          querySelector={querySelector}
        />
      </div>
    </div>
  );
};

export default PageHeader;
