import {
  PaymentMethodEnum,
  TicketStatusEnum
} from "../../../components/reservations/res.enum";

export enum PassengerTypeEnum {
  adult = 1,
  children = 3,
  baby = 4
}

type ticketDetails = {
  arrivalStationName: string;
  countryId: 0;
  departureStationName: string;
  email: string;
  journeyDate: string;
  operatorName: string;
  stationFrom: number;
  stationTo: number;
  ticketId: number;
  tripId: number;
};
export type clients = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  passengerType: PassengerTypeEnum;
};
interface IGetJourneys {
  bookingId: string;
  clients: clients[];
  insertDate: string;
  oneWayTicketDetails: ticketDetails[];
  roundWayTicketDetails: ticketDetails[];
  paymentMethod: PaymentMethodEnum;
  paymentStatus: TicketStatusEnum;
  pdfLink: string;
  totalPrice: number;
}
export default IGetJourneys;
