import axios from "axios";
import { getToken } from "../utils/auth/getToken";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["Content-Type"] =
  "application/json, text/plain, */*";

export const setupAxios = () => {
  axios.interceptors.request.use((config) => {
    const token = getToken();

    if (token)
      config.headers!.Authorization = `${token.token_type} ${token.access_token}`;

    return config;
  });
  axios.interceptors.response.use(
    (response) => {
      // Edit response config
      const _response =
        typeof response.data?.totalPages === "number"
          ? { data: { ...response.data, result: response.data?.data } }
          : { data: response.data?.data || response.data };

      return {
        ..._response,
        status: response.status,
        statusText: response.statusText,
        statusCode: response.data?.statusCode || "",
        message: response.data?.message || ""
      };
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export { axios };
