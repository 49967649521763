import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import FormWrapper from "../../components/bundles/FormWrapper";
import {
  paymentMethods,
  paymentStatus
} from "../../components/bundles/reservations/utils/PaymentsOptions";
import InsertDate from "../../components/reservation_v2/InsertDate";
import ReservationForm from "../../components/reservation_v2/form/ReservationForm";
import useFetch from "../../hooks/useFetch";
import { IReservationFormSchemaV2 } from "../../interfaces/reservations/Reservations.types";
import { getReservationsDetails } from "../../services/Reservations.service";
import { ReservationSchema } from "../../utils/validations/Reservation.schema";

const ReservationEdit = () => {
  const methods = useForm<IReservationFormSchemaV2>({
    resolver: yupResolver(ReservationSchema),
    shouldFocusError: false
  });
  const { id } = useParams();

  const { loading, response, error } = useFetch(getReservationsDetails, {
    initRequest: true,
    params: { uniqueJourneyId: id || "" }
  });

  useEffect(() => {
    if (response)
      methods.reset({
        uniqueJourneyId: response.data.uniqueJourneyId,
        email: response.data.clientNames[0].email,
        oneWayTicketDetails: response.data.oneWayTicketDetails,
        roundWayTicketDetails: response.data.roundWayTicketDetails,
        paymentMethod: response.data.paymentMethodId
          ? paymentMethods.find(
              (el) => el.value === response.data.paymentMethodId
            )
          : { label: "None", value: 0 },
        paymentStatus: paymentStatus.find(
          (el) => el.value === response.data.paymentStatus
        )
      });
  }, [response]);

  return (
    <FormProvider {...methods}>
      <FormWrapper
        showPrompt={
          !methods.formState.isSubmitSuccessful &&
          !!Object.keys(methods.formState.dirtyFields).length
        }
        backTitle="Kthehu tek rezervimet"
        backTo={"/ticket/reservations"}
        backState={["Rezervimet", "Version 2.0"]}
        error={!!error}
        mode={"edit"}
        response={response}
        loading={loading}
        notFoundMessage="Rezervimi nuk u gjet"
        title={"Modifiko rezervimin"}
        rightHeaderContent={
          <InsertDate insertDate={response?.data.insertDate || ""} />
        }
      >
        <>
          {response?.data ? <ReservationForm details={response?.data} /> : null}
        </>
      </FormWrapper>
    </FormProvider>
  );
};

export default ReservationEdit;
