/* eslint-disable max-len */

import { FC } from "react";
import { IIcon } from "./IIcon";

const FileImageListIcon: FC<IIcon> = ({ color, className, size }) => {
  const currentColor = color || "currentColor";
  const _size = size || 16;
  return (
    <span className={className}>
      <svg
        width={_size}
        height={_size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="file-image-list">
          <g id="Union">
            <path
              d="M4.66659 4.66634C4.2984 4.66634 3.99992 4.96482 3.99992 5.33301V6.66634C3.99992 7.03453 4.2984 7.33301 4.66659 7.33301H5.99992C6.36811 7.33301 6.66659 7.03453 6.66659 6.66634V5.33301C6.66659 4.96482 6.36811 4.66634 5.99992 4.66634H4.66659Z"
              fill={currentColor}
            />
            <path
              d="M3.99992 9.33301C3.99992 8.96482 4.2984 8.66634 4.66659 8.66634H5.99992C6.36811 8.66634 6.66659 8.96482 6.66659 9.33301V10.6663C6.66659 11.0345 6.36811 11.333 5.99992 11.333H4.66659C4.2984 11.333 3.99992 11.0345 3.99992 10.6663V9.33301Z"
              fill={currentColor}
            />
            <path
              d="M8.66659 4.83301C8.39044 4.83301 8.16659 5.05687 8.16659 5.33301C8.16659 5.60915 8.39044 5.83301 8.66659 5.83301H11.9999C12.2761 5.83301 12.4999 5.60915 12.4999 5.33301C12.4999 5.05687 12.2761 4.83301 11.9999 4.83301L8.66659 4.83301Z"
              fill={currentColor}
            />
            <path
              d="M8.16659 7.99967C8.16659 7.72353 8.39044 7.49967 8.66659 7.49967L11.9999 7.49967C12.2761 7.49967 12.4999 7.72353 12.4999 7.99967C12.4999 8.27582 12.2761 8.49967 11.9999 8.49967L8.66659 8.49967C8.39044 8.49967 8.16659 8.27582 8.16659 7.99967Z"
              fill={currentColor}
            />
            <path
              d="M8.66659 10.1663C8.39044 10.1663 8.16659 10.3902 8.16659 10.6663C8.16659 10.9425 8.39044 11.1663 8.66659 11.1663L11.9999 11.1663C12.2761 11.1663 12.4999 10.9425 12.4999 10.6663C12.4999 10.3902 12.2761 10.1663 11.9999 10.1663L8.66659 10.1663Z"
              fill={currentColor}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.33325 3.33301C1.33325 2.22844 2.22868 1.33301 3.33325 1.33301H12.6666C13.7712 1.33301 14.6666 2.22844 14.6666 3.33301V12.6663C14.6666 13.7709 13.7712 14.6663 12.6666 14.6663H3.33325C2.22868 14.6663 1.33325 13.7709 1.33325 12.6663V3.33301ZM3.33325 2.33301H12.6666C13.2189 2.33301 13.6666 2.78072 13.6666 3.33301V12.6663C13.6666 13.2186 13.2189 13.6663 12.6666 13.6663H3.33325C2.78097 13.6663 2.33325 13.2186 2.33325 12.6663V3.33301C2.33325 2.78072 2.78097 2.33301 3.33325 2.33301Z"
              fill={currentColor}
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

export default FileImageListIcon;
