/* eslint-disable max-len */
import { FC } from "react";
import { IIcon } from "./IIcon";

const BusDashboardIcon: FC<IIcon> = ({ color }) => {
  const currentColor = color || "currentColor";
  return (
    <span>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="bus">
          <g id="Union">
            <path
              d="M7.5 16C8.32843 16 9 15.3284 9 14.5C9 13.6716 8.32843 13 7.5 13C6.67157 13 6 13.6716 6 14.5C6 15.3284 6.67157 16 7.5 16Z"
              fill={currentColor}
            />
            <path
              d="M18 14.5C18 15.3284 17.3284 16 16.5 16C15.6716 16 15 15.3284 15 14.5C15 13.6716 15.6716 13 16.5 13C17.3284 13 18 13.6716 18 14.5Z"
              fill={currentColor}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.25 6C3.25 3.92893 4.92893 2.25 7 2.25H17C19.0711 2.25 20.75 3.92893 20.75 6V16C20.75 16.854 20.3608 17.617 19.75 18.1214V20C19.75 20.9665 18.9665 21.75 18 21.75H17C16.0335 21.75 15.25 20.9665 15.25 20V18.75H8.75V20C8.75 20.9665 7.9665 21.75 7 21.75H6C5.0335 21.75 4.25 20.9665 4.25 20V18.1214C3.63925 17.617 3.25 16.854 3.25 16V6ZM4.75 6C4.75 4.75736 5.75736 3.75 7 3.75H17C18.2426 3.75 19.25 4.75736 19.25 6V10.25H4.75V6ZM16.75 18.75V20C16.75 20.1381 16.8619 20.25 17 20.25H18C18.1381 20.25 18.25 20.1381 18.25 20V18.7388C18.1677 18.7462 18.0843 18.75 18 18.75H16.75ZM18 17.25C18.6904 17.25 19.25 16.6904 19.25 16V11.75H4.75V16C4.75 16.6904 5.30964 17.25 6 17.25H18ZM7.25 20V18.75H6C5.91573 18.75 5.83234 18.7462 5.75 18.7388V20C5.75 20.1381 5.86193 20.25 6 20.25H7C7.13807 20.25 7.25 20.1381 7.25 20Z"
              fill={currentColor}
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

export default BusDashboardIcon;
