import BusDashboardIcon from "../../components/shared/svgs/BusDashboad";
import useFetch from "../../hooks/useFetch";
import { getOperatorActiveLinesAndAgents } from "../../services/Analytics.service";
import AccountProfileUsersIcon from "../shared/svgs/AccountProfileUsersIcon";
import CalendarIcon from "../shared/svgs/CalendarIcon";

const ActiveLinesAndAgents = () => {
  const { response, loading } = useFetch(getOperatorActiveLinesAndAgents, {
    initRequest: true
  });
  return (
    <div className="flex gap-4">
      <Item
        loading={loading}
        x={response?.data.activeLines}
        title="totali i linjave aktive në platformë"
        icon={<BusDashboardIcon />}
      />
      <Item
        loading={loading}
        x={response?.data.activeAgents}
        title="agjenta aktiv në platformë"
        icon={<AccountProfileUsersIcon />}
      />
      <Item
        loading={loading}
        x={response?.data.todayActiveLines}
        title="linjat aktive për sot"
        icon={<CalendarIcon size={24}/>}
      />
    </div>
  );
};

export default ActiveLinesAndAgents;

const Item = ({
  title,
  x,
  loading,
  icon
}: {
  title: string;
  x?: number;
  loading: boolean;
  icon: React.ReactNode;
}) => (
  <div className="py-5 pl-6 pr-8 flex justify-between gap-2.5 border border-gray-200 rounded-lg w-full ">
    <div className="flex flex-col gap-2.5">
      <p className="text-2xs font-medium text-neutral uppercase">{title}</p>
      {!loading ? (
        <p className="text-2xl font-medium">{x}</p>
      ) : (
        <div className="w-1/2 h-[33px] bg-gray-200 rounded-lg animate-pulse" />
      )}
    </div>
    <div className="my-1 flex items-center justify-center w-12 rounded-full bg-[#ECF8F4] text-green">
      {icon}
    </div>
  </div>
);
