import { IPagination } from "../IPagination";

// export interface IPromotionCode {
//   promotionCodeType: number;
//   name: string;
//   promoCode: ISingleCode;
//   email: string;
//   expirationDate: string;
//   description: string;
// }
export enum PromotionCodeTypeEnum {
  student = 1,
  reseller = 2
}

export interface IPromotionCode {
  count: number;
  id: number;
  insertDate: string;
  promotionCodeType: number;
  name: string;
  promoCode: string;
  expirationDate: string;
  email: string;
  description: string;
  numberOfTimesUsed: number;
  operatorId: number;
  dateUpdated: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IPromotionCodes extends IPagination<IPromotionCode> {}
