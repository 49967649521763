import { FC } from "react";
import useWindowSize from "../../../../hooks/useWindowResize";
import Animation from "../../Animation";
import ClickAwayListener from "../../Clickaway";
import Modal from "../../Modal";
import { IDayWrapper } from "../Datepicker.types";
import Input from "./Input";

const DayWrapper: FC<IDayWrapper> = ({
  children,
  show,
  setShow,
  date,
  paddingType,
  onlyModal,
  wrapperClassName,
  disabled,
  ...labelProps
}) => {
  const size = useWindowSize();
  if (size["2xl"] && !onlyModal) {
    return (
      <ClickAwayListener onClickAway={() => setShow(false)}>
        <div className={wrapperClassName}>
          <Input
            date={date}
            setShow={setShow}
            show={show}
            {...labelProps}
            paddingType={paddingType}
            disabled={disabled}
          />
          <Animation show={show} type="fade-top">
            <div
              className="xl:z-50 xl:absolute xl:right-0 xl:top-[calc(100%+0.5rem)] bg-white rounded-md
                        shadow-calendar"
            >
              {children}
            </div>
          </Animation>
        </div>
      </ClickAwayListener>
    );
  } else
    return (
      <div>
        <Input
          date={date}
          setShow={setShow}
          show={show}
          {...labelProps}
          paddingType={paddingType}
          disabled={disabled}
          onlyModal={onlyModal}
        />
        <Modal
          position="top"
          show={show}
          onClose={() => setShow(false)}
          modalWidthTouch=""
          hasHeader={false}
        >
          {/* <div className="px-6 ">
              {date && (
                <span className="font-semibold">{format(date, "PP")}</span>
              )}
            </div> */}
          {children}
        </Modal>
      </div>
    );
};

export default DayWrapper;
