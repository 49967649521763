
const CustomLengendPerLine = () => {
  return (
    <div className="flex justify-end items-center mt-6">
      <div className="flex justify-center items-center ">
        <div className="w-2 h-2 rounded-full bg-blue mr-2" />
        <p className="text-xs font-medium leading-[18px]">
          Totali i shitjeve për linja
        </p>
      </div>
      <div className="flex justify-center items-center ml-2 p-2">
        <div className="w-2 h-2 rounded-full bg-confirm mr-2" />
        <p className="text-xs font-medium leading-[18px]">
          Numri i rezervimeve për linja
        </p>
      </div>
    </div>
  );
};

export default CustomLengendPerLine;
