import { CSSProperties, ReactNode } from "react";
import { cx } from "../../utils/classnames";

interface BoxProps {
  className?: string;
  shadow?: boolean | string;
  rounded?: boolean | string;
  border?: boolean | string;
  padding?: boolean | string;
  style?: CSSProperties;
  children: ReactNode;
}

const Box: React.FC<BoxProps> = ({
  children,
  className,
  style,
  shadow = true,
  rounded = true,
  border = true,
  padding = true
}) => (
  <div
    className={cx([
      className,
      typeof rounded === "string" ? rounded : rounded && "rounded-lg",
      typeof border === "string" ? border : border && "border border-gray-200",
      typeof shadow === "string" ? shadow : shadow && "shadow",
      typeof padding === "string" ? padding : padding && "p-4",
      "bg-white"
    ])}
    style={style}
  >
    {children}
  </div>
);

export default Box;
