import { forwardRef } from "react";
import { TextareaProps } from "./Input.types";
import InputWrapper from "./InputWrapper";

const TextArea = forwardRef(
  (
    {
      inputClassName,
      border,
      label,
      className,
      error,
      labelClassName,
      ...props
    }: TextareaProps,
    ref: any
  ) => (
    <InputWrapper
      id={props.id}
      label={label}
      name={props.name}
      className={className}
      error={error}
      labelClassName={labelClassName}
    >
      <textarea
        {...props}
        id={props.id || props.name}
        ref={ref}
        className={`${inputClassName}
        text-sm w-full outline-none focus:border-primary transition-all duration-200
         ${
           typeof border === "string" ? border : "border border-gray-300"
         } p-3 rounded-lg`}
      ></textarea>
    </InputWrapper>
  )
);

export default TextArea;
