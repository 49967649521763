import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { FC } from "react";
import { cx } from "../../utils/classnames";
import FlagAL from "../shared/svgs/FlagAL";
import FlagUS from "../shared/svgs/FlagUS";
import Animation from "../ui/Animation";

interface ILocalizations {
  activeId: 1 | 2;
  onChange: (a: 1 | 2) => void;
  alError: boolean;
  enError: boolean;
}

const Localizations: FC<ILocalizations> = ({
  activeId,
  onChange,
  alError,
  enError
}) => {
  return (
    <div className="border-b border-gray-200 ">
      <style>{`
          .type-left {
            left: 0;
            right: calc(50% + 15px);
            transition: right 0.4s ease 85ms, left 0.4s ease;
          }
          .type-right {
            right: -6px;
            left: calc(50% + 0px);
            transition: left 0.4s ease 85ms, right 0.4s ease;
          }
        `}</style>
      <div className="inline-flex relative">
        <button
          type="button"
          className={cx([
            "flex items-center mr-3 p-2 duration-500 transition-colors rounded-t-md",
            alError && activeId !== 2 && "bg-warning bg-opacity-10"
          ])}
          onClick={() => {
            onChange(2);
          }}
        >
          <span className="w-5 h-5">
            <FlagAL />
          </span>
          <span className="ml-1.5 text-sm text-neutral font-medium">Shqip</span>
          <Animation show={alError} type="fade-left">
            <div>
              <ExclamationTriangleIcon className="h-4 w-4 ml-2 text-warning" />
            </div>
          </Animation>
        </button>
        <button
          type="button"
          className={cx([
            "flex items-center p-2 duration-500 transition-colors rounded-t-md",
            enError && activeId !== 1 && "bg-warning bg-opacity-10 "
          ])}
          onClick={() => {
            onChange(1);
          }}
        >
          <span className="w-5 h-5">
            <FlagUS />
          </span>

          <span className="ml-1.5 text-sm text-black font-medium">
            Anglisht
          </span>
          <Animation show={enError} type="fade-left">
            <div>
              <ExclamationTriangleIcon className="h-4 w-4 ml-2 text-warning" />
            </div>
          </Animation>
        </button>
        <span
          className={cx([
            "absolute bottom-0 h-0.5 bg-primary",
            "transform-gpu",
            activeId === 2 ? "type-left" : "type-right"
          ])}
        />
      </div>
    </div>
  );
};

export default Localizations;
