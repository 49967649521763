import { axios } from "../config/axios";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import { IPagination } from "../interfaces/IPagination";
import {
  IUserRole,
  IUserRoleSchema
} from "../interfaces/endpoints/IUserRoles.types";
import { serialize } from "../utils/serializeToQueryString";

export type getAllUserRolesType = {
  pageIndex: number;
  pageSize: number;
};

export const getAllUserRoles = async (
  att: getAllUserRolesType
): Promise<IAxiosResponse<IPagination<IUserRoleSchema>>> => {
  return axios.get<IPagination<IUserRoleSchema>>(
    `UserRole/UserRoles${serialize(att)}`
  );
};

export const getCurrentUserRole = async (
  id: number
): Promise<IAxiosResponse<IUserRoleSchema>> => {
  return axios.get<IUserRoleSchema>(`UserRole/${id}`);
};

export const createUserRole = async (body: IUserRole): Promise<number> => {
  return axios.post("UserRole", body);
};

export const editUserRole = async (
  body: IUserRole,
  id: number
): Promise<number> => {
  return axios.put(`UserRole/${id}`, body);
};

export const deleteUserRoleById = async (
  id: number
): Promise<IAxiosResponse<IUserRole>> => {
  return axios.delete(`UserRole/${id}`);
};
