import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { FC, ReactNode } from "react";
// import AlertIcon from "../../public/svgs/Alert";
import { cx } from "../../utils/classnames";

export interface IAlertBox {
  message: ReactNode;
  type?: "info" | "warning";
  className?: string;
  show?: boolean;
}

const AlertBox: FC<IAlertBox> = ({
  type = "info",
  message,
  className,
  show = true
}) => {
  if (!show) return null;
  return (
    <div
      className={cx([
        "px-2 py-1 md:py-2 rounded-lg flex items-center w-full",
        type === "info" && "bg-blue-100",
        type === "warning" && "bg-warning-100 bg-opacity-50",
        className
      ])}
    >
      <ExclamationCircleIcon
        className={cx([
          "w-4 h-4 mr-2 self-start",
          type === "info" && "text-blue",
          type === "warning" && "text-warning"
        ])}
      />
      <span className="text-xs text-black flex words-break w-full">{message}</span>
    </div>
  );
};

export default AlertBox;
