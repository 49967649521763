/* eslint-disable max-len */

import { FC } from "react";
import { IIcon } from "./IIcon";

const Seating: FC<IIcon> = ({ color, size }) => {
  const currentColor = color || "currentColor";
  const currentSize = size || 16;
  return (
    <span>
      <svg
        width={currentSize}
        height={currentSize}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-auto h-auto"
      >
        <path
          d="M10.5 16.6875H7.5C5.025 16.6875 3.5325 16.1925 3.255 13.68C3.21 13.3425 3.1875 12.9375 3.1875 12.375V5.625C3.1875 5.0625 3.21 4.65 3.2625 4.2975C3.5325 1.8075 5.025 1.3125 7.5 1.3125H10.5C12.975 1.3125 14.4675 1.8075 14.745 4.32C14.79 4.6575 14.8125 5.0625 14.8125 5.625V12.375C14.8125 12.9375 14.79 13.35 14.7375 13.7025C14.4675 16.1925 12.975 16.6875 10.5 16.6875ZM7.5 2.4375C5.0175 2.4375 4.545 2.94 4.3725 4.44C4.335 4.755 4.3125 5.115 4.3125 5.625V12.375C4.3125 12.885 4.335 13.245 4.3725 13.5375C4.5375 15.06 5.0175 15.5625 7.5 15.5625H10.5C12.9825 15.5625 13.455 15.06 13.6275 13.56C13.6725 13.245 13.6875 12.885 13.6875 12.375V5.625C13.6875 5.115 13.665 4.755 13.6275 4.4625C13.4625 2.94 12.9825 2.4375 10.5 2.4375H7.5Z"
          fill={currentColor}
        />
        <path
          d="M1.5 15.1875C1.1925 15.1875 0.9375 14.9325 0.9375 14.625V3.375C0.9375 3.0675 1.1925 2.8125 1.5 2.8125C1.8075 2.8125 2.0625 3.0675 2.0625 3.375V14.625C2.0625 14.9325 1.8075 15.1875 1.5 15.1875Z"
          fill={currentColor}
        />
        <path
          d="M16.5 14.8125C16.1925 14.8125 15.9375 14.5575 15.9375 14.25V3C15.9375 2.6925 16.1925 2.4375 16.5 2.4375C16.8075 2.4375 17.0625 2.6925 17.0625 3V14.25C17.0625 14.5575 16.8075 14.8125 16.5 14.8125Z"
          fill={currentColor}
        />
      </svg>
    </span>
  );
};

export default Seating;
