/* eslint-disable max-len */

import { FC } from "react";
import { IIcon } from "./IIcon";

const Other: FC<IIcon> = ({ color, size }) => {
  const currentColor = color || "currentColor";
  const currentSize = size || 16;
  return (
    <span>
      <svg
        width={currentSize}
        height={currentSize}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-auto h-auto"
      >
        <path
          d="M8 5.9375C8.31066 5.9375 8.5625 6.18934 8.5625 6.5V10.4375H9.5C9.81066 10.4375 10.0625 10.6893 10.0625 11C10.0625 11.3107 9.81066 11.5625 9.5 11.5625H6.5C6.18934 11.5625 5.9375 11.3107 5.9375 11C5.9375 10.6893 6.18934 10.4375 6.5 10.4375H7.4375V7.0625H6.5C6.18934 7.0625 5.9375 6.81066 5.9375 6.5C5.9375 6.18934 6.18934 5.9375 6.5 5.9375H8Z"
          fill={currentColor}
        />
        <path
          d="M8 5C8.41421 5 8.75 4.66421 8.75 4.25C8.75 3.83579 8.41421 3.5 8 3.5C7.58579 3.5 7.25 3.83579 7.25 4.25C7.25 4.66421 7.58579 5 8 5Z"
          fill={currentColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5ZM8 14.375C11.5208 14.375 14.375 11.5208 14.375 8C14.375 4.47918 11.5208 1.625 8 1.625C4.47918 1.625 1.625 4.47918 1.625 8C1.625 11.5208 4.47918 14.375 8 14.375Z"
          fill={currentColor}
        />
      </svg>
    </span>
  );
};

export default Other;
