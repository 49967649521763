
import { FC } from "react";
import { IIcon } from "./IIcon";

const Add: FC<IIcon> = ({ color, size }) => {
  const currentColor = color || "currentColor";
  const currentSize = size || 16;
  return (
    <span>
      <svg
        width={currentSize}
        height={currentSize} viewBox="0 0 16 16"
        fill="none" xmlns="http://www.w3.org/2000/svg"
        className="w-auto h-auto"
      >
        {/* eslint-disable-next-line max-len */}
        <path fillRule="evenodd" clipRule="evenodd" d="M8.00001 14.6663C11.6819 14.6663 14.6667 11.6816 14.6667 7.99967C14.6667 4.31778 11.6819 1.33301 8.00001 1.33301C4.31811 1.33301 1.33334 4.31778 1.33334 7.99967C1.33334 11.6816 4.31811 14.6663 8.00001 14.6663ZM8.00001 4.66634C8.3682 4.66634 8.66668 4.96482 8.66668 5.33301V7.33301H10.6667C11.0349 7.33301 11.3333 7.63148 11.3333 7.99967C11.3333 8.36786 11.0349 8.66634 10.6667 8.66634H8.66668V10.6663C8.66668 11.0345 8.3682 11.333 8.00001 11.333C7.63182 11.333 7.33334 11.0345 7.33334 10.6663V8.66634H5.33334C4.96515 8.66634 4.66668 8.36786 4.66668 7.99967C4.66668 7.63148 4.96515 7.33301 5.33334 7.33301H7.33334V5.33301C7.33334 4.96482 7.63182 4.66634 8.00001 4.66634Z" fill={currentColor} />
      </svg>
    </span>
  );
};

export default Add;
