import axios from "axios";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import { IPagination } from "../interfaces/IPagination";
import { SelectParamsType } from "../interfaces/ISelectParams";
import {
  IBundleFeature,
  IUploadTravelBundleIcon
} from "../interfaces/bundles/BundleFeatures";
import { serialize } from "../utils/serializeToQueryString";

export const getPagedBundleIncludes = async (
  params: SelectParamsType
): Promise<IAxiosResponse<IPagination<IBundleFeature>>> => {
  return axios.get(`TravelBundle/TravelBundleIncludes${serialize(params)}`);
};

export const createBundleFeature = async ({
  data
}: {
  data: IBundleFeature;
}): Promise<IAxiosResponse<IBundleFeature>> => {
  return axios.post("/TravelBundle/TravelBundleInclude", data);
};

export const editBundleFeature = async ({
  data
}: {
  data: IBundleFeature;
}): Promise<IAxiosResponse<IBundleFeature>> => {
  return axios.put("/TravelBundle/TravelBundleInclude", data);
};

export const UploadTravelBundleIncludeIcon = async (
  data: FormData
): Promise<IAxiosResponse<IUploadTravelBundleIcon>> => {
  return axios.post("/TravelBundle/UploadTravelBundleIncludeIcon", data, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const getTravelBundleIncludeById = async (
  id: number
): Promise<IAxiosResponse<IBundleFeature>> => {
  return axios.get(`/TravelBundle/TravelBundleInclude/${id}`);
};
