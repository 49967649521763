import React, { useEffect } from "react";
import { DateRange } from "react-day-picker";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useSearchParams } from "react-router-dom";
import { defaultConfig } from "../../../hooks/useSearch";
import { IHeaderDateRange } from "../../../interfaces/components/HeaderPage.types";
import Button from "../../ui/Button";
import RangePicker from "../../ui/datepicker/range/RangePicker";

export type reservation = {
  date: DateRange | undefined;
};

const HeaderDateRange: React.FC<IHeaderDateRange> = ({
  dateFromSelector = defaultConfig.dateFromSelector,
  dateToSelector = defaultConfig.dateToSelector
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation();
  const dateFromParam = searchParams.get(dateFromSelector);
  const dateToParam = searchParams.get(dateToSelector);

  const defaultValues = React.useMemo(() => {
    return {
      date: {
        from: dateFromParam ? new Date(dateFromParam) : undefined,
        to: dateToParam ? new Date(dateToParam) : undefined
      }
    };
  }, [dateFromParam, dateToParam]);

  const { control, setValue, reset } = useForm<reservation>();

  useEffect(() => {
    setValue("date", defaultValues.date, { shouldDirty: true });
  }, [defaultValues.date, setValue]);

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const res = `${year}-${month}-${day}`;
    return res;
  };

  const handleDate = (dateRange: DateRange | undefined) => {
    if (
      !dateRange ||
      (dateRange.from === undefined && dateRange.to === undefined)
    ) {
      searchParams.delete("dateFrom");
      searchParams.delete("dateTo");
    } else {
      const { from, to } = dateRange;
      if (from) {
        searchParams.set("dateFrom", formatDate(from));
      } else {
        searchParams.delete("dateFrom");
      }
      if (to) {
        searchParams.set("dateTo", formatDate(to));
      } else {
        searchParams.delete("dateTo");
      }
    }
    setSearchParams(searchParams, { replace: true, state });
  };

  const handleClearClick = () => {
    reset({ date: undefined });

    searchParams.delete("dateFrom");
    searchParams.delete("dateTo");
    setSearchParams(searchParams, { replace: true, state });
  };

  return (
    <Controller
      control={control}
      name={"date"}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        return (
          <div className=" flex gap-3 items-center justify-end max-w-[420px] w-full">
            {(dateFromParam || dateToParam) && (
              <Button btnType="primary" onClick={handleClearClick}>
                Clear
              </Button>
            )}
            <div className=" max-w-[340px] w-full">
              <RangePicker
                onChange={(r) => {
                  onChange(r);
                  handleDate(r);
                }}
                showApply={false}
                range={value}
                iconColor="secondary"
                iconSide="right"
                placeholder="Zgjedh data e udhëtimit"
                error={error?.message}
                paddingType="fill"
              />
            </div>
          </div>
        );
      }}
    />
  );
};

export default HeaderDateRange;
