import { Controller, useFormContext } from "react-hook-form";
import { OfficeReservationSchema } from "../../../pages/office/ChooseLine";

interface RowItem {
  firstText: string;
  secondText: string;
  registerName: keyof OfficeReservationSchema["passagers"];
}

const RowItem: React.FC<RowItem> = ({
  firstText,
  secondText,
  registerName
}) => {
  const { control } = useFormContext<OfficeReservationSchema>();
  const btnCss=
    "w-8 h-8 flex items-center justify-center border rounded-full hover:bg-gray-100 cursor-pointer";

  return (
    <div className="flex items-center justify-between gap-11 my-2">
      <div>
        <p className="text-sm font-medium">{firstText}</p>
        <p className="text-xs text-gray">{secondText}</p>
      </div>
      <Controller
        control={control}
        name={`passagers.${registerName}`}
        render={({ field: { value, onChange } }) => {
          const handleChange = (val: number) => {
            if ((registerName !== "adult" && val >= 0 && val <= 9) ||
              (registerName === "adult" && val >= 1 && val <= 9)) {
              onChange(val);
            }
          };
          return (
            <div className="flex items-center">
              <span
                onClick={() => handleChange(value - 1)}
                className={btnCss}>
                -
              </span>
              <span className="w-10 text-center">{value}</span>
              <span
                onClick={() => handleChange(value + 1)}
                className={btnCss}>
                +
              </span>
            </div>
          );
        }}
      />
    </div>
  );
};

export default RowItem;
