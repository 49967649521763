import { BaseType } from "./Select.types";

export function instanceOfBaseType(
  object: unknown,
  parameter: string
): object is BaseType {
  return (
    object !== null &&
    typeof object === "object" &&
    `${parameter}` in object
  );
}
