import { FC } from "react";
import { IIcon } from "./IIcon";

const Radio: FC<IIcon> = ({ color, size, className }) => {
  const currentColor = color || "currentColor";
  const currentSize = size || 10;
  return (
    <span className={className}>
      <svg
        width={currentSize}
        height={currentSize}
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.99967 9.16659C7.30086 9.16659 9.16634 7.30111 9.16634 4.99992C9.16634 2.69873 7.30086
          0.833252 4.99967 0.833252C2.69849 0.833252 0.833008 2.69873 0.833008 4.99992C0.833008 7.30111
           2.69849 9.16659 4.99967 9.16659ZM4.99967 6.66659C5.92015 6.66659 6.66634 5.92039 6.66634
            4.99992C6.66634 4.07944 5.92015 3.33325 4.99967 3.33325C4.0792 3.33325 3.33301 4.07944
            3.33301 4.99992C3.33301 5.92039 4.0792 6.66659 4.99967 6.66659Z"
          // fill="#F15B2D"
          fill={currentColor}
        />
      </svg>
    </span>
  );
};

export default Radio;
