import { useCallback, useEffect, useRef, useState } from "react";
import { axios } from "../config/axios";

export interface RequestStateProps {
  loading: boolean;
  error: any | null;
  // data can hold any value
  response: any;
}

export type RequestMethodTypes = "get" | "post" | "put" | "delete";

export const useRequest = (
  url: string,
  method: RequestMethodTypes,
  initReq: boolean = true
) => {
  // subsctiption ref
  const subscriptionRef = useRef(false);

  // form state
  const [requestState, setRequestState] = useState<RequestStateProps>({
    loading: method === "get" && initReq ? true : false,
    error: null,
    response: null
  });

  const handleRequest = useCallback(
    async(
      data?: object,
      successCallback?: (response: any) => void,
      errorCallback?: (error: any) => void,
      rewriteUrl?: string
    ) => {
      // if subscribed trigger callback
      if (subscriptionRef.current) {
        // generate fetch function
        setRequestState((current) =>
          !current.loading ? { ...current, loading: true } : current
        );
        try {
          const response = await axios[method](rewriteUrl || url, data);
          setRequestState(() => ({
            error: null,
            response,
            loading: false
          }));
          successCallback && successCallback(response);
        } catch (error) {
          setRequestState((current) => ({ ...current, error, loading: false }));
          errorCallback && errorCallback(error);
        }
      }
    },
    [url, method]
  );
  useEffect(() => {
    // on mount subscribe
    subscriptionRef.current = true;
    if (method === "get" && initReq)
      handleRequest()
        // not sure this then catch
        .then(() => null)
        .catch(() => null);
    return () => {
      // on unmount unsubscribe to prevent memory leaks
      subscriptionRef.current = false;
    };
  }, [method, initReq, handleRequest]);

  return {
    requestState,
    handleRequest
  };
};
