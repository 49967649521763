import { useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { apiFuncType } from "../interfaces/hooks/IFetch";
import { searchConfigType } from "../interfaces/hooks/ISearch";
import { parseQueryString } from "../utils/parseQueryString";
import useFetch from "./useFetch";

export const defaultConfig = {
  paginationSelector: "pageindex",
  withPagination: true,
  querySelector: "searchkeyword",
  withQuerySearch: false,
  filterSelector: "filter",
  withFilter: false,
  resetResponseOnError: false,
  withDateFrom: false,
  dateFromSelector: "dateFrom",
  withDateTo: false,
  dateToSelector: "dateTo"
};

export const useSearch = <T, D = undefined>(
  apiFunc: apiFuncType<T, D>,
  config?: searchConfigType
) => {
  const {
    paginationSelector = config?.paginationSelector ||
     defaultConfig.paginationSelector,
    withPagination = config?.withPagination || defaultConfig.withPagination,
    querySelector = config?.querySelector || defaultConfig.querySelector,
    withQuerySearch = config?.withQuerySearch || defaultConfig.withQuerySearch,
    filterSelector = config?.filterSelector || defaultConfig.filterSelector,
    withFilter = config?.withFilter || defaultConfig.withFilter,
    resetResponseOnError = config?.resetResponseOnError ||
    defaultConfig.resetResponseOnError,
    dateFromSelector = config?.dateFromSelector || defaultConfig.dateFromSelector,
    dateToSelector = config?.dateToSelector || defaultConfig.dateToSelector,
    withDateFrom = config?.withDateFrom || defaultConfig.withDateFrom,
    withDateTo = config?.withDateTo || defaultConfig.withDateTo
  } = config || defaultConfig;

  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation();
  const handlePageChange = ({ selected }: { selected: number }) => {
    if (selected !== 0) {
      searchParams.set(paginationSelector, `${selected + 1}`);
    } else searchParams.delete(paginationSelector);
    setSearchParams(searchParams, { replace: false, state });
  };

  const {
    [paginationSelector]: page,
    [querySelector]: query,
    [filterSelector]: filter,
    [dateFromSelector]: fromDate,
    [dateToSelector]: toDate
  } = parseQueryString(searchParams.toString());

  const params = useMemo(() => {
    const model = {} as D extends undefined ? never : D;
    return {
      ...model,
      ...config?.otherQuery,
      ...(withPagination && {
        [paginationSelector]: page || 1
      }),
      ...(withQuerySearch && { [querySelector]: query }),
      ...(withFilter && filter && { [filterSelector]: filter }),
      ...(withDateFrom && {  [dateFromSelector]: fromDate }),
      ...(withDateTo && { [dateToSelector]: toDate })
    };
  }, [page, query,fromDate, toDate, filter, JSON.stringify(config?.otherQuery)]);

  const { response, loading, error, setResponse, setError, setLoading } =
    useFetch<T, D>(apiFunc, {
      initRequest: true,
      params,
      resetResponseOnError
    });

  return {
    response,
    loading,
    error,
    query,
    page: parseInt(`${page || 1}`),
    filter,
    setResponse,
    setError,
    setLoading,
    handlePageChange
  };
};
