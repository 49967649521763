import { CheckIcon } from "@heroicons/react/24/outline";
import { cx } from "../../../../utils/classnames";
import { IListItem } from "../Select.types";
import { instanceOfBaseType } from "../utils";

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const ListItem = <T extends unknown>({
  active,
  item,
  index,
  getItemProps,
  getOptionLabel,
  highlightedIndex
}: IListItem<T>) => {
  const _getOptionLabel = (o: T) => {
    if (getOptionLabel) return getOptionLabel(o);
    if (instanceOfBaseType(o, "label")) return o.label;
    return (
      <span className="text-warning">
        No label prop, please use{" "}
        <span className="italic font-bold">getOptionLabel</span> method to use
        custom label
      </span>
    );
  };
  return (
    <li
      className={cx([
        highlightedIndex === index && "bg-primary bg-opacity-5",
        active ? "text-primary" : "text-black",
        "p-3 flex justify-between items-center text-sm cursor-pointer"
      ])}
      {...getItemProps({ item, index })}
    >
      <span>{_getOptionLabel(item)}</span>
      {active && <CheckIcon className="w-4 h-4 text-primary" />}
    </li>
  );
};

export default ListItem;
