/* eslint-disable max-lines */
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import FormWrapper from "../../../components/ui/FormWrapper";
import Input from "../../../components/ui/input/Input";
import ToggleButton from "../../../components/user-roles/roles/ToggleButton";
import useFetch from "../../../hooks/useFetch";
import useNavigateState from "../../../hooks/useNavigateState";
import { IUserRole } from "../../../interfaces/endpoints/IUserRoles.types";
import {
  createUserRole,
  editUserRole,
  getCurrentUserRole
} from "../../../services/UserRole.service";
import { RolesSchema } from "../../../utils/validations/Role.schema";

interface AddRoleProps {
  mode: "create" | "edit";
}

const AddRole: React.FC<AddRoleProps> = ({ mode }) => {
  const { id } = useParams();
  const methods = useForm<IUserRole>({
    resolver: yupResolver(RolesSchema)
  });
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = methods;
  const navigate = useNavigate();
  const stateCrumbs = useNavigateState(["Rolet"]);
  const { state }: any = useLocation();

  const { response } = useFetch(getCurrentUserRole, {
    initRequest: mode === "edit",
    params: Number(id)
  });

  useEffect(() => {
    if (mode === "edit" && response && response.data) {
      Object.entries(response.data).forEach(([key, value]) => {
        const userRole = key as keyof IUserRole;
        setValue(userRole, value);
      });
    }
  }, [response, id, setValue]);

  const onSubmit: SubmitHandler<IUserRole> = async (data) => {
    if (mode === "edit") {
      await editUserRole(data, Number(id))
        .then(() => {
          toast.success("Roli është ndryshuar me sukses.", {
            position: "top-right"
          });
          setTimeout(() => {
            navigate(`/users/roles?pageindex=${state.page ? state.page : 1}`, {
              state: stateCrumbs
            });
          }, 500);
        })
        .catch(() => toast.error("Ndodhi një gabim gjatë ndryshimit."));
    } else {
      await createUserRole(data)
        .then(() => {
          toast.success("Roli është shtuar me sukses.", {
            position: "top-right"
          });
          setTimeout(() => {
            navigate("/users/roles", { state: ["Roli i udhëtimeve"] });
          }, 500);
        })
        .catch(() =>
          toast.error("Ndodhi një gabim gjatë krijimit.", {
            position: "top-right"
          })
        );
    }
  };
  return (
    <FormProvider {...methods}>
      <Link
        className="flex items-center mb-4 text-sm font-semibold text-primary"
        to={"/users/roles"}
        state={["Rolet"]}
      >
        <ChevronLeftIcon className="w-4 h-4 mr-2" />
        <span>Kthehu tek lista</span>
      </Link>
      <FormWrapper
        showPrompt={
          !methods.formState.isSubmitSuccessful &&
          !!Object.keys(methods.formState.dirtyFields).length
        }
        onSubmit={handleSubmit(onSubmit)}
        title={mode === "edit" ? "Modifiko rolin" : "Shto rol të ri"}
      >
        <Input
          {...register("roleName")}
          label="Emri i rolit"
          className="mb-6"
          placeholder="Shkruaj rolin e përdoruesit këtu..."
          error={errors.roleName?.message}
        />
        <ToggleButton />
      </FormWrapper>
    </FormProvider>
  );
};

export default AddRole;
