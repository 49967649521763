import { PencilIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import usePageIndex from "../../hooks/usePageIndex";
import { useSearch } from "../../hooks/useSearch";
import {
  deleteTripNoticesById,
  getAllTripNotices
} from "../../services/Notices.service";
import AuditAuthor from "../audit-author/AuditAuthor";
import DeleteModal from "../ui/DeleteModal";
import IconBtn from "../ui/IconBtn";
import Table, { Row } from "../ui/table";
import Pagination from "../ui/table/Pagination";
import Can from "../users/permissions/Can";

const columns: (string | number)[] = [
  "Trip ID",
  "Lloji i informatës",
  "Titulli",
  "Përshkrimi",
  "Detajet",
  "Veprimet"
];

const TripNoticesTable = () => {
  const { response, loading, handlePageChange, page, setResponse } = useSearch(
    getAllTripNotices,
    {
      withQuerySearch: true
    }
  );
  const { pageIndex, state }: any = usePageIndex();

  return (
    <div className="flex flex-col" style={{ height: "calc(100vh - 11rem)" }}>
      <Table titles={columns} style="hover" loading={loading}>
        {response?.data.result?.length ? (
          response?.data.result?.map((row) => {
            const highlighted = +state?.params?.id === row.id;
            return (
            <Row
              key={row.id}
              // id={row.id}
              tripId={row.tripId}
              className={`${highlighted && "highlighted"}`}
              TripNoticeType={row.tripNoticeType}
              title={row.title.slice(0, 30)}
              description={
                row.description.slice(0, 65) +
                (row.description.length > 65 ? "..." : "")
              }
              auditAuthor={
                <AuditAuthor
                  createdByName={row.auditAuthor.createdByName}
                  createdByLastName={row.auditAuthor.createdByLastName}
                  updatedByName={row.auditAuthor.updatedByName}
                  updatedByLastName={row.auditAuthor.updatedByLastName}
                  insertDate={row.auditAuthor.insertDate}
                  updateDate={row.auditAuthor.updateDate}
                />}
              actions={
                <span className="flex">
                  <Can permissions="tripnotices.tripnotice.update">
                    <Link
                      to={`/notices/edit/${row.id}`}
                      state={{ crumbs: [
                        "Informatat për oraret",
                        "Edito Informatën"
                      ],
                      page: pageIndex }}
                    >
                      <IconBtn className="w-6 h-6">
                        <PencilIcon className="w-4 h-4 text-neutral" />
                      </IconBtn>
                    </Link>
                  </Can>
                  <Can permissions="tripnotices.tripnotice.delete">
                    <DeleteModal
                      params={row.id}
                      title="Konfirmo fshirjen e informatës."
                      handleDelete={deleteTripNoticesById}
                      successMessage="Informata është fshirë me sukses."
                      errorMessage="Informata nuk është fshirë me sukses."
                      loadingMessage="Informata është duke u fshirë..."
                      onSuccess={() => {
                        setResponse((prev) =>
                          prev
                            ? {
                                ...prev,
                                data: {
                                  ...prev.data,
                                  result: prev.data.result.filter(
                                    (el) => el.id !== row.id
                                  )
                                }
                              }
                            : null
                        );
                      }}
                    />
                  </Can>
                </span>
              }
            />
          );
})
        ) : (
          <></>
        )}
      </Table>
      <Pagination
        onPageChange={handlePageChange}
        totalCount={response?.data?.totalCount || 0}
        length={response?.data?.result.length}
        page={page}
        pageSize={10}
      />
    </div>
  );
};

export default TripNoticesTable;
