import { format, parse } from "date-fns";
import { useEffect } from "react";
import useDashboards from "../../hooks/useDashboards";
import useFetch from "../../hooks/useFetch";
import { getOperatorTicketsToBePaidOnBusAnalytics } from "../../services/Analytics.service";

const TicketsToBePaidOnBusAnalytics = () => {
  const { line, from, to } = useDashboards();

  const { response, loading, handleFunc } = useFetch(
    getOperatorTicketsToBePaidOnBusAnalytics,
    { loading: true, resetResponseOnError: true  }
  );

  useEffect(() => {
    if (from && to) {
      void handleFunc({
        startDate: format(parse(from, "dd/MM/yyyy", new Date()), "MM-dd-yyyy"),
        endDate: format(parse(to, "dd/MM/yyyy", new Date()), "MM-dd-yyyy"),
        lineId: line?.value || undefined
      });
    }
  }, [from, to, line]);

  return (
    <div className="flex flex-col gap-3">
      <div className="border border-gray-200 rounded-lg px-6 h-full flex flex-col justify-center">
        <p className="text-2xs uppercase font-medium text-neutral">
          nr. i biletave për pagesë në autobus
        </p>
        {loading ? <div className="bg-gray-200 h-8 animate-pulse rounded-lg w-1/2"/> : (
          <p className="text-2xl font-medium">{response?.data.nrOfTickets}</p>
        )}
      </div>
      <div className="border border-gray-200 rounded-lg px-6 h-full flex flex-col justify-center">
        <p className="text-2xs uppercase font-medium text-neutral">
          totali i biletave për pagesë në autobus
        </p>
        {loading ? <div className="bg-gray-200 h-8 animate-pulse rounded-lg w-1/2"/> : (
          <p className="text-2xl font-medium">
            {response?.data.totalSales.toFixed(2)} €
          </p>
        )}
      </div>
    </div>
  );
};

export default TicketsToBePaidOnBusAnalytics;
