import { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import useSuperAdmin from "./useSuperAdmin";

const RouteSuperAdmin: FC<{ children: ReactNode }> = ({ children }) => {
  const isSuperAdmin = useSuperAdmin();
  return isSuperAdmin ? <>{children}</> : <Navigate to="/404" replace />;
};

export default RouteSuperAdmin;
