import { useAuth } from "oidc-react";
import { FC, ReactNode, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { parseQueryString } from "../../utils/parseQueryString";
import AlertBox from "../ui/Alert";
import Button from "../ui/Button";
import { SpinLoader } from "../ui/SpinLoader";

interface AuthWrapperProps {
  children?: ReactNode;
  heading: ReactNode;
  paragraph: ReactNode;
  span?: ReactNode;
  linkContent: ReactNode;
  linkTo: string;
}

const AuthWrapper: FC<AuthWrapperProps> = ({
  children,
  heading,
  paragraph,
  span,
  linkContent,
  linkTo
}) => {
  const { search } = useLocation();
  const { invitation, error } = parseQueryString(search);

  const [logginIn, setLogginIn] = useState<boolean>(false);

  const { signIn } = useAuth();

  const logIn = () => {
    setLogginIn((prev) => {
      if (!prev)
        void signIn({
          prompt: "login",
          redirect_uri: `${process.env.REACT_APP_URL}/invitation/${invitation}`
        });
      return true;
    });
  };

  return (
    <div>
      <div className="my-6">
        <AlertBox
          message={
            <div className="flex justify-between w-full">
              {invitation ? (
                <>
                  <span>Invite jo valid!</span>
                  <Button
                    btnType="custom"
                    className="flex justify-center items-center text-black-900 group"
                    fontWeight="font-normal"
                    padding="p-0"
                    onClick={logIn}
                  >
                    <span
                      {...(!logginIn && {
                        className: `duration-300 text-opacity-90 group-hover:text-opacity-100
                  relative justify-center items-center
       before:bg-black-900 before:absolute before:w-full before:h-[1px] before:rounded-sm
       before:bottom-0 before:left-0 before:origin-left before:scale-x-0
       before:duration-300 before:ease-in-out before:transform group-hover:before:origin-left
       group-hover:before:scale-x-100 transition-all`
                      })}
                    >
                      {logginIn ? (
                        <>
                          <SpinLoader
                            className="ml-2"
                            formatClassName="h-4 w-4"
                            colorText="text-primary"
                          />
                        </>
                      ) : (
                        "Retry"
                      )}
                    </span>
                  </Button>
                </>
              ) : null}
              {error === "401" ? <span>Unauthorized user!</span> : ""}
            </div>
          }
          type="warning"
          show={!!invitation || error === "401"}
          className="mb-4"
        />
        <h2 className="font-medium text-black mb-3">
          {heading}
        </h2>
        <p className="leading-[18px] text-gray">
          {paragraph}
        </p>
      </div>
      {children}
      <div className="mt-4">
        {span && (
          <span className="text-sm leading-[18px] text-neutral mr-2">
            {span}
          </span>
        )}
        <Link
          className="text-sm leading-[18px] text-primary relative
                  before:bg-primary before:absolute before:w-full before:h-[1px] before:rounded-sm
                   before:bottom-0 before:left-0 before:origin-left before:scale-x-0
                   before:duration-300 before:ease-in-out before:transform hover:before:origin-left
                   hover:before:scale-x-100 transition-all"
          to={linkTo}
        >
          {linkContent}
        </Link>
      </div>
    </div>
  );
};

export default AuthWrapper;
