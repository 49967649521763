import { Bars2Icon } from "@heroicons/react/24/outline";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { SideBarContext } from "../../context/SideBarContext";
import useUser from "../../hooks/useUser";
import detectDevice from "../../utils/isTouch";
import IconBtn from "../ui/IconBtn";
import BreadCrumbs from "./BreadCrumbs";
import RightContent from "./RightContent";

const Header: React.FC = () => {
  const { isSideBarOpen, setSideBar } = useContext(SideBarContext);
  const isTouch = detectDevice();

  const { user } = useUser();
  const wordExtension = ["png", "jpg", "jpeg"];
  const isImage = wordExtension.some((ext) =>
    user?.operator.pictureUrl?.includes(ext)
  );

  return (
    <header
      className="w-full shadow bg-white h-16 items-center z-30
    flex sticky top-0 flex-none justify-between md:pr-8 pr-1"
    >
      <div className="flex items-center">
        <div className="md:w-80 mr-7 flex items-center">
          <IconBtn
            onClick={() => setSideBar(!isSideBarOpen)}
            className="md:mx-5 mx-3"
          >
            <Bars2Icon className="w-5 h-5 text-gray-600" />
          </IconBtn>
          <Link to="/">
            {user?.operator.pictureUrl && isImage ? (
              <img
                width={150}
                height={65}
                src={user?.operator.pictureUrl}
                className="-ml-2 max-h-[50px] max-w-[200px] object-contain object-left"
                alt=""
              />
            ) : (
              <img width={150} src="/logo.svg" className="-ml-2" alt="" />
            )}
          </Link>
        </div>
        {!isTouch && <BreadCrumbs />}
      </div>
      <RightContent />
    </header>
  );
};

export default Header;
