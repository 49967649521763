import { Outlet } from "react-router-dom";
import withProtected from "../../hoc/withProtected";

const AuthLayout = () => {
  return (
    <main className="min-h-screen flex items-center bg-gradient-to-br from-gray-50 to-gray-100 px-10">
      <div className="flex container shadow-2xl mx-auto overflow-hidden rounded-xl bg-white p-5">
        <div className="w-1/2 relative overflow-hidden rounded-2xl md:block hidden">
          <span
            className="bg-gradient-to-t from-[#f15b2e57] to-transparent
           w-full absolute left-0 bottom-0 right-0 h-full"
          />
          <img
            src="/images/travel_icon_lines_half.png"
            className="absolute left-0 bottom-0 w-2/3"
            alt=""
          />
          <div
            className="absolute bottom-0 left-0 text-white py-14 px-10"
            style={{ textShadow: "0 0 5px black" }}
          >
            <p className="text-xl font-bold">Me ne ju gjeni:</p>
            <p>{">"} Shfaqje te orareve te linjave</p>
            <p>{">"} Rezervim online te biletave</p>
            <p>{">"} Faqe personale per operatore</p>
          </div>
          <img src="/images/girl_montain.png" alt="" />
        </div>
        <Outlet />
      </div>
    </main>
  );
};

export default withProtected(AuthLayout, false);
