import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/ui/Button";
import Modal from "../../../components/ui/Modal";
import { SpinLoader } from "../../../components/ui/SpinLoader";
import Input from "../../../components/ui/input/Input";
import Select from "../../../components/ui/select/Select";
import useSuperAdmin from "../../../components/users/permissions/useSuperAdmin";
import useFetch from "../../../hooks/useFetch";
import useInvitedUsers from "../../../hooks/useInvitedUsers";
import useUser from "../../../hooks/useUser";
import { InvitedUserCreateFormType } from "../../../interfaces/endpoints/IUser.types";
import { getAllOperators } from "../../../services/Operators.service";
import { getAllUserRoles } from "../../../services/UserRole.service";
import { createInvitedUser } from "../../../services/Users.service";
import { InviteUserCreateSchema } from "../../../utils/validations/User.schema";

const InviteUser = () => {
  const navigator = useNavigate();
  const { onCreate } = useInvitedUsers();
  const { user } = useUser();
  const {
    handleSubmit,
    control,
    register,
    formState: { errors }
  } = useForm<InvitedUserCreateFormType>({
    ...(user?.operator && { defaultValues: { operator: user.operator } }),
    resolver: yupResolver(InviteUserCreateSchema)
  });
  const superAdmin = useSuperAdmin();
  const { handleFunc, loading } = useFetch(createInvitedUser);

  const onClose = () =>
    !loading &&
    navigator("/users/overview/invite", {
      state: ["User overview", "Users", "Invite User"]
    });

  const onSubmit = async (data: InvitedUserCreateFormType) => {
    const res = await handleFunc({
      email: data.email,
      operatorId: data.operator.id,
      roleIds: data.roles.map((el) => el.id)
    });
    if (res?.status === 200 && res?.data) {
      onCreate(res.data);
      toast.success("Ftesa është dërguar me sukses.");
      onClose();
    } else toast.error("Ndodhi një gabim gjatë krijimit.");
  };
  return (
    <Modal
      show={true}
      position="center"
      onClose={onClose}
      buttonElement={
        <Button
          form="invite-create"
          className="flex justify-center items-center"
          type="submit"
          disabled={loading}
        >
          {loading ? <SpinLoader formatClassName="w-4 h-4 mr-2" /> : null}
          Konfirmo
        </Button>
      }
      title="Shto User"
    >
      <form
        id="invite-create"
        onSubmit={handleSubmit(onSubmit)}
        className="px-6 pt-8 pb-10 "
      >
        <Input
          {...register("email")}
          label="Email*"
          placeholder="Shkruaj email këtu..."
          error={errors?.email?.message}
        />
        <Controller
          control={control}
          name="roles"
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Select
                className="mt-6"
                label="Role*"
                placeholder="Zgjedh rolet"
                onSelectedListChange={(selected) => onChange(selected)}
                // beforeClassName="z-50"
                maxHeightList="max-h-40"
                selectedItems={value}
                selectType="multiple"
                getOptionLabel={(o) => o.roleName}
                asyncFunc={getAllUserRoles}
                error={error?.message}
                isAbsolute={false}
              />
            );
          }}
        />
        {superAdmin?.id ? (
          <Controller
            name="operator"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                className="mt-6"
                maxHeightList="max-h-40"
                isAbsolute={false}
                label="Operator Name*"
                placeholder="Zgjedh operatorin"
                selectedItem={value}
                onSelectedItemChange={(item) => onChange(item)}
                itemToString={(item) => item?.operatorName || ""}
                getOptionLabel={(item) => item.operatorName}
                asyncFunc={getAllOperators}
                error={errors.operator?.id?.message}
              />
            )}
          />
        ) : null}
      </form>
    </Modal>
  );
};
export default InviteUser;
