import { array, date, number, object, string } from "yup";
import { PhotoType } from "../../interfaces/bundles/Bundles.types";

export const BundleSchema = object().shape({
  photos: array()
    .max(20, "Fusha e fotografive duhet të ketë 20 foto është maksimumi!")
    .min(3, "Fusha e fotografive duhet të ketë të paktën 3 foto!")
    .test(
      "photo",
      "Të paktën një nga fotot është ende duke u ngarkuar...",
      (value) => !value?.some((el: PhotoType) => el.pending)
    )
    .test(
      "photo",
      "Të paktën një nga fotot ka gabim gjatë ngarkimit (Hiqni ato dhe provoni ta ngarkoni përsëri)!",
      (value) => !value?.some((el: PhotoType) => el.error)
    ),
  locales: array().of(
    object().shape({
      title: string()
        .required("Vendos titullin!")
        .max(100, "Titulli duhet të jetë më së shumti 100 karaktere")
        .typeError("Vendos titullin!"),
      description: string()
        .required("Vendos agjenden!")
        .max(3000, "Agjenda duhet të jetë më së shumti 3000 karaktere")
        .typeError("Vendos agjenden!")
    })
  ),
  packages: array().min(1, "Fusha e pakove duhet të ketë të paktën 1 pako!"),
  journeys: array().of(
    object().shape({
      travelBundlePrice: number()
        .typeError("Vendos qmimin!")
        .required("Vendos qmimin!"),
      maxReservationCount: number()
        .typeError("Vendos limitin!")
        .required("Vendos limitin!"),
      range: object().shape({
        from: date().required("Vendos daten!"),
        to: date().required("Vendos daten!")
      })
    })
  ),
  fromLocation: object().required("Vendos stacionin e nisjes"),
  toLocation: object()
    .required("Vendos stacionin e arritjes")
    .test(
      "photo",
      "Stacioni i arritjes nuk mund te jete i njejt me te nisjes",
      (value, context) => {
        return !(value?.cityId === context.parent?.fromLocation?.cityId);
      }
    ),
  locationIncludes: array().test("length", "", (value, context) => {
    if (value && value?.length >= 2) return true;
    else {
      const from = context.parent.fromLocation?.name
        ? context.parent.fromLocation?.name.split(",")[1]
        : "";
      const to = context.parent.toLocation?.name
        ? context.parent.toLocation?.name.split(",")[1]
        : "";

      return context.createError({
        message: `Vendos te pakten 2 vende ${
          from?.length || to?.length
            ? `(psh:${from?.length ? `${from},` : ""} ${to?.length ? to : ""})`
            : ""
        }`,
        type: "required"
      });
    }
  })
});
