import { axios } from "../config/axios";
import {
  IPromotionCode,
  IPromotionCodes
} from "../interfaces/endpoints/IPromotionCodes";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import { serialize } from "../utils/serializeToQueryString";

export type getAllPromoCodesParamsType = {
  pageIndex: number;
  searchKeyword?: string;
};
export interface generateCodeParams {
  name?: string;
  promotionCodeType?: number;
  id?: number;
}
export const getAllPromotionCodes = async (
  att: getAllPromoCodesParamsType
): Promise<IAxiosResponse<IPromotionCodes>> => {
  return axios.get<IPromotionCodes>(
    `PromotionCode/PromotionCodes${serialize(att)}`
  );
};

export const getCurrentPromotionCode = async (
  id: number
): Promise<IAxiosResponse<IPromotionCode>> => {
  return axios.get<IPromotionCode>(`PromotionCode/PromotionCodeById?id=${id}`);
};

export const postPromotionCode = async (
  body: IPromotionCode
): Promise<number> => {
  return axios.post("PromotionCode/PromotionCode", body);
};

export const editPromotionCode = async (
  body: IPromotionCode,
  id: number
): Promise<number> => {
  return axios.put(`PromotionCode/PromotionCode?id=${id}`, body);
};

export const deletePromotionCode = async (id: number): Promise<boolean> => {
  return axios.delete(`PromotionCode/PromotionCode/${id}`);
};

export const getPromotionTypes = async (): Promise<
  IAxiosResponse<string[]>
> => {
  return axios.get<string[]>("PromotionCode/GetPromotionTypes");
};

export const generatePromotionCode = async (
  generateCodeParams: generateCodeParams
): Promise<IAxiosResponse<string>> => {
  return axios.get(
    // eslint-disable-next-line max-len
    `PromotionCode/GeneratePromotionCode?promotionCodeType=${generateCodeParams.promotionCodeType}&name=${generateCodeParams.name}`
  );
};

export const notifyPromotionCode = async (
  generateCodeParams: generateCodeParams
): Promise<number> => {
  return axios.post(
    `PromotionCode/NotifyPromotionCode?promotionCodeId=${generateCodeParams.id}`
  );
};
