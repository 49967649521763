/* eslint-disable max-len */
/* eslint-disable max-lines */
import { FC, useEffect, useRef, useState } from "react";
import ImageUploading, {
  ImageListType,
  ImageType
} from "react-images-uploading";

import { PlusIcon, StarIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import { Controller, useFormContext } from "react-hook-form";
import { v4 as uuid } from "uuid";
import {
  IBundleSchema,
  PhotoType
} from "../../../../interfaces/bundles/Bundles.types";
import { getGuid, uploadPhoto } from "../../../../services/Media.service";
import { cx } from "../../../../utils/classnames";
import SpinnerLoader from "../../../ui/SpinnerLoader";

interface UploadPhoto {
  editMode?: boolean;
}

const UploadPhoto: FC<UploadPhoto> = ({ editMode }) => {
  const [guid, setGuid] = useState<string>("");
  // const { isMobile } = useContext(CheckMobileContext);

  const { control, watch, setValue, formState, trigger } =
    useFormContext<IBundleSchema>();

  useEffect(() => {
    if (watch("photos")?.[0]?.picture?.pictureGuid && editMode)
      setGuid(watch("photos")?.[0]?.picture?.pictureGuid || "");
    else if (!guid) void getGuid().then((res) => setGuid(res.data.guid));
  }, [watch("photos")?.length]);

  const changeHandler = (
    imageList: ImageListType,
    addUpdatedIndex?: Array<number>
  ) => {
    const multiple = true;
    const _imageList = [
      ...(!multiple && Array.isArray(addUpdatedIndex)
        ? watch("photos").map((img, index) => {
            return Object.assign(img, {
              index,
              isCoverPicture:
                typeof img?.isCoverPicture === "boolean"
                  ? img?.isCoverPicture
                  : index === 0 && !img?.error,
              pending: typeof img?.pending === "boolean" ? img?.pending : true,
              completed:
                typeof img?.completed === "boolean" ? img?.completed : false,
              error: typeof img?.error === "boolean" ? img?.error : false,
              // isFirstTime: typeof img?.isFirstTime === "boolean" ? img?.isFirstTime : true,
              state: img?.state || "not-uploaded"
            });
          })
        : []),
      ...imageList.map((img, index) => ({
        ...img,
        index,
        uuid: img?.uuid || uuid(),
        isCoverPicture:
          typeof img?.isCoverPicture === "boolean"
            ? img?.isCoverPicture
            : index === 0,
        pending: typeof img?.pending === "boolean" ? img?.pending : true,
        completed: typeof img?.completed === "boolean" ? img?.completed : false,
        error: typeof img?.error === "boolean" ? img?.error : false,
        state: img?.state || "not-uploaded"
        // dataURL:img?.dataURL:
      }))
      // ...imageList.map((img, index) => {
      //   return Object.assign(img, {
      // index,
      // uuid: img?.uuid || uuid(),
      // isCoverPicture:
      //   typeof img?.isCoverPicture === "boolean"
      //     ? img?.isCoverPicture
      //     : index === 0,
      // pending: typeof img?.pending === "boolean" ? img?.pending : true,
      // completed:
      //   typeof img?.completed === "boolean" ? img?.completed : false,
      // error: typeof img?.error === "boolean" ? img?.error : false,
      // state: img?.state || "not-uploaded"
      //   });
      // })
    ];

    setValue("photos", _imageList);

    //? Because our back end dont support this feature, this block of code is comment DONT REMOVE
    // const onUploadedImages = () => {
    //   const _imagePromises = imageList.map(async (image: any) => {
    //     if (image.isFirstTime && !image.error) {
    //       const formData = new FormData();
    //       formData.append("file", image.file);

    //       try {
    //         const res = await fetch(`${API}media/uploadfile?guid=${guid}`, {
    //           method: "POST",
    //           body: formData,
    //           headers: { Authorization: `Bearer ${session?.access_token}` },
    //         });
    //         const dataJSON = res.status === 200 ? await res.json() : null;

    //         setValue(
    //           "photos",
    //           watch("photos")?.map((img: any) =>
    //             img?.uuid === image?.uuid
    //               ? Object.assign(img, {
    //                   pending: false,
    //                   completed: true,
    //                   pictureId: dataJSON.data.id,
    //                   picture: dataJSON.data,
    //                 })
    //               : img
    //           )
    //         );
    //       } catch (error) {
    //         setValue(
    //           "photos",
    //           watch("photos")?.map((img: any) =>
    //             img?.uuid === image?.uuid
    //               ? Object.assign(img, {
    //                   pending: false,
    //                   completed: false,
    //                   error: true,
    //                 })
    //               : img
    //           )
    //         );
    //       }
    //     }
    //   });
    // setValue(
    //   "photos",
    //   watch("photos")?.map((img: any) =>
    //     Object.assign(img, { isFirstTime: false })
    //   )
    // );
    //   return _imagePromises;
    // };

    //  await Promise.allSettled([...onUploadedImages()]);
  };

  const onUploadPhoto = async (photo: ImageType) => {
    const formData = new FormData();
    if (photo.file) formData.append("file", photo.file);

    try {
      const uploadedImage = await uploadPhoto({ guid, body: formData });
      setValue(
        "photos",
        watch("photos")?.map((img) =>
          img?.uuid === photo?.uuid
            ? Object.assign(img, {
                pending: false,
                completed: true,
                pictureId: uploadedImage.data.id,
                picture: uploadedImage.data,
                state: "finish"
              })
            : img
        )
      );
    } catch (error) {
      setValue(
        "photos",
        watch("photos")?.map((img) =>
          img?.uuid === photo?.uuid
            ? Object.assign(img, {
                pending: false,
                completed: false,
                isCoverPicture: false,
                error: true,
                state: "finish"
              })
            : img
        )
      );
    }
  };

  useEffect(() => {
    const gallery = watch("photos") || [];

    const stillProcessing = gallery?.some((t) => t?.state === "processing");

    if (!stillProcessing) {
      const findUnuploadedPhoto = gallery.find(
        (el) => !el?.completed && !el?.error && el?.state === "not-uploaded"
      );
      if (findUnuploadedPhoto && !stillProcessing) {
        setValue(
          "photos",
          gallery.map((img) =>
            findUnuploadedPhoto?.uuid === img?.uuid
              ? { ...img, state: "processing" }
              : img
          )
        );
        void onUploadPhoto(findUnuploadedPhoto);
      }
    }
  }, [watch("photos")]);

  const makeMainImg = (imageKey: PhotoType["pictureId"]) => {
    if (imageKey !== undefined) {
      const allImagesIds = [...watch("photos")];
      allImagesIds.map((image) => {
        image?.pictureId === imageKey
          ? Object.assign(image, {
              isCoverPicture: true
            })
          : Object.assign(image, {
              isCoverPicture: false
            });
      });
      setValue("photos", allImagesIds);
    }
  };

  const dragItem = useRef() as any;

  const handleDragStart = (e: any, position: number) => {
    e.stopPropagation();
    dragItem.current = position;
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", dragItem.current.innerHTML);
  };

  const handleDragOver = (e: any, position: number) => {
    e.stopPropagation();
    const copyListItems = [...watch("photos")];
    const dragItemContent = copyListItems[dragItem.current];
    if (dragItem.current === position) {
      return;
    }
    copyListItems?.splice(dragItem.current, 1);
    copyListItems?.splice(position, 0, dragItemContent);
    dragItem.current = position;
    setValue("photos", copyListItems);
  };

  const handleDrop = (e: any, position: number) => {
    e.stopPropagation();
    e.target.innerHTML = "";
    if (dragItem.current !== position) {
      e.target.innerHTML = e.dataTransfer.getData("text/html");
    }
  };
  useEffect(() => {
    if (formState.submitCount > 0 && !watch("photos").some((el) => el.pending))
      void trigger("photos");
  }, [watch("photos")]);
  return (
    <>
      {/* {isMobile ? <Polyfill /> : null} */}
      <Controller
        name="photos"
        control={control}
        defaultValue={[]}
        render={(props) => {
          return (
            <>
              <ImageUploading
                value={props.field.value}
                onChange={changeHandler}
                multiple={true}
                maxNumber={20}
                acceptType={["jpg", "png", "jpeg"]}
                maxFileSize={10485760}
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemove,
                  isDragging,
                  dragProps,
                  errors
                }: any) => {
                  return (
                    <div className="upload__image-wrapper curpo" {...dragProps}>
                      <div
                        className={`pb-6 pt-3 px-8 min-h-[160px] rounded-lg transition-all duration-500 flex flex-wrap border-dashed border-2 relative border-gray-200 hover:border-primary ${
                          isDragging ? "  border-primary" : ""
                        }${
                          formState.errors.photos || errors
                            ? "border-warning"
                            : ""
                        }`}
                      >
                        {imageList.length === 0 ? (
                          <div
                            onClick={onImageUpload}
                            className="flex items-center justify-center flex-col w-full top-0 cursor-pointer transition"
                          >
                            <button
                              type="button"
                              className="px-4 py-2 border border-primary text-primary rounded font-semibold mb-3 flex items-center bg-white"
                            >
                              <PlusIcon
                                className="w-4 h-4 mr-1"
                                strokeWidth={2}
                              />
                              <span>Zgjidhni fotot</span>
                            </button>

                            <p className="text-gray-600 text-xs">
                              Fotografia perferohet te jete 864 x 400 px
                            </p>
                          </div>
                        ) : null}

                        {imageList.map((image: any, index: number) => {
                          return (
                            <div
                              key={index}
                              className="group w-full sm:w-[105px] h-[105px] mr-0 sm:mr-3 mt-3"
                              draggable
                              data-effect-allowed="move"
                              onDragEnter={(e) => e.preventDefault()}
                              onDragStart={(e) => handleDragStart(e, index)}
                              onDragOver={(e) => handleDragOver(e, index)}
                              onDrop={(e) => handleDrop(e, index)}
                            >
                              <div
                                className={cx([
                                  "rounded-md relative bg-transparent h-full",
                                  image?.error && "ring-2 ring-warning"
                                ])}
                              >
                                {image?.isCoverPicture ? (
                                  <span className="flex items-center justify-center absolute top-0 left-3 py-1.5 w-9 bg-primary text-white z-10 rounded-b-3xl">
                                    <StarIcon className="w-5 h-5" />
                                  </span>
                                ) : null}
                                {image?.error ? (
                                  <span className="flex items-center justify-center absolute top-0 left-3 py-1 w-9 bg-red text-white z-20 rounded-b-3xl cursor-pointer">
                                    <span title="Failed to upload selected photo, please try again">
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          clipRule="evenodd"
                                          fill="currentColor"
                                          d="M2 14.5C2 12.358 3.22445 10.502 5.01159 9.5938C5.22197 5.91685 8.27035 3 12 3C15.7296 3 18.778 5.91685 18.9884 9.5938C20.7755 10.502 22 12.358 22 14.5C22 17.369 19.8032 19.725 17 19.9776V20H7V19.9776C4.19675 19.725 2 17.369 2 14.5ZM16.8654 18.4836L16.6837 18.5H7.31625L7.13463 18.4836C5.09749 18.3001 3.5 16.5857 3.5 14.5C3.5 12.9444 4.38774 11.5934 5.69117 10.931L6.45988 10.5404L6.50914 9.67949C6.67436 6.79179 9.07008 4.5 12 4.5C14.9299 4.5 17.3256 6.79179 17.4909 9.67949L17.5401 10.5404L18.3088 10.931C19.6123 11.5934 20.5 12.9444 20.5 14.5C20.5 16.5857 18.9025 18.3001 16.8654 18.4836ZM14.8462 10.1538C15.0513 10.3588 15.0513 10.6912 14.8462 10.8962L12.7425 13L14.8461 15.1036C15.0511 15.3086 15.0511 15.641 14.8461 15.8461C14.641 16.0511 14.3086 16.0511 14.1036 15.8461L12 13.7425L9.8964 15.8461C9.69137 16.0511 9.35896 16.0511 9.15393 15.8461C8.9489 15.641 8.9489 15.3086 9.15393 15.1036L11.2575 13L9.15377 10.8962C8.94874 10.6912 8.94874 10.3588 9.15377 10.1538C9.3588 9.94874 9.69122 9.94874 9.89625 10.1538L12 12.2575L14.1038 10.1538C14.3088 9.94874 14.6412 9.94874 14.8462 10.1538Z"
                                        />
                                      </svg>
                                    </span>
                                  </span>
                                ) : null}
                                <span
                                  className="absolute w-full h-full bg-center bg-cover bg-no-repeat rounded-md"
                                  style={{
                                    backgroundImage: `url(${image?.dataURL})`
                                  }}
                                />
                                {image?.pending && (
                                  <div className="absolute w-5 h-5 right-[35%] top-[40%]">
                                    <SpinnerLoader />
                                  </div>
                                )}
                                <div
                                  className={cx([
                                    "z-10 absolute w-full h-full rounded-md flex items-center justify-center cursor-pointer transition-all duration-300",
                                    !image?.error &&
                                      "hover:bg-black hover:bg-opacity-50",
                                    image?.pending && "bg-black bg-opacity-50"
                                  ])}
                                >
                                  <div className="flex ">
                                    {image?.completed ? (
                                      <button
                                        type="button"
                                        className={
                                          "flex justify-center absolute top-0 left-3 py-1.5 w-9 rounded-b-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300 "
                                        }
                                        onClick={() =>
                                          makeMainImg(image?.pictureId)
                                        }
                                        title="Make Cover Picture"
                                      >
                                        <span>
                                          <StarIcon className="w-5 h-5 text-white" />
                                        </span>
                                      </button>
                                    ) : null}

                                    <button
                                      type="button"
                                      className="flex justify-center items-center rounded-full absolute -top-1.5 -right-1.5 w-5 h-5 px-0 bg-red hover:rotate-90 duration-300"
                                      onClick={() => onImageRemove(index)}
                                      title="Remove"
                                    >
                                      <XMarkIcon className="w-4 h-4 text-white" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        {imageList.length !== 0 && imageList.length < 20 ? (
                          <div className="group w-full sm:w-[105px] h-[105px] mr-0 sm:mr-3 mt-3 cursor-pointer">
                            <div
                              onClick={onImageUpload}
                              className="border-dashed border-2 bg-white border-gray-200 rounded-lg w-full h-full flex items-center justify-center text-gray-600 transition-colors"
                            >
                              <span className="w-full flex items-center justify-center flex-col">
                                <PlusCircleIcon className="w-6 h-6 text-primary" />
                                <p className="text-sm break-all px-1">
                                  Shto Foto
                                </p>
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {(errors || formState?.errors?.photos) && (
                        <Validations
                          message={errors || formState.errors.photos?.message}
                          formError={!!formState.errors.photos}
                          maxImages={20}
                        />
                      )}
                    </div>
                  );
                }}
              </ImageUploading>
            </>
          );
        }}
      />
    </>
  );
};

export default UploadPhoto;

const Validations: React.FC<{
  message: any;
  maxImages: number;
  formError: boolean;
}> = ({ message, maxImages, formError }) => {
  let error = formError ? message : "";
  if (message?.maxNumber)
    error = `Fusha e fotografive duhet të ketë ${maxImages} foto është maksimumi!`;
  if (message?.acceptType)
    error = "Mund të ngarkoni vetëm imazhe në formatet .jpg, .jpeg ose .png";
  if (message?.maxFileSize)
    error = "Çdo foto e bashkangjitur mund të ketë një maksimum prej 10 Mb";
  if (message?.resolution)
    error = "Fotografia duhet të jetë së paku 1 px në gjerësi dhe lartësi";

  return (
    <>
      {typeof error === "string" && (
        <div className="text-red-500 text-xs mt-0.5">{error}</div>
      )}
    </>
  );
};
