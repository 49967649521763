import * as Yup from "yup";

const TripsSchema = Yup.object().shape({
  // operationalInterval: Yup.object().test({
  //   name: "at-least-one-day-selected",
  //   message: "Zgjedh ditën/ditët e udhëtimit për orarin",
  //   test: (value) => {
  //     const days = [
  //       "monday",
  //       "tuesday",
  //       "wednesday",
  //       "thursday",
  //       "friday",
  //       "saturday",
  //       "sunday"
  //     ];
  //     return days.some((day) => value[day] === true);
  //   }
  // }),
  trip: Yup.object().shape({
    lineId: Yup.number()
      .typeError("Vendos linjën e orarit")
      .required("Vendos linjën e orarit"),
    departureTimeString: Yup.string().required("Vendos kohën e nisjes"),
    arrivalTimeString: Yup.string().required("Vendos kohën e arritjes"),
    isReservable: Yup.boolean().test(
      "Valuta",
      "Valuta duhet të jetë euro per te qene e rezervushme",
      (isReservable, ctx) => {
        if (isReservable && ctx.parent.defaultCurrencyId  === 2) {
          return false;
        }
        return true;
      }
    )
  }),
  schedules: Yup.array().of(
    Yup.object().shape({
      stationId: Yup.number()
        .typeError("Vendos linjën e orarit")
        .required("Vendos stacionin e ndalesës"),
      // departureTime: Yup.string().test(
      //   "Vendos kohën e nisjes nga stacioni",
      //   "Koha duhet të jetë më e madhe se koha e nisjes së linjës",
      //   (departureTime: any, ctx: any) => {
      //     if (departureTime) {
      //       return departureTime > ctx.parent.departureTime;
      //     }
      //     return false;
      //   }
      // )

      departureTimeString: Yup.string()
        .typeError("sheno dicka")
        .required("Vendos kohën e nisjes")
    })
  )
});
export default TripsSchema;
