import { format } from "date-fns";
import { FC, useEffect, useState } from "react";
import { DateRange } from "react-day-picker";
import { IRangePicker } from "../Datepicker.types";
import Picker from "../Picker";
import RangeWrapper from "./RangeWrapper";

const RangePicker: FC<IRangePicker> = ({
  range: _range,
  onApply,
  onChange,
  paddingType,
  pickerProps,
  onlyModal = false,
  showApply,
  disabled,
  hasHelpers,
  ...labelProps
}) => {
  const [range, setRange] = useState<DateRange | undefined>(_range);
  const [show, setShow] = useState(false);
  const [inputRange, setInputRange] = useState<{ from: string; to: string }>(
    range
      ? {
        from: range?.from ? format(range?.from, "dd/MM/yyyy") : "",
        to: range?.to ? format(range?.to, "dd/MM/yyyy") : ""
      }
      : {
        from: "",
        to: ""
      }
  );

  useEffect(() => {
    setRange(_range);
    setInputRange(() => ({
      from: _range?.from ? format(_range?.from, "dd/MM/yyyy") : "",
      to: _range?.to ? format(_range?.to, "dd/MM/yyyy") : ""
    }));
  }, [_range]);

  const onButtonApply = () => {
    onApply && onApply(range);
    setShow(false);
  };

  const _onChangeRange = (r: DateRange | undefined) => {
    onChange && onChange(r);
    setRange(r);
  };

  return (
    <RangeWrapper
      show={show}
      setShow={setShow}
      wrapperClassName="relative w-full"
      inputRange={inputRange}
      setInputRange={setInputRange}
      onChangeRange={_onChangeRange}
      range={range}
      paddingType={paddingType}
      onlyModal={onlyModal}
      disabled={disabled}
      {...labelProps}
    >
      <div
        className=""
      >
        <Picker
          {...pickerProps}
          disabled={disabled}
          mode="range"
          defaultMonth={range?.from}
          selected={range}
          hasHelpers={hasHelpers}
          onSelect={(r: DateRange | undefined) => {
            setRange(r);
            onChange && onChange(r);
            setInputRange(() => ({
              from: r?.from ? format(r?.from, "dd/MM/yyyy") : "",
              to: r?.to ? format(r?.to, "dd/MM/yyyy") : ""
            }));
          }}
          onCancel={() => setShow(false)}
          onApply={onButtonApply}
          showApply={showApply}
        />
      </div>
    </RangeWrapper>
  );
};

export default RangePicker;
