import { Outlet } from "react-router-dom";
import InvitedUsersList from "../../../components/users/InvitedUsersList";
import { InvitedUsersProvider } from "../../../context/InvitedUsersContext";

const InvitedUsers = () => {
  return (
    <InvitedUsersProvider>
      <InvitedUsersList />
      <Outlet />
    </InvitedUsersProvider>
  );
};

export default InvitedUsers;
