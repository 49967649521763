/* eslint-disable max-len */
import { FC } from "react";
import { IIcon } from "./IIcon";

const Briefcase: FC<IIcon> = ({ color, size }) => {
  const currentColor = color || "currentColor";
  const currentSize = size || 16;
  return (
    <span>
      <svg
        width={currentSize}
        height={currentSize}
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-auto h-auto"
      >
        <path
          d="M11.9987 17.0609H5.99871C2.53371 17.0609 1.88871 15.4484 1.72371 13.8809L1.16121 7.87336C1.07871 7.08586 1.05621 5.92336 1.83621 5.05336C2.51121 4.30336 3.62871 3.94336 5.24871 3.94336H12.7487C14.3762 3.94336 15.4937 4.31086 16.1612 5.05336C16.9412 5.92336 16.9187 7.08586 16.8362 7.88086L16.2737 13.8734C16.1087 15.4484 15.4637 17.0609 11.9987 17.0609ZM5.24871 5.06086C3.98121 5.06086 3.11121 5.30836 2.66871 5.80336C2.30121 6.20836 2.18121 6.83086 2.27871 7.76086L2.84121 13.7684C2.96871 14.9534 3.29121 15.9359 5.99871 15.9359H11.9987C14.6987 15.9359 15.0287 14.9534 15.1562 13.7609L15.7187 7.76836C15.8162 6.83086 15.6962 6.20836 15.3287 5.80336C14.8862 5.30836 14.0162 5.06086 12.7487 5.06086H5.24871Z"
          fill={currentColor}
        />
        <path
          d="M12 5.0625C11.6925 5.0625 11.4375 4.8075 11.4375 4.5V3.9C11.4375 2.565 11.4375 2.0625 9.6 2.0625H8.4C6.5625 2.0625 6.5625 2.565 6.5625 3.9V4.5C6.5625 4.8075 6.3075 5.0625 6 5.0625C5.6925 5.0625 5.4375 4.8075 5.4375 4.5V3.9C5.4375 2.58 5.4375 0.9375 8.4 0.9375H9.6C12.5625 0.9375 12.5625 2.58 12.5625 3.9V4.5C12.5625 4.8075 12.3075 5.0625 12 5.0625Z"
          // fill="#6E6E6E"
          fill={currentColor}
        />
        <path
          d="M9 12.5625C6.9375 12.5625 6.9375 11.2875 6.9375 10.5225V9.75C6.9375 8.6925 7.1925 8.4375 8.25 8.4375H9.75C10.8075 8.4375 11.0625 8.6925 11.0625 9.75V10.5C11.0625 11.28 11.0625 12.5625 9 12.5625ZM8.0625 9.5625C8.0625 9.6225 8.0625 9.69 8.0625 9.75V10.5225C8.0625 11.295 8.0625 11.4375 9 11.4375C9.9375 11.4375 9.9375 11.3175 9.9375 10.515V9.75C9.9375 9.69 9.9375 9.6225 9.9375 9.5625C9.8775 9.5625 9.81 9.5625 9.75 9.5625H8.25C8.19 9.5625 8.1225 9.5625 8.0625 9.5625Z"
          // fill="#6E6E6E"
          fill={currentColor}
        />
        <path
          d="M10.4996 11.0762C10.2221 11.0762 9.97463 10.8662 9.94463 10.5812C9.90713 10.2737 10.1246 9.98872 10.4321 9.95122C12.4121 9.70372 14.3096 8.95372 15.9071 7.79122C16.1546 7.60372 16.5071 7.66372 16.6946 7.91872C16.8746 8.16622 16.8221 8.51871 16.5671 8.70621C14.8121 9.98121 12.7421 10.7987 10.5671 11.0762C10.5446 11.0762 10.5221 11.0762 10.4996 11.0762Z"
          // fill="#6E6E6E"
          fill={currentColor}
        />
        <path
          d="M7.50054 11.0857C7.47804 11.0857 7.45554 11.0857 7.43304 11.0857C5.37804 10.8532 3.37554 10.1032 1.64304 8.91816C1.38804 8.74566 1.32054 8.39316 1.49304 8.13816C1.66554 7.88316 2.01804 7.81566 2.27304 7.98816C3.85554 9.06816 5.67804 9.75066 7.55304 9.96816C7.86054 10.0057 8.08554 10.2832 8.04804 10.5907C8.02554 10.8757 7.78554 11.0857 7.50054 11.0857Z"
          fill={currentColor}
        />
      </svg>
    </span>
  );
};

export default Briefcase;
