import { ArrowUpOnSquareIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { ChangeEvent, useState } from "react";
import { useFormContext } from "react-hook-form";
import { LoaderIcon } from "react-hot-toast";
import Input from "../../../components/ui/input/Input";
import { IBundleFeature } from "../../../interfaces/bundles/BundleFeatures";
import { UploadTravelBundleIncludeIcon } from "../../../services/BundleFeatures.service";
import useCan from "../../users/permissions/useCan";

const UploadSvg = () => {
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);
  const {
    register,
    setValue,
    formState: { errors },
    watch
  } = useFormContext<IBundleFeature>();
  const formData = new FormData();

  const onChangeHandle = (e: ChangeEvent<HTMLInputElement>) => {
    setLoadingUpload(true);
    if (e.target && e.target.files) {
      const file = e.target.files[0];
      formData.append("file", file);
      void UploadTravelBundleIncludeIcon(formData).then((res) => {
        const url = res.data.message.split(
          /http:\/\/blob\.gjirafa\.tech\/gjcom\/travel(-dev|-stg)?\/globalimages\/bundleicons\//
        );
        const filename = url[url.length - 1].split(".").slice(0, -1).join(".");

        setValue("svg", filename);
        setValue("svgUrl", res.data.message);
        setLoadingUpload(false);
      });
    }
  };
  const can = useCan("media.picture.create");
  return (
    <div className="mb-6">
      {!watch("svgUrl")?.length ? (
        <>
          {can ? (
            <label
              htmlFor="file-upload"
              className={`border-2 border-dashed items-center text-sm text-gray-600
                          h-36 w-36 justify-center flex flex-col mb-4 rounded-xl
                          cursor-pointer hover:border-primary transition-colors duration-300
                          ${errors.svgUrl?.message ? "border-warning" : ""}
                          `}
            >
              {loadingUpload ? (
                <span>
                  <LoaderIcon className="w-8 h-8" />
                </span>
              ) : (
                <span className="flex flex-col justify-center items-center">
                  <ArrowUpOnSquareIcon className="w-6 h-6 p-1" />
                  Ngarko imazhin
                </span>
              )}
            </label>
          ) : (
            <div
              className="border-2 border-dashed items-center text-sm text-gray-600
                          h-36 w-36 justify-center flex flex-col mb-4 rounded-xl
                           bg-gray-200 cursor-not-allowed transition-colors duration-300 px-4"
            >
              Nuk keni leje per te shtuar foto!
            </div>
          )}
          <Input
            {...register("svgUrl")}
            type="file"
            className="hidden"
            id="file-upload"
            error={errors.svgUrl?.message}
            onChange={(e) => onChangeHandle(e)}
          />
          {errors.svgUrl?.message ? (
            <div className="text-red-500 text-xs mt-0.5">
              {errors.svgUrl?.message}
            </div>
          ) : null}
        </>
      ) : (
        <div className="flex relative w-36 h-36 rounded-lg">
          <div className="w-full h-full border items-center flex justify-center rounded-lg">
            <img src={watch("svgUrl")} alt="Ikona" className="object-cover" />
          </div>
          <button
            type="button"
            className="text-gray-600 hover:text-black absolute right-0"
            onClick={() => {
              setValue("svg", "");
              setValue("svgUrl", "");
            }}
          >
            <XCircleIcon className="w-8 h-8 p-1" />
          </button>
        </div>
      )}
    </div>
  );
};

export default UploadSvg;
