import { THeadProps } from "./interfaces";
import Th from "./Th";

const Head: React.FC<THeadProps> = ({ titles, thClassName }) => {
  return (
    <thead className="sticky top-0 bg-white z-10 rounded-t-md">
      <tr>
        {titles.map((title, i) => (
          <Th key={i} name={title} className={thClassName} />
        ))}
      </tr>
    </thead>
  );
};

export default Head;
