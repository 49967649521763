import { useSearch } from "../../../hooks/useSearch";
import { getPagedBundles } from "../../../services/Bundles.service";
import NotfoundTable from "../../ui/NotfoundTable";
import Table from "../../ui/table";
import Pagination from "../../ui/table/Pagination";
import BundleRow from "./BundleRow";

const columns: (string | number)[] = [
  "Pako ID",
  "Titulli",
  "Çmimi",
  "Datat e udhëtimit",
  "Limiti i rezervimeve",
  "Statusi",
  "Detajet",
  "Veprimet"
];

const Bundles = () => {
  const { response, loading, handlePageChange, page, setResponse } = useSearch(
    getPagedBundles,
    {
      withQuerySearch: true,
      withFilter: true,
      filterSelector: "bundleStatus"
    }
  );

  return (
    <div className="flex flex-col" style={{ height: "calc(100vh - 11rem)" }}>
      <Table
        titles={columns}
        loading={loading}
        className="overflow-x-auto"
        style="hover"
      >
        {response?.data.result?.length ? (
          response?.data.result?.map((row, i: number) => (
            <BundleRow
              row={row}
              key={i}
              onDeleteSuccess={() => {
                setResponse((prev) =>
                  prev
                    ? {
                      ...prev,
                      data: {
                        ...prev.data,
                        result: prev.data.result.filter(
                          (el) => el.id !== row.id
                        )
                      }
                    }
                    : null
                );
              }}
            />
          ))
        ) : (
          <></>
        )}
      </Table>
      {!response?.data.result?.length && !loading ? (
        <NotfoundTable
          header="Nuk ka pako në list"
          paragraph="Shto pako duke klikuar butonin lartë"
        />
      ) : null}
      <Pagination
        onPageChange={handlePageChange}
        totalCount={response?.data?.totalCount || 0}
        length={response?.data?.result.length}
        page={page}
        pageSize={10}
      />
    </div>
  );
};

export default Bundles;
