import { PencilIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import { Link } from "react-router-dom";
import { BundlesOrderListType } from "../../../interfaces/bundles/BundlesOrder.types";
import { deleteBundleOrderById } from "../../../services/BundlesOrder.service";
import AuditAuthor from "../../audit-author/AuditAuthor";
import DeleteModal from "../../ui/DeleteModal";
import IconBtn from "../../ui/IconBtn";
import { Row } from "../../ui/table";
import Can from "../../users/permissions/Can";

interface IBundleReservationRow {
  row: BundlesOrderListType;
  onDeleteSuccess: (id: number) => void;
}

const BundlesOrderRow: FC<IBundleReservationRow> = ({
  row,
  onDeleteSuccess
}) => (
  <Row
    className="group"
    displayOrder={row.displayOrder}
    bundleId={row.travelBundle?.id}
    bundleTitle={row.travelBundle?.title}
    bundleOperator={row.travelBundle?.operator?.name}
    auditAuthor={<AuditAuthor {...row.auditAuthor} />}
    actions={
      <span className="flex items-center">
        <Can permissions="travelbundles.travelbundle.diplayorder.update">
          <Link
            to={`edit/${row.id}?dpO=${row.displayOrder}`}
            state={[
              "Menaxhimi i ofertave",
              "Renditja e ofertave",
              `${row.travelBundle?.title}`
            ]}
          >
            <IconBtn className="w-6 h-6">
              <PencilIcon className="w-4 h-4 text-neutral" />
            </IconBtn>
          </Link>
        </Can>
        <Can permissions="travelbundles.travelbundle.diplayorder.delete">
          <DeleteModal
            params={row.id}
            title="Konfirmo fshirjen e pakos nga renditja."
            handleDelete={deleteBundleOrderById}
            successMessage="Pako është fshire me sukses."
            errorMessage="Pako nuk është fshire me sukses."
            loadingMessage="Pako është duke u fshire..."
            onSuccess={onDeleteSuccess}
          />
        </Can>
      </span>
    }
  />
);

export default BundlesOrderRow;
