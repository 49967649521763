import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { IBundleForm, IBundleSchema } from "../../../../interfaces/bundles/Bundles.types";
import {
  getAllCities,
  getAllLocations
} from "../../../../services/Locations.service";
import Select from "../../../ui/select/Select";

const Stations: FC<Pick<IBundleForm, "mode">> = ({ mode }) => {
  const { control } = useFormContext<IBundleSchema>();
  return (
    <div className="flex lg:flex-row flex-col justify-between items-start mb-6">
      <Controller
        control={control}
        name="fromLocation"
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <Select
              className=""
              label="Nisja*"
              disabled={mode === "edit"}
              placeholder="Zgjedh vendin e nisjes"
              onSelectedItemChange={(item) => onChange(item)}
              selectedItem={value}
              getOptionLabel={(o) => o.name}
              getOptionValue={(o)=>o?.cityId}
              itemToString={(o) => o?.name || ""}
              asyncFunc={getAllLocations}
              error={error?.message}
            />
          );
        }}
      />
      <Controller
        control={control}
        name="toLocation"
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <Select
              className="lg:mx-6 lg:my-0 my-6"
              label="Arritja*"
              disabled={mode === "edit"}
              placeholder="Zgjedh vendin e arritjes"
              onSelectedItemChange={(item) => onChange(item)}
              selectedItem={value}
              getOptionLabel={(o) => o.name}
              getOptionValue={(o)=>o?.cityId}
              itemToString={(o) => o?.name || ""}
              asyncFunc={getAllLocations}
              error={error?.message}
            />
          );
        }}
      />
      <Controller
        control={control}
        name="locationIncludes"
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <Select
              name="locationIncludes"
              label="Vendet e përfshira*"
              placeholder="Zgjedh vendet e përfshira"
              onSelectedListChange={(selected) => onChange(selected)}
              selectType="multiple"
              getOptionLabel={(o) => o.locationName}
              selectedItems={value}
              asyncFunc={getAllCities}
              error={error?.message}
            />
          );
        }}
      />
    </div>
  );
};

export default Stations;
