/* eslint-disable max-lines */
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import MultipleLangFields from "../../components/trip-notices/create/MultipleLangFields";
import {
  noticePriority,
  noticeType
} from "../../components/trip-notices/create/Notices";
import NoticesSelectDropdowns from "../../components/trip-notices/create/SelectDropdowns";
import FormWrapper from "../../components/ui/FormWrapper";
import useFetch from "../../hooks/useFetch";
import useNavigateState from "../../hooks/useNavigateState";
import { ITripNoticeForm } from "../../interfaces/endpoints/ITripsNotices";
import {
  createTripNotices,
  editTripNotices,
  getCurrentTripNotices
} from "../../services/Notices.service";
import { TripNoticesSchema } from "../../utils/validations/TripNotice.schema";

interface CreateTripNoticeProps {
  mode: "create" | "edit";
}

const CreateTripNotice: React.FC<CreateTripNoticeProps> = ({ mode }) => {
  const { id } = useParams();
  const { state }: any = useLocation();
  const stateCrumbs = useNavigateState(["Informata për oraret"]);

  const methods = useForm<ITripNoticeForm>({
    resolver: yupResolver(TripNoticesSchema),
    defaultValues: {
      title: "",
      description: "",
      locales: [
        { languageId: 1, title: "", description: "" },
        { languageId: 2, title: "", description: "" }
      ]
    }
  });

  const { handleSubmit } = methods;
  const navigate = useNavigate();

  const { response } = useFetch(getCurrentTripNotices, {
    initRequest: mode === "edit",
    params: Number(id)
  });

  useEffect(() => {
    if (mode === "edit" && response && response.data) {
      const { data } = response;
      methods.reset({
        id: response.data.id,
        tripId: response.data.routeDetails,
        title: data.locales[1].title,
        description: data.locales[1].description,
        locales:
          data.locales?.length === 2
            ? data.locales
            : [
                { languageId: 1, title: "", description: "" },
                { languageId: 2, title: "", description: "" }
              ],
        tripNoticePriority: response.data.tripNoticePriority
          ? noticePriority.find(
              (el) => el.value === response.data.tripNoticePriority
            )
          : { label: "None", value: 0 },
        tripNoticeType: response.data.tripNoticeType
          ? noticeType.find((el) => el.value === response.data.tripNoticeType)
          : { label: "None", value: 0 }
      });
    }
  }, [response, id]);

  const onSubmit: SubmitHandler<ITripNoticeForm> = async (data) => {
    if (mode === "edit") {
      await editTripNotices({
        ...data,
        id: data.id,
        title: data.locales[1].title,
        description: data.locales[1].description,
        tripId: data.tripId.trip.id,
        tripNoticePriority: data.tripNoticePriority.value,
        tripNoticeType: data.tripNoticeType.value,
        locales: data.locales,
        isActive: true
      })
        .then(() => {
          toast.success("Informata për orarin është ndryshuar me sukses.", {
            position: "top-right"
          });
          setTimeout(() => {
            navigate(`/notices?pageindex=${state.page ? state.page : 1}`, {
              state: stateCrumbs
            });
          }, 500);
        })
        .catch(() => toast.error("Ndodhi një gabim gjatë ndryshimit."));
    } else {
      await createTripNotices({
        ...data,
        title: data.locales[1].title,
        description: data.locales[1].description,
        tripId: data.tripId.trip.id,
        tripNoticePriority: data.tripNoticePriority.value,
        tripNoticeType: data.tripNoticeType.value,
        locales: data.locales,
        isActive: true
      })
        .then(() => {
          toast.success("Informata për orarin është publikuar me sukses.", {
            position: "top-right"
          });
          setTimeout(() => {
            navigate("/notices", { state: ["Informata për oraret"] });
          }, 500);
        })
        .catch(() =>
          toast.error("Ndodhi një gabim gjatë krijimit.", {
            position: "top-right"
          })
        );
    }
  };
  console.log(methods.formState.errors,"tung");
  return (
    <FormProvider {...methods}>
      <Link
        className="flex items-center mb-4 text-sm font-semibold text-primary"
        to={"/notices"}
        state={["Informata për oraret"]}
      >
        <ChevronLeftIcon className="w-4 h-4 mr-2" />
        <span>Kthehu tek lista</span>
      </Link>
      <FormWrapper
        showPrompt={
          !methods.formState.isSubmitSuccessful &&
          !!Object.keys(methods.formState.dirtyFields).length
        }
        onSubmit={handleSubmit(onSubmit)}
        title={
          mode === "edit"
            ? "Modifiko informatën për orarin"
            : "Shto informatë të re për orarin"
        }
        className="mx-0 max-w-full"
      >
        <NoticesSelectDropdowns mode={mode} />
        <MultipleLangFields />
      </FormWrapper>
    </FormProvider>
  );
};

export default CreateTripNotice;
