import Bundles from "../../../components/bundles/manage/Bundles";
import HeaderPage from "../../../components/lines-managements/header/HeaderPage";
import useCan from "../../../components/users/permissions/useCan";

const BundlesPage = () => {
  const can = useCan("travelbundles.travelbundle.create");
  return (
    <>
      <HeaderPage
        {...(can && {
          link: {
            to: "create",
            children: "Shto oferte të re",
            state: [
              "Menaxhimi i ofertave",
              "Ofertat e udhëtimeve",
              "Shto oferte të re"
            ]
          }
        })}
        hasFilter
        videoTutorial={{
          link: "https://video.gjirafa.com/embed/deshironi-te-shtoni-nje-oferte-te-re?autoplay=true&am=true",
          title: "Si të shtoni një pako?"
        }}
        filterName="Filtro Pakot"
        filterSelctor="bundleStatus"
        options={[
          { label: "Aktive", id: "true" },
          { label: "JoAktive", id: "false" }
        ]}
      />
      <Bundles />
    </>
  );
};
export default BundlesPage;
