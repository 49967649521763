/* eslint-disable max-lines */
import {
  ArrowDownIcon,
  ArrowRightIcon,
  QuestionMarkCircleIcon
} from "@heroicons/react/24/outline";
import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import FormWrapper from "../../../components/ui/FormWrapper";
import Input from "../../../components/ui/input/Input";
import useCan from "../../../components/users/permissions/useCan";
import IPricing, {
  IPostPriceSchema
} from "../../../interfaces/endpoints/IPricing";
import { postPrice } from "../../../services/Pricing.service";
import { cx } from "../../../utils/classnames";
import { Inputs } from "./index";

interface PricingTableProps {
  data?: IPricing;
  tripParams: Inputs;
}

const PricingTable: FC<PricingTableProps> = ({ data, tripParams }) => {
  const entriesOfPrice = Object.entries(data?.schedulePricing || []);
  const entriesLength = entriesOfPrice.length;
  const emptyTds = (length: number) => {
    const emptyTdArr = [];
    for (let i = 0; i < entriesLength - length; i++) {
      emptyTdArr.push(<td className="md:border h-12 bg-gray-100"></td>);
    }
    return emptyTdArr;
  };
  const { handleSubmit, register } = useForm({});

  const onSubmit: SubmitHandler<any> = async (pricingData) => {
    const pricesVal: IPostPriceSchema["prices"] = Object.values(
      pricingData.prices
    );
    await postPrice({
      ageGroupId: tripParams?.ageGroupId,
      isTwoWay: tripParams?.isTwoWayTrip,
      applyToAll: false,
      lineId: tripParams?.lineId,
      tripId: tripParams?.tripId,
      prices: pricesVal
        .filter((el) => el.oneWayPrice || el.roundTripPrice)
        .map((e) => ({
          ...e,
          ...(tripParams?.isTwoWayTrip
            ? { oneWayPrice: 0 }
            : { roundTripPrice: 0 })
        }))
    })
      .then(() => {
        toast.success("Çmimi është publikuar me sukses.", {
          position: "top-right"
        });
      })
      .catch(() => toast.error("Ndodhi një gabim gjatë krijimit.", { duration: 6000 }));
  };

  const can = useCan("pricings.pricing.update");

  const datas = entriesOfPrice.map((entry, i) => {
    return (
      <>
        {i === 0 ? (
          <thead className="rounded-lg">
            <td
              className="border w-24 md:w-60 sticky left-0 top-0 bg-white z-50"
              style={{ width: "140px" }}
            >
              <ul className="text-gray-400">
                <li className="border-b border-dashed h-6 flex items-center text-sm">
                  <ArrowRightIcon className="w-3 h-3 md:w-4 md:h-4 md:mx-3" />
                  Destinacioni
                </li>
                <li className="h-6 flex items-center text-sm">
                  <ArrowDownIcon className="w-3 h-3 md:w-4 md:h-4 md:mx-3" />
                  Nisja
                </li>
              </ul>
            </td>
            {entry[1].map((stat) => (
              <td
                className="border px-3 sticky top-0 bg-white md:bg-transparent
              text-sm md:text-base h-full w-32 md:h-[53px]"
              >
                {stat.destination.stationName.replace("Stacioni ", "")}
              </td>
            ))}
          </thead>
        ) : null}
        <tr className="border h-[52px]">
          <td className="border px-3 sticky left-0 bg-white md:bg-transparent text-sm md:text-base">
            {/* <span className="bg-red h-[53px] w-full border flex -mx-3 px-3 items-center"> */}
            {entry[0].replace("Stacioni ", "")}
            {/* </span> */}
          </td>
          {emptyTds(entry[1].length)}
          {entry[1].map((pr, index) => {
            return (
              <td className="border h-12">
                <span
                  className={cx([
                    "flex items-center justify-center px-3 w-full h-full",
                    !can && "cursor-not-allowed bg-gray-200"
                  ])}
                >
                  <span className="text-gray-600 font-light">€</span>
                  {tripParams.isTwoWayTrip === false ? (
                    <>
                      <Input
                        type="number"
                        {...register(`prices.${i}_${index}.oneWayPrice`, {
                          valueAsNumber: true
                        })}
                        defaultValue={pr.price}
                        border={"border-none"}
                        disabled={!can}
                        // className="w-full focus:border-primary h-full bg-confirm-300 top-0"
                        placeholder="0.00"
                        step="any"
                      />
                      <span
                        className="text-gray-600 text-sm underline cursor-pointer hidden md:flex"
                        data-tooltip-content=
                        {`Çmimi për: ${pr.departure.stationName} - ${pr.destination.stationName}`}
                        data-tooltip-variant="dark"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-float={true}
                      >
                        <QuestionMarkCircleIcon
                          className="w-4 h-4"
                          height={20}
                        />
                      </span>
                      <span className="hidden md:inline">
                        <Tooltip
                          place="bottom"
                          id="my-tooltip"
                          delayHide={50}
                        />
                      </span>
                    </>
                  ) : (
                    <>
                      <Input
                        type="number"
                        {...register(`prices.${i}_${index}.roundTripPrice`, {
                          valueAsNumber: true
                        })}
                        defaultValue={pr.price}
                        border={"border-none"}
                        disabled={!can}
                        // className="w-full focus:border-primary h-full bg-confirm"
                        placeholder="0.00"
                        step="any"
                      />
                      <span
                        className="text-gray-600 text-sm underline cursor-pointer hidden md:flex"
                        data-tooltip-content=
                        {`Çmimi për: ${pr.departure.stationName} - ${pr.destination.stationName}`}
                        data-tooltip-variant="dark"
                        data-tooltip-id="my-tooltip"
                        data-tooltip-float={true}
                      >
                        <QuestionMarkCircleIcon
                          className="w-4 h-4"
                          height={20}
                        />
                      </span>
                      <span className="hidden md:inline">
                        <Tooltip
                          place="bottom"
                          id="my-tooltip"
                          delayHide={50}
                        />
                      </span>
                    </>
                  )}
                  <input
                    type="text"
                    className="absolute hidden"
                    defaultValue={tripParams?.tripId}
                    {...register(`prices.${i}_${index}.tripId`, {
                      valueAsNumber: true
                    })}
                  />
                  <input
                    type="text"
                    className="absolute hidden"
                    defaultValue={pr.priceId}
                    {...register(`prices.${i}_${index}.id`, {
                      valueAsNumber: true
                    })}
                  />
                  <input
                    type="text"
                    className="absolute hidden"
                    defaultValue={tripParams?.ageGroupId}
                    {...register(`prices.${i}_${index}.ageGroupId`, {
                      valueAsNumber: true
                    })}
                  />
                  <input
                    type="text"
                    className="absolute hidden"
                    defaultValue={pr.departure.id}
                    {...register(`prices.${i}_${index}.stationFrom`, {
                      valueAsNumber: true
                    })}
                  />
                  <input
                    type="text"
                    className="absolute hidden"
                    defaultValue={pr.destination.id}
                    {...register(`prices.${i}_${index}.stationTo`, {
                      valueAsNumber: true
                    })}
                  />
                </span>
              </td>
            );
          })}
        </tr>
      </>
    );
  });

  return (
    <FormWrapper
      pricing
      title="Cakto çmimet"
      onSubmit={handleSubmit(onSubmit)}
      className="max-w-full shadow-none border-none md:h-screen md:-mb-7"
      showSaveBtn={can}
    >
      {datas.length === 0 ? (
        <div className="w-full bg-gray-100 h-14 rounded-md justify-center overflow-auto">
          <p className="text-gray-500 text-center p-4">
            Ende nuk keni zgjedhur një linjë dhe orar...
          </p>
        </div>
      ) : (
        <>
          <table className="w-full table-fixed table mb-8">{datas}</table>
          {/* <div className="w-full h-full table table-fixed md:hidden">
            {datas}
          </div> */}
        </>
      )}
    </FormWrapper>
  );
};

export default PricingTable;
