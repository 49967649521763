import { useParams } from "react-router-dom";
const stateNavigate = ( crumbs: string[] ) => {
  const { id } = useParams();
  return ({
  crumbs: crumbs,
  params: {
    id: id,
    edited: true
  } }
  );
};

export default stateNavigate;
