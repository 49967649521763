import { FunnelIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { FC, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { defaultConfig } from "../../../hooks/useSearch";
import { IFilters } from "../../../interfaces/components/HeaderPage.types";
import { cx } from "../../../utils/classnames";
import Animation from "../../ui/Animation";
import Box from "../../ui/Box";
import ClickAwayListener from "../../ui/Clickaway";
import Checkbox from "../../ui/input/Checkbox";

const Filter: FC<IFilters> = ({ options, filterName, filterSelctor }) => {
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // const filter = `filter-${event.target.id}`;
    if (event.target.checked)
      searchParams.set(
        filterSelctor || defaultConfig.filterSelector,
        event.target.id
      );
    else searchParams.delete(filterSelctor || defaultConfig.filterSelector);
    searchParams.delete(defaultConfig.paginationSelector);
    setSearchParams(searchParams, { replace: true, state });
  };

  const onClear = () => {
    // options?.forEach((el) => searchParams.delete(`filter-${el.id}`));
    searchParams.delete(filterSelctor || defaultConfig.filterSelector);
    setSearchParams(searchParams, { replace: true, state });
    setOpen(false);
  };
  const filterLength = searchParams
    .toString()
    .split("&")
    .filter((el) =>
      el.startsWith(filterSelctor || defaultConfig.filterSelector)
    ).length;
  return (
    <div className="ml-3 md:ml-6">
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <div className="relative">
          <button
            onClick={() => setOpen((prev) => !prev)}
            className={cx([
              "flex justify-center items-center leading-5 rounded-lg whitespace-nowrap group",
              "ring-1 transition-shadow duration-200 hover:ring-gray-500 py-2 px-3",
              open ? "ring-primary" : "ring-blue-stroke"
            ])}
          >
            <FunnelIcon
              className={cx([
                "w-4 h-5 duration-500",
                filterLength
                  ? "text-primary"
                  : open
                  ? "text-black"
                  : "text-neutral group-hover:text-black duration-200"
              ])}
              {...(filterLength && {
                fill: "currentColor",
                fillRule: "evenodd"
              })}
            />
            <span
              className={cx([
                "md:inline hidden md:ml-2 ml-0",
                filterLength || open
                  ? "text-black"
                  : "text-neutral group-hover:text-black duration-200"
              ])}
            >
              {filterName}
            </span>
          </button>
          <Animation type="height" show={open} leaveInDom={!!options?.length}>
            <div
              className="absolute mt-2 right-0 z-50"
              style={{ minWidth: "calc(100% + 80px)" }}
            >
              <Box padding="py-1">
                {options?.map((el) => (
                  <Checkbox
                    key={`${el.label}_${el.id}`}
                    label={el.label}
                    name={el.label.toLowerCase()}
                    id={el.id.toString()}
                    onChange={handleChange}
                    checked={
                      searchParams.get(
                        filterSelctor || defaultConfig.filterSelector
                      ) === el.id.toString() || false
                    }
                    inputClassName="px-3 py-2 hover:bg-gray-50"
                  />
                ))}
                <div
                  className="border-t border-gray-200 px-3 py-2 flex justify-start items-center text-sm
              hover:bg-gray-50 cursor-pointer group"
                  onClick={onClear}
                >
                  <XMarkIcon
                    className="w-[18px] h-[18px] mr-2 text-neutral-300
                    group-hover:text-neutral duration-300
                  "
                    strokeWidth={2}
                  />
                  Clear
                </div>
              </Box>
            </div>
          </Animation>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default Filter;
