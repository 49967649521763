import HeaderPage from "../../components/lines-managements/header/HeaderPage";
import PromotionCodes from "../../components/promotion-codes";
import useCan from "../../components/users/permissions/useCan";

const PromoCodes: React.FC = () => {
  const can = useCan("promotioncodes.promotioncode.create");
  return (
    <>
      <HeaderPage
        {...(can && {
          link: {
            to: "add",
            children: "Shto kod të ri",
            state: ["Kodet promocionale", "Shto kod të ri"]
          }
        })}
      />
      <PromotionCodes />
    </>
  );
};

export default PromoCodes;
