/* eslint-disable max-lines */
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import Bus from "../../../components/shared/svgs/Bus";
import Train from "../../../components/shared/svgs/Train";
import FormWrapper from "../../../components/ui/FormWrapper";
import Input from "../../../components/ui/input/Input";
import Select from "../../../components/ui/select/Select";
import SuperAdmin from "../../../components/users/permissions/SuperAdmin";
import { ILineSchema } from "../../../interfaces/endpoints/lines/ILineSchema";
import {
  editLine,
  getCurrentLine,
  postLine
} from "../../../services/Lines.service";
import { getAllLocations } from "../../../services/Locations.service";
import { serialize } from "../../../utils/serializeToQueryString";
import { lineTypeEnum } from "./ListOfLines";

const AddLine: React.FC<{ editMode?: boolean }> = ({ editMode }) => {
  const { id } = useParams();
  const [lineType, setLineType] = useState<number>(1);
  // const { addLine, editLine: editLineContext } = useContext(LinesContext);
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields, isSubmitSuccessful },
    setValue,
    control
  } = useForm<ILineSchema>();
  const navigate = useNavigate();
  useEffect(() => {
    editMode && void getCurrentLineAPI();
  }, []);

  const handleLineTypeChange = (e: React.MouseEvent<HTMLSpanElement>) => {
    const lineTypeId = Number(e.currentTarget.id);
    setLineType(lineTypeId);
  };

  const getCurrentLineAPI = async () => {
    const { data } = await getCurrentLine(Number(id));
    const defualtStation = {
      parentid: 0,
      typeid: 0,
      latitude: 0,
      longitude: 0,
      cityId: 0,
      childrens: null
    };
    setValue("arrivalStation", {
      id: data.arrivalStation,
      name: `${data.arrivalCityName}, ${data.arrivalStationName}`,
      ...defualtStation
    });
    setValue("departureStation", {
      id: data.departureStation,
      name: `${data.departureCityName}, ${data.departureStationName}`,
      ...defualtStation
    });
    setValue("lineName", data.lineName);
    setValue("description", `${data.description}`);
    setValue("lineType", data.lineType);
    setLineType(data.lineType);
  };
  const onSubmit: SubmitHandler<ILineSchema> = async (data) => {
    if (editMode) {
      await editLine({
        ...data,
        departureStation: data.departureStation.id,
        arrivalStation: data.arrivalStation.id,
        id: Number(id),
        lineType: lineType || data.lineType
      })
        .then(() => {
          toast.success("Linja është ndryshuar me sukses.", {
            position: "top-right"
          });
          setTimeout(() => {
            navigate("/lines", { state: ["Menaxhimi i linjave"] });
          }, 500);
        })
        .catch(() => toast.error("Ndodhi një gabim gjatë ndryshimit."));
    } else {
      await postLine({
        ...data,
        departureStation: data.departureStation.id,
        arrivalStation: data.arrivalStation.id,
        lineType: lineType || data.lineType
      })
        .then(({ data: lineId }) => {
          toast.success("Linja është shtuar me sukses.", {
            position: "top-right"
          });
          console.log(lineId, data, "TUNG");
          setTimeout(() => {
            navigate(
              {
                pathname: "/trips/create",
                hash: "line",
                search: serialize({ lineName: data.lineName, lineId })
              },
              { state: ["Menaxhimi i linjave", "Oraret", "Shto orar të ri"] }
            );
          }, 500);
        })
        .catch(() => toast.error("Ndodhi një gabim gjatë krijimit."));
    }
    //   editMode ? editLineContext(data, Number(id)) : addLine(data);
    //   toast.success(
    //     `Linja eshte ${editMode ? "edituar" : "shtuar"} me sukses.`,
    //     {
    //       position: "top-right"
    //     }
    //   );
    //   navigate({ pathname: "/trips/create", hash: "line" });
    // } catch (error) {
    //   toast.error(`${error}` || `Failed to ${editMode ? "edit" : "add"}`);
    // }
  };

  // eslint-disable-next-line max-len
  const lineTypeClass =
    "cursor-pointer flex p-3 rounded-lg justify-center w-32 mb-6 h-full items-center border";

  const showPrompt = !isSubmitSuccessful && !!Object.keys(dirtyFields).length;

  return (
    <>
      <Link
        className="flex items-center mb-4 text-sm font-semibold text-primary"
        to={"/lines"}
        state={["Informatat për linja"]}
      >
        <ChevronLeftIcon className="w-4 h-4 mr-2" />
        <span>Kthehu tek lista</span>
      </Link>
      <FormWrapper
        showPrompt={showPrompt}
        onSubmit={handleSubmit(onSubmit)}
        title={editMode ? "Edito informaten" : "Shto linjë të re"}
      >
        <SuperAdmin>
          <div className="flex w-full gap-3 justify-center md:justify-start">
            <div
              className={`${lineTypeClass} ${
                lineType === 1 ? "border-primary text-primary" : "text-gray-600"
              }`}
              id={String(lineTypeEnum.bus)}
              onClick={handleLineTypeChange}
              {...register("lineType", { value: lineType })}
            >
              <Bus size={18} />
              <span className="ml-2 text-md">Autobus</span>
            </div>

            <div
              className={`${lineTypeClass} ${
                lineType === 2 ? "border-primary text-red" : "text-gray-600"
              }`}
              id={String(lineTypeEnum.train)}
              onClick={handleLineTypeChange}
              {...register("lineType", { value: lineType })}
            >
              <Train size={18} />
              <span className="ml-2 text-md">Tren</span>
            </div>
          </div>
        </SuperAdmin>
        <Controller
          control={control}
          name="departureStation"
          rules={{ required: "Ploteso stacionin e nisjes" }}
          render={({ field: { value, onChange } }) => (
            <Select
              asyncFunc={getAllLocations}
              onSelectedItemChange={onChange}
              selectedItem={value}
              label="Stacioni i nisjes"
              className="mb-6"
              placeholder="Zgjedh"
              getOptionLabel={(o) => (
                <span className="flex items-center">
                  {o.stationtype === 1 ? (
                    <Bus size={18} />
                  ) : (
                    <Train size={18} />
                  )}{" "}
                  <span className="ml-2">{o.name}</span>
                </span>
              )}
              error={errors.departureStation?.message}
              itemToString={(o) => o?.name || ""}
            />
          )}
        />
        <Controller
          control={control}
          name="arrivalStation"
          rules={{ required: "Ploteso stacionin e arritjes" }}
          render={({ field: { value, onChange } }) => (
            <Select
              asyncFunc={getAllLocations}
              onSelectedItemChange={onChange}
              selectedItem={value}
              getOptionLabel={(o) => (
                <span className="flex items-center">
                  {o.stationtype === 1 ? (
                    <Bus size={18} />
                  ) : (
                    <Train size={18} />
                  )}{" "}
                  <span className="ml-2">{o.name}</span>
                </span>
              )}
              itemToString={(o) => o?.name || ""}
              label="Stacioni i arritjes"
              className="mb-6"
              placeholder="Zgjedh"
              error={errors.arrivalStation?.message}
            />
          )}
        />
        <Input
          {...register("lineName", { required: "Vendos emrin e linjës" })}
          label="Emri i linjës"
          error={errors.lineName?.message}
          placeholder="Shkruani emrin e linjës këtu..."
          className="mb-6"
        />
        <Input
          register={register("description")}
          {...register("description")}
          label="Përshkrimi (Opcionale)"
          error={errors.description?.message}
          placeholder="Shkruani përshkrimin e linjës këtu..."
        />
      </FormWrapper>
    </>
  );
};

export default AddLine;
