import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import usePageIndex from "../../hooks/usePageIndex";
import { useSearch } from "../../hooks/useSearch";
import { deleteStation, getAllStations } from "../../services/Locations.service";
import AuditAuthor from "../audit-author/AuditAuthor";
import DeleteModal from "../ui/DeleteModal";
import Table, { Row } from "../ui/table";
import Pagination from "../ui/table/Pagination";
import Can from "../users/permissions/Can";

// const can = useCan(["locations.station.update", "locations.station.delete"]);

const columns: (string | number)[] = [
  "ID e stacionit",
  "Stacioni",
  "Qyteti",
  // "Lloji i stacionit",
  "Rangu i stacionit",
  "Detajet",
  "Veprimet"
];

const StationsTable = () => {
  const { response, loading, handlePageChange, page, setResponse } = useSearch(
    getAllStations,
    {
      withQuerySearch: true
    }
  );

  const { pageIndex }: any = usePageIndex();

  return (
    <div className="flex flex-col" style={{ height: "calc(100vh - 11rem)" }}>
      <Table titles={columns} style="hover" loading={loading}>
        {response?.data.result?.length ? (
          response?.data.result?.map((row) => (
            <Row
              key={row.id}
              id={row.id}
              station={row.stationName}
              city={row.locationName}
              // country={row.stationType}
              stationRank={row.stationRank}
              auditAuthor={
                <AuditAuthor
                  createdByName={row.auditAuthor.createdByName}
                  createdByLastName={row.auditAuthor.createdByLastName}
                  updatedByName={row.auditAuthor.updatedByName}
                  updatedByLastName={row.auditAuthor.updatedByLastName}
                  insertDate={row.auditAuthor.insertDate}
                  updateDate={row.auditAuthor.updateDate}
                />}
              actions={
                <>
                  <span className="flex items-center">
                    <Can permissions="locations.station.update">
                      <Link
                        className="p-2.5 md:p-1"
                        state={{ crumbs: ["Stacionet", "Edito Stacionin"], page: pageIndex }}
                        to={`/locations/edit/${row.id}`}
                        // state={["Menaxhimi i linjave", "Linjat", "Edito"]}
                      >
                        <PencilSquareIcon className="w-5 h-5 md:w-4" />
                      </Link>
                    </Can>
                    <Can permissions="locations.station.delete">
                    <DeleteModal
                      params={row.id}
                      title="Konfirmo fshirjen e stacionit."
                      handleDelete={deleteStation}
                      successMessage="Stacioni është fshirë me sukses."
                      errorMessage="Stacioni nuk është fshirë me sukses."
                      loadingMessage="Stacioni është duke u fshirë..."
                      onSuccess={() => {
                        setResponse((prev) =>
                          prev
                            ? {
                                ...prev,
                                data: {
                                  ...prev.data,
                                  result: prev.data.result.filter(
                                    (el) => el.id !== row.id
                                  )
                                }
                              }
                            : null
                        );
                      }}
                    />
                    </Can>
                  </span>
                </>
              }
            />
          ))
        ) : (
          <></>
        )}
      </Table>
      <Pagination
        onPageChange={handlePageChange}
        totalCount={response?.data?.totalCount || 0}
        length={response?.data?.result.length}
        page={page}
        pageSize={10}
      />
    </div>
  );
};

export default StationsTable;
