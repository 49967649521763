import { format, parse } from "date-fns";
import { useEffect } from "react";
import useDashboards from "../../hooks/useDashboards";
import useFetch from "../../hooks/useFetch";
import { getOperatorAgentSalesAnalytics } from "../../services/Analytics.service";
import SpinnerLoader from "../ui/SpinnerLoader";

const AgentSalesAnalytics = () => {
  const { line, from, to } = useDashboards();

  const { response, loading, handleFunc } = useFetch(
    getOperatorAgentSalesAnalytics,
    { loading: true, resetResponseOnError: true }
  );

  useEffect(() => {
    if (from && to) {
      void handleFunc({
        startDate: format(parse(from, "dd/MM/yyyy", new Date()), "MM-dd-yyyy"),
        endDate: format(parse(to, "dd/MM/yyyy", new Date()), "MM-dd-yyyy"),
        lineId: line?.value || undefined
      });
    }
  }, [from, to, line]);

  return (
    <div className="flex flex-col border border-gray-200 rounded-lg py-4 flex-1 relative">
      <div className="flex justify-between mb-3 px-6">
        <p className="text-xs uppercase leading-6 font-medium text-neutral">
          Agjentët
        </p>
        <p className="text-sm leading-6 font-bold">
          {loading ? (
            <span className="h-4 px-3.5 mr-1.5 rounded bg-gray-200 animate-pulse" />
          ) : (
            <span>{response?.data.nrofTotalTickets}</span>
          )}{" "}
          bileta totale
        </p>
      </div>
      <table className="">
        <thead
          className="table w-[90%] border-b border-gray-200 mx-6"
          style={{ tableLayout: "fixed" }}
        >
          <tr className="">
            <th className="text-xs pb-1.5 text-neutral font-medium text-left">
              Emri
            </th>
            <th className="text-xs pb-1.5 text-neutral font-medium text-left">
              Email
            </th>
            <th className="text-xs pb-1.5 text-neutral font-medium text-left">
              Bileta të shitura
            </th>
          </tr>
        </thead>
        {response?.data.agentSales.length && !loading ? (
          <tbody className="block h-[187px] overflow-auto tableDiv mr-1">
            {response?.data.agentSales.map((agent) => (
              <tr
                className="table w-[90%] first:mt-2 ml-6"
                style={{ tableLayout: "fixed" }}
              >
                <td className="pr-6 py-2 text-xs truncate" title={agent.nameSurname}>{agent.nameSurname}</td>
                <td className="pr-6 py-2 text-xs truncate" title={agent.email}>{agent.email}</td>
                <td className="pr-6 py-2 text-xs font-bold truncate" title={`${agent.sales} €`}>
                  {agent.sales} €
                </td>
              </tr>
            ))}
          </tbody>
        ) : null}
      </table>
      {!response?.data.agentSales.length && !loading ? (
        <div className="h-[187px] mx-6 mb-2 flex flex-col items-center mt-6">
          <p className="text-neutral text-sm">Nuk keni asnje te dhene!</p>
          <p className="text-neutral text-xs">
            Per linjen e caktuar dhe data e zgjedhuar.
          </p>
        </div>
      ) : null}
      {loading ? (
        <SpinnerLoader className="text-primary absolute right-0 left-0 top-24" />
      ) : null}
    </div>
  );
};

export default AgentSalesAnalytics;
