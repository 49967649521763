import ActiveLinesAndAgents from "../../../components/dashboards/ActiveLinesAndAgents";
import AgentSalesAnalytics from "../../../components/dashboards/AgentSalesAnalytics";
import BundleAnalytics from "../../../components/dashboards/BundleAnalytics";
import FilteredAnalytics from "../../../components/dashboards/FilteredAnalytics";
import OfficeAndOnlineSalesAnalytics from "../../../components/dashboards/OfficeAndOnlineSalesAnalytics";
import SelectLineAndDate from "../../../components/dashboards/SelectLineAndDate";
import TicketsToBePaidOnBusAnalytics from "../../../components/dashboards/TicketsToBePaidOnBusAnalytics";
import TripsSalesAnalytics from "../../../components/dashboards/TripsSalesAnalytics";

const OperatorsDashboard = () => {
  return (
    <div className="flex flex-col gap-6">
      <ActiveLinesAndAgents />
      <SelectLineAndDate />
      <div className="flex justify-between flex-wrap gap-5">
        <FilteredAnalytics />
        <TripsSalesAnalytics />
      </div>
      <div className=" flex gap-5">
        <TicketsToBePaidOnBusAnalytics />
        <AgentSalesAnalytics />
        <OfficeAndOnlineSalesAnalytics />
      </div>
      <BundleAnalytics />
    </div>
  );
};

export default OperatorsDashboard;
