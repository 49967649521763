/* eslint-disable max-len */

import { FC } from "react";
import { IIcon } from "./IIcon";

const Health: FC<IIcon> = ({ color, size }) => {
  const currentColor = color || "currentColor";
  const currentSize = size || 16;
  return (
    <span>
      <svg
        width={currentSize}
        height={currentSize}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-auto h-auto"
      >
        <path
          d="M11.2275 17.0625H6.72754C2.65504 17.0625 0.915039 15.3225 0.915039 11.25V6.75C0.915039 2.6775 2.65504 0.9375 6.72754 0.9375H11.2275C15.3 0.9375 17.04 2.6775 17.04 6.75V11.25C17.04 15.3225 15.3075 17.0625 11.2275 17.0625ZM6.72754 2.0625C3.27004 2.0625 2.04004 3.2925 2.04004 6.75V11.25C2.04004 14.7075 3.27004 15.9375 6.72754 15.9375H11.2275C14.685 15.9375 15.915 14.7075 15.915 11.25V6.75C15.915 3.2925 14.685 2.0625 11.2275 2.0625H6.72754Z"
          fill={currentColor}
        />
        <path
          d="M8.58719 13.5447C8.40719 13.5447 7.97219 13.4772 7.71719 12.8247L6.86219 10.6647C6.74219 10.3572 6.31469 10.0722 5.99219 10.0722L1.49219 10.0872C1.18469 10.0872 0.929688 9.83967 0.929688 9.52467C0.929688 9.21717 1.17719 8.96217 1.49219 8.96217L5.99219 8.94717H5.99969C6.78719 8.94717 7.62719 9.51717 7.91969 10.2522L8.60219 11.9772L10.1472 8.06217C10.3797 7.47717 10.7922 7.39467 10.9647 7.37967C11.1372 7.37217 11.5572 7.40967 11.8497 7.96467L12.6297 9.44217C12.7647 9.69717 13.1697 9.94467 13.4622 9.94467H16.5072C16.8147 9.94467 17.0697 10.1997 17.0697 10.5072C17.0697 10.8147 16.8147 11.0697 16.5072 11.0697H13.4622C12.7497 11.0697 11.9697 10.5972 11.6397 9.96717L11.0547 8.85717L9.47969 12.8247C9.20219 13.4697 8.75969 13.5447 8.58719 13.5447Z"
          fill={currentColor}
        />
      </svg>
    </span>
  );
};

export default Health;
