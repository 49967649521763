import { FC } from "react";
import { IReservationV2 } from "../../interfaces/reservations/Reservations.types";
interface UsersInfoProps {
  clientNames?: IReservationV2["clientNames"];
  journeyGuid: IReservationV2["journeyGuid"];
  paymentStatus: IReservationV2["paymentStatus"];
  uniqueJourneyId?: IReservationV2["uniqueJourneyId"];
}

const UsersInfo: FC<UsersInfoProps> = ({
  clientNames,
  journeyGuid,
  paymentStatus,
  uniqueJourneyId
}) => {
  return (
    <div className="flex flex-col col-start-1 col-span-4">
      <div className="text-sm">
        {uniqueJourneyId && <span className="text-xs mr-1 text-neutral">ID: </span>}
        {paymentStatus === 1 ? (
          <a
            href={`${process.env.REACT_APP_CDN_API_URL}/journeyreservationdata/
          ${journeyGuid}/${uniqueJourneyId}.pdf`.replace(/\s/g, "")}
            target="_blank"
            className="group"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <span
              className="duration-300 text-opacity-90 group-hover:text-opacity-100
                       relative justify-center items-center
                       before:bg-primary before:absolute before:w-full before:h-[1px] before:rounded-sm
                       before:bottom-0 before:left-0 before:origin-left before:scale-x-0
                       before:duration-300 before:ease-in-out before:transform group-hover:before:origin-left
                       group-hover:before:scale-x-100 transition-all text-primary"
            >
              {uniqueJourneyId}
            </span>
          </a>
        ) : (
          uniqueJourneyId
        )}
      </div>
      {clientNames ? (
        <>
          <div className="flex items-center">
            <p className="text-sm">
              {clientNames[0].firstName} {clientNames[0].lastName}
            </p>
          </div>
          <div className="flex items-center">
            <p className="text-sm font-light">{clientNames[0].email}</p>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default UsersInfo;
