import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { IStations } from "../../../interfaces/endpoints/IStations";
import Bus from "../../shared/svgs/Bus";
import Train from "../../shared/svgs/Train";
import Input from "../../ui/input/Input";
import Select from "../../ui/select/Select";
import MultpileLangFields from "./MultipleLangFieldsStation";
import { stationRank } from "./Stations";

export enum stationTypeEnum {
  "bus" = 1,
  "train" = 2
}

const CreateStation = () => {
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext<IStations>();
  const [stationType, setStationType] = useState<number>(1);
  const handleLineTypeChange = (e: React.MouseEvent<HTMLSpanElement>) => {
    const stationTypeId = Number(e.currentTarget.id);
    setStationType(stationTypeId);
  };
  const stationTypeClass =
    "cursor-pointer flex p-3 rounded-lg justify-center items-center border";

  return (
    <>
      <MultpileLangFields />
      <div className="flex w-full relative">
        <Link
          to="https://gj.al/4uiRkg"
          target="_blank"
          className="absolute left-[70px] cursor-pointer"
        >
          <>
            <div
              data-tooltip-content="Klikoni këtu për të gjetur Place ID"
              data-tooltip-id="placeIdFinder"
              className="bg-gray-700 ring-1 ring-gray-300 text-white text-xs font-medium h-4 w-4
              rounded-full flex justify-center items-center cursor-pointer"
            >
              i{" "}
            </div>
            <Tooltip id="placeIdFinder" className="z-50" />
          </>
        </Link>
        <Input
          {...register("station.placeId")}
          label="Place ID*"
          className="mb-6 w-full"
          placeholder="Shkruaj Place ID këtu..."
          error={errors.station?.placeId?.message}
        />
      </div>
      <div className="flex items-center">
        <Controller
          control={control}
          name="station.stationRank"
          render={({ field: { onChange, value } }) => (
            <Select
              label="Rangu i stacionit*"
              placeholder="Zgjedh rangun e stacionit"
              className={`${
                errors.station?.stationRank?.message ? "" : "mb-6"
              }`}
              selectedItem={value}
              options={stationRank}
              onSelectedItemChange={(item) => onChange(item)}
              error={errors.station?.stationRank?.message}
            />
          )}
        />
        <div className="flex gap-3 ml-6">
          <div
            className={`${stationTypeClass} ${
              stationType === 1
                ? "border-primary text-primary"
                : "text-gray-600"
            }`}
            id={String(stationTypeEnum.bus)}
            onClick={handleLineTypeChange}
            {...register("station.stationType", { value: stationType })}
          >
            <Bus size={18} />
          </div>
          <div
            className={`${stationTypeClass} ${
              stationType === 2 ? "border-primary text-red" : "text-gray-600"
            }`}
            id={String(stationTypeEnum.train)}
            onClick={handleLineTypeChange}
            {...register("station.stationType", { value: stationType })}
          >
            <Train size={18} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateStation;
