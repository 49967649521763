import { axios } from "../config/axios";
import { ITripType } from "./../interfaces/endpoints/trips/Trips.types";
// import { getAllTripsParamsType } from "../interfaces/endpoints/trips/ITrips.sevice";
import { IEditTrips } from "../interfaces/endpoints/trips/IEditTrip";
import { ITrips } from "../interfaces/endpoints/trips/Trips.types";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import { serialize } from "../utils/serializeToQueryString";

export type getAllTripsParamsType = {
  pageIndex: number;
  searchKeyword?: string;
  lineId?: number;
};

export const getAllTrips = async (
  att: getAllTripsParamsType
): Promise<IAxiosResponse<ITrips>> => {
  return axios.get<ITrips>(`route/TripsByOperator${serialize(att)}`);
};
export const getCurrentTrip = async (
  id: number
): Promise<IAxiosResponse<ITripType>> => {
  return axios.get<ITripType>(`route/trip/${id}`);
};
export const editTrip = async (
  body: IEditTrips
): Promise<IAxiosResponse<IEditTrips>> => {
  return axios.post("Route/EditTrip", body);
};
export const deleteTrip = async (id: number): Promise<boolean> => {
  return axios.delete(`Route/DeleteTrip/${id}`);
};
export const postTrip = async (
  body: ITripType
): Promise<IAxiosResponse<number>> => {
  return axios.post("Route/PostTrip", body);
};
export const getTripsByLine = async (
  lineId: number
): Promise<IAxiosResponse<ITripType[]>> => {
  return axios.get<ITripType[]>(`Route/Trips/${lineId}`);
};
// export const getTripsByOperator = async (
//   operatorId: getAllTripsParamsType
// ): Promise<IAxiosResponse<ITripType[]>> => {
//   return axios.get<ITripType[]>(`Route/TripsByOperator/${operatorId}`);
// };
