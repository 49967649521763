import { FC } from "react";
import { cx } from "../../../utils/classnames";

export interface DayProps {
  day: DaysShortEnum;
  enable: boolean;
}

export enum DaysShortEnum {
  monday = "H",
  tuesday = "Ma",
  wednesday = "Më",
  thursday = "E",
  friday = "P",
  saturday = "Sh",
  sunday = "D"
}

const Day: FC<DayProps> = ({ day, enable }) => (
  <span
    className={cx([
      "rounded-full py-1 px-2 mr-1.5",
      enable ? "text-primary ring-1 ring-gray-200 bg-white" : "text-gray-500 bg-gray-100"
    ])}
  >
    {day}
  </span>
);

export default Day;
