import { axios } from "../config/axios";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import { IPagination } from "../interfaces/IPagination";
import {
  ISingleNotice,
  ITripNotices,
  ITripNoticesSchema
} from "../interfaces/endpoints/ITripsNotices";
import { serialize } from "../utils/serializeToQueryString";

export type getAllTripNoticesType = {
  pageIndex: number;
  pageSize: number;
};

export const getAllTripNotices = async (
  att: getAllTripNoticesType
): Promise<IAxiosResponse<IPagination<ITripNotices>>> => {
  return axios.get<IPagination<ITripNotices>>(
    `TripNotices/GetTripsNotices${serialize(att)}`
  );
};

export const getCurrentTripNotices = async (
  id: number
): Promise<IAxiosResponse<ISingleNotice>> => {
  return axios.get<ISingleNotice>(
    `TripNotices/GetTripNoticeById?tripNoticeId=${id}`
  );
};

export const createTripNotices = async (
  body: ITripNoticesSchema
): Promise<number> => {
  return axios.post("/TripNotices/CreateTripNotice", body);
};

export const editTripNotices = async (body: ITripNoticesSchema): Promise<boolean> => {
  return axios.put("TripNotices/UpdateTripNotice", body);
};

export const deleteTripNoticesById = async (
  id: number
): Promise<IAxiosResponse<ITripNotices>> => {
  return axios.delete(`TripNotices/TripNotice/${id}`);
};
