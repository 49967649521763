import { array, object, string } from "yup";

export const TripNoticesSchema = object().shape({
  tripId: object().required("Vendos orarin!"),
  tripNoticePriority: object().required("Vendos prioritetin!"),
  tripNoticeType: object().required("Vendos llojin!"),
  locales: array().of(
    object().shape({
      title: string()
        .required("Vendos titullin!")
        .typeError("Vendos titullin!"),
      description: string()
        .required("Vendos përshkrimin!")
        .typeError("Vendos përshkrimin!")
    })
  )
});
