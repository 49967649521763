import { useFormContext } from "react-hook-form";
import { IUserRole } from "../../../interfaces/endpoints/IUserRoles.types";

const ToggleButton = () => {
  const { getValues, register } = useFormContext<IUserRole>();
  const currentValue = getValues("status");

  return (
    <div className="flex items-center justify-start">
      <span className="mr-2 text-neutral block text-sm leading-[17px] whitespace-nowrap">
        Aktiv
      </span>
      <label className="switch" htmlFor="status">
        <input
          id="status"
          type="checkbox"
          defaultChecked={currentValue}
          {...register("status")}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default ToggleButton;
