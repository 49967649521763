import { PlusIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import { Link } from "react-router-dom";
import { IHeaderActions } from "../../../interfaces/components/HeaderPage.types";
import { cx } from "../../../utils/classnames";

const HeaderActions: FC<IHeaderActions> = ({ ...props }) => {
  return (
    <Link
      {...props}
      className={cx([
        props?.className,
        "flex justify-between items-center sm:px-4 px-3 leading-[18px] whitespace-nowrap",
        "font-bold duration-300 text-sm sm:py-[9px] py-[10px]",
        "bg-primary text-white rounded-lg group hover:shadow-xl"
      ])}
    >
      <span className="relative">
        <PlusIcon
          strokeWidth={3}
          className={cx([
            "w-4 h-4",
            "absolute group-hover:translate-y-4 translate-y-0 opacity-100 group-hover:opacity-0",
            "transition-all duration-300",
            !!props?.children && "sm:mr-1"
          ])}
        />
        <PlusIcon
          strokeWidth={3}
          className={cx([
            "w-4 h-4",
            "group-hover:translate-y-0 -translate-y-4 opacity-0 group-hover:opacity-100",
            "transition-all duration-300",
            !!props?.children && "sm:mr-1"
          ])}
        />
      </span>
      <span className="hidden sm:inline whitespace-nowrap">
        {props?.children}
      </span>
    </Link>
  );
};

export default HeaderActions;
