import { PassengerTypeEnum } from "./IGetJorneys";

export type passagerType = {
  id?: number;
  type: PassengerTypeEnum;
  name: string;
  lastName: string;
  passportId: string | null;
};
export enum OfficePaymentMethodEnum {
  "Online" = 1,
  "Online " = 2,
  Transfer = 3,
  Cash = 4,
  POS = 5,
  Bus = 6
}

interface ICreateReservationSchema {
  passagers: Partial<passagerType>[];
  phoneNumber: string;
  email: string;
  paymentMethod: OfficePaymentMethodEnum;
}

export interface IJourneyTripModel {
  oneWayTripId: number;
  oneWayFromStationId: number;
  oneWayToStationId: number;
  roundWayTripId: number;
  roundWayFromStationId?: number;
  roundWayToStationId: number;
  oneWayJourneyDate: string;
  roundWayJourneyDate: string | null;
  isReservable: boolean;
  journeyType: number;
}
export type clientNames = {
  id?: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  passportId: string;
  ageGroupId: number;
};
export interface ICreateReservationModel {
  journeyTripMappingGuid: string;
  journeyTripMappings: IJourneyTripModel[];
  email: string;
  clientNames: clientNames[];
  international: boolean;
  paymentMethod: number;
}

interface ticketDetails {
  ticketId: number;
  tripId: number;
  stationFrom: number;
  departureStationName: string;
  stationTo: 4;
  arrivalStationName: string;
  journeyDate: string;
  email: string;
  countryId: 0;
  operatorName: string;
}

export interface IGetResrvationById {
  journeyGuid: string;
  uniqueJourneyId: string;
  price: number;
  paymentStatus: number;
  paymentMethodId: number;
  oneWayTicketDetails: ticketDetails[];
  roundWayTicketDetails: ticketDetails[];
  clientNames: clientNames[];
  paymentDate: string;
  insertDate: string;
}

export default ICreateReservationSchema;
