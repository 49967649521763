import { axios } from "../config/axios";
import {
  IFilteredAnalyticsPerLineProps,
  IFilteredAnalyticsProps,
  IStaticAnalyticsProps,
  OfficeAndOnlineSalesAnalyticsModel,
  OperatorActiveLinesAndAgentsModel,
  OperatorAgentsSalesAnalyticsModel,
  OperatorBundleAnalyticsModel,
  OperatorFilteredAnalyticsListModel,
  TicketsToBePaidOnBusAnalyticsModel,
  TripSalesPerDateModel,
  TripsSalesAnalyticsModel
} from "../interfaces/endpoints/IAnalytics.types";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import { serialize } from "../utils/serializeToQueryString";

export const getStaticAnalytics = async (): Promise<
  IAxiosResponse<IStaticAnalyticsProps>
> => {
  return axios.get<IStaticAnalyticsProps>("dashboard/staticanalytics");
};

export const getFilteredAnalytics = async (params: {
  startDate: string;
  endDate: string;
}): Promise<IAxiosResponse<IFilteredAnalyticsProps>> => {
  return axios.get<IFilteredAnalyticsProps>(
    `dashboard/filteredanalytics${serialize(params)}`
  );
};

export const getFilteredAnalyticsPerLine = async (params: {
  startDate: string;
  endDate: string;
  lineId?: string;
  operatorId?: string;
}): Promise<IAxiosResponse<IFilteredAnalyticsPerLineProps>> => {
  return axios.get<IFilteredAnalyticsPerLineProps>(
    `dashboard/linesearchandreservation${serialize(params)}`
  );
};
// * New

export const getOperatorActiveLinesAndAgents = async (): Promise<
  IAxiosResponse<OperatorActiveLinesAndAgentsModel>
> => {
  return axios.get<OperatorActiveLinesAndAgentsModel>(
    "v1/OperatorDashboard/ActiveLinesAndAgents"
  );
};

export const getOperatorFilteredAnalytics = async (params: {
  startDate: string;
  endDate: string;
  lineId?: number;
}): Promise<IAxiosResponse<OperatorFilteredAnalyticsListModel>> => {
  return axios.get<OperatorFilteredAnalyticsListModel>(
    `v1/OperatorDashboard/FilteredAnalytics${serialize(params)}`
  );
};

export const getOperatorAgentSalesAnalytics = async (params: {
  startDate: string;
  endDate: string;
  lineId?: number;
}): Promise<IAxiosResponse<OperatorAgentsSalesAnalyticsModel>> => {
  return axios.get<OperatorAgentsSalesAnalyticsModel>(
    `v1/OperatorDashboard/AgentSalesAnalytics${serialize(params)}`
  );
};

export const getOperatorBundleAnalytics = async (params: {
  startDate: string;
  endDate: string;
}): Promise<IAxiosResponse<OperatorBundleAnalyticsModel>> => {
  return axios.get<OperatorBundleAnalyticsModel>(
    `v1/OperatorDashboard/BundleAnalytics${serialize(params)}`
  );
};

export const getOperatorTicketsToBePaidOnBusAnalytics = async (params: {
  startDate: string;
  endDate: string;
  lineId?: number;
}): Promise<IAxiosResponse<TicketsToBePaidOnBusAnalyticsModel>> => {
  return axios.get<TicketsToBePaidOnBusAnalyticsModel>(
    `v1/OperatorDashboard/TicketsToBePaidOnBusAnalytics${serialize(params)}`
  );
};

export const getOperatorOfficeAndOnlineSalesAnalytics = async (params: {
  startDate: string;
  endDate: string;
  lineId?: number;
}): Promise<IAxiosResponse<OfficeAndOnlineSalesAnalyticsModel>> => {
  return axios.get<OfficeAndOnlineSalesAnalyticsModel>(
    `v1/OperatorDashboard/OfficeAndOnlineSalesAnalytics${serialize(params)}`
  );
};

export const getOperatorTripsSalesAnalytics = async (params: {
  startDate: string;
  endDate: string;
  lineId?: number;
}): Promise<IAxiosResponse<TripsSalesAnalyticsModel[]>> => {
  return axios.get<TripsSalesAnalyticsModel[]>(
    `v1/OperatorDashboard/TripsSalesAnalytics${serialize(params)}`
  );
};

export const getOperatorTripSalesPerDate = async (params: {
  dateFrom: string;
  dateTo: string;
  tripId?: string;
}): Promise<IAxiosResponse<TripSalesPerDateModel>> => {
  return axios.get<TripSalesPerDateModel>(
    `v1/OperatorDashboard/TripSalesPerDate${serialize(params)}`
  );
};
