import { Outlet } from "react-router-dom";
import HeaderPage from "../../../components/lines-managements/header/HeaderPage";
import UsersNavigate from "../../../components/users/UsersNavigate";
import useCan from "../../../components/users/permissions/useCan";
import useFetch from "../../../hooks/useFetch";
import { getAllUserRoles } from "../../../services/UserRole.service";

const UsersOverview = () => {
  const { response } = useFetch(getAllUserRoles, {
    initRequest: true,
    params: { pageIndex: 1, pageSize: 999 }
  });
  const can = useCan("users.user.create");
  return (
    <>
      <HeaderPage
        {...(can && {
          link: {
            to: "invite/add",
            children: "Shto user të ri",
            state: ["User overview", "Users", "Shto user të ri"]
          }
        })}
        filterName="Filtro Userat"
        filterSelctor="userRole"
        hasFilter
        options={response?.data.result.map((el) => ({
          label: el.roleName,
          id: el.id
        }))}
      />
      <UsersNavigate />
      <Outlet />
    </>
  );
};

export default UsersOverview;
