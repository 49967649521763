import { useSearch } from "../../hooks/useSearch";
import { getReservations_v2 } from "../../services/Reservations.service";
import Pagination from "../ui/table/Pagination";
import SkeletonTicket from "./SkeletonTicket";
import Ticket from "./Ticket";

const Reservations = () => {
  const { response, loading, handlePageChange, page } = useSearch(
    getReservations_v2,
    {
      withQuerySearch: true,
      filterSelector: "statusId",
      withFilter: true,
      otherQuery: { filterByJourneyDate: "false", pageSize: "12" }
    }
  );

  return (
    <>
      <style>
        {`
        .ticket:before {
          clip-path: circle(70% at 100% 50%);
        }
        .ticket:after {
          clip-path: circle(70% at 0 50%);
        }
        `}
      </style>
      <div className="flex gap-4 flex-col lg:max-w-[75%] mx-auto">
        <SkeletonTicket loading={loading} />
        {!loading && response?.data.result.map((el) => <Ticket {...el} />)}
      </div>
      {response?.data.totalPages ? (
        <Pagination
          onPageChange={handlePageChange}
          totalCount={response?.data?.totalCount || 0}
          length={response?.data?.result.length}
          page={page}
          pageSize={response?.data.pageSize || 12}
          viewingOf={false}
          className="border-none px-0 py-0 justify-center mt-8"
        />
      ) : null}
    </>
  );
};

export default Reservations;
