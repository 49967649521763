/* eslint-disable max-lines */
import {
  Cog8ToothIcon,
  InformationCircleIcon
} from "@heroicons/react/24/outline";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import useUser from "../../hooks/useUser";
import Button from "../ui/Button";
import DropDown from "../ui/DropDown";
import Can from "../users/permissions/Can";
import useCan from "../users/permissions/useCan";
import useSuperAdmin from "../users/permissions/useSuperAdmin";

const RightContent: React.FC = () => {
  const { user, logOut } = useUser();
  const canUpdate = useCan("users.user.operator.update");
  const superAdmin = useSuperAdmin();
  return (
    <div className="flex items-center">
      {!superAdmin ? (
        <div
          data-tooltip-id="free_trail"
          className="px-3 py-2 rounded-md bg-gray-100  flex items-center gap-1.5 mr-4"
          data-tooltip-content={""}
          data-tooltip-float={true}
        >
          <InformationCircleIcon className="w-5 h-5" />
          <p className="text-xs text-neutral">
            <span className="font-semibold">Free Trial</span> (skadon në Janar
            2024)
          </p>
          <Tooltip
            id="free_trail"
            style={{
              backgroundColor: "#757575",
              borderRadius: "8px",
              padding: "10px 12px",
              width: "253px",
              display: "flex",
              justifyContent: "center"
            }}
          >
            <p className="text-sm leading-5 text-white">
              Periudha e provimit do të skadojë <br /> brenda për një muaj -{" "}
              <span className="font-semibold">Janar 2024.</span>
              <br />
              Për më shumë:{" "}
              <span className="font-medium underline">travel@gjirafa.com</span>
            </p>
          </Tooltip>
        </div>
      ) : null}
      {user?.operator?.operatorName ? (
        <Can permissions="users.user.operator.read">
          {canUpdate ? (
            <Link
              to="operator/change"
              state={["Operatoret", "Ndrysho Operatorin"]}
              className="mr-4 bg-gradient-to-bl from-primary to-pink text-white text-sm px-3 py-1.5
          rounded-lg shadow-bottom"
            >
              {user?.operator?.operatorName}
            </Link>
          ) : (
            <div
              className="mr-4 bg-gradient-to-bl from-primary to-pink text-white text-sm px-3 py-1.5
            rounded-lg shadow-bottom"
            >
              {" "}
              {user?.operator?.operatorName}
            </div>
          )}
        </Can>
      ) : null}
      <DropDown
        boxPadding={false}
        boxClassName="mt-2 w-72"
        name={
          <div
            className=" bg-gray-100 ring-1 ring-gray-300 text-neutral font-medium
           h-8 w-8 rounded-full flex justify-center items-center"
          >
            {user?.firstName.charAt(0)}
          </div>
        }
      >
        <div className="">
          <div className="flex pt-4 px-4 gap-2">
            <div
              className="bg-gray-100 ring-1 ring-gray-300 text-neutral font-medium
           h-8 w-8 rounded-full flex justify-center items-center"
            >
              {user?.firstName.charAt(0)}
            </div>{" "}
            <div className="flex flex-col">
              <span className="text-sm">
                {user?.firstName} {user?.lastName}
              </span>
              <span className="text-xs text-neutral"> {user?.email}</span>
            </div>
          </div>
          <a
            href="https://myaccount.gjirafa.com/"
            target="_blank"
            className="group flex p-4 items-center justify-start text-sm cursor-pointer hover:bg-gray-100
            leading-[18px]"
          >
            <Cog8ToothIcon
              className="mr-1.5 h-4 w-4 group-hover:translate-x-0.5 transform-gpu text-neutral
            duration-200 "
              style={{ backfaceVisibility: "hidden" }}
            />
            Menaxho Profilin
          </a>
          <Button
            btnType="custom"
            className="text-gray-700 flex justify-start items-center border-t w-full border-gray-200
             hover:bg-gray-100 group"
            padding="p-4"
            fontWeight={false}
            rounded={false}
            onClick={logOut}
          >
            <ArrowRightOnRectangleIcon
              className="mr-1.5 h-4 w-4 group-hover:translate-x-0.5 transform-gpu text-neutral
            duration-200 "
              style={{ backfaceVisibility: "hidden" }}
            />
            <span className=""> Dil</span>
          </Button>
        </div>
      </DropDown>
    </div>
  );
};

export default RightContent;
