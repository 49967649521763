import { FC } from "react";
import { IDashboardCard } from "../../interfaces/components/Dashboard.types";
import { cx } from "../../utils/classnames";
import Animation from "../ui/Animation";
import Box from "../ui/Box";

const DashboardCard: FC<IDashboardCard> = ({
  title,
  currentValue,
  ratePercentage,
  ratePercentageDesc,
  className,
  border,
  padding = "px-6 py-4",
  rounded,
  animation = true,
  loading,
  delay,
  duration = 400,
  type = "fade-in",
  currency = "€"
}) => {
  return (
    <Animation show={true} type={type} duration={duration} delay={delay}>
      <div className={cx(["w-full group", className])}>
        <Box
          className={cx([
            "w-full h-full",
            animation &&
            "transform-gpu transition-all duration-500 group-hover:shadow-xl"
          ])}
          border={border}
          rounded={rounded}
          shadow={false}
          padding={padding}
        >
          <div className="flex">
            <p
              className={cx([
                "flex text-neutral font-medium leading-[18px] mb-1 text-xs",
                animation &&
                "transform-gpu transition-all duration-400 group-hover:text-primary"
              ])}
            >
              {title}
            </p>
          </div>
          <div className="flex">
            <div className="text-black text-xl font-medium mb-1 leading-[30px] w-full empty:h-[30px]">
              {loading ? (
                <div className="w-1/3 h-[30px] bg-gray-300 animate-pulse rounded-sm" />
              ) : currentValue !== undefined ? (
                `${currentValue} ${currency ? currency : ""}`
              ) : null}
            </div>
          </div>
          <div className="leading-[18px] text-xs empty:h-[18px]">
            {loading ? (
              <div className="w-full h-[18px] bg-gray-300 animate-pulse rounded-sm" />
            ) : (
              <>
                {ratePercentage !== undefined ? (
                  <>
                    <span
                      className={cx([
                        "inline-flex mr-2 font-bold",
                        animation &&
                        "transform-gpu transition-all duration-500 scale-100 group-hover:scale-[1.20]",
                        ratePercentage?.startsWith("-")
                          ? "text-red"
                          : "text-confirm"
                      ])}
                      style={{ willChange: "transform", backfaceVisibility: "hidden" }}
                    >
                      {ratePercentage}%
                    </span>
                    <span className="font-medium text-neutral">
                      {ratePercentageDesc}
                    </span>
                  </>
                ) : null}
              </>
            )}
          </div>
        </Box>
      </div>
    </Animation>
  );
};

export default DashboardCard;
