/* eslint-disable max-len */

import { FC } from "react";
import { IIcon } from "./IIcon";

const Ticket: FC<IIcon> = ({ color, size }) => {
  const currentColor = color || "currentColor";
  const currentSize = size || 16;
  return (
    <span>
      <svg
        width={currentSize}
        height={currentSize}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-auto h-auto"
      >
        <path
          d="M12.75 15.5625H5.25C1.9425 15.5625 0.9375 14.5575 0.9375 11.25V10.875C0.9375 10.5675 1.1925 10.3125 1.5 10.3125C2.22 10.3125 2.8125 9.72 2.8125 9C2.8125 8.28 2.22 7.6875 1.5 7.6875C1.1925 7.6875 0.9375 7.4325 0.9375 7.125V6.75C0.9375 3.4425 1.9425 2.4375 5.25 2.4375H12.75C16.0575 2.4375 17.0625 3.4425 17.0625 6.75V7.5C17.0625 7.8075 16.8075 8.0625 16.5 8.0625C15.78 8.0625 15.1875 8.655 15.1875 9.375C15.1875 10.095 15.78 10.6875 16.5 10.6875C16.8075 10.6875 17.0625 10.9425 17.0625 11.25C17.0625 14.5575 16.0575 15.5625 12.75 15.5625ZM2.0625 11.37C2.0775 13.95 2.61 14.4375 5.25 14.4375H12.75C15.255 14.4375 15.8625 13.995 15.93 11.745C14.8575 11.49 14.0625 10.5225 14.0625 9.375C14.0625 8.2275 14.865 7.26 15.9375 7.005V6.75C15.9375 4.0725 15.435 3.5625 12.75 3.5625H5.25C2.61 3.5625 2.0775 4.05 2.0625 6.63C3.135 6.885 3.9375 7.8525 3.9375 9C3.9375 10.1475 3.135 11.115 2.0625 11.37Z"
          fill={currentColor}
        />
        <path
          d="M7.5 5.4375C7.1925 5.4375 6.9375 5.1825 6.9375 4.875V3C6.9375 2.6925 7.1925 2.4375 7.5 2.4375C7.8075 2.4375 8.0625 2.6925 8.0625 3V4.875C8.0625 5.1825 7.8075 5.4375 7.5 5.4375Z"
          fill={currentColor}
        />
        <path
          d="M7.5 10.9341C7.1925 10.9341 6.9375 10.6791 6.9375 10.3716V7.61914C6.9375 7.31164 7.1925 7.05664 7.5 7.05664C7.8075 7.05664 8.0625 7.31164 8.0625 7.61914V10.3716C8.0625 10.6866 7.8075 10.9341 7.5 10.9341Z"
          fill={currentColor}
        />
        <path
          d="M7.5 15.5625C7.1925 15.5625 6.9375 15.3075 6.9375 15V13.125C6.9375 12.8175 7.1925 12.5625 7.5 12.5625C7.8075 12.5625 8.0625 12.8175 8.0625 13.125V15C8.0625 15.3075 7.8075 15.5625 7.5 15.5625Z"
          fill={currentColor}
        />
      </svg>
    </span>
  );
};

export default Ticket;
