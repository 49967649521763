interface SpinnerProps {
  className?: string;
  size?: number;
}

const Spinner: React.FC<SpinnerProps> = ({ className, size }) => {
  return (
    <span
      className={`${className} block rounded-full relative animate-spin spinner w-6 h-6 `}
      style={{
        width: size,
        height: size,
        borderTop: "3px solid currentcolor",
        borderRight: "3px solid rgba(255,255,255,0.4)",
        borderBottom: "3px solid rgba(255,255,255,0.4)",
        borderLeft: "3px solid rgba(255,255,255,0.4)"
      }}
    />
  );
};

export default Spinner;
