import { FC } from "react";

interface INotfoundTable {
  header: string;
  paragraph?: string;
}

const NotfoundTable: FC<INotfoundTable> = ({ header, paragraph }) => {
  return (
    <div
      className="border-b border-x rounded-b-md border-separate flex-1 flex  items-center
    flex-col"
    >
      <h1 className="text-lg font-medium mb-3 mt-20">{header}</h1>
      <p className="text-sm text-neutral">
        {paragraph}
      </p>
    </div>
  );
};

export default NotfoundTable;
