import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { FC } from "react";
import ReactPagination, { ReactPaginateProps } from "react-paginate";
import { cx } from "../../../utils/classnames";

export interface PaginationProps {
  totalCount: number;
  pageSize: number;
  page: number;
  onPageChange: ReactPaginateProps["onPageChange"];
  length?: number;
  viewingOf?: boolean;
  className?: string;
}

const Pagination: FC<PaginationProps> = ({
  totalCount,
  pageSize,
  page,
  onPageChange,
  length,
  viewingOf = true,
  className
}) => {
  if (!totalCount || !length) return null;

  return (
    <div
      className={cx([
        "flex md:items-center md:flex-row flex-col items-start text-primary-tertText text-sm leading-5",
        "border-b border-x rounded-b-md border-separate py-4 px-6",
        className
      ])}
    >
      <ReactPagination
        containerClassName="flex items-center"
        pageCount={totalCount / pageSize}
        forcePage={page - 1 || 0}
        onPageChange={onPageChange || undefined}
        previousLabel={<ChevronLeftIcon className="w-4 h-4 text-gray" />}
        nextLabel={<ChevronRightIcon className="w-4 h-4 text-gray" />}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        previousClassName="px-2"
        nextClassName="px-2"
        pageClassName="py-0.5 px-2 flex items-center justify-center rounded select-none"
        pageLinkClassName="focus:outline-none"
        activeClassName="text-primary ring-1 ring-primary"
        // breakLabel={null}
        breakClassName="px-2"
        previousLinkClassName="focus:outline-none select-none"
        nextLinkClassName="focus:outline-none select-none"
      />
      {viewingOf && (
        <div className="select-none text-neutral ml-4 md:ml-6 text-xs font-medium xl:mt-0 mt-2 ">
          Viewing {pageSize * (page || 1) - (pageSize - 1)} to{" "}
          {pageSize * (page || 1) - (pageSize - length)} of {totalCount} Items
        </div>
      )}
    </div>
  );
};
export default Pagination;
