/* eslint-disable max-lines */
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { FC, useEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { OfficeReservationSchema } from "../../pages/office/ChooseLine";
import { getLinesSelectList } from "../../services/Lines.service";
import { availableLines } from "../../services/Reservations.service";
import Button from "../ui/Button";
import DropDown from "../ui/DropDown";
import DayPicker from "../ui/datepicker/day/DayPicker";
import Select from "../ui/select/Select";
import PassagersBox from "./passagersBox";
// import { IUpperSearchProps } from "../../interfaces/components/OfficeSale.types";
import { useToggle } from "../../hooks/useToggle";
import { IUpperSearchProps } from "../../interfaces/components/OfficeSale.types";

const UpperSearch: FC<IUpperSearchProps> = ({ resetData }) => {
  const { control, watch, setValue, getValues } =
    useFormContext<OfficeReservationSchema>();
  const [selectedFromDate, setSelectedFromDate] = useState<Date | null>(null);
  const [disabledDaysOfTheWeek, setDisabledDaysOfTheWeek] = useState<number[]>([0,1,2,3,4,5,6]);
  const [reverseDisabledDaysOfTheWeek, setReverseDisabledDaysOfTheWeek] = useState<number[]>([0,1,2,3,4,5,6]);
  const [selectedLineId, setSelectedLineId] = useState<number | undefined>(
    undefined
  );
  const today = useMemo(() => new Date(), []);
  const [resetDateToggled, toggleResetDate] = useToggle(false);

  useEffect(() => {
    const fetchData = async (reverse: boolean) => {
      if (selectedLineId !== undefined) {
        try {
          const response = await availableLines({
            lineId: selectedLineId,
            reverse
          });
          if(response.data){
            if (reverse) {
              setReverseDisabledDaysOfTheWeek(response.data
                .map((item, index) => item.routeCount === 0 ? (index + 1) % 7 : -1)
                .filter((index) => index !== -1));
            } else {
              setDisabledDaysOfTheWeek(response.data
                .map((item, index) => item.routeCount === 0 ? (index + 1) % 7 : -1)
                .filter((index) => index !== -1));
            }
          }

        } catch (error) {
          // All days are disabled
          console.error("Error fetching data:", error);
        }
      }
    };

    if (getValues("date.from")) {
      setValue("date", { from: undefined, to: undefined });
      toggleResetDate();
      resetData();
    }

    void fetchData(false);
    void fetchData(true);
  }, [selectedLineId]);

  const passagers = Object.values(watch("passagers")).reduce(
    (total, n) => total + n,
    0
  );

  return (
    <div className="flex items-start gap-11">
      <Controller
        control={control}
        name={"trip"}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Select
            autoFocus={true}
            defaultIsOpen
            label="Linja:"
            asyncFunc={getLinesSelectList}
            selectedItem={value}
            onSelectedItemChange={(selectedItem) => {
              onChange(selectedItem);
              setSelectedLineId(selectedItem.value);
            }}
            error={error?.message}
          />
        )}
      />
      <div>
        <span className="text-neutral block mb-1.5 text-sm leading-[17px] whitespace-nowrap">
          Passagjerët
        </span>
        <DropDown
          name={
            <div className="flex gap-2 items-center">
              <span className="text-primary font-semibold">{passagers}</span>
              {passagers > 1 ? "pasagjerë" : "pasagjer"}
            </div>
          }
        >
          <PassagersBox />
        </DropDown>
      </div>
      <Controller
        control={control}
        name={"date"}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <DayPicker
              modifiers={{
                disabled: [
                  {
                    dayOfWeek: disabledDaysOfTheWeek
                  },
                  {
                    before: today
                  }
                ]
              }}
              numberOfMonths={2}
              resetDateToggled={resetDateToggled}
              label="Data e nisjes*"
              iconSide="right"
              iconColor="secondary"
              placeholder="Zgjedh datën e nisjes"
              date={value?.from ? value.from : undefined}
              onChangeDate={(date) => {
                setSelectedFromDate(date ?? null);
                onChange({ ...value, from: date });
              }}
              error={error?.message}
              pickerProps={{
                fromDate: new Date(),
                disabled: { before: new Date() }
              }}
              showApply={false}
              lineId={selectedLineId}
            />
            <DayPicker
              modifiers={{
                disabled: [
                  {
                    dayOfWeek: reverseDisabledDaysOfTheWeek
                  },
                  {
                    before: today
                  }
                ]
              }}
              numberOfMonths={2}
              resetDateToggled={resetDateToggled}
              label="Data e kthimit"
              iconSide="right"
              iconColor="secondary"
              placeholder="Zgjedh data e kthimit"
              date={value?.to ? value.to : undefined}
              onChangeDate={(date) => {
                onChange({ ...value, to: date });
              }}
              error={error?.message}
              pickerProps={{ fromDate: selectedFromDate ?? new Date() }}
              showApply={false}
              lineId={selectedLineId}
              disabled={!selectedFromDate}
            />
          </>
        )}
      />
      <Button type="submit" className="h-10 self-end">
        <MagnifyingGlassIcon className="w-5 h-5" />
      </Button>
    </div>
  );
};

export default UpperSearch;
