import { cx } from "../../../utils/classnames";
import { ColProps } from "./interfaces";

const Th: React.FC<ColProps> = ({ name, className }) => {
  return (
    <th
      className={cx([
        "border-b text-left px-5 py-3 font-normal text-gray-600 text-xs",
        className
      ])}
    >
      {name}
    </th>
  );
};

export default Th;
