import { FC } from "react";
import { cx } from "../../utils/classnames";

type StatusType = "green" | "red" | "orange" | "gray" | "blue";

enum BgColorEnum {
  "green" = "bg-[#D1FAE5]",
  "orange" = "bg-[#FEF3C7]",
  "gray" = "bg-gray-200",
  "red" = "bg-[#FFE4E6]",
  "blue" = "bg-blue/50"
}
enum TextColorEnum {
  "green" = "text-[#10B981]",
  "orange" = "text-[#F59E0B]",
  "gray" = "text-black",
  "red" = "text-[#F43F5E]",
  "blue" = "text-blue"
}

enum DotColorEnum {
  "green" = "bg-[#10B981]",
  "orange" = "bg-[#F59E0B]",
  "gray" = "bg-black",
  "red" = "bg-[#F43F5E]",
  "blue" = "bg-blue"
}

interface IStatus {
  type: StatusType;
  name: string;
}

const Status: FC<IStatus> = ({ name, type }) => {
  return (
    <div
      className={cx([
        "inline-flex justify-center items-center rounded-full px-3 text-xs py-0.5 whitespace-nowrap",
        BgColorEnum[type],
        TextColorEnum[type]
      ])}
    >
      <span
        className={cx(["w-1.5 h-1.5 rounded-full mr-2", DotColorEnum[type]])}
      />
      {name}
    </div>
  );
};

export default Status;
