import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { IStations } from "../../../interfaces/endpoints/IStations";
import Localizations from "../../bundles/Localizations";
import Input from "../../ui/input/Input";

const MultpileLangFieldsStation = () => {
  const {
    register,
    formState: { errors },
    control
  } = useFormContext<IStations>();
  const { fields } = useFieldArray({
    control,
    name: "station.locales",
    keyName: "uuid"
  });

  const [langIdActive, setLangIdActive] = useState<1 | 2>(2);

  return (
    <div className="rounded-xl bg-gray-100 p-3 mb-6">
      <Localizations
        activeId={langIdActive}
        onChange={setLangIdActive}
        alError={
          !!errors.station?.locales?.length && !!errors.station.locales[1]
        }
        enError={
          !!errors.station?.locales?.length && !!errors.station.locales[0]
        }
      />
      <div className="overflow-hidden mt-2 -mx-2">
        <div
          className={`transform duration-500 transition-all w-full flex
    ${langIdActive === 1 ? "translate-x-0" : "-translate-x-full"}`}
        >
          {fields.map((field, index) => {
            return (
              <Input
                key={field.uuid}
                label="Emri i Stacionit*"
                className="w-full flex-none px-2 mb-1"
                {...register(`station.locales.${index}.name`)}
                placeholder="Shkruaj emrin e stacionit këtu..."
                error={
                  errors.station?.locales &&
                  errors?.station?.locales[index]?.name?.message
                }
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MultpileLangFieldsStation;
