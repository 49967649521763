import { useContext } from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/shared/Header";
import SideBar from "../../components/shared/sideBar";
import { SideBarContext } from "../../context/SideBarContext";
import withProtected from "../../hoc/withProtected";
// import detectDevice from "../../utils/isTouch";
import useWindowSize from "../../hooks/useWindowResize";

const PrivateLayout = () => {
  const { isSideBarOpen } = useContext(SideBarContext);
  // const isTouch = detectDevice();
  const { md, sm } = useWindowSize();
  let mainDivStyle: React.CSSProperties = {};
  if (!sm && !md && isSideBarOpen) mainDivStyle = { maxWidth: "calc(100% - 20rem)" };
  else if (!sm && !md && !isSideBarOpen) mainDivStyle = { maxWidth: "calc(100% - 5rem)" };

  return (
    <main>
      <Header />
      <div className="flex h-full">
        <SideBar />
        <div className="md:p-7 p-3 transition-all duration-300 transform-gpu w-full"
          style={mainDivStyle}>
          <Outlet />
        </div>
      </div>
    </main>
  );
};

export default withProtected(PrivateLayout, true);
