/* eslint-disable @typescript-eslint/indent */
/* eslint-disable max-lines */
import {
  Children,
  cloneElement,
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState
} from "react";

export interface AnimationProps {
  defaultHeight?: number;
  show: boolean;
  duration?: number;
  children: ReactElement;
  delay?: number;
  transform?: "transform" | "transform-gpu" | "transform-none";
  translateShift?: { in?: string; out?: string };
  leaveInDom?: boolean;
  timingFunction?:
  | "ease"
  | "ease-in"
  | "ease-out"
  | "ease-in-out"
  | "linear"
  | string;
  type:
  | "fade"
  | "fade-left"
  | "fade-right"
  | "fade-top"
  | "fade-bottom"
  | "fade-in"
  | "fade-out"
  | "height";
}

const GenerateAnimation = (
  type: AnimationProps["type"],
  translateShift?: AnimationProps["translateShift"]
) => {
  switch (type) {
    case "fade":
      return {
        in: "opacity-100 pointer-events-auto",
        out: "opacity-0 pointer-events-none"
      };
    case "fade-left":
      return {
        in: `opacity-100 pointer-events-auto ${translateShift?.in || "translate-x-0"
          }`,
        out: `opacity-0 pointer-events-none ${translateShift?.out || "-translate-x-5"
          }`
      };
    case "fade-right":
      return {
        in: `opacity-100 pointer-events-auto ${translateShift?.in || "translate-x-0"
          }`,
        out: `opacity-0 pointer-events-none ${translateShift?.out || "translate-x-5"
          }`
      };
    case "fade-top":
      return {
        in: `opacity-100 pointer-events-auto ${translateShift?.in || "translate-y-0"
          }`,
        out: `opacity-0 pointer-events-none ${translateShift?.out || "-translate-y-5"
          }`
      };
    case "fade-bottom":
      return {
        in: `opacity-100 pointer-events-auto ${translateShift?.in || "translate-y-0"
          }`,
        out: `opacity-0 pointer-events-none ${translateShift?.out || "translate-y-5"
          }`
      };
    case "fade-in":
      return {
        in: "opacity-100 pointer-events-auto scale-100",
        out: "opacity-0 pointer-events-none scale-95"
      };
    case "fade-out":
      return {
        in: "opacity-100 pointer-events-auto scale-100",
        out: "opacity-0 pointer-events-none scale-105"
      };
    case "height":
      return {
        in: "overflow-hidden",
        out: "overflow-hidden"
      };
  }
};

const Animation: FC<AnimationProps> = ({
  children,
  show,
  duration = 150,
  delay = 0,
  translateShift,
  transform = "transform-gpu",
  timingFunction = "ease",
  type,
  defaultHeight,
  leaveInDom = false
}) => {
  const [height, setHeight] = useState<number | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [state, setState] = useState<boolean>(false);

  const animation = GenerateAnimation(type, translateShift);
  const {
    props: { className, style, ...childProps }
  } = children;

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (show) {
      if (!leaveInDom) setOpen(show);
      timer = setTimeout(() => {
        setState(show);
      });
    } else {
      setState(show);
      if (!leaveInDom) {
        timer = setTimeout(() => {
          setOpen(show);
        }, duration + delay);
      }
    }
    return () => clearTimeout(timer);
  }, [show, leaveInDom]);

  const onRefChange = useCallback((node: HTMLElement) => {
    if (type === "height" && height === null) {
      if (node === null) setHeight(null);
      else setHeight(node?.clientHeight || null);
    }
  }, []);

  if (Children.count(children) !== 1)
    return <div className="bg-warning">Please Add only one children!</div>;

  if (!open && !leaveInDom) return null;

  return Children.only(
    cloneElement(children, {
      ref: onRefChange,
      className: `transition-all origin-center ${className}
       ${transform} ${state ? animation.in : animation.out}`,
      style: {
        ...style,
        transitionDuration: style?.transitionDuration || `${duration}ms`,
        transitionDelay: style?.transitionDelay || `${delay}ms`,
        transitionTimingFunction: style?.transitionTimingFunction || timingFunction,
        ...(type === "height" &&
          (height !== null || defaultHeight) && {
          height: `${state ? defaultHeight || height || 0 : 0}px`
        })
      },
      ...childProps
    })
  );
};

export default Animation;
