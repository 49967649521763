import {
  CheckCircleIcon,
  ClockIcon,
  XCircleIcon
} from "@heroicons/react/24/outline";
import { FC } from "react";
import { IReservationV2 } from "../../interfaces/reservations/Reservations.types";
import Passengers from "../shared/svgs/Passengers";
import InsertDate from "./InsertDate";

interface PaymentInfoProps {
  paymentStatus: IReservationV2["paymentStatus"];
  paymentMethodId: IReservationV2["paymentMethodId"];
  price: IReservationV2["price"];
  clientNames: IReservationV2["clientNames"];
  insertDate?: IReservationV2["insertDate"]; // Make it optional using "?"
}

const PaymentInfo: FC<PaymentInfoProps> = ({
  paymentStatus,
  paymentMethodId,
  price,
  clientNames,
  insertDate
}) => {
  return (
    <div className="col-start-7 col-span-4 row-start-1  flex flex-col space-x-4">
      <div className="flex justify-end">
        {clientNames.length && clientNames.length > 1 ? (
          <div className="flex items-center mr-5">
            <div className="flex">
              <p className="mr-1 text-sm">{clientNames.length}</p>
              <span className="w-4 h-4">
                <Passengers />
              </span>
            </div>
          </div>
        ) : null}
        <div
          className={`${
            (paymentStatus === 1 &&
              "bg-green text-white px-1 py-0.5 rounded-lg text-[13px]") ||
            (paymentStatus === 2 &&
              "bg-orange text-white px-1 py-0.5 rounded-lg text-[13px]") ||
            (paymentStatus === 3 &&
              "bg-red text-white px-1 py-0.5 rounded-lg text-[13px]") ||
            (paymentStatus === 4 &&
              "bg-blue text-white px-1 py-0.5 rounded-lg text-[13px]")
          }`}
        >
          {paymentMethodId ? (
            <div
              className="rounded-lg flex justify-center items-center"
              title={`${
                (paymentStatus === 1 && "I Konfirmuar") ||
                (paymentStatus === 2 && "I Pa përfunduar") ||
                (paymentStatus === 3 && "I Anuluar") ||
                (paymentStatus === 4 && "I Rimbursuar")
              }`}
            >
              <div className="inline-flex items-center w-max" title="Totali">
                {price}€
              </div>
              <span className="mx-2 rounded-full bg-white p-[1.5px]" />
              {paymentMethodId === 2 && (
                <span className="whitespace-nowrap">Stripe</span>
              )}
              {paymentMethodId === 1 && (
                <span className="whitespace-nowrap">RBKO</span>
              )}
              {paymentStatus === 1 && (
                <CheckCircleIcon className="w-4 h-4 ml-1" />
              )}
              {paymentStatus === 3 && <XCircleIcon className="w-4 h-4 ml-1" />}
              {paymentStatus === 4 && <XCircleIcon className="w-4 h-4 ml-1" />}
            </div>
          ) : (
            <span className="whitespace-nowrap flex items-center">
              I Pa përfunduar <ClockIcon className="w-4 h-4 ml-1" />
            </span>
          )}
        </div>
      </div>
      {insertDate && <InsertDate insertDate={insertDate} />}
    </div>
  );
};

export default PaymentInfo;
