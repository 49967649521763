import dayjs from "dayjs";
import { FC } from "react";
import { Tooltip } from "react-tooltip";

interface AuditAuthorProps {
  createdByName: string;
  createdByLastName: string;
  updatedByName: string;
  updatedByLastName: string;
  insertDate: string;
  updateDate: string;
}

const AuditAuthor: FC<AuditAuthorProps> = ({
  insertDate,
  createdByName,
  createdByLastName,
  updatedByName,
  updateDate,
  updatedByLastName
}) => {
  const createTooltipContent = `${createdByName} ${createdByLastName}`;
  const updateTooltipContent = `${updatedByName} ${updatedByLastName}`;

  return (
    <span className="flex flex-col ">
      <span className="flex items-center text-gray-600">
        Created by:
        {typeof createdByName === "string" ? (
          <>
            <div
              data-tooltip-content={createTooltipContent}
              data-tooltip-id="createdBy"
              className="bg-gray-700 ring-1 ring-gray-300 text-white font-medium h-6 w-6
              rounded-full flex justify-center items-center mx-2 my-1 cursor-pointer"
            >
              {createdByName.charAt(0)}
            </div>
            <Tooltip id="createdBy" className="z-50" />
          </>
        ) : (
          " - "
        )}
        At {insertDate ? dayjs(insertDate).format("YYYY/MM/DD") : null}
      </span>
      {typeof updatedByName === "string" && (updateDate !== null && updateDate !== undefined) ? (
        <span className="flex items-center text-gray-600">
          Updated by:
          {typeof updatedByName === "string" ? (
            <>
              <div
                data-tooltip-content={updateTooltipContent}
                data-tooltip-id="updatedBy"
                className="bg-gray-700 ring-1 ring-gray-300 text-white font-medium h-6 w-6
              rounded-full flex justify-center items-center mx-2 my-1 cursor-pointer"
              >
                {updatedByName.charAt(0)}
              </div>
              <Tooltip id="updatedBy" className="z-50" />
            </>
          ) : " - "}
          At {updateDate ? dayjs(updateDate).format("YYYY/MM/DD"):null}
        </span>
      ) : null}
    </span>
  );
};

export default AuditAuthor;
