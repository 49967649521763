import { useCombobox, UseComboboxStateChange } from "downshift";
import { isEqual } from "lodash";
import { cx } from "../../../utils/classnames";
import useSelect from "./hook/useSelect";
import { ISelectAsync, ISelectOptions } from "./Select.types";
import List from "./shared/List";
import SelectInput from "./shared/SelectInput";
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const Select = <T, D extends unknown>({
  asyncFunc,
  getOptionLabel,
  itemToString,
  selectedItem: _selectedItem,
  selectedItems,
  inputClassName,
  emptyItem,
  onSelectedItemChange,
  className,
  pageSize,
  options,
  searchQuerySelector,
  searchFunc,
  selectType = "single",
  onSelectedListChange,
  dataSelector,
  extraParams,
  maxHeightList,
  isAbsolute,
  getOptionValue: _getOptionValue,
  defaultIsOpen,
  ...props
}: ISelectAsync<T, D> | ISelectOptions<T, D>) => {
  const getOptionValue = (value?: T|null) => _getOptionValue ? _getOptionValue(value) : value;
  const { items, onInputValueChange, loading, handleScroll, itemToString: _itemToString } = useSelect({
    emptyItem, pageSize, dataSelector, searchQuerySelector, itemToString, disabled: props.disabled,
    ...(asyncFunc
      ? { asyncFunc, extraParams }
      : options
        ? { options, searchFunc }
        : { options: [] as T[] })
  });
  const _onSelectedItemChange = (changes: UseComboboxStateChange<T>) => {
    if (changes?.selectedItem) {
      if (selectType === "single")
        onSelectedItemChange && onSelectedItemChange(changes.selectedItem);
      else if (selectType === "multiple") {
        let _selectedItems = [...(selectedItems || [])];
        const isSelected = _selectedItems.some((el) =>
          isEqual(getOptionValue(el), getOptionValue(changes.selectedItem as T))
        );
        if (isSelected)
          _selectedItems = _selectedItems.filter(
            (el) => !isEqual(getOptionValue(el), getOptionValue(changes.selectedItem as T))
          );
        else _selectedItems.push(changes.selectedItem);
        onSelectedListChange &&
          onSelectedListChange(_selectedItems, changes.selectedItem);
      }
    }
  };
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
    getToggleButtonProps
  } = useCombobox<T>({
    defaultIsOpen,
    onInputValueChange,
    items,
    itemToString: _itemToString,
    onSelectedItemChange: _onSelectedItemChange,
    selectedItem: selectType === "multiple" ? null : _selectedItem || null,
    stateReducer: (_, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            ...(selectType === "multiple" && { isOpen: true })
          };
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue:
              _itemToString(changes.selectedItem as T) || changes.inputValue,
            ...(selectType === "multiple" && { inputValue: "" })
          };
        default:
          return changes;
      }
    }
  });
  return (
    <div className={cx(["relative w-full", className])}>
      <SelectInput
        selectedItems={selectedItems}
        selectType={selectType}
        onSelectedItemChange={_onSelectedItemChange}
        getInputProps={getInputProps}
        getToggleButtonProps={getToggleButtonProps}
        inputClassName={inputClassName}
        loading={items.length ? loading : false}
        isOpen={isOpen}
        getOptionLabel={getOptionLabel}
        {...props}
      />
      <List
        highlightedIndex={highlightedIndex}
        getInputProps={getInputProps}
        selectType={selectType}
        items={items}
        isOpen={isOpen}
        handleScroll={handleScroll}
        getMenuProps={getMenuProps}
        getItemProps={getItemProps}
        selectedItem={selectedItem}
        selectedItems={selectedItems}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        maxHeightList={maxHeightList}
        isAbsolute={isAbsolute}
      />
    </div>
  );
};
export default Select;
