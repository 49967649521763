/* eslint-disable max-len */

import { FC } from "react";
import { IIcon } from "./IIcon";

const Danger: FC<IIcon> = ({ color, size }) => {
  const currentColor = color || "currentColor";
  const currentSize = size || 16;
  return (
    <span>
      <svg
        width={currentSize}
        height={currentSize}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-auto h-auto"
      >
        <path
          d="M9 11.0625C8.6925 11.0625 8.4375 10.8075 8.4375 10.5V6.75C8.4375 6.4425 8.6925 6.1875 9 6.1875C9.3075 6.1875 9.5625 6.4425 9.5625 6.75V10.5C9.5625 10.8075 9.3075 11.0625 9 11.0625Z"
          fill={currentColor}
        />
        <path
          d="M9 13.4999C8.955 13.4999 8.9025 13.4924 8.85 13.4849C8.805 13.4774 8.76 13.4624 8.715 13.4399C8.67 13.4249 8.625 13.4024 8.58 13.3724C8.5425 13.3424 8.505 13.3124 8.4675 13.2824C8.3325 13.1399 8.25 12.9449 8.25 12.7499C8.25 12.5549 8.3325 12.3599 8.4675 12.2174C8.505 12.1874 8.5425 12.1574 8.58 12.1274C8.625 12.0974 8.67 12.0749 8.715 12.0599C8.76 12.0374 8.805 12.0224 8.85 12.0149C8.9475 11.9924 9.0525 11.9924 9.1425 12.0149C9.195 12.0224 9.24 12.0374 9.285 12.0599C9.33 12.0749 9.375 12.0974 9.42 12.1274C9.4575 12.1574 9.495 12.1874 9.5325 12.2174C9.6675 12.3599 9.75 12.5549 9.75 12.7499C9.75 12.9449 9.6675 13.1399 9.5325 13.2824C9.495 13.3124 9.4575 13.3424 9.42 13.3724C9.375 13.4024 9.33 13.4249 9.285 13.4399C9.24 13.4624 9.195 13.4774 9.1425 13.4849C9.0975 13.4924 9.045 13.4999 9 13.4999Z"
          fill={currentColor}
        />
        <path
          d="M13.5449 16.6209H4.45486C2.99236 16.6209 1.87486 16.0884 1.30486 15.1284C0.74236 14.1684 0.81736 12.9309 1.52986 11.6484L6.07486 3.47336C6.82486 2.12336 7.85986 1.38086 8.99986 1.38086C10.1399 1.38086 11.1749 2.12336 11.9249 3.47336L16.4699 11.6559C17.1824 12.9384 17.2649 14.1684 16.6949 15.1359C16.1249 16.0884 15.0074 16.6209 13.5449 16.6209ZM8.99986 2.50586C8.29486 2.50586 7.60486 3.04586 7.05736 4.02086L2.51986 12.2034C2.00986 13.1184 1.92736 13.9584 2.27986 14.5659C2.63236 15.1734 3.41236 15.5034 4.46236 15.5034H13.5524C14.6024 15.5034 15.3749 15.1734 15.7349 14.5659C16.0949 13.9584 16.0049 13.1259 15.4949 12.2034L10.9424 4.02086C10.3949 3.04586 9.70486 2.50586 8.99986 2.50586Z"
          fill={currentColor}
        />
      </svg>
    </span>
  );
};

export default Danger;
