import { ArrowLongUpIcon, ArrowsUpDownIcon } from "@heroicons/react/24/outline";
import { FC } from "react";

interface PriceProps {
  prices: number[];
}

const Prices: FC<PriceProps> = ({ prices }) => {
  return (
    <span className="flex items-center">
      {prices.length === 0 ? (
        <span className="text-neutral ">Nuk ka çmim</span>
      ) : (
        <>
          {prices[0] === 0 ? null : <span className="flex items-center justify-center mr-4">
            <ArrowLongUpIcon className="w-4 h-4 text-neutral" /> {prices[0]}€
          </span>}
          {prices[1] === 0 ? null : <span className="flex items-center justify-center">
            <ArrowsUpDownIcon className="w-4 h-4 text-neutral mr-1" /> {prices[1]}€
          </span>}
        </>
      )}
    </span>
  );
};

export default Prices;
