import { array, object, string } from "yup";

export const BundleReservationSchema = object().shape({
  paymentStatus: object().required("Vendos statusin"),
  travelBundleReservationUserInfos: array().of(
    object().shape({
      firstName: string().typeError("Vendos Emrin!").required("Vendos Emrin!"),
      lastName: string()
        .typeError("Vendos Mbiemrin!")
        .required("Vendos Mbiemrin!")
    })
  )
});
