import { ArrowRightIcon } from "@heroicons/react/24/outline";
type location = {
  station: string;
  time: string;
};

interface DepartureProps {
  fromLocation: location;
  toLocation: location;
}

const Departure: React.FC<DepartureProps> = ({ fromLocation, toLocation }) => {
  return (
    <div className="flex">
      <div className="flex items-start gap-2">
        <span className="w-3 h-3 bg-white border-4 border-primary rounded-full mt-1.5" />
        <div>
          <p className="font-semibold">{fromLocation.time.substring(0, 5)}</p>
          <p className="text-gray-500 text-sm">{fromLocation.station}</p>
        </div>
      </div>
      <span className="text-gray mx-4 mt-0.5">
        <ArrowRightIcon className="w-5 h-5" />
      </span>
      <div className="flex items-start gap-2">
        <span className="w-3 h-3  bg-gray-300 rounded-full mt-1.5" />
        <div>
          <p className="font-semibold">{toLocation.time.substring(0, 5)}</p>
          <p className="text-gray-500 text-sm">{toLocation.station}</p>
        </div>
      </div>
    </div>
  );
};

export default Departure;
