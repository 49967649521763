import { createBrowserHistory } from "history";
import { WebStorageStateStore } from "oidc-client-ts";
import { UserManager } from "oidc-react";

// create browser history
export const history = createBrowserHistory();

const userManager = new UserManager({
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  authority: `${process.env.REACT_APP_AUTHORITY_API_URL}`,
  client_id: `${process.env.REACT_APP_AUTHORITY_CLIENT_ID}`,
  client_secret: process.env.REACT_APP_AUTHORITY_CLIENT_SECRET,
  redirect_uri: `${process.env.REACT_APP_URL}`,
  post_logout_redirect_uri: process.env.REACT_APP_URL,
  popup_redirect_uri: process.env.REACT_APP_URL,
  response_type: process.env.REACT_APP_AUTHORITY_RESPONSE_TYPE,
  scope: process.env.REACT_APP_AUTHORITY_SCOPE,
  automaticSilentRenew: true,
  silent_redirect_uri: process.env.REACT_APP_URL
});

export const oidc = {
  onSignIn: () => {
    history.replace("/");
    window.location.hash = "";
  },
  userManager: userManager,
  autoSignIn: false
};
