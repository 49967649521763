/* eslint-disable max-lines */
import { Controller, SubmitHandler, useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import useNavigateState from "../../hooks/useNavigateState";
import { UserEditFormType } from "../../interfaces/endpoints/IUser.types";
import { getAllOperators } from "../../services/Operators.service";
import { getAllUserRoles } from "../../services/UserRole.service";
import { editInvitedUser } from "../../services/Users.service";
import { serialize } from "../../utils/serializeToQueryString";
import Actions from "../bundles/Actions";
import Checkbox from "../ui/input/Checkbox";
import Input from "../ui/input/Input";
import Select from "../ui/select/Select";
import useSuperAdmin from "./permissions/useSuperAdmin";

const UserEditForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const stateCrumbs = useNavigateState(["User overview", "Users"]);
  const { state }: any = useLocation();

  const {
    handleSubmit,
    register,
    formState: { errors },
    control
  } = useFormContext<UserEditFormType>();
  const superAdmin = useSuperAdmin();
  const { loading, handleFunc } = useFetch(editInvitedUser);

  const onSubmit: SubmitHandler<UserEditFormType> = (data) => {
    void handleFunc({ id: Number(id), roles: data.roles.map((el) => el.id) })
      .then((res) => {
        if (res?.status === 200) {
          toast.success("Useri është ndryshuar me sukses.", {
            duration: 2000
          });
          navigate(`/users/overview${serialize({ pageindex: state.page })}`, {
            state: stateCrumbs
          });
        } else toast.error("Ndodhi një gabim gjatë ndryshimit.");
      })
      .catch(() => {
        toast.error("Ndodhi një gabim gjatë ndryshimit.");
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="max-w-lg mx-auto h-full max-h-full w-full p-5">
        <div className="flex justify-between gap-6">
          <Input
            {...register("firstName")}
            label="Emri*"
            placeholder="Shkruaj emrin këtu..."
            error={errors?.firstName?.message}
            disabled={true}
            inputClassName="text-gray-500"
            className="w-full"
          />
          <Input
            {...register("lastName")}
            label="Mbiemri*"
            placeholder="Shkruaj mbiemrin këtu..."
            error={errors?.lastName?.message}
            disabled={true}
            inputClassName="text-gray-500"
            className="w-full"
          />
        </div>
        <Input
          {...register("email")}
          label="Email*"
          placeholder="Shkruaj email këtu..."
          error={errors?.firstName?.message}
          disabled={true}
          inputClassName="text-gray-500"
          className="mt-4"
        />
        <Controller
          control={control}
          name="roles"
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Select
                className="mt-4"
                label="Rolet*"
                placeholder="Zgjedh rolet"
                onSelectedListChange={(selected) => onChange(selected)}
                selectedItems={value}
                selectType="multiple"
                getOptionLabel={(o) => o.roleName}
                asyncFunc={getAllUserRoles}
                error={error?.message}
              />
            );
          }}
        />
        {superAdmin?.id ? (
          <Controller
            name="operator"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Select
                className="mt-4"
                label="Operator Name*"
                placeholder="Zgjedh operatorin"
                selectedItem={value}
                disabled
                onSelectedItemChange={(item) => onChange(item)}
                itemToString={(item) => item?.operatorName || ""}
                getOptionLabel={(item) => item.operatorName}
                asyncFunc={getAllOperators}
                error={errors.operator?.id?.message}
              />
            )}
          />
        ) : null}
        <Controller
          name="isActive"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Checkbox
              label="Aktiv"
              name="isActive"
              onChange={onChange}
              checked={value}
              className="my-6"
              inputClassName="w-max flex-row-reverse"
              disabled
            />
          )}
        />
      </div>
      <Actions loading={loading} buttonTitle="Edito User" />
    </form>
  );
};

export default UserEditForm;
