import { FC, ReactNode } from "react";
import ReactRouterPrompt from "react-router-prompt";
import Animation from "../ui/Animation";
import Back, { IBack } from "../ui/Back";
import Box from "../ui/Box";
import Button from "../ui/Button";
import Modal from "../ui/Modal";
import SpinnerLoader from "../ui/SpinnerLoader";
import NotFoundForm, { INotFoundForm } from "./NotFoundForm";

interface IFormWrapper {
  children: ReactNode;
  backTitle: IBack["title"];
  backTo: IBack["to"];
  backState?: IBack["state"];
  loading?: boolean;
  notFoundMessage?: INotFoundForm["message"];
  error: boolean;
  mode: "create" | "edit";
  title: string;
  rightHeaderContent?: ReactNode;
  response: unknown;
  showPrompt?: boolean;
}

const FormWrapper: FC<IFormWrapper> = ({
  children,
  backTitle,
  backTo,
  backState,
  loading,
  notFoundMessage,
  error,
  mode,
  response,
  title,
  rightHeaderContent,
  showPrompt
}) => {
  return (
    <>
      <ReactRouterPrompt when={!!showPrompt}>
        {({ isActive, onConfirm, onCancel }) => (
          <Modal hasHeader={false} show={isActive} position="top" modalWidth="372px">
            <div className="p-6 flex flex-col items-center justify-between h-[175px]">
              <h1 className="text-lg font-medium">
                A jeni të sigurt për këtë veprim?
              </h1>
               <p className="text-sm text-neutral-300">
                Nëse vazhdoni, të dhënat tua do të humbasin.
              </p>
              <div className="w-full flex gap-4 text-sm">
                <Button btnType="custom" fontWeight="medium" className="w-full border"
                  onClick={onCancel}>
                  Anulo
                </Button>
                <Button fontWeight="medium" className="w-full" onClick={onConfirm}>
                  Vazhdo
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </ReactRouterPrompt>
      <Back title={backTitle} to={backTo} className="mb-2" state={backState} />
      <div className="relative">
        {loading ? <SpinnerLoader className="pt-10" /> : null}
        {error ? <NotFoundForm message={notFoundMessage} /> : null}
        <Animation
          show={(!loading && !!response) || mode === "create"}
          type={mode === "create" ? "fade-top" : "fade-right"}
          duration={300}
        >
          <div>
            <Box padding={false} shadow={false}>
              <div className="pt-4 px-5 flex justify-between items-center mb-4">
                <h1 className="text-base font-medium leading-6">{title}</h1>
                {rightHeaderContent}
              </div>
              {children}
            </Box>
          </div>
        </Animation>
      </div>
    </>
  );
};

export default FormWrapper;
