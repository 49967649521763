import { ArrowRightIcon, ClockIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import Button from "../../../components/ui/Button";
import Spinner from "../../../components/ui/Spinner";
import { OfficeSalesContext } from "../../../context/OfficeSalesContext";
import { OfficePaymentMethodEnum } from "../../../interfaces/endpoints/officeSales/ICreateReservationSchema";

const Summary: React.FC<{ setNextStep: Dispatch<SetStateAction<boolean>> }> = ({
  setNextStep
}) => {
  const { summData } = useContext(OfficeSalesContext);
  const [disabled, setDisabled] = useState<boolean>(false);
  // let price: number = +summData.departure.price * summData.passagers.length;
  // if (summData.return && Object.keys(summData.return).length)
  //   price = price + +summData.return.price * summData.passagers.length;
  return (
    <>
      <div className="w-80 mx-auto">
        <p className="font-medium mb-5">Rezervo Udhëtimin</p>
        <p className="text-4xl font-bold mb-4 text-black">
          {typeof summData.price === "number" ? summData.price.toFixed(2) : null} €
        </p>
        <Section border title="Udhetimi">
          <div className="flex items-center gap-2">
            {summData?.departure?.departureLocation}
            <ArrowRightIcon className="w-4 h-4" />
            {summData?.departure?.arrivalLocation}
          </div>
          <div className="flex items-center gap-3 text-gray mt-2">
            <p>{dayjs(summData.departureDate).format("YYYY-MM-DD")}</p>
            <p className="flex items-center">
              <ClockIcon className="w-4 h-4 mr-2" />
              <p>{summData.departure.departureTime.substring(0, 5)}</p>
            </p>
          </div>
          {summData.return && Object.keys(summData.return).length ? (
            <>
              <div className="flex items-center gap-2 mt-4">
                {summData.return.departureLocation}
                <ArrowRightIcon className="w-4 h-4" />
                {summData.return.arrivalLocation}
              </div>
              <div className="flex items-center gap-3 text-gray mt-2">
                <p>{dayjs(summData.returnDate).format("YYYY-MM-DD")}</p>
                <p className="flex items-center">
                  <ClockIcon className="w-4 h-4 mr-2" />
                  <p>{summData.return.departureTime.substring(0, 5)}</p>
                </p>
              </div>
            </>
          ) : null}
        </Section>
        <Section border title="Pasagjeret">
          <p className="mb-3 ">
            <span className="text-gray">
              {summData.passagers.length} Pasagjerë
            </span>
          </p>
          {summData.passagers.map((p) => (
            <div className="mb-3 font-medium ">
              {p.name} {p.lastName}
            </div>
          ))}
        </Section>
        <Section title="Numri i telefonit">{summData.phoneNumber}</Section>
        <Section title="Email">{summData.email}</Section>
        <Section title="Mënyra e Pagesës">
          {OfficePaymentMethodEnum[summData.paymentMethod]}
        </Section>
      </div>
      <div className="flex justify-end border-t pt-5 bottom-0 sticky bg-white mt-5">
        <div>
          <Button
            className="border-primary border text-primary bg-white mr-3 w-32"
            type="button"
            btnType="custom"
            onClick={() => setNextStep(false)}
          >
            <span>Kthehu </span>
          </Button>
          <Button
            onClick={() => setDisabled(true)}
            className="w-32 relative"
            type="submit"
          >
            <span
              className={`transition-all ${
                disabled ? "opacity-0" : "opacity-100"
              }`}
            >
              Vazhdo
            </span>
            <span
              className={`transition-all absolute inset-0 w-6 h-6 m-auto ${
                !disabled ? "opacity-0" : "opacity-100"
              }`}
            >
              <Spinner />
            </span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default Summary;

const Section: React.FC<{
  children: React.ReactNode;
  title: string;
  border?: boolean;
}> = ({ title, children, border }) => (
  <div
    className={`flex w-full justify-between ${
      border ? "border-b pb-10 mb-10" : "mb-4"
    }`}
  >
    <p className="text-sm text-gray">{title}:</p>
    <div className="flex flex-col items-end">{children}</div>
  </div>
);
