type timeList = {
  name: string;
  id: string;
}[];

const useTimeList = () => {
  const timeList: timeList = [] as timeList;
  for (let hours = 0; hours < 24; hours++) {
    for (let minutes = 0; minutes < 60; minutes++) {
      const time = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
      timeList.push({ name: time, id: time });
    }
  }
  return timeList;
};

export default useTimeList;
