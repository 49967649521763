import dayjs from "dayjs";
import { ReservationsPerDayModel } from "../../interfaces/endpoints/IAnalytics.types";
import { cx } from "../../utils/classnames";
import Box from "../ui/Box";

const CustomTooltipCharts = (data: {
  active?: boolean;
  payload?: { payload: ReservationsPerDayModel }[];
}) => {
  if (data.active && data.payload && data.payload.length) {
    return (
      <Box
        padding="px-2.5 py-2"
        className={cx([
          "flex flex-col items-start justify-start text-xs leading-3 text-black outline-none min-w-[110px]"
        ])}
      >
        <span className="mb-3">
          <span className="font-bold">
            {data.payload[0].payload.nrOfReservations}
          </span>{" "}
          rezervime
        </span>
        <span className="text-neutral">
          {dayjs(data.payload[0].payload.date).format("MMMM DD,")}
        </span>
        <span className="text-neutral mt-1">
          {dayjs(data.payload[0].payload.date).format("YYYY")}
        </span>
      </Box>
    );
  }
  return null;
};

export default CustomTooltipCharts;
