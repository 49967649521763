import dayjs from "dayjs";
import { FC } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { IFilteredAnalyticsGraphProps } from "../../interfaces/components/Dashboard.types";
import Box from "../ui/Box";
import SpinnerLoader from "../ui/SpinnerLoader";
import CustomTooltipCharts from "./CustomTooltipCharts";

const FilteredAnalyticsGraph: FC<IFilteredAnalyticsGraphProps> = ({
  data,
  loading
}) => {
  const longestLabelLength =
    data
      ?.map((c) => c.nrOfReservations)
      .reduce(
        (acc, cur) =>
          cur.toString().length > acc ? cur.toString().length : acc,
        0
      ) || 1;
  return (
    <Box
      shadow="shadow-xl"
      padding="px-6 pt-6 pb-3.5"
      className="xl:w-[350px] h-[222px]"
    >
      <div className="w-full h-[184px] relative">
        <ResponsiveContainer>
          <AreaChart data={data || []}>
            <defs>
              <linearGradient id="nrOfReservations" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="0%"
                  stopColor="#F15B2D"
                  stopOpacity={0.5}
                  gradientTransform="180deg"
                />
                <stop offset="100%" stopColor="#F15B2D" stopOpacity={0} />
              </linearGradient>
            </defs>
            {data !== undefined && !loading && (
              <XAxis
                dataKey="date"
                axisLine={false}
                tickSize={0}
                tickFormatter={(date) => dayjs(date).format("DD/MM/YYYY")}
                interval="preserveStartEnd"
                fontSize={8}
                dy={16}
                color="#6E6E6E"
              />
            )}
            {data !== undefined && !loading && (
              <YAxis
                axisLine={false}
                allowDecimals={false}
                tickLine={false}
                dataKey="nrOfReservations"
                fontSize={8}
                color="#6E6E6E"
                width={longestLabelLength * 8}
              />
            )}
            <CartesianGrid
              strokeDasharray="4"
              stroke="#EAEAEA"
              vertical={false}
              horizontalPoints={[8, 35, 64, 93, 123]}
            />
            <Tooltip
              cursor={{
                stroke: "#2C2C2C",
                fill: "#2C2C2C",
                opacity: 0.5,
                strokeWidth: 1
              }}
              content={<CustomTooltipCharts />}
              wrapperStyle={{ outline: "none" }}
            />
            {data !== undefined && !loading && (
              <Area
                type="monotone"
                dataKey="nrOfReservations"
                stroke="#F15B2D"
                strokeWidth={2}
                fillOpacity={1}
                fill="url(#nrOfReservations)"
              />
            )}
            <Legend
              verticalAlign="bottom"
              content={() => (
                <div className="flex justify-center items-center mt-3">
                  <div className="w-2 h-2 rounded-full bg-primary mr-2" />
                  <p className="text-xs font-medium leading-[18px]">
                    Numri i rezervimeve
                  </p>
                </div>
              )}
            />
          </AreaChart>
        </ResponsiveContainer>
        {data === undefined && loading ? (
          <SpinnerLoader className="absolute top-0 w-full h-[120px] flex justify-center items-center" />
        ) : null}
      </div>
    </Box>
  );
};

export default FilteredAnalyticsGraph;
