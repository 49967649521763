/* eslint-disable max-len */
const Passengers = () => {
  return (
    <span>
      <svg
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.4619 8.03368C15.2729 8.03368 14.3619 8.94226 14.3619 10C14.3619 11.0578 15.2729 11.9664 16.4619 11.9664C17.6509 11.9664 18.5619 11.0578 18.5619 10C18.5619 8.94226 17.6509 8.03368 16.4619 8.03368ZM13.1619 10C13.1619 8.22284 14.6683 6.83368 16.4619 6.83368C18.2555 6.83368 19.7619 8.22284 19.7619 10C19.7619 11.7772 18.2555 13.1664 16.4619 13.1664C14.6683 13.1664 13.1619 11.7772 13.1619 10ZM13.2008 14.9658C13.2008 14.6344 13.4694 14.3658 13.8008 14.3658H18.3008C20.0945 14.3658 21.6008 15.7556 21.6008 17.5334C21.6008 18.6748 20.6755 19.6001 19.5341 19.6001H15C14.6686 19.6001 14.4 19.3314 14.4 19.0001C14.4 18.6687 14.6686 18.4001 15 18.4001H19.5341C20.0127 18.4001 20.4008 18.012 20.4008 17.5334C20.4008 16.4746 19.4894 15.5658 18.3008 15.5658H13.8008C13.4694 15.5658 13.2008 15.2971 13.2008 14.9658Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.49999 4.60002C6.65223 4.60002 5.19999 6.02698 5.19999 7.73291C5.19999 9.43884 6.65223 10.8658 8.49999 10.8658C10.3478 10.8658 11.8 9.43884 11.8 7.73291C11.8 6.02698 10.3478 4.60002 8.49999 4.60002ZM3.99999 7.73291C3.99999 5.31525 6.03956 3.40002 8.49999 3.40002C10.9604 3.40002 13 5.31525 13 7.73291C13 10.1506 10.9604 12.0658 8.49999 12.0658C6.03956 12.0658 3.99999 10.1506 3.99999 7.73291ZM5.89999 14.732C4.05266 14.732 2.59999 16.1594 2.59999 17.8667V18C2.59999 18.7732 3.2268 19.4 3.99999 19.4H13C13.7732 19.4 14.4 18.7732 14.4 18V17.8667C14.4 16.1594 12.9473 14.732 11.1 14.732H5.89999ZM1.39999 17.8667C1.39999 15.4481 3.43951 13.532 5.89999 13.532H11.1C13.5605 13.532 15.6 15.4481 15.6 17.8667V18C15.6 19.436 14.4359 20.6 13 20.6H3.99999C2.56405 20.6 1.39999 19.436 1.39999 18V17.8667Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export default Passengers;
