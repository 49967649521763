import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import "react-tooltip/dist/react-tooltip.css";
import { ITripNoticeForm } from "../../../interfaces/endpoints/ITripsNotices";
import { cx } from "../../../utils/classnames";
import Localizations from "../../bundles/Localizations";
import Input from "../../ui/input/Input";
import Textarea from "../../ui/input/Textarea";

const MultipleLangFields = () => {
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext<ITripNoticeForm>();

  const { fields } = useFieldArray({
    control,
    name: "locales",
    keyName: "uuid"
  });

  const [langIdActive, setLangIdActive] = useState<1 | 2>(2);

  return (
    <>
      <Localizations
        activeId={langIdActive}
        onChange={(langId) => setLangIdActive(langId)}
        alError={!!errors.locales?.length && !!errors.locales[1]}
        enError={!!errors.locales?.length && !!errors.locales[0]}
      />
      <div className="overflow-hidden">
        <div
          className={`transform duration-500 transition-all w-full flex
          ${langIdActive === 1 ? "translate-x-0" : "-translate-x-full"}`}
        >
          {fields.map((field, index) => {
            return (
              <div
                key={field.uuid}
                className={cx([
                  "w-full flex-none p-[1px] duration-500",
                  langIdActive === field.languageId
                    ? "opacity-100"
                    : "opacity-0"
                ])}
              >
                <Input
                  {...register(`locales.${index}.title`)}
                  label="Titulli"
                  className="my-6"
                  placeholder="Shkruaj titullin e informatës këtu..."
                  error={
                    errors?.locales && errors?.locales[index]?.title?.message
                  }
                />
                <Textarea
                  {...register(`locales.${index}.description`)}
                  label="Përshkrimi"
                  className="my-6"
                  placeholder="Shkruaj përshkrimin për informatën këtu..."
                  error={
                    errors?.locales && errors?.locales[index]?.description?.message
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default MultipleLangFields;
