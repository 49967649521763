import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { IStations } from "../interfaces/endpoints/IStations";

const mapContainerStyle = {
  width: "100%",
  height: "400px",
  borderRadius: "14px"
};

//kosovo
const center = {
  lat: 42.65234783876951,
  lng: 21.1579763948839
};

type coordinates = {
  lat: number;
  lng: number;
};

type coordName = {
  lat: any;
  lng: any;
};

interface IGmap {
  className?: string;
  coordinates?: coordinates;
  names: coordName;
}

const Gmap: React.FC<IGmap> = ({ coordinates, names }) => {
  const [markers, setMarkers] = useState<coordinates>();
  const [position, setPosition] = useState<coordinates>(center);
  const [zoom, setZoom] = useState<number>(9);
  const [clicked, setClicked] = useState<boolean>(false);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string
  });

  const {
    setValue,
    formState: { errors }
  } = useFormContext<IStations>();

  useEffect(() => {
    if (isLoaded && coordinates && !clicked) {
      setPosition(coordinates);
      const newMarker = {
        lat: coordinates.lat,
        lng: coordinates.lng
      };
      setMarkers(newMarker);
      setZoom(13);
    }
  }, [coordinates]);

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      const newMarker = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      };
      setMarkers(newMarker);
      setValue(names.lat, newMarker.lat);
      setValue(names.lng, newMarker.lng);
      setClicked(true);
    }
  };

  return isLoaded ? (
    <div>
      <GoogleMap
        clickableIcons={true}
        mapContainerStyle={mapContainerStyle}
        center={position}
        zoom={zoom}
        onClick={handleMapClick}
      >
        {markers ? (
          <Marker
            draggable
            onDragEnd={handleMapClick}
            animation={google.maps.Animation.DROP}
            position={{ lat: markers.lat, lng: markers.lng }}
          />
        ) : null}
      </GoogleMap>
      {markers ? (
        <div className="flex flex-row justify-end mt-2">
          <p className="text-xs text-primary mr-1">Koordinatat:</p>
          <p className="text-xs text-gray-700">{markers.lat},</p>
          <p className="text-xs text-gray-700 ml-1"> {markers.lng}</p>
        </div>
      ) : null}
      <p className="text-red-500 text-xs mt-0.5">{errors.station?.latitude?.message}</p>
    </div>
  ) : (
    <></>
  );
};

export default Gmap;
