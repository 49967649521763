import dayjs from "dayjs";
import { FC } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { IFilteredAnalyticsPerLineGraphProps } from "../../interfaces/components/Dashboard.types";
import SpinnerLoader from "../ui/SpinnerLoader";
import CustomLengendPerLine from "./CustomLengendPerLine";
import CustomTooltipPerLineCharts from "./CustomTooltipPerLineCharts";

const FilteredAnalyticsPerLineGraph: FC<
  IFilteredAnalyticsPerLineGraphProps
> = ({ loading, data }) => {
  const longestLabelLength =
    data
      ?.map((c) => c.nrOfSales)
      .reduce(
        (acc, cur) =>
          cur.toString().length > acc ? cur.toString().length : acc,
        0
      ) || 1;

  return (
    <div className="w-full h-[260px]">
      <div className="w-full h-full relative">
        <ResponsiveContainer>
          <AreaChart data={data}>
            <defs>
              <linearGradient id="nrOfSales" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="0%"
                  stopColor="#3A81F6"
                  stopOpacity={0.5}
                  gradientTransform="180deg"
                />
                <stop offset="100%" stopColor="#3A81F6" stopOpacity={0} />
              </linearGradient>
              <linearGradient
                id="nrOfReservationsPerLine"
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop
                  offset="0%"
                  stopColor="#36B37E"
                  stopOpacity={0.5}
                  gradientTransform="180deg"
                />
                <stop offset="100%" stopColor="#36B37E" stopOpacity={0} />
              </linearGradient>
            </defs>
            {data !== undefined && !loading ? (
              <>
                <XAxis
                  dataKey="date"
                  axisLine={false}
                  tickSize={0}
                  tickFormatter={(date) => dayjs(date).format("DD/MM/YYYY")}
                  interval="preserveStartEnd"
                  fontSize={8}
                  dy={24}
                  color="#6E6E6E"
                />
                <YAxis
                  axisLine={false}
                  allowDecimals={false}
                  tickLine={false}
                  // dataKey="nrOfSales"
                  fontSize={8}
                  color="#6E6E6E"
                  width={longestLabelLength * 8}
                />
              </>
            ) : null}
            <CartesianGrid
              strokeDasharray="4"
              stroke="#EAEAEA"
              vertical={false}
              horizontalPoints={[8, 35, 64, 93, 123]}
            />
            <Tooltip
              cursor={{
                stroke: "#2C2C2C",
                fill: "#2C2C2C",
                opacity: 0.5,
                strokeWidth: 1
              }}
              content={<CustomTooltipPerLineCharts />}
              wrapperStyle={{ outline: "none" }}
            />
            {data !== undefined && !loading ? (
              <>
                <Area
                  type="monotone"
                  dataKey="nrOfSales"
                  stroke="#3A81F6"
                  strokeWidth={2}
                  fillOpacity={1}
                  fill="url(#nrOfSales)"
                />
                <Area
                  type="monotone"
                  dataKey="nrOfReservations"
                  stroke="#36B37E"
                  strokeWidth={2}
                  fillOpacity={1}
                  fill="url(#nrOfReservationsPerLine)"
                />
              </>
            ) : null}
            <Legend verticalAlign="bottom" content={<CustomLengendPerLine />} />
          </AreaChart>
        </ResponsiveContainer>
        {data === undefined && loading ? (
          <SpinnerLoader className="absolute top-0 w-full h-[120px] flex justify-center items-center" />
        ) : null}
      </div>
    </div>
  );
};
export default FilteredAnalyticsPerLineGraph;
