import { Td } from "./Td";

export const Row = ({
  className,
  ...props
}: {
  [key: string]: string | number | React.ReactNode | any;
  className?: string;
}) => {
  const data = Object.values(props);
  return (
    <tr className={`${className} relative`}>
      {data.map((td, i) => (
        <Td key={i} name={td} />
      ))}
    </tr>
  );
};
