import BundleFeatures from "../../../components/bundles/features/BundleFeatures";
import HeaderPage from "../../../components/lines-managements/header/HeaderPage";
import useCan from "../../../components/users/permissions/useCan";

const Features: React.FC = () => {
  const can = useCan("travelbundles.travelbundle.include.create");
  return (
    <>
      <HeaderPage
        {...(can && {
          link: {
            to: "create",
            children: "Shto një veçori",
            state: [
              "Menaxhimi i ofertave",
              "Veçoritë e ofertave",
              "Shto një veçori"
            ]
          }
        })}
      />
      <BundleFeatures />
    </>
  );
};

export default Features;
