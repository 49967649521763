/* eslint-disable max-lines */
import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Button from "../../../components/ui/Button";
import FormWrapper from "../../../components/ui/FormWrapper";
import { OfficeSalesContext } from "../../../context/OfficeSalesContext";
import useFetch from "../../../hooks/useFetch";
import { ICalculatePrice } from "../../../interfaces/endpoints/officeSales/ICalculatePriceSchema";
import ICreateReservationSchema, {
  clientNames,
  passagerType
} from "../../../interfaces/endpoints/officeSales/ICreateReservationSchema";
import { editTicket } from "../../../interfaces/endpoints/officeSales/IEditReservationSchema";
import { PassengerTypeEnum } from "../../../interfaces/endpoints/officeSales/IGetJorneys";
import {
  editOfficeReservation,
  getCalculatedPrice,
  getJourneyGuid,
  getOfficeReservationById,
  getTripByTripId,
  postOfficeReservation
} from "../../../services/OfficeSales.service";
import { parseQueryString } from "../../../utils/parseQueryString";
import CreateOfficeReservation from "../../../utils/validations/CreateOfflineReservation.schema";
import Information from "./Information";
import PassagerFields from "./PassagerFields";
import SelectedTrip from "./SelectedTrip";
import Summary from "./Summary";
interface CreateReservationProps {
  mode: "create" | "edit";
}
type searchParams = {
  dd: string;
  dr: string;
  padult: string;
  tripD: string;
  tripR?: string;
};

const CreateReservation: React.FC<CreateReservationProps> = ({ mode }) => {
  const _passengers: Partial<passagerType>[] = [];
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const adults = +searchParams.get("padult")!;
  const children = +searchParams.get("pchild")!;
  const babies = +searchParams.get("pbaby")!;
  const navigate = useNavigate();
  const generatePassengers = (
    type: PassengerTypeEnum,
    count: number
  ): Partial<passagerType>[] => {
    return Array.from({ length: count }, () => ({
      type,
      name: "",
      lastName: "",
      passportId: undefined,
      departureSeats: undefined,
      returnSeats: undefined
    }));
  };
  const [nextStep, setNextStep] = useState<boolean>(false);
  const {
    setSumData,
    data: dataContext,
    setData
  } = useContext(OfficeSalesContext);

  _passengers.push(...generatePassengers(PassengerTypeEnum.adult, adults));
  _passengers.push(...generatePassengers(PassengerTypeEnum.children, children));
  _passengers.push(...generatePassengers(PassengerTypeEnum.baby, babies));
  const { tripD, tripR, dd, dr } = parseQueryString<searchParams>(
    searchParams.toString()
  );

  const methods = useForm<ICreateReservationSchema>({
    defaultValues: {
      passagers: _passengers
    },
    resolver: yupResolver(CreateOfficeReservation)
  });

  const { response: reservationRes } = useFetch(getOfficeReservationById, {
    initRequest: mode === "edit",
    params: id
  });

  const { response: departureTripRes, handleFunc: getDepartureTrip } =
    useFetch(getTripByTripId);
  const { response: returnTripRes, handleFunc: getReturnTrip } =
    useFetch(getTripByTripId);

  const { handleFunc: getGuid } = useFetch(getJourneyGuid);
  const { handleFunc: createOfficeReservation } = useFetch(
    postOfficeReservation,
    { initRequest: false }
  );

  useEffect(() => {
    if (mode === "create") {
      void getDepartureTrip(+tripD).then((res) => {
        if (res?.data)
          setData((prev) => ({
            ...prev,
            departure: {
              arrivalLocation: res?.data.trip.line.arrivalCityName || "",
              arrivalTime: res?.data.trip.arrivalTime,
              departureLocation: res.data.trip.line.departureCityName,
              departureTime: res.data.trip.departureTime,
              fromStationId: 0,
              id: 0,
              price: "",
              toStationId: 0
            }
          }));
      });
      if (tripR !== undefined) {
        void getReturnTrip(+tripR).then((res) => {
          if (res?.data)
            setData((prev) => ({
              ...prev,
              return: {
                arrivalLocation: res?.data.trip.line.arrivalCityName || "",
                arrivalTime: res?.data.trip.arrivalTime,
                departureLocation: res.data.trip.line.departureCityName,
                departureTime: res.data.trip.departureTime,
                fromStationId: 0,
                id: 0,
                price: "",
                toStationId: 0
              }
            }));
        });
      }
    } else if (reservationRes?.data && mode === "edit") {
      const departureTripId =
        reservationRes?.data.oneWayTicketDetails[0].tripId || 0;
      const returnTripId = reservationRes?.data.roundWayTicketDetails.length
        ? reservationRes?.data.roundWayTicketDetails[0].tripId || 0
        : 0;
      void getDepartureTrip(+departureTripId).then((res) => {
        if (res?.data)
          setData((prev) => ({
            ...prev,
            departure: {
              arrivalLocation: res?.data.trip.line.arrivalCityName || "",
              arrivalTime: res?.data.trip.arrivalTime,
              departureLocation: res.data.trip.line.departureCityName,
              departureTime: res.data.trip.departureTime,
              fromStationId: 0,
              id: 0,
              price: "",
              toStationId: 0
            }
          }));
      });
      if (returnTripId !== 0) {
        void getReturnTrip(returnTripId).then((res) => {
          if (res?.data)
            setData((prev) => ({
              ...prev,
              return: {
                arrivalLocation: res?.data.trip.line.arrivalCityName || "",
                arrivalTime: res?.data.trip.arrivalTime,
                departureLocation: res.data.trip.line.departureCityName,
                departureTime: res.data.trip.departureTime,
                fromStationId: 0,
                id: 0,
                price: "",
                toStationId: 0
              }
            }));
        });
      }
    }
  }, [tripD, tripR, reservationRes]);

  const getPassagersFromId = reservationRes?.data.clientNames.map<passagerType>(
    (p) => {
      return {
        id: p.id,
        name: p.firstName,
        lastName: p.lastName,
        passportId: p.passportId,
        type: p.ageGroupId
      };
    }
  );
  useEffect(() => {
    if (mode === "edit") {
      methods.reset({
        email: reservationRes?.data.clientNames[0].email,
        paymentMethod: reservationRes?.data.paymentMethodId,
        phoneNumber: reservationRes?.data.clientNames[0].phoneNumber,
        passagers: getPassagersFromId
      });
    }
  }, [reservationRes]);
  const onSubmit: SubmitHandler<ICreateReservationSchema> = async (data) => {
    const passengers = data.passagers.map<clientNames>((p) => {
      return {
        id: p.id || 0,
        firstName: p.name || "",
        ageGroupId: p.type || 0,
        email: data.email,
        lastName: p.lastName || "",
        passportId: p.passportId || "",
        phoneNumber: data.phoneNumber
      };
    });
    if (mode === "create") {
      const journeyTripsArr = [
        {
          isReservable: true,
          journeyType: tripR ? 3 : 1,
          oneWayFromStationId:
            departureTripRes?.data.trip.line.departureStation || 0,
          oneWayJourneyDate: dd,
          oneWayToStationId:
            departureTripRes?.data.trip.line.arrivalStation || 0,
          oneWayTripId: +tripD,
          roundWayFromStationId:
            returnTripRes?.data.trip.line.departureStation || 0,
          roundWayJourneyDate: dr ? dr : null,
          roundWayToStationId:
            returnTripRes?.data.trip.line.arrivalStation || 0,
          roundWayTripId: tripR ? Number(tripR) : 0
        }
      ];
      await getGuid(journeyTripsArr).then(async (guidRes) => {
        await createOfficeReservation({
          journeyTripMappingGuid: guidRes!.data,
          journeyTripMappings: journeyTripsArr,
          email: data.email,
          international: true,
          paymentMethod: data.paymentMethod,
          clientNames: passengers
        })
          .then(() => {
            toast.success("Rezervimi u shtua me sukses!");
            navigate("/office", {
              state: ["Menaxhimi i Rezervimeve", "Shto rezervim të ri"]
            });
          })
          .catch(() => {
            toast.error("Ndodhi nje gabim.");
          });
      });
    } else {
      const oneWayTicket =
        reservationRes?.data.oneWayTicketDetails.map<editTicket>((t) => {
          return {
            id: t.ticketId,
            journeyDate: t.journeyDate,
            tripId: t.tripId
          };
        });
      const twoWayTicket =
        reservationRes?.data.roundWayTicketDetails.map<editTicket>((t) => {
          return {
            id: t.ticketId,
            journeyDate: t.journeyDate,
            tripId: t.tripId
          };
        });
      const tickets = oneWayTicket?.concat(twoWayTicket || []);
      await editOfficeReservation({
        email: data.email,
        paymentMethodId: data.paymentMethod,
        paymentStatus: 1,
        uniqueJourneyId: id || "",
        tickets: tickets || [],
        userInfos: passengers
      })
        .then(() => {
          toast.success("Rezervimi u editua me sukses!");
          navigate("/office", {
            state: ["Menaxhimi i Rezervimeve", "Shto rezervim të ri"]
          });
        })
        .catch(() => {
          toast.error("Ndodhi nje gabim.");
        });
    }
  };
  const handleNext = async () => {
    const { email, passagers, paymentMethod, phoneNumber } =
      methods.getValues();
    const passagersInType = passagers.map<number>((n) => Number(n.type) || 0);
    const tripDetails: ICalculatePrice = {
      oneWayTripId: departureTripRes?.data.trip.id || 0,
      roundWayTripId: returnTripRes?.data.trip.id,
      passengers: passagersInType
    };
    void getCalculatedPrice(tripDetails).then((res) =>
      setSumData((prev) => ({ ...prev, price: res.data }))
    );
    console.log(dataContext);
    const validData = await methods.trigger();
    if (validData) {
      setSumData((prev) => ({
        ...prev,
        email: email,
        passagers: passagers,
        paymentMethod: paymentMethod,
        phoneNumber: phoneNumber,
        departure: dataContext.departure,
        return: dataContext.return,
        departureDate:
          mode === "create"
            ? dd || ""
            : reservationRes?.data.oneWayTicketDetails[0].journeyDate || "",
        returnDate:
          mode === "create"
            ? dr || ""
            : reservationRes?.data.roundWayTicketDetails[0]?.journeyDate || ""
      }));
      setNextStep(true);
    }
  };
  return (
    <FormProvider {...methods}>
      <FormWrapper
        onSubmit={methods.handleSubmit(onSubmit)}
        title={
          !nextStep
            ? mode === "create"
              ? "Rezervim i ri"
              : "Edito Rezervimin"
            : ""
        }
        showSaveBtn={false}
        className="max-w-full flex flex-col justify-between"
        showPrompt={!methods.formState.isSubmitSuccessful}
      >
        {!nextStep ? (
          <>
            <div>
              <SelectedTrip
                departureTripRes={departureTripRes?.data}
                returnTripRes={returnTripRes?.data}
              />
              <PassagerFields mode={mode} />
              <Information />
            </div>
            <div className="flex justify-end border-t pt-5 bottom-0 sticky bg-white mt-5">
              <div>
                <Button
                  className="border-primary border text-primary bg-white mr-3 w-32"
                  type="button"
                  btnType="custom"
                  onClick={() => navigate(-1)}
                >
                  <span>Anulo</span>
                </Button>
                <Button onClick={handleNext} className="w-32" type="button">
                  Vazhdo
                </Button>
              </div>
            </div>
          </>
        ) : (
          <Summary setNextStep={setNextStep} />
        )}
      </FormWrapper>
    </FormProvider>
  );
};

export default CreateReservation;
