import BlogsTable from "../../components/blogs";
import HeaderPage from "../../components/lines-managements/header/HeaderPage";
import useCan from "../../components/users/permissions/useCan";

const Blogs: React.FC = () => {
  const can = useCan("blogs.blog.create");
  return (
    <>
      <HeaderPage
        {...(can && {
          link: {
            to: "add",
            children: "Shto blog të ri",
            state: ["Blogu i udhëtimeve", "Shto blog të ri"]
          }
        })}
      />
      <BlogsTable />
    </>
  );
};

export default Blogs;
