/* eslint-disable max-len */

import { FC } from "react";
import { IIcon } from "./IIcon";

const Star: FC<IIcon> = ({ color, size }) => {
  const currentColor = color || "currentColor";
  const currentSize = size || 16;
  return (
    <span>
      <svg
        width={currentSize}
        height={currentSize}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-auto h-auto"
      >
        <path
          d="M13.2451 17.0027C12.8476 17.0027 12.3376 16.8752 11.7001 16.5002L9.45759 15.1727C9.22509 15.0377 8.77509 15.0377 8.55009 15.1727L6.30009 16.5002C4.97259 17.2877 4.19259 16.9727 3.84009 16.7177C3.49509 16.4627 2.95509 15.8102 3.30759 14.3102L3.84009 12.0077C3.90009 11.7677 3.78009 11.3552 3.60009 11.1752L1.74009 9.31524C0.810088 8.38523 0.885088 7.59023 1.01259 7.20023C1.14009 6.81024 1.54509 6.12023 2.83509 5.90273L5.22759 5.50523C5.45259 5.46773 5.77509 5.22773 5.87259 5.02523L7.20009 2.37773C7.80009 1.17023 8.58759 0.990234 9.00009 0.990234C9.41259 0.990234 10.2001 1.17023 10.8001 2.37773L12.1201 5.01773C12.2251 5.22023 12.5476 5.46023 12.7726 5.49773L15.1651 5.89523C16.4626 6.11273 16.8676 6.80273 16.9876 7.19273C17.1076 7.58273 17.1826 8.37773 16.2601 9.30773L14.4001 11.1752C14.2201 11.3552 14.1076 11.7602 14.1601 12.0077L14.6926 14.3102C15.0376 15.8102 14.5051 16.4627 14.1601 16.7177C13.9726 16.8527 13.6726 17.0027 13.2451 17.0027ZM9.00009 13.9427C9.36759 13.9427 9.73509 14.0327 10.0276 14.2052L12.2701 15.5327C12.9226 15.9227 13.3351 15.9227 13.4926 15.8102C13.6501 15.6977 13.7626 15.3002 13.5976 14.5652L13.0651 12.2627C12.9226 11.6402 13.1551 10.8377 13.6051 10.3802L15.4651 8.52023C15.8326 8.15273 15.9976 7.79274 15.9226 7.54523C15.8401 7.29773 15.4951 7.09523 14.9851 7.01273L12.5926 6.61523C12.0151 6.51773 11.3851 6.05273 11.1226 5.52773L9.80259 2.88773C9.56259 2.40773 9.26259 2.12273 9.00009 2.12273C8.73759 2.12273 8.43759 2.40773 8.20509 2.88773L6.87759 5.52773C6.61509 6.05273 5.98509 6.51773 5.40759 6.61523L3.02259 7.01273C2.51259 7.09523 2.16759 7.29773 2.08509 7.54523C2.00259 7.79274 2.17509 8.16023 2.54259 8.52023L4.40259 10.3802C4.85259 10.8302 5.08509 11.6402 4.94259 12.2627L4.41009 14.5652C4.23759 15.3077 4.35759 15.6977 4.51509 15.8102C4.67259 15.9227 5.07759 15.9152 5.73759 15.5327L7.98009 14.2052C8.26509 14.0327 8.63259 13.9427 9.00009 13.9427Z"
          fill={currentColor}
        />
      </svg>
    </span>
  );
};

export default Star;
