import { ArrowPathIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import Input from "../../components/ui/input/Input";
import { PromotionCodeTypeEnum } from "../../interfaces/endpoints/IPromotionCodes";
import { generatePromotionCode } from "../../services/PromoCodes.service";
import { cx } from "../../utils/classnames";

interface PromotionCodeInputProps {
  editMode?: boolean;
}

const PromotionCodeInput: React.FC<PromotionCodeInputProps> = ({
  editMode
}) => {
  // const [promoCode, setPromoCode] = useState("");
  const [isRotated, setIsRotated] = useState(false);
  const [codeError, setCodeError] = useState<boolean>(false);
  // const { id } = useParams();
  const { register, setValue, watch, getValues } = useFormContext();

  let name: string;
  let promotionCodeType: number;
  if (!editMode) {
    name = watch("name");
    promotionCodeType = watch("promotionCodeType");
  } else {
    name = getValues("name");
    promotionCodeType = getValues("promotionCodeType");
  }

  const handleGeneratePromoCode = async () => {
    setIsRotated(false);
    if (
      (name !== undefined && name?.length > 0) ||
      (promotionCodeType &&
        promotionCodeType !== undefined &&
        Number(promotionCodeType) === PromotionCodeTypeEnum.student)
    ) {
      const response = await generatePromotionCode({ name, promotionCodeType });
      // setPromoCode(response.data);
      setValue("promoCode", response.data);
      setIsRotated(true);
      setCodeError(false);
    } else {
      setCodeError(true);
    }
  };
  // useEffect(() => {
  //   if (editMode) {
  //     void generatePromotionCode({ name, promotionCodeType }).then((res) =>
  //       setPromoCode(res.data)
  //     );
  //   }
  // }, [name, promotionCodeType]);

  // setValue("promoCode", promoCode);
  return (
    <div className="mb-6">
      {/* <div className="flex w-full items-end"> */}
      <Input
        {...register("promoCode")}
        placeholder="Gjenero kodin promocional..."
        label="Gjenero kodin"
        className={cx([
          // "w-full",
          editMode && "pointer-events-none opacity-60"
        ])}
        afterClassName="pointer-events-auto"
        after={
          <button
            onClick={handleGeneratePromoCode}
            type="button"
            className={cx([
              "items-center rounded-md bg-gray-200 py-1.5 px-2 -mr-2",
              "duration-300 transform-all ring-1 hover:shadow hover:ring-2 ring-gray-300",
              editMode && "pointer-events-none opacity-60"
            ])}
          >
            <ArrowPathIcon
              className={cx([
                "w-5 h-5 text-gray-700 transition-transform duration-300 ease-in-out transform",
                isRotated && "rotate-180"
              ])}
            />
          </button>
        }
      />
      {/* </div> */}
      {codeError && (
        <p className="text-red text-xs mt-1">
          Duhet të zgjedhet lloji dhe të shënohet emri.
        </p>
      )}
    </div>
  );
};

export default PromotionCodeInput;
