import useBundlesOrder from "../../../hooks/useBundlesOrder";
import NotfoundTable from "../../ui/NotfoundTable";
import Table from "../../ui/table";
import BundlesOrderRow from "./BundlesOrderRow";

const columns: (string | number)[] = [
  "Renditja",
  "Pako ID",
  "Pako e udhëtimit",
  "Biznisi",
  "Detajet",
  "Veprimet"
];

const BundlesOrder = () => {
  const { loading, response, onDelete } = useBundlesOrder();
  return (
    <div className="flex flex-col" style={{ height: "calc(100vh - 11rem)" }}>
      <Table
        titles={columns}
        loading={loading}
        className="overflow-x-auto"
        style="hover"
      >
        {response?.data.length ? (
          response?.data.map((row, i: number) => (
            <BundlesOrderRow
              row={row}
              key={`${i}_${row.displayOrder}_${row.id}`}
              onDeleteSuccess={onDelete}
            />
          ))
        ) : (
          <></>
        )}
      </Table>
      {!response?.data.length && !loading ? (
        <NotfoundTable
          header="Nuk ka pako në renditje"
          paragraph="Shto pako duke klikuar butonin lartë"
        />
      ) : null}
    </div>
  );
};

export default BundlesOrder;
