import { format, parse } from "date-fns";
import { useEffect } from "react";
import { DateRange } from "react-day-picker";
import useDashboardDatePicker from "../../hooks/useDashboardDatePicker";
import useFetch from "../../hooks/useFetch";
import { getFilteredAnalytics } from "../../services/Analytics.service";
import Animation from "../ui/Animation";
import RangePicker from "../ui/datepicker/range/RangePicker";
import DashboardCard from "./DashboardCard";
import FilteredAnalyticsGraph from "./FilteredAnalyticsGraph";

const FilteredAnalytics = () => {
  const { from, to, setLocalDates } = useDashboardDatePicker();
  const { loading, response, handleFunc } = useFetch(getFilteredAnalytics, {
    initRequest: false,
    loading: true,
    resetResponseOnError: true
  });

  useEffect(() => {
    if (from && to) {
      void handleFunc({
        startDate: format(parse(from, "dd/MM/yyyy", new Date()), "MM-dd-yyyy"),
        endDate: format(parse(to, "dd/MM/yyyy", new Date()), "MM-dd-yyyy")
      });
    }
  }, [from, to]);

  const onDateApply = (r: DateRange | undefined) => {
    if (r?.from && r?.to)
      setLocalDates({
        from: format(r.from, "dd/MM/yyyy"),
        to: format(r.to, "dd/MM/yyyy")
      });
  };

  return (
    <div className="mt-6">
      <div className="flex items-center mb-4 justify-end">
        <div className="">
          <RangePicker
            label="Filtro datën:"
            className="flex flex-row items-center"
            labelClassName="mb-0 mr-2"
            // paddingType="fill"
            range={
              from && to
                ? {
                  from: parse(from, "dd/MM/yyyy", new Date()),
                  to: parse(to, "dd/MM/yyyy", new Date())
                }
                : undefined
            }
            pickerProps={{ toDate:new Date() }}
            hasHelpers={true}
            onApply={onDateApply}
          />
        </div>
      </div>
      <div className="flex w-full xl:flex-row flex-col">
        <Animation type="fade-in" show={true} delay={100} duration={400}>
          <div>
            <FilteredAnalyticsGraph
              loading={loading}
              data={response?.data?.reservationsPerDay}
            />
          </div>
        </Animation>
        <div className="xl:ml-6 xl:mt-0 mt-4 flex w-full flex-col">
          <div className="mb-2.5 lg:mr-0 mr-2.5 w-full flex lg:flex-row flex-col">
            <DashboardCard
              title="Totali i biletave te shitura"
              delay={200}
              type="fade-in"
              className="mr-2.5 lg:mb-0 mb-2.5"
              currentValue={response?.data.sales?.currentValue || "0"}
              ratePercentage={response?.data.sales?.ratePercentage || "0"}
              ratePercentageDesc="prej periudhës së kaluar"
              loading={loading}
            />
            <DashboardCard
              delay={300}
              type="fade-in"
              title="Vlera mesatare e biletave"
              currentValue={
                response?.data.averagePricePerReservation?.currentValue || "0"
              }
              ratePercentage={
                response?.data.averagePricePerReservation?.ratePercentage || "0"
              }
              ratePercentageDesc="prej periudhës së kaluar"
              loading={loading}
            />
          </div>
          <div className="w-full flex lg:flex-row flex-col">
            <DashboardCard
              delay={400}
              type="fade-in"
              className="mr-2.5 lg:mb-0 mb-2.5"
              title="Numri i rezervimeve"
              currentValue={
                response?.data.numberOfReservations?.currentValue || "0"
              }
              ratePercentage={
                response?.data.numberOfReservations?.ratePercentage || "0"
              }
              ratePercentageDesc="prej periudhës së kaluar"
              loading={loading}
              currency={false}
            />
            <DashboardCard
              delay={400}
              type="fade-in"
              title="Numri i biletave"
              currentValue={response?.data.numberOfTickets?.currentValue || "0"}
              ratePercentage={
                response?.data.numberOfTickets?.ratePercentage || "0"
              }
              ratePercentageDesc="prej periudhës së kaluar"
              loading={loading}
              currency={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default FilteredAnalytics;
