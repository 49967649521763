import { number, object, string } from "yup";

const PromotionCodesSchema = object().shape({
  promotionCodeType: number().required("Zgjedh llojin e kodit"),
  name: string().test(
    "Emri",
    "Vendos emrin për të gjeneruar kodin",
    (promotionCodeType: any, ctx: any) => {
      console.log(promotionCodeType, ctx, "console.log");
      if (promotionCodeType === 1) {
        return false;
      }
      return true;
    }
  ),
  promoCode: string()
    .typeError("Kodi gjenerohet kur ka emër dhe llojin e parazgjedhur")
    .required("Vendos emrin dhe llojin për të gjeneruar kodin"),
  email: string().required("Vendos email adresën"),
  expirationDate: string().required("Vendos datën e skadimit")
});
export default PromotionCodesSchema;
