import HeaderPage from "../../../components/lines-managements/header/HeaderPage";
import StationsTable from "../../../components/locations";
import useCan from "../../../components/users/permissions/useCan";

const Stations: React.FC = () => {
  const can = useCan("locations.station.create");
  return (
    <>
      <HeaderPage
        {...(can && {
          link: {
            to: "create",
            children: "Shto stacion të ri",
            state: ["Stacionet", "Shto stacion të ri"]
          }
        })}
      />
      <StationsTable />
    </>
  );
};

export default Stations;
