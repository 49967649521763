import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import FormWrapper from "../../components/ui/FormWrapper";
import Input from "../../components/ui/input/Input";
import Select from "../../components/ui/input/Select";
import Textarea from "../../components/ui/input/Textarea";
import { EnumAnnouncementLanguage, IAnnouncementSchema } from "../../interfaces/endpoints/IAnnouncements";
import { ILocation } from "../../interfaces/endpoints/ILocations";
import { editAnnouncement, getCurrentAnnouncement, postAnnouncement } from "../../services/Announcements.service";
import { getAllLocations } from "../../services/Locations.service";
import AnnouncementSchema from "../../utils/validations/Announcement.schema";

const EditAnnouncement: React.FC<{ editMode?: boolean }> = ({ editMode }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [locations, setLocations] = useState<ILocation[]>([]);

  useEffect(() => {
    if (editMode) {
      getCurrentAnnouncement(Number(id))
        .then((res) => {
          res.data.announcementDate = String(dayjs(res.data.announcementDate).format("YYYY-MM-DD"));
          const deletedData: IAnnouncementSchema = res.data;
          Object.entries(deletedData).map((el: any) => {
            setValue(el[0], el[1]);
          }
          );
        })
        .catch(() => toast.error("Ndodhi një gabim."));
    }
  }, []);

  useEffect(() => {
    getAllLocations({ pageSize: 99999999 })
      .then((res) => setLocations(res.data.result))
      .catch((err) => console.log(err));
  }, [id]);

  const { register, handleSubmit, setValue, formState: { errors, dirtyFields, isSubmitSuccessful } }
   = useForm<IAnnouncementSchema>({
    resolver: yupResolver(AnnouncementSchema)
  });

  const onSubmit: SubmitHandler<IAnnouncementSchema> = async (data) => {
    if (editMode) {
      await editAnnouncement(data)
        .then(() => {
          toast.success("Informata është ndryshuar me sukses.", {
            position: "top-right"
          });
          setTimeout(() => {
            navigate("/announcements",
              { state: ["Informatat për linja"] });
          }, 500);
        })
        .catch(() => toast.error("Ndodhi një gabim gjatë ndryshimit."));
    } else {
      await postAnnouncement(data)
        .then(() => {
          toast.success("Njoftimi është publikuar me sukses.", {
            position: "top-right"
          });
          setTimeout(() => {
            navigate("/announcements",
              { state: ["Informatat për linja"] });
          }, 500);
        })
        .catch(() => toast.error("Ndodhi një gabim gjatë krijimit."));
    }
  };
  const today = dayjs();

  const showPrompt = !isSubmitSuccessful && !!Object.keys(dirtyFields).length;
  return (
    <>
      <Link className="flex items-center mb-4 text-sm font-semibold text-primary"
        to={"/announcements"} state={["Informatat për linja"]} >
        <ChevronLeftIcon className="w-4 h-4 mr-2" />
        <span>
          Kthehu tek lista
        </span>
      </Link>
      <FormWrapper showPrompt={showPrompt} onSubmit={handleSubmit(onSubmit)}
       title={editMode ? "Edito informaten" : "Shto informatë"}>
        <Input className="mb-6" placeholder="Shkruaj titullin e informates"
          label="Titulli" {...register("title")} error={errors.title?.message} />
        <Textarea className="mb-6" placeholder="Shkruaj informaten për këtë linjë"
          label="Përshkrimi" {...register("description")} error={errors.description?.message} />
        <Select className="mb-6"
          {...register("stationFrom")}
          label="Nga" error={errors.stationFrom?.message}
          options={locations?.map((lc) => {
            return { id: lc.id, name: lc.name };
          })} />
        <Select className="mb-6"
          {...register("stationTo")}
          label="Në" error={errors.stationTo?.message}
          options={locations?.map((lc) => {
            return { id: lc.id, name: lc.name };
          })} />
        <Select className="mb-6"
          {...register("languageId")}
          label="Gjuha" error={errors.languageId?.message}
          options={[
            { id: 1, name: EnumAnnouncementLanguage[1] },
            { id: 2, name: EnumAnnouncementLanguage[2] }
          ]} />
        <Input {...register("announcementDate")}
          type={"date"} className="mb-6" placeholder="Zgjedh për cilen datë"
          label="Për cilen datë"
          min={today.format("YYYY-MM-DD")}
          error={errors.announcementDate?.message} />
        <Input className="mb-6" placeholder="Linku"
          label="Linku" {...register("url")} />

      </FormWrapper>
    </>
  );
};

export default EditAnnouncement;
