/* eslint-disable max-lines */
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import DaySelect from "../../../components/lines-managements/trips/SelectDays";
import NewTrip from "../../../components/lines-managements/trips/Trip";
import Schedules from "../../../components/lines-managements/trips/schedules";
import CreateTripWrapper from "../../../components/lines-managements/trips/schedules/CreateTripWrapper";
import FormWrapper from "../../../components/ui/FormWrapper";
import useFetch from "../../../hooks/useFetch";
import useNavigateState from "../../../hooks/useNavigateState";
import { ILocation } from "../../../interfaces/endpoints/ILocations";
import { ITripType } from "../../../interfaces/endpoints/trips/Trips.types";
import { getLinesSelectList } from "../../../services/Lines.service";
import { getAllLocations } from "../../../services/Locations.service";
import {
  editTrip,
  getCurrentTrip,
  postTrip
} from "../../../services/Trips.service";
import { serialize } from "../../../utils/serializeToQueryString";
import TripsSchema from "../../../utils/validations/Trips.schema";
const CreateTrip: React.FC<{ editMode: boolean }> = ({ editMode }) => {
  const { id } = useParams();
  const { state }: any = useLocation();
  console.log(state);
  const stateCrumbs = useNavigateState(["Menaxhimi i linjave", "Oraret"]);
  const navigate = useNavigate();
  const [locations, setLocations] = useState<ILocation[]>([]);
  const { response: linesRes } = useFetch(getLinesSelectList, {
    initRequest: !editMode,
    params: { pageIndex: 1, pageSize: 999999999 }
  });

  const { response: tripRes } = useFetch(getCurrentTrip, {
    initRequest: editMode,
    params: Number(id)
  });

  useEffect(() => {
    if (editMode && tripRes && tripRes.data) {
      Object.entries(tripRes.data).forEach(([key, value]) => {
        const trip = key as keyof ITripType;
        setValue(trip, value);
      });
    }
    getAllLocations({ pageSize: 99999999 })
      .then((res) => setLocations(res.data.result))
      .catch((err) => console.log(err));
  }, [tripRes, id]);

  const methods = useForm<ITripType>({
    resolver: yupResolver(TripsSchema),
    defaultValues: {
      operationalInterval: {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
        sunday: true
      }
    }
  });
  const {
    register,
    handleSubmit,
    setValue
    // formState: { errors }
  } = methods;

  const onSubmit: SubmitHandler<ITripType> = async (data) => {
    if (editMode) {
      await editTrip({
        operationalInterval: {
          monday: data.operationalInterval.monday,
          tuesday: data.operationalInterval.tuesday,
          wednesday: data.operationalInterval.wednesday,
          thursday: data.operationalInterval.thursday,
          friday: data.operationalInterval.friday,
          saturday: data.operationalInterval.saturday,
          sunday: data.operationalInterval.sunday
        },
        prices: data.prices,
        routeDescription: data.routeDescription,
        routeName: data.routeName,
        trip: {
          arrivalTime: data.trip.arrivalTimeString,
          departureTime: data.trip.departureTimeString,
          defaultCurrencyId: data.trip.defaultCurrencyId,
          duration: Math.abs(data.trip.duration),
          lineId: data.trip.lineId,
          isReservable: data.trip.isReservable,
          // isActive: !!data.trip.isActive,
          id: data.trip.id
        },
        schedules: data.schedules
          ?.filter((el) => el)
          .map((el) => ({
            arrivalTime: el.arrivalTimeString,
            departureTime: el.departureTimeString,
            duration: el.duration,
            stationId: el.stationId,
            tripId: el.tripId,
            id: 0
          }))
      })
        .then(() => {
          toast.success("Orari është ndryshuar me sukses.", {
            position: "top-right"
          });
          setTimeout(() => {
            navigate(`/trips?pageindex=${state.page}`, {
              state: stateCrumbs
            });
          }, 500);
        })
        .catch(() => toast.error("Ndodhi një gabim gjatë ndryshimit."));
    } else {
      const lineName =
        linesRes?.data.result.find((el) => el.value === data.trip.lineId)
          ?.label || "";
      await postTrip({
        ...data,
        trip: {
          departureTimeString: data.trip.departureTimeString,
          arrivalTimeString: data.trip.arrivalTimeString,
          defaultCurrencyId: data.trip.defaultCurrencyId,
          arrivalTime: data.trip.arrivalTimeString,
          departureTime: data.trip.departureTimeString,
          duration: Math.abs(data.trip.duration),
          lineId: data.trip.lineId,
          isReservable: data.trip.isReservable,
          // isActive: data.trip.isActive,
          id: data.trip.id
        },
        schedules: data?.schedules
          ? data?.schedules
              ?.filter((el) => el)
              .map((el) => ({
                arrivalTime: el.arrivalTimeString,
                departureTime: el.departureTimeString,
                arrivalTimeString: el.arrivalTimeString,
                departureTimeString: el.departureTimeString,
                stationId: el.stationId,
                duration: el.duration,
                id: el.id
              }))
          : []
      })
        .then((res) => {
          toast.success("Orari është publikuar me sukses.", {
            position: "top-right"
          });
          setTimeout(() => {
            navigate(
              {
                pathname: "/prices",
                hash: "trips",
                search: lineName.length
                  ? serialize({
                      lineName,
                      lineId: data.trip.lineId,
                      tripId: res.data,
                      tripTime: `${data.trip.departureTimeString} - ${data.trip.arrivalTimeString}`
                    })
                  : ""
              },
              { state: ["Menaxhimi i linjave", "Çmimet"] }
            );
          }, 500);
        })
        .catch(() =>
          toast.error(
            // eslint-disable-next-line max-len
            "Ndodhi një gabim gjatë krijimit.Kontrolloni kohën e nisjes dhe arritjes, koha e nisjes nga ndalesat duhet të jetë pas orës së nisjes së linjës.",
            { duration: 6000 }
          )
        );
    }
  };

  return (
    <FormProvider {...methods}>
      <Link
        className="flex items-center mb-4 text-sm font-semibold text-primary"
        to={"/trips"}
        state={["Informatat për oraret"]}
      >
        <ChevronLeftIcon className="w-4 h-4 mr-2" />
        <span>Kthehu tek oraret</span>
      </Link>
      <FormWrapper
        showPrompt={
          !methods.formState.isSubmitSuccessful &&
          !!Object.keys(methods.formState.dirtyFields).length
        }
        onSubmit={handleSubmit(onSubmit)}
        title={editMode ? "Modifiko orarin" : "Shto një orar të ri"}
        className="mx-0 max-w-full"
      >
        <div className="w-full">
          <NewTrip response={linesRes} editMode={editMode} />
          <Schedules
            locations={locations}
            schedulesRes={tripRes?.data.schedules}
            tripIndex={tripRes?.data.trip.id}
          />
        </div>
        <div className="flex items-end  w-full justify-between">
          <CreateTripWrapper title="Ditët" className="border-none mt-5">
            <DaySelect register={register} />
          </CreateTripWrapper>
          {/* <div className="border flex items-center px-3 py-1 rounded-lg">
            <input
              type="checkbox"
              id="active"
              className="cursor-pointer "
              {...register("trip.isActive")}
            />
            <label
              htmlFor="active"
              className="ml-3 text-sm  font-semibold
         transitions hover:font-bold  cursor-pointer transition-all duration-75"
            >
              Aktive
            </label>
          </div> */}
        </div>
      </FormWrapper>
    </FormProvider>
  );
};
export default CreateTrip;
