import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import FormWrapper from "../../../components/bundles/FormWrapper";
import UserEditForm from "../../../components/users/UserEditForm";
import useFetch from "../../../hooks/useFetch";
import { UserEditFormType } from "../../../interfaces/endpoints/IUser.types";
import { getUserById } from "../../../services/Users.service";
import { UserSchema } from "../../../utils/validations/User.schema";

const EditUser = () => {
  const { id } = useParams();

  const methods = useForm<UserEditFormType>({
    resolver: yupResolver(UserSchema),
    shouldFocusError: false,
    defaultValues: {
      firstName: "",
      email: "",
      isActive: false,
      lastName: "",
      operator: {},
      roles: []
    }
  });

  const { response, error, loading } = useFetch(getUserById, {
    initRequest: true,
    params: Number(id)
  });

  useEffect(() => {
    if (response && response.data) {
      console.log(
        response.data.usersRolesMappings.length
          ? response.data.usersRolesMappings.map((el) => ({
              status: el.role.status,
              id: el.role.id,
              roleName: el.role.roleName,
              isSystemRole: el.role.isSystemRole
            }))
          : []
      );
      methods.reset({
        email: response.data.userName,
        firstName: response.data.firstName || "",
        lastName: response.data.lastName || "",
        isActive: response.data.isActive,
        operator: response.data.operator,
        roles: response.data.usersRolesMappings.map((el) => ({
          status: el.role.status,
          id: el.role.id,
          roleName: el.role.roleName,
          isSystemRole: el.role.isSystemRole
        }))
      });
    }
  }, [response, id]);

  return (
    <FormProvider {...methods}>
      <FormWrapper
        showPrompt={
          !methods.formState.isSubmitSuccessful &&
          !!Object.keys(methods.formState.dirtyFields).length
        }
        backTitle="Kthehu tek lista"
        backTo={"/users/overview"}
        backState={["User overview", "Users"]}
        error={!!error}
        mode="edit"
        response={response}
        loading={loading}
        notFoundMessage="User-i nuk u gjet"
        title="Edito Userin"
      >
        <UserEditForm />
      </FormWrapper>
    </FormProvider>
  );
};

export default EditUser;
