import { object } from "yup";
// import { OfficeReservationSchema } from "../../pages/office/ChooseLine";

const OfficeSalesSchema = object().shape({
  trip:  object().required("Vendos linjen"),
  date:  object().shape({
  }).required("Vendos daten!")
});

export default OfficeSalesSchema;
