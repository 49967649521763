import { ReactNode } from "react";

interface CreateTripWrapperProps {
  children: ReactNode;
  title: string;
  className?: string;
  border?: boolean;
}

const CreateTripWrapper: React.FC<CreateTripWrapperProps> =
  ({ children, title, className, border = true }) => {
    return (
      <div className={`${border && "border-b"} -mx-5 px-5 ${className} relative`}>
        <p className="mb-5 text-neutral">{title}</p>
        <div>{children}</div>
      </div>
    );
  };

export default CreateTripWrapper;
