import { array, object, string } from "yup";

export const ReservationSchema = object().shape({
  roundWayTicketDetails: array().of(
    object().shape({
      tripId: string().required("Vendos TripId!").typeError("Vendos TripId!")
    })
  ),
  oneWayTicketDetails: array().of(
    object().shape({
      tripId: string().required("Vendos TripId!").typeError("Vendos TripId!")
    })
  )
});
