import { useFormContext } from "react-hook-form";
import "react-tooltip/dist/react-tooltip.css";
import { IStations } from "../../../interfaces/endpoints/IStations";
import Gmaps from "../../Gmaps";
import Input from "../../ui/input/Input";
import MultpileLangFieldsCity from "./MultipleLangFieldsCity";

const CreateCity = () => {
  const { register } = useFormContext<IStations>();

  return (
    <>
      {/* <Input
        {...register("city.locationName")}
        label="Qyteti*"
        placeholder="Shkruaj emrin e qytetit këtu..."
        className="mb-6"
        // error={errors?.city.locationName?.message}
      /> */}
      <div className="mb-6">
      <MultpileLangFieldsCity />
      </div>
      <Input
        {...register("city.postalCode")}
        className="mb-4"
        label="ZIP Kodi*"
        placeholder="Shkruaj ZIP kodin këtu..."
      />{" "}
      <Gmaps
        className="max-w-full mx-0"
        names={{
          lat: "city.locationCoordinate.latitude",
          lng: "city.locationCoordinate.longitude"
        }}
      />
    </>
  );
};

export default CreateCity;
