import { FC } from "react";
import { Navigate } from "react-router-dom";
import { ICan } from "../../../interfaces/components/Can.types";
import useCan from "./useCan";

const RouteCan: FC<ICan> = ({ children, permissions }) => {
  const can = useCan(permissions);
  return can ? <>{children}</> : <Navigate to="/404" replace />;
};

export default RouteCan;
