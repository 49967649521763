import { FC } from "react";
import { ICan } from "../../../interfaces/components/Can.types";
import useCan from "./useCan";

const Can: FC<ICan> = ({ children, permissions }) => {
  const can = useCan(permissions);
  return can ? <>{children}</> : null;
};

export default Can;
