import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { FC, useState } from "react";
import Button from "../ui/Button";
import IconBtn from "../ui/IconBtn";
import Modal from "../ui/Modal";

interface DeleteCodeProps {
  index?: number;
  handleDelete: () => void;
}
const DeleteCode: FC<DeleteCodeProps> = ({ handleDelete }) => {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/space-before-function-paren

  const deleteTrip = () => {
    setDeleteModal(false);
    handleDelete();
  };

  return (
    <>
      <IconBtn className="w-6 h-6" onClick={() => setDeleteModal(true)}>
        <TrashIcon className="w-4 h-4" />
      </IconBtn>
      <Modal
        hasHeader={false}
        position="center"
        show={deleteModal}
        onClose={() => setDeleteModal(false)}
        mini
        calcHeight={false}
        buttonElement={<Button onClick={deleteTrip}>Konfirmo</Button>}
      >
        <div className="flex justify-center mt-6">
          Konfirmo fshirjen e kodit promocional.
          <IconBtn
            className="absolute right-2 top-2"
            onClick={() => setDeleteModal(false)}
          >
            <XMarkIcon className="w-4 h-4 text-neutral" strokeWidth={2} />
          </IconBtn>
        </div>
        <p className="justify-center w-full flex mt-6 text-gray-600 text-sm">
          Ky veprim nuk mund të kthehet mbrapa.
        </p>
      </Modal>
    </>
  );
};

export default DeleteCode;
