import { useAuth } from "oidc-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSection from "../../components/auth/LoadingSection";
import useFetch from "../../hooks/useFetch";
import { invitationUser } from "../../services/Users.service";

const Invitation = () => {
  const [invitationAccept, setInvitationAccept] = useState<boolean>(false);
  const { guid } = useParams();
  const navigate = useNavigate();
  const { handleFunc } = useFetch(invitationUser);

  const { signIn, isLoading, userData, signOut } = useAuth();

  useEffect(() => {
    if (!isLoading && !invitationAccept) {
      if (userData?.access_token) {
        if (guid) {
          setInvitationAccept(true);
          void handleFunc(guid)
            .then((res) => {
              if (res?.status === 200) {
                navigate("/");
              } else {
                void signOut();
                navigate(`/login?invitation=${guid}`);
              }
            })
            .catch(() => {
              void signOut();
              navigate(`/login?invitation=${guid}`);
            });
        }
      } else {
        void signIn({
          prompt: "login",
          redirect_uri: `${process.env.REACT_APP_URL}/invitation/${guid}`
        });
      }
    }
  }, [isLoading, userData]);

  return <LoadingSection />;
};

export default Invitation;
