import { ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactRouterPrompt from "react-router-prompt";
import { cx } from "../../utils/classnames";
import Box from "./Box";
import Button from "./Button";
import Modal from "./Modal";

interface FormWrapperProps {
  className?: string;
  children: ReactNode;
  title?: string;
  pricing?: boolean;
  onSubmit: () => Promise<void>;
  showSaveBtn?: boolean;
  showPrompt?: boolean;
}

const FormWrapper: React.FC<FormWrapperProps> = ({
  children,
  className,
  onSubmit,
  title,
  pricing,
  showSaveBtn = true,
  showPrompt
}) => {
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState<boolean>(false);

  const _onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDisabled(true);
    await onSubmit();
    setTimeout(()=> {
      setDisabled(false);
    }, 1000);
  };

  return (
    <>
    <ReactRouterPrompt when={!!showPrompt}>
        {({ isActive, onConfirm, onCancel }) => (
          <Modal hasHeader={false} show={isActive} position="top" modalWidth="372px">
          <div className="p-6 flex flex-col items-center justify-between h-[175px]">
            <h1 className="text-lg font-medium">
              A jeni të sigurt për këtë veprim?
            </h1>
             <p className="text-sm text-neutral-300">
              Nëse vazhdoni, të dhënat tua do të humbasin.
            </p>
            <div className="w-full flex gap-4 text-sm">
              <Button btnType="custom" fontWeight="medium" className="w-full border"
                onClick={onCancel}>
                Anulo
              </Button>
              <Button fontWeight="medium" className="w-full" onClick={onConfirm}>
                Vazhdo
              </Button>
            </div>
          </div>
        </Modal>
        )}
      </ReactRouterPrompt>
    <Box className={cx([className, "h-full p-0"])}>
      <form
        onSubmit={_onSubmit}
        className="flex flex-col justify-between h-full"
      >
        {title ? (
          <h1
            className={`text-xl ${
              pricing
                ? "border-none mx-4 md:mx-0 mb-5"
                : "mb-2 py-3 px-5 border-b"
            }`}
          >
            {title}
          </h1>
        ) : null}

        <div
          className={cx([
            className,
            `max-w-lg mx-auto h-full max-h-full w-full
         ${pricing ? "pb-5 px-0 overflow-x-auto" : "overflow-auto p-5"}`
          ])}
        >
          {children}
        </div>
        {showSaveBtn ? (
          <div
            className={`border-t p-4 justify-end flex sticky  bottom-0 rounded-b-lg bg-white z-10
        ${pricing && "md:-mx-7 md:border"}`}
          >
            <div>
              <Button
                btnType="custom"
                className={`border border-primary text-primary mr-4
            ${pricing && "hidden"}`}
                onClick={() => navigate(-1)}
              >
                Anulo
              </Button>
              {showSaveBtn ? (
                <Button type="submit" disabled={disabled}>Ruaj ndryshimet</Button>
              ) : null}
            </div>
          </div>
        ) : null}
      </form>
    </Box>
    </>
  );
};

export default FormWrapper;
