import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { FC, useContext, useState } from "react";
import toast from "react-hot-toast";
import Button from "../../../components/ui/Button";
import IconBtn from "../../../components/ui/IconBtn";
import Modal from "../../../components/ui/Modal";
import { LinesContext } from "../../../context/LinesProvider";
import { deleteLine } from "../../../services/Lines.service";

interface DeleteLineProps {
  index: number;
}
const DeleteLine: FC<DeleteLineProps> = ({ index }) => {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const { deleteLine: deleteLineFromContext } = useContext(LinesContext);
  // eslint-disable-next-line @typescript-eslint/space-before-function-paren
  const handleDelete = async (id: number) => {
    try {
      await deleteLine(id);
      deleteLineFromContext(id);
      toast.success("Linja është fshirë me sukses.", {
        position: "top-right"
      });
    } catch (error) {
      toast.error(`${error}` || "404");
    }
  };
  return (
    <>
      <IconBtn className="md:p-1" onClick={() => setDeleteModal(true)}>
        <TrashIcon className="w-5 h-5 md:w-4 md:h-4" />
      </IconBtn>
      <Modal
        hasHeader={false}
        position="center"
        show={deleteModal}
        onClose={() => setDeleteModal(false)}
        mini
        calcHeight={false}
        buttonElement={
          <Button onClick={() => handleDelete(index)}>Konfirmo</Button>
        }
      >
        <div className="flex justify-center mt-6">
          Konfirmo fshirjen e linjës.
          <IconBtn
            className="absolute right-2 top-2"
            onClick={() => setDeleteModal(false)}
          >
            <XMarkIcon className="w-4 h-4 text-neutral" strokeWidth={2} />
          </IconBtn>
        </div>
        <p className="justify-center w-full flex mt-6 text-gray-600 text-sm">
          Ky veprim nuk mund të kthehet mbrapa.
        </p>
      </Modal>
    </>
  );
};

export default DeleteLine;
