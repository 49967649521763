import { useEffect, useState } from "react";
import { LineSelectList } from "../interfaces/endpoints/lines/ILines";

const defaultLocation = {
  label: "All",
  oldValue: 0,
  parentValue: 0,
  selected: false,
  value: 0
};

const useDashboardSelectLine = () => {
  const [line, setLine] = useState<LineSelectList | null>(null);

  useEffect(() => {
    const storageDashboad = () => {
      const localLine = getLocalLine();
      if (localLine === null) setLocalLine(defaultLocation);
      else setLine(localLine);
    };
    window.addEventListener("storage:dashboard-line-change", storageDashboad);
    window.dispatchEvent(new Event("storage:dashboard-line-change"));
    return () => {
      window.removeEventListener(
        "storage:dashboard-line-change",
        storageDashboad
      );
    };
  }, []);

  const getLocalLine = () => {
    const localLine = sessionStorage.getItem("dashboard:line");
    try {
      const _line: LineSelectList = JSON.parse(localLine || "");
      if (_line.value !== undefined) return _line;
      else return null;
    } catch (error) {
      return null;
    }
  };

  const setLocalLine = (d: LineSelectList | null) => {
    sessionStorage.setItem("dashboard:line", JSON.stringify(d));
    window.dispatchEvent(new Event("storage:dashboard-line-change"));
  };

  return { line, setLocalLine, defaultLocation };
};

export default useDashboardSelectLine;
