import { PencilIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import usePageIndex from "../../hooks/usePageIndex";
import { useSearch } from "../../hooks/useSearch";
import { deleteBlogById, getAllBlogs } from "../../services/Blogs.service";
import AuditAuthor from "../audit-author/AuditAuthor";
import DeleteModal from "../ui/DeleteModal";
import IconBtn from "../ui/IconBtn";
import Table, { Row } from "../ui/table";
import Pagination from "../ui/table/Pagination";
import Can from "../users/permissions/Can";

const columns: (string | number)[] = [
  "Blog ID",
  "Titulli",
  "Përshkrimi",
  // "Data e shkrimit",
  "Detajet",
  "Veprimet"
];

const BlogsTable = () => {
  const { response, loading, handlePageChange, page, setResponse } = useSearch(
    getAllBlogs,
    {
      withQuerySearch: true
    }
  );

  // const replaceTags = (paragraph: string) => {
  //   return paragraph.replace(/<[^>]*>/g, " ");
  // };
  const { pageIndex, state }: any = usePageIndex();

  const replaceTags = (paragraph: string) => {
    const desc = paragraph.replace(/<[^>]*>/g, " ");
    return desc.slice(0, 65) + (desc.length > 65 ? "..." : "");
  };

  return (
    <div className="flex flex-col" style={{ height: "calc(100vh - 11rem)" }}>
      <Table titles={columns} style="hover" loading={loading}>
        {response?.data.result?.length ? (
          response?.data.result?.map((row) => {
            const highlighted = +state?.params?.id === row.id;
            return (
            <Row
              key={row.id}
              id={row.id}
              className={`${highlighted && "highlighted"}`}
              title={row.title}
              description={replaceTags(row.description)}
              // inserDate={row.inserDate}
              auditAuthor={
                <AuditAuthor
                  createdByName={row.auditAuthor.createdByName}
                  createdByLastName={row.auditAuthor.createdByLastName}
                  updatedByName={row.auditAuthor.updatedByName}
                  updatedByLastName={row.auditAuthor.updatedByLastName}
                  insertDate={row.auditAuthor.insertDate}
                  updateDate={row.auditAuthor.updateDate}
                />}
              actions={
                <span className="flex">
                  <Can permissions="blogs.blog.update">
                    <Link
                      to={`/blogs/edit/${row.id}`}
                      state={{ crumbs: [
                        "Informatat për blogun",
                        "Edito Blogun"
                      ],
                      page: pageIndex }}
                    >
                      <IconBtn className="w-6 h-6">
                        <PencilIcon className="w-4 h-4 text-neutral" />
                      </IconBtn>
                    </Link>
                  </Can>
                  <Can permissions="blogs.blog.delete">
                    <DeleteModal
                      params={row.id}
                      title="Konfirmo fshirjen e blogut."
                      handleDelete={deleteBlogById}
                      successMessage="Blogu është fshirë me sukses."
                      errorMessage="Blogu nuk është fshirë me sukses."
                      loadingMessage="Blogu është duke u fshirë..."
                      onSuccess={() => {
                        setResponse((prev) =>
                          prev
                            ? {
                                ...prev,
                                data: {
                                  ...prev.data,
                                  result: prev.data.result.filter(
                                    (el) => el.id !== row.id
                                  )
                                }
                              }
                            : null
                        );
                      }}
                    />
                  </Can>
                </span>
              }
            />
          );
})
        ) : (
          <></>
        )}
      </Table>
      <Pagination
        onPageChange={handlePageChange}
        totalCount={response?.data?.totalCount || 0}
        length={response?.data?.result.length}
        page={page}
        pageSize={10}
      />
    </div>
  );
};

export default BlogsTable;
