import {
  PencilSquareIcon,
  PlusCircleIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import "dayjs/locale/sq";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/ui/Button";
import IconBtn from "../../components/ui/IconBtn";
import Table, { Row } from "../../components/ui/table";
import Can from "../../components/users/permissions/Can";
import {
  EnumAnnouncementLanguage,
  IAnnouncement
} from "../../interfaces/endpoints/IAnnouncements";
import {
  deleteAnnouncement,
  getAllAnnouncements
} from "../../services/Announcements.service";

const AnnouncementList = () => {
  const navigate = useNavigate();
  const [announcement, setAnnouncement] = useState<IAnnouncement[]>([]);

  const columns = [
    "Titulli",
    "Data",
    "Pershkrimi",
    "Nga",
    "Ne",
    "Gjuha",
    "Veprimet"
  ];

  useEffect(() => {
    getAllAnnouncements({ pageSize: 10 })
      .then((res) => setAnnouncement(res?.data?.result || []))
      .catch((err) => toast.error(err?.message || ""));
  }, []);

  const handleDeleteAnnouncement = (id: number) => {
    deleteAnnouncement(id)
      .then(() => {
        toast.success("Informate është larguar me sukses.");
        const deleted = announcement.filter((x) => {
          return x.id !== id;
        });
        setAnnouncement(deleted);
      })
      .catch(() => toast.error("Ndodhi një gabim gjatë fshirjes."));
  };
  return (
    <>
      <Can permissions="route.announcement.create">
        <Button
          className="flex items-center mb-4"
          onClick={() =>
            navigate("/announcements/add", {
              state: ["Informatat për linja", "Shto informatë"]
            })
          }
        >
          <PlusCircleIcon className="w-4 h-4" />
          <span className="ml-1">Shto informatë</span>
        </Button>
      </Can>
      <Table titles={columns} style="hover">
        {announcement.map((el) => (
          <Row
            key={el.title}
            title={el.title}
            date={dayjs(el.announcementDate).format("MMMM D, YYYY")}
            desc={el.description}
            from={el.stationNameFrom}
            to={el.stationNameTo}
            lang={EnumAnnouncementLanguage[el.languageId]}
            actions={
              <span className="flex justify-center items-center">
                <Can permissions="route.announcement.update">
                  <Link
                    className="p-1 w-6 h-6"
                    to={`/announcements/edit/${el.id}`}
                    state={["Informatat për linja", "Edito Informaten"]}
                  >
                    <PencilSquareIcon />
                  </Link>
                </Can>
                <Can permissions="route.announcement.update">
                  <IconBtn
                    className="p-2 w-6 h-6"
                    onClick={() => handleDeleteAnnouncement(el.id)}
                  >
                    <TrashIcon />
                  </IconBtn>
                </Can>
              </span>
            }
          />
        ))}
      </Table>
    </>
  );
};

export default AnnouncementList;
