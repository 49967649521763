import { forwardRef } from "react";
import { SelectProps } from "./Input.types";
import InputWrapper from "./InputWrapper";

const Select = forwardRef(
  (
    {
      inputClassName,
      border,
      label,
      className,
      options,
      selectedId,
      error,
      hasEmptyOption = true,
      ...props
    }: SelectProps,
    ref: any
  ) => (
    <InputWrapper
      id={props.id}
      label={label}
      name={props.name}
      className={`relative ${className}`}
      error={error}
    >
      <select
        {...props}
        ref={ref}
        defaultValue={selectedId && selectedId}
        className={`${inputClassName} transition-colors px-2 focus:ring-primary
         w-full h-11 ${error ? "outline outline-1 outline-red-500" : ""}
        ${typeof border === "string"
            ? border
            : "ring-1 ring-blue-stroke transition-shadow duration-200 hover:ring-gray-500"
          } rounded-lg`}
      >
        {hasEmptyOption && <option value=""></option>}
        {options.map((option) => (
          <option
            key={option.id}
            value={option.id}
            data-name={option.name}
          >
            {option.name}
          </option>
        ))}
      </select>
    </InputWrapper>
  )
);

export default Select;
