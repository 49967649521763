import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useEffect, useRef, useState } from "react";
import { cx } from "../../../../utils/classnames";
import SpinnerLoader from "../../SpinnerLoader";
import Input from "../../input/Input";
import { ISelectInput } from "../Select.types";
import { instanceOfBaseType } from "../utils";
import MultiplePills from "./MultiplePills";

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const SelectInput = <T, D extends unknown>({
  getInputProps,
  getToggleButtonProps,
  inputClassName,
  loading,
  isOpen,
  selectType,
  selectedItems,
  getOptionLabel,
  onSelectedItemChange,
  ...props
}: ISelectInput<T, D>) => {
  const [selected, setSelected] = useState<T[]>([]);
  const [extendsList, setExtendsList] = useState<T[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const contentRef = useRef<number>(0);

  const _getOptionLabel = (o: T) => {
    if (getOptionLabel) return getOptionLabel(o);
    if (instanceOfBaseType(o, "label")) return o.label;
  };

  useEffect(() => {
    if (selectedItems && selectType === "multiple") {
      let width = 0;
      let _index = 0;
      selectedItems.forEach((el, index) => {
        const mainDiv = document.createElement("div");
        mainDiv.setAttribute(
          "class",
          "py-1 ring-1 inline-flex justify-center items-center" +
          "ring-gray-300 rounded-full text-sm leading-[18px] bg-white whitespace-nowrap pl-6 pr-9 flex"
        );
        const content = document.createTextNode(`${_getOptionLabel(el)}`);
        mainDiv.appendChild(content);
        document.body.appendChild(mainDiv);
        width = width + mainDiv.offsetWidth;
        if (width < (inputRef.current?.offsetWidth || 0) - 150) {
          contentRef.current = width + 10;
          _index = index + 1;
        }
        document.body.removeChild(mainDiv);
      });
      const _selectedItems = selectedItems;
      if (width < (inputRef.current?.offsetWidth || 0) - 150) {
        setSelected(_selectedItems);
        setExtendsList([]);
      } else {
        const _selected = _selectedItems.slice(0, _index);
        setSelected(_selected);
        setExtendsList(_selectedItems.slice(_selected.length));
      }
    }

  }, [selectedItems]);

  return (
    <Input
      {...getInputProps({ ref: inputRef })}
      onClick={getToggleButtonProps().onClick}
      onFocus={(e) => e}
      {...(selectType === "multiple" && { value: "" })}
      inputClassName={cx(["cursor-pointer", inputClassName])}
      style={{
        ...(selectType === "multiple" && {
          paddingLeft: `${selected?.length
            ? contentRef.current +
            (extendsList.length
              ? extendsList.length.toString().length * 10 + 45
              : selected.length * 2 + 12)
            : 12
            }px`
        })
      }}
      after={
        <span className="pr-2 flex justify-center items-center">
          {loading && <SpinnerLoader className="mr-3" size={20} />}
          <ChevronDownIcon
            className={cx([
              "h-3 w-3 duration-150 transform-gpu ",
              isOpen ? "rotate-180" : "rotate-0"
            ])}
          />
        </span>
      }
      {...(selectType === "multiple" && {
        before: (
          <MultiplePills
            extendsList={extendsList}
            selected={selected}
            disabled={!!props?.disabled}
            getOptionLabel={getOptionLabel}
            onSelectedItemChange={onSelectedItemChange}
          />
        ),
        beforeClassName: props.beforeClassName || "z-40"
      })}
      {...props}
      {...(selectType === "multiple" && {
        readOnly: true,
        placeholder: selected.length ? "" : props.placeholder
      })}
    />
  );
};
export default SelectInput;
