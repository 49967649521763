import { PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import Button from "../../../components/ui/Button";
import IconBtn from "../../../components/ui/IconBtn";
import Input from "../../../components/ui/input/Input";
import Select from "../../../components/ui/input/Select";
import ICreateReservationSchema from "../../../interfaces/endpoints/officeSales/ICreateReservationSchema";
import { PassengerTypeEnum } from "../../../interfaces/endpoints/officeSales/IGetJorneys";

const PassagerFields: React.FC<{ mode: "create" | "edit" }> = ({ mode }) => {
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext<ICreateReservationSchema>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "passagers",
    keyName: "pid"
  });
  const passengerTypeArray = [
    { name: "I/E Rritur", id: PassengerTypeEnum.adult },
    { name: "Fëmijë", id: PassengerTypeEnum.children },
    { name: "Bebe", id: PassengerTypeEnum.baby }
  ];
  return (
    <div className="mt-5">
      {fields.map((fl, i) => {
        return (
          <div key={fl.pid} className="mb-5">
            <p>Pasagjeri {i + 1}</p>
            <div className="grid grid-flow-col gap-4 w-full  mt-3">
              <Controller
                control={control}
                name={`passagers.${i}.type`}
                render={({ fieldState: { error } }) => (
                  <Select
                    error={error?.message}
                    options={passengerTypeArray}
                    {...register(`passagers.${i}.type`)}
                  />
                )}
              />
              <Input
                placeholder="Emri"
                {...register(`passagers.${i}.name`)}
                error={errors.passagers && errors.passagers[i]?.name?.message}
              />
              <Input
                placeholder="Mbiemri"
                {...register(`passagers.${i}.lastName`)}
                error={
                  errors.passagers && errors.passagers[i]?.lastName?.message
                }
              />
              <Input
                placeholder="Passport ID"
                {...register(`passagers.${i}.passportId`)}
                error={
                  errors.passagers && errors.passagers[i]?.passportId?.message
                }
              />
              {mode === "create" ? (
                <div className="w-full flex justify-end pt-2">
                  <IconBtn onClick={() => (i > 0 ? remove(i) : null)}>
                    <TrashIcon className="w-5 h-5" />
                  </IconBtn>
                </div>
              ) : null}
            </div>
          </div>
        );
      })}
      {mode === "create" ? (
        <Button
          onClick={() =>
            append({
              name: "",
              lastName: "",
              passportId: undefined,
              type: undefined
            })
          }
          className="text-primary flex items-center gap-2 transition-colors hover:bg-primary/10"
          btnType="custom"
        >
          <PlusCircleIcon className="w-5 h-5" />
          Shto nje pasagjer te ri
        </Button>
      ) : null}
    </div>
  );
};

export default PassagerFields;
