interface DaySelectProps {
  register: any;
}

const DaySelect: React.FC<DaySelectProps> = ({ register }) => {
  return (
    <>
      <div className="flex-wrap flex gap-7 md:gap-0">
        <div className="md:px-4 flex items-center">
          <input
            type="checkbox"
            id="monday"
            className="cursor-pointer"
            {...register("operationalInterval.monday")}
          />
          <label
            htmlFor="monday"
            className="ml-3 text-sm text-neutral cursor-pointer"
          >
            E Hënë
          </label>
        </div>
        <div className="md:px-4 flex items-center">
          <input
            type="checkbox"
            id="tuesday"
            className="cursor-pointer "
            {...register("operationalInterval.tuesday")}
          />
          <label
            htmlFor="tuesday"
            className="ml-3 text-sm text-neutral cursor-pointer"
          >
            E Martë
          </label>
        </div>
        <div className="md:px-4 flex items-center">
          <input
            type="checkbox"
            id="wednesday"
            className="cursor-pointer "
            {...register("operationalInterval.wednesday")}
          />
          <label
            htmlFor="wednesday"
            className="ml-3 text-sm text-neutral cursor-pointer "
          >
            E Mërkurë
          </label>
        </div>
        <div className="md:px-4 flex items-center">
          <input
            type="checkbox"
            id="thursday"
            className="cursor-pointer "
            {...register("operationalInterval.thursday")}
          />
          <label
            htmlFor="thursday"
            className="ml-3 text-sm text-neutral cursor-pointer"
          >
            E Enjte
          </label>
        </div>
        <div className="md:px-4 flex items-center">
          <input
            type="checkbox"
            id="friday"
            className="cursor-pointer "
            {...register("operationalInterval.friday")}
          />
          <label
            htmlFor="friday"
            className="ml-3 text-sm text-neutral cursor-pointer"
          >
            E Premte
          </label>
        </div>
        <div className="md:px-4 flex items-center">
          <input
            type="checkbox"
            id="saturday"
            className="cursor-pointer "
            {...register("operationalInterval.saturday")}
          />
          <label
            htmlFor="saturday"
            className="ml-3 text-sm text-neutral cursor-pointer"
          >
            E Shtunë
          </label>
        </div>
        <div className="md:px-4 flex items-center">
          <input
            type="checkbox"
            id="sunday"
            className="cursor-pointer "
            {...register("operationalInterval.sunday")}
          />
          <label
            htmlFor="sunday"
            className="ml-3 text-sm text-neutral cursor-pointer"
          >
            E Diel
          </label>
        </div>
      </div>
    </>
  );
};

export default DaySelect;
