/* eslint-disable max-len */
import { FC } from "react";
import { IIcon } from "./IIcon";

const Train: FC<IIcon> = ({ size }) => {
  const currentSize = size || 16;
  return (
    <span>
      <svg
        width={currentSize}
        height={currentSize}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-auto h-auto"
      >
        <path
          d="M14 8.66667C14 6.08667 11.7533 4 7.33333 4H2"
          stroke="currentColor"
          strokeWidth="1.125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 9.9974H12.6667C13.0203 9.9974 13.3594 9.85692 13.6095 9.60687C13.8595 9.35682 14 9.01768 14 8.66406"
          stroke="currentColor"
          strokeWidth="1.125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 4V7.33333H13.6667"
          stroke="currentColor"
          strokeWidth="1.125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 6.66406V9.33073"
          stroke="currentColor"
          strokeWidth="1.125"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="currentColor"
        />
        <path
          d="M5.33325 7.33333V4"
          stroke="currentColor"
          strokeWidth="1.125"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="currentColor"
        />
        <path
          d="M8.66675 7.33594V4.33594"
          stroke="currentColor"
          strokeWidth="1.125"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="currentColor"
        />
        <path
          d="M2 12.6641H14"
          stroke="currentColor"
          strokeWidth="1.125"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

export default Train;
