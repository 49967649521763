import { axios } from "../config/axios";
import { Ijourneys } from "../interfaces/endpoints/Ijourneys";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import { IPagination } from "../interfaces/IPagination";
import {
  IAvailableDates,
  IEditReservationDetails,
  IGetLineId,
  IGetReservationDetails,
  IGetReservationV2,
  IReservationV2
} from "../interfaces/reservations/Reservations.types";
import { serialize } from "../utils/serializeToQueryString";

export type getAllTripsParamsType = {
  pagenumber?: number;
};

export const getReservations = async (
  att: getAllTripsParamsType
): Promise<IAxiosResponse<Ijourneys>> => {
  return axios.get<Ijourneys>(`reservation/journeys${serialize(att)}`);
};

//
// NEW
//
export const getReservations_v2 = async ({
  filterByJourneyDate = false,
  ...att
}: IGetReservationV2): Promise<IAxiosResponse<IPagination<IReservationV2>>> => {
  return axios.get(
    `reservation/journeysV2${serialize({ filterByJourneyDate, ...att })}`
  );
};

export const getReservationsDetails = async ({
  uniqueJourneyId
}: IGetReservationDetails): Promise<
  IAxiosResponse<IReservationV2>
> => {
  return axios.get(`Reservation/JourneyReservation/${uniqueJourneyId}`);
};

export const editReservationsDetails = async (
  data: IEditReservationDetails
): Promise<IAxiosResponse<{}>> => {
  return axios.post("Reservation/JourneyReservation", data);
};

export const availableLines = async ({ lineId, reverse }: IGetLineId):
 Promise<IAxiosResponse<IAvailableDates[]>> => {
  return axios.get(`Route/WeeklyAvailableRoutes?lineId=${lineId}&reverse=${reverse}`);
};

// export const editJourneyTicket_v2 = async ({
//   uniqueJourneyId,
//   body
// }: IChangeJourneyTicket): Promise<IAxiosResponse<any>> => {
//   return axios.put(
//     `reservation/changeJourneyTicketV2${serialize({ uniqueJourneyId })}`,
//     { body }
//   );
// };

// export const changeJourneyStatus_v2 = async ({
//   ...att
// }: IChangeJourneyStatus): Promise<IAxiosResponse<any>> => {
//   return axios.put(`reservation/changeJourneyStatusV2${serialize(att)}`);
// };
