import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import { Link, LinkProps } from "react-router-dom";
import { cx } from "../../utils/classnames";

export interface IBack extends Pick<LinkProps, "to" | "state"> {
  className?: string;
  title: string;
}

const Back: FC<IBack> = ({ className, to, state, title }) => {
  return (
    <Link
      className={cx([
        "inline-flex items-center text-sm font-semibold text-primary group",
        "hover:bg-primary hover:bg-opacity-5 rounded-md px-4 py-[9px] transition-all duration-300",
        className
      ])}
      to={to}
      state={state}
    >
      <span className="relative">
        <ChevronLeftIcon
          strokeWidth={3}
          className="absolute w-4 h-4 mr-1 group-hover:-translate-x-4 translate-x-0 opacity-100
      group-hover:opacity-0
       transition-all duration-300"
        />
        <ChevronLeftIcon
          strokeWidth={3}
          className="w-4 h-4 mr-1 group-hover:translate-x-0 translate-x-4 opacity-0
      group-hover:opacity-100
       transition-all duration-300"
        />
      </span>
      <span>{title}</span>
    </Link>
  );
};

export default Back;
