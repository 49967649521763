import { QuestionMarkCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ReactNode, useState } from "react";
import IconBtn from "../../ui/IconBtn";
import Modal from "../../ui/Modal";

const VideoTutorials = () => {
  const [showVideoList, setShowVideoList] = useState<boolean>(false);

  const videos = [
    {
      title: "Shtoni një linjë",
      link: "https://video.gjirafa.com/embed/deshironi-te-shtoni-nje-linje-te-re?autoplay=true&am=true"
    },
    {
      title: "Shtoni një ofertë",
      link: "https://video.gjirafa.com/embed/deshironi-te-shtoni-nje-oferte-te-re?autoplay=true&am=true"
    },
    {
      title: "Krijoni një rezervim",
      link: "https://video.gjirafa.com/embed/do-beni-nje-rezervim-nga-zyrja-juaj?autoplay=true&am=true"
    }
  ];

  return (
    <>
      <div className="px-4 relative">
        {showVideoList ? (
      <div className="absolute bottom-10 bg-white border left-4 right-4 shadow-lg rounded-lg p-3 w-72 mb-3.5">
            <div className="font-semibold flex items-center justify-between">
              <span>Shikoni video për si të:</span>
              <IconBtn onClick={() => setShowVideoList(false)}>
                <XMarkIcon className="w-5 h-5" />
              </IconBtn>
            </div>
            <div>
              {videos.map((vid, i) => (
                <ModalItem key={i} number={i + 1} link={vid.link}>
                  {vid.title}
                </ModalItem>
              ))}
            </div>
          </div>
        ) : null}
        <div className="flex items-center justify-between overflow-hidden whitespace-nowrap">
          <div className="text-sm text-gray-600 flex gap-1.5 items-center">
            <span>© Mundësuar nga </span>
            <img width={105} src="/logo.svg" className="" alt="" />
          </div>
          <span
            onMouseOver={() => setShowVideoList(true)}
            className="p-3 cursor-pointer flex items-center justify-center sticky right-0 bg-white rounded-lg"
          >
            <QuestionMarkCircleIcon className="w-6 h-6 text-gray-600" />
          </span>
        </div>

      </div>
    </>
  );
};

export default VideoTutorials;

const ModalItem: React.FC<{
  children: ReactNode;
  number: number;
  link: string;
}> = ({ children, number, link }) => {
  const [showVideo, setShowVideo] = useState<boolean>(false);
  return (
    <>
      <div
        onClick={() => setShowVideo(true)}
        className="flex px-2 py-3 items-center rounded-md hover:bg-gray-200 cursor-pointer"
      >
        <span
          className="text-primary rounded-full border border-primary w-5 h-5 flex
     items-center justify-center mr-3"
        >
          {number}
        </span>
        <div>{children}</div>
      </div>
      <Modal
        position="center"
        show={showVideo}
        title={children}
        onClose={() => setShowVideo(false)}
      >
        <div
          style={{ paddingTop: "56.25%", position: "relative", width: "100%" }}
        >
          <iframe
            className="no-margin"
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%"
            }}
            src={link}
            frameBorder="0"
            allow="autoplay"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>
    </>
  );
};
