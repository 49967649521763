import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import {
  OfficeSalesContext,
  tripType
} from "../../../context/OfficeSalesContext";
import { IAxiosResponse } from "../../../interfaces/IAxiosResponse";
import { IPagination } from "../../../interfaces/IPagination";
import Routes from "../../../interfaces/endpoints/officeSales/IRoutes";
import { responseType } from "../../../interfaces/hooks/IFetch";
import { OfficeReservationSchema } from "../../../pages/office/ChooseLine";
import Button from "../../ui/Button";
import Table, { Row } from "../../ui/table";
import Departure from "./DepartureCol";

interface ILines {
  lines: responseType<IAxiosResponse<IPagination<Routes>>>;
  lineType: tripType;
}

const Lines: React.FC<ILines> = ({ lines, lineType }) => {
  const departureText = lineType === "departure" ? "Nisja" : "Kthimi";
  const rows = [departureText, "Cmimi", ""];
  const { getValues } = useFormContext<OfficeReservationSchema>();
  const { selectedTripId, setSelectedTripId, setData } =
    useContext(OfficeSalesContext);

  const selectTrip = (trips: Routes) => {
    const trip = trips.paths[0];
    setData((prevState) => ({
      ...prevState,
      date: {
        from: getValues("date.from"),
        to: getValues("date.to")
      },
      price: trip.price,
      passagers: {
        adult: getValues("passagers.adult"),
        children: getValues("passagers.children"),
        baby: getValues("passagers.baby")
      },
      [lineType]: {
        arrivalLocation: trip.arrivalCityName,
        arrivalTime: trip.arrivalTime,
        departureLocation: trip.departureCityName,
        departureTime: trip.departureTime,
        id: trip.tripId,
        stationFromId: trip.stationFromId,
        stationToId: trip.stationToId,
        price: trip.price
      }
    }));
    setSelectedTripId((prevSelectedTripId) => ({
      ...prevSelectedTripId,
      [lineType]: trip.tripId
    }));
  };

  return (
    <div>
      <Table titles={rows}>
        {lines ? (
          lines?.data.result.map((row) => (
            <Row
              key={row.paths[0].tripId}
              departure={
                <Departure
                  fromLocation={{
                    station: row.paths[0].departureCityName,
                    time: row.paths[0].departureTime
                  }}
                  toLocation={{
                    station: row.paths[0].arrivalCityName,
                    time: row.paths[0].arrivalTime
                  }}
                />
              }
              price={
                <Col first={`${row.paths[0].price}€`} second="per person" />
              }
              button={
                <>
                  {selectedTripId[lineType] === row.paths[0].tripId ? (
                    <Button
                      fontWeight="500"
                      className="border border-transparent outline-none"
                    >
                      U zgjodh
                    </Button>
                  ) : (
                    <Button
                      onClick={() => selectTrip(row)}
                      btnType="custom"
                      fontWeight="500"
                      className="text-primary border border-primary outline-none"
                    >
                      Zgjedh
                    </Button>
                  )}
                </>
              }
            />
          ))
        ) : (
          <></>
        )}
      </Table>
    </div>
  );
};

export default Lines;

type colProps = {
  first: string;
  second: string;
};

const Col: React.FC<colProps> = ({ first, second }) => (
  <div>
    <p className="text-primary font-semibold text-lg">{first}</p>
    <p className="text-gray text-sm">{second}</p>
  </div>
);
