import HeaderPage from "../../components/lines-managements/header/HeaderPage";
import TripNoticesTable from "../../components/trip-notices";
import useCan from "../../components/users/permissions/useCan";

const TripNotices: React.FC = () => {
  const can = useCan("tripnotices.tripnotice.create");
  return (
    <>
      <HeaderPage
        {...(can && {
          link: {
            to: "create",
            children: "Shto informatë të re për orarin",
            state: ["Informata për oraret", "Shto informatë të re për orarin"]
          }
        })}
      />
      <TripNoticesTable />
    </>
  );
};

export default TripNotices;
