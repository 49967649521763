import { array, number, object, string } from "yup";

export const InviteUserCreateSchema = object().shape({
  email: string().required("Vendos Email-in!").email("Vendos nje email valid!"),
  operator: object().shape({
    id: number().typeError("Vendos operatorin!").required("Vendos operatorin!")
  }),
  roles: array()
    .required("Duhet te kete se paku nje role!")
    .min(1, "Duhet te kete se paku nje role!")
});

export const UserSchema = object().shape({
  roles: array()
    .required("Duhet te kete se paku nje role!")
    .min(1, "Duhet te kete se paku nje role!")
});
