import { IReturnTypeToken } from "../../interfaces/IReturnTypeToken";

export const getToken = (): IReturnTypeToken => {
  const user = localStorage.getItem(
    `oidc.user:${process.env.REACT_APP_AUTHORITY_API_URL}:${process.env.REACT_APP_AUTHORITY_CLIENT_ID}`
  );
  if (typeof user === "string") {
    const _user = JSON.parse(user || "");

    if (!_user?.access_token) return null;
    return _user;
  }

  return null;
};
