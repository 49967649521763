import {
  CheckCircleIcon,
  PencilIcon,
  XCircleIcon
} from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { FC } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { UserType } from "../../interfaces/endpoints/IUser.types";
import { deleteUserById } from "../../services/Users.service";
import DeleteModal from "../ui/DeleteModal";
import IconBtn from "../ui/IconBtn";
import { Row } from "../ui/table";
import Can from "./permissions/Can";

interface IUserRow {
  row: UserType;
  className?: string;
  pageIndex: number;
  onDeleteSuccess: () => void;
}

const UserRow: FC<IUserRow> = ({ row, className, pageIndex, onDeleteSuccess }) => {
  return (
    <Row
      key={`${row.id}`}
      name={`${row.firstName} ${row.lastName}`}
      email={row.userName}
      opertorName={row.operator?.operatorName}
      role={
        <>
          <span
            data-tooltip-id={`${row.id}`}
            data-tooltip-content={row.usersRolesMappings
              .map((el) => el.role.roleName)
              .join(",")}
            data-tooltip-variant="dark"
            data-tooltip-float={true}
          >
            {row.usersRolesMappings[0]?.role?.roleName}
            {row.usersRolesMappings.length > 1 && (
              <span className="rounded-full py-1 px-2 mx-1.5 text-primary ring-1 ring-gray-200 bg-white">
                +{row.usersRolesMappings.length - 1}
              </span>
            )}
          </span>
          <Tooltip id={`${row.id}`} />
        </>
      }
      insertDate={dayjs.utc(row.insertDate).tz().format("DD/MM/YYYY")}
      active={
        <div>
          {row.isActive ? (
            <IconBtn className="w-4 h-4">
              <CheckCircleIcon className="w-6 h-6 text-blue" />
            </IconBtn>
          ) : (
            <IconBtn className="w-4 h-4">
              <XCircleIcon className="w-6 h-6 text-red" />
            </IconBtn>
          )}
        </div>
      }
      className={`${className} group`}
      actions={
        <span className="flex items-center">
          <Can permissions="users.user.update">
            <Link
              to={`/users/edit/${row.id}`}
              state={{ crumbs: [
                "User overview",
                "Users",
                `${row.firstName} ${row.lastName}`
              ], page: pageIndex }}

            >
              <IconBtn className="w-6 h-6">
                <PencilIcon className="w-4 h-4 text-neutral" />
              </IconBtn>
            </Link>
          </Can>
          <Can permissions="users.user.delete">
            <DeleteModal
              params={row.id}
              title="Konfirmo fshirjen e userit."
              handleDelete={deleteUserById}
              successMessage="Useri eshte fshire me sukses"
              errorMessage="Useri nuk është fshire me sukses."
              loadingMessage="Useri është duke u fshire..."
              onSuccess={onDeleteSuccess}
            />
          </Can>
        </span>
      }
    />
  );
};

export default UserRow;
