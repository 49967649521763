import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import {
  CaptionProps,
  useNavigation,
  useSelectRange,
  useSelectSingle
} from "react-day-picker";
import { cx } from "../../../utils/classnames";

export type mode = "range" | "single" | "default" | "multiple";

const CustomCaption = (
  props: CaptionProps & { mode?: mode; numberOfMonths?: number; hasHelpers?: boolean }
) => {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();
  const single = useSelectSingle();
  const multipe = useSelectRange();

  return (
    <div
      className={cx([
        "flex flex-col",
        props.displayIndex === 1 && props.hasHelpers && "-mr-[140px]"
      ])}
    >
      <div className="flex justify-between items-center my-3 mx-6 z-10">
        {Number(props.numberOfMonths || 1) >= 1 && props.displayIndex === 0 ? (
          <button
            disabled={!previousMonth}
            type="button"
            onClick={() => previousMonth && goToMonth(previousMonth)}
            className="w-8 h-8 ring-1 ring-gray-300
           duration-300 rounded-full flex items-center justify-center hover:ring-gray-500"
          >
            <ChevronLeftIcon className="w-4 h-4" />
          </button>
        ) : null}
        <p className="flex-1 flex justify-center text-lg font-semibold">
          {format(props.displayMonth, "MMM yyy")}
        </p>
        {(
          Number(props.numberOfMonths || 1) > 1
            ? props.displayIndex === 1
            : true
        ) ? (
          <button
            disabled={!nextMonth}
            type="button"
            onClick={() => nextMonth && goToMonth(nextMonth)}
            className="w-8 h-8 ring-1 ring-gray-300
          duration-300 rounded-full flex items-center justify-center hover:ring-gray-500"
          >
            <ChevronRightIcon className="w-4 h-4" />
          </button>
        ) : null}
      </div>
      {props.displayIndex === 0 ? (
        <div className="my-1 ml-8 flex items-center gap-2 -mr-20">
          {props.mode === "single" ? (
            <>
              {" "}
              <p className="text-sm text-neutral-300 whitespace-nowrap">
                Data:
              </p>
              <div className="px-3 py-2 rounded border border-gray-200 text-sm">
                {single.selected ? format(single.selected, "P") : "dd/mm/yyyy"}
              </div>
            </>
          ) : null}
          {props.mode === "range" ? (
            <>
              <p className="text-sm text-neutral-300 whitespace-nowrap">
                Datë tjetër:
              </p>
              <div className="px-3 py-2 rounded border border-gray-200 text-sm">
                {multipe.selected?.from ? (
                  format(multipe.selected?.from, "P")
                ) : (
                  <span className="text-gray-400">dd/mm/yyyy</span>
                )}
              </div>
              <p className="text-sm text-neutral-300">deri</p>
              <div className="px-3 py-2 rounded border border-gray-200 text-sm">
                {multipe.selected?.to ? (
                  format(multipe.selected?.to, "P")
                ) : (
                  <span className="text-gray-400">dd/mm/yyyy</span>
                )}
              </div>
            </>
          ) : null}
        </div>
      ) : (
        <div className="h-[47.5px] invisible" />
      )}
    </div>
  );
};

export default CustomCaption;
