interface IconBtnProps {
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
}

const IconBtn: React.FC<IconBtnProps> = ({ children, className, onClick }) => {
  return (<span onClick={onClick} className={`${className} transition-colors hover:bg-gray-200 cursor-pointer
       rounded-full flex items-center justify-center w-8 h-8 select-none`}>{children}</span>);
};

export default IconBtn;
