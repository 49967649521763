import HeaderPage from "../../../components/lines-managements/header/HeaderPage";
import Trips from "../../../components/lines-managements/trips/Trips";
import useCan from "../../../components/users/permissions/useCan";

const TripsPage: React.FC = () => {
  const can = useCan("route.trip.create");
  return (
    <>
      <HeaderPage
        {...(can && {
          link: {
            to: "create",
            children: "Shto orar të ri",
            state: ["Menaxhimi i linjave", "Oraret", "Shto orar të ri"]
          }
        })}
      />
      <Trips />
    </>
  );
};

export default TripsPage;
