import { array, number, object, string } from "yup";

export const LocationsSchema = object().shape({
  // city: object().shape({
  //   postalCode: string().required("Vendos kodin postar!"),
  //   longitude: string().required("Vendos koordinatat e qytetit!"),
  //   latitude: string().required("Vendos koordinatat e qytetit!"),
  //   locales: array()
  //     .of(
  //       object().shape({
  //         languageId: number().required("Vendos ID e gjuhës!"),
  //         name: string().required("Vendos emrin e qytetit!")
  //       })
  //     )
  //     .when("cityId", {
  //       is: (cityId: any) => cityId === null,
  //       then: array().required("Vendos lokalet e qytetit!"),
  //       otherwise: array().notRequired()
  //     })
  // }),
  // cityId: object()
  //   .when("city", {
  //     is: (city: any) => !city || city?.cityName?.trim().length === 0,
  //     then: object().required("Vendos qytetin!"),
  //     otherwise: object().notRequired()
  //   })
  //   .shape({
  //     id: number().required("Vendos ID e qytetit!")
  //   }),
  // country: object().shape({
  //   locales: array()
  //     .of(
  //       object().shape({
  //         languageId: number().required("Vendos ID e gjuhës!"),
  //         name: string().required("Vendos emrin e shtetit!")
  //       })
  //     )
  //     .when("countryId", {
  //       is: (countryId: any) => countryId === null,
  //       then: array().required("Vendos lokalet e shtetit!"),
  //       otherwise: array().notRequired()
  //     })
  // }),
  // countryId: object()
  //   .when("country", {
  //     is: (country: any) =>
  //       !country || country?.countryName?.trim().length === 0,
  //     then: object().required("Vendos shtetin!"),
  //     otherwise: object().notRequired()
  //   })
  //   .shape({
  //     id: number().required("Vendos ID e shtetit!")
  //   }),
  station: object().shape({
    stationRank: object().required("Vendos rangun e stacionit!"),
    // bizSlug: string().required("Vendos Biz Slug!"),
    latitude: string().required("Vendos koordinatat e stacionit!"),
    longitude: string().required("Vendos koordinatat e stacionit!"),
    locales: array()
      .of(
        object().shape({
          languageId: number().required("Vendos ID e gjuhës!"),
          name: string().required("Vendos emrin e stacionit!")
        })
      )
      .required("Vendos lokalet e stacionit!")
  })
});
