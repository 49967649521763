import { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import useFetch from "../../../hooks/useFetch";
import { getAllPrices } from "../../../services/Pricing.service";
import PricingTable from "./PricingTable";
import SearchTrip from "./SearchTrip";

export type Inputs = {
  tripId: number;
  ageGroupId: number;
  isTwoWayTrip: boolean;
  lineId: number;
};

const Prices: React.FC = () => {
  const [tripParams, setTripParams] = useState<Inputs>({
    tripId: 0,
    ageGroupId: 0,
    lineId: 0,
    isTwoWayTrip: true
  });
  const { response, handleFunc, loading } = useFetch(getAllPrices, {
    initRequest: false
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    void handleFunc({
      tripId: data.tripId,
      ageGroupId: data.ageGroupId,
      lineId: data.lineId,
      isTwoWayTrip: data.isTwoWayTrip
    });
    setTripParams({
      lineId: data.lineId,
      tripId: data.tripId,
      ageGroupId: data.ageGroupId,
      isTwoWayTrip: data.isTwoWayTrip
    });
  };
  const methods = useForm<Inputs>();

  return (
    <>
      <FormProvider {...methods}>
        <SearchTrip onSubmit={onSubmit} />
        <div className={`relative ${loading ? "opacity-50" : "-mx-3 md:mx-0"}`}>
          {loading && (
            <div className="absolute inset-0 flex items-center justify-center">
              Duke u mbushur të dhënat...
            </div>
          )}
          {!loading && (
            <PricingTable data={response?.data} tripParams={tripParams} />
          )}
        </div>
      </FormProvider>
    </>
  );
};

export default Prices;
