import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";
import Animation from "../ui/Animation";

const BreadCrumbs: React.FC = () => {
  const { state }: string[] | any = useLocation();

  let crumbs: string[];
  if(Array.isArray(state)) crumbs = state;
  else if(state === "" || state === null) crumbs = ["Balina"];
  else crumbs = state.crumbs;

  return (
    <div>
      <p>{crumbs ? crumbs[0] : "Ballina"}</p>
      <small className="flex items-center">
        {crumbs && crumbs?.map((el: string, i: number) =>
          <Animation
            type="fade-left"
            show={true}
            delay={100 * i}
            duration={300}
            key={i}
          >
            <span className="flex items-center">
              <span className={`${crumbs?.length - 1 === i && "text-primary font-semibold"}`}>{el}</span>
              {crumbs?.length - 1 !== i && <ChevronRightIcon className="w-3 h-3 mx-2 text-gray-600" />}
            </span>
          </Animation>
        )}
      </small>
    </div>
  );
};

export default BreadCrumbs;
