/* eslint-disable max-len */
import { FC } from "react";
import { IIcon } from "./IIcon";

const Bus: FC<IIcon> = ({ color, size }) => {
  const currentColor = color || "currentColor";
  const currentSize = size || 16;
  return (
    <span>
      <svg
        width={currentSize}
        height={currentSize}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-auto h-auto"
      >
        <path
          d="M5.625 12C6.24632 12 6.75 11.4963 6.75 10.875C6.75 10.2537 6.24632 9.75 5.625 9.75C5.00368 9.75 4.5 10.2537 4.5 10.875C4.5 11.4963 5.00368 12 5.625 12Z"
          fill={currentColor}
        />
        <path
          d="M13.5 10.875C13.5 11.4963 12.9963 12 12.375 12C11.7537 12 11.25 11.4963 11.25 10.875C11.25 10.2537 11.7537 9.75 12.375 9.75C12.9963 9.75 13.5 10.2537 13.5 10.875Z"
          fill={currentColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.4375 4.5C2.4375 2.9467 3.6967 1.6875 5.25 1.6875H12.75C14.3033 1.6875 15.5625 2.9467 15.5625 4.5V12C15.5625 12.6405 15.2706 13.2128 14.8125 13.5911V15C14.8125 15.7249 14.2249 16.3125 13.5 16.3125H12.75C12.0251 16.3125 11.4375 15.7249 11.4375 15V14.0625H6.5625V15C6.5625 15.7249 5.97487 16.3125 5.25 16.3125H4.5C3.77513 16.3125 3.1875 15.7249 3.1875 15V13.5911C2.72944 13.2128 2.4375 12.6405 2.4375 12V4.5ZM3.5625 4.5C3.5625 3.56802 4.31802 2.8125 5.25 2.8125H12.75C13.682 2.8125 14.4375 3.56802 14.4375 4.5V7.6875H3.5625V4.5ZM12.5625 14.0625V15C12.5625 15.1036 12.6464 15.1875 12.75 15.1875H13.5C13.6036 15.1875 13.6875 15.1036 13.6875 15V14.0541C13.6257 14.0597 13.5632 14.0625 13.5 14.0625H12.5625ZM13.5 12.9375C14.0178 12.9375 14.4375 12.5178 14.4375 12V8.8125H3.5625V12C3.5625 12.5178 3.98223 12.9375 4.5 12.9375H13.5ZM5.4375 15V14.0625H4.5C4.4368 14.0625 4.37426 14.0597 4.3125 14.0541V15C4.3125 15.1036 4.39645 15.1875 4.5 15.1875H5.25C5.35355 15.1875 5.4375 15.1036 5.4375 15Z"
          fill={currentColor}
        />
      </svg>
    </span>
  );
};

export default Bus;
