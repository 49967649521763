/* eslint-disable max-len */

import { FC } from "react";
import { IIcon } from "./IIcon";

const Airpod: FC<IIcon> = ({ color, size }) => {
  const currentColor = color || "currentColor";
  const currentSize = size || 16;
  return (
    <span>
      <svg
        width={currentSize}
        height={currentSize}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-auto h-auto"
      >
        <path
          d="M11.3402 17.0566H6.66016C3.63766 17.0566 1.83016 16.4641 1.50016 13.4266C1.44016 12.9916 1.41016 12.4741 1.41016 11.8066V6.18164C1.41016 5.53664 1.44016 5.00414 1.50016 4.55414C1.84516 1.50914 3.57766 0.931641 6.66766 0.931641H11.3552C14.4452 0.931641 16.1852 1.50914 16.5152 4.56914C16.5902 5.07164 16.6052 5.64164 16.6052 6.18164V11.8066C16.6052 12.4591 16.5752 12.9766 16.5152 13.4416C16.1702 16.4791 14.4302 17.0566 11.3402 17.0566ZM6.66016 2.05664C3.47266 2.05664 2.82766 2.74664 2.61016 4.68914C2.55016 5.10164 2.52766 5.58914 2.52766 6.18164V11.8066C2.52766 12.4216 2.55766 12.8866 2.61016 13.2841C2.82766 15.2416 3.46516 15.9316 6.66016 15.9316H11.3402C14.5277 15.9316 15.1727 15.2416 15.3902 13.3066C15.4427 12.8941 15.4727 12.4066 15.4727 11.8066V6.18164C15.4727 5.68664 15.4652 5.16164 15.3977 4.71164C15.1802 2.74664 14.5427 2.05664 11.3477 2.05664H6.66016Z"
          fill={currentColor}
        />
        <path
          d="M15.9197 7.24609H12.8447C12.5372 7.24609 12.2822 6.99109 12.2822 6.68359C12.2822 6.37609 12.5372 6.12109 12.8447 6.12109H15.9197C16.2272 6.12109 16.4822 6.37609 16.4822 6.68359C16.4822 6.99109 16.2272 7.24609 15.9197 7.24609Z"
          fill={currentColor}
        />
        <path
          d="M5.15117 7.24609H2.07617C1.76867 7.24609 1.51367 6.99109 1.51367 6.68359C1.51367 6.37609 1.76867 6.12109 2.07617 6.12109H5.15117C5.45867 6.12109 5.71367 6.37609 5.71367 6.68359C5.71367 6.99109 5.45867 7.24609 5.15117 7.24609Z"
          fill={currentColor}
        />
        <path
          d="M11.3128 8.78203H6.69277C6.13027 8.78203 5.59027 8.55703 5.20027 8.15203C4.81027 7.77703 4.59277 7.24453 4.59277 6.68203C4.59277 5.52703 5.53777 4.58203 6.69277 4.58203H11.3053C11.8678 4.58203 12.4078 4.81453 12.7978 5.21203C13.1878 5.59453 13.4128 6.11953 13.4128 6.68203C13.4128 7.83703 12.4678 8.78203 11.3128 8.78203ZM6.69277 5.70703C6.15277 5.70703 5.71777 6.14203 5.71777 6.68203C5.71777 6.94453 5.81527 7.18453 5.99527 7.35703C6.18277 7.55203 6.43027 7.65703 6.69277 7.65703H11.3128C11.8528 7.65703 12.2878 7.22203 12.2878 6.68203C12.2878 6.42703 12.1828 6.18703 12.0028 6.00703C11.8153 5.81203 11.5678 5.70703 11.3053 5.70703H6.69277Z"
          fill={currentColor}
        />
      </svg>
    </span>
  );
};

export default Airpod;
