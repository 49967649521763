import { Controller, useFormContext } from "react-hook-form";
import Input from "../../../components/ui/input/Input";
import Select from "../../../components/ui/input/Select";
import ICreateReservationSchema, { OfficePaymentMethodEnum } from "../../../interfaces/endpoints/officeSales/ICreateReservationSchema";

const Information = () => {
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext<ICreateReservationSchema>();

  const paymentMethod = [
    { name: "Cash", id: OfficePaymentMethodEnum.Cash },
    { name: "POS", id: OfficePaymentMethodEnum.POS },
    { name: "Paguaj në autobus", id: OfficePaymentMethodEnum.Bus }
  ];

  return (
    <div>
      <p className="text-xl mb-2 py-3 border-t mt-4">Informacionet</p>
      <div className="grid grid-flow-col gap-4">
        <Input
          label="Numer telefoni"
          placeholder="049123123"
          {...register("phoneNumber")}
          error={errors && errors.phoneNumber?.message}
        />
        <Input
          label="E-mail:"
          placeholder="Shkruaj Emailin"
          {...register("email")}
          error={errors && errors.email?.message}
        />
        <Controller
          name={"paymentMethod"}
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => {
            return (
              <Select
                error={error && error.message}
                onChange={onChange}
                label="Menyra e pageses"
                value={value}
                options={paymentMethod}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default Information;
