import { useCombobox, UseComboboxStateChange } from "downshift";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useInfiniteScroll } from "../../../../hooks/useInfiniteScroll";
import { IAxiosResponse } from "../../../../interfaces/IAxiosResponse";
import { IPagination } from "../../../../interfaces/IPagination";
import { IList, ISelectHookAsync, ISelectHookOptions } from "../Select.types";
import { instanceOfBaseType } from "../utils";
type handleParams<T> = {
  search?: string;
  prev: boolean;
  params?: T;
};
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const useSelect = <T, D extends unknown>({
  asyncFunc,
  emptyItem,
  pageSize,
  options,
  searchFunc,
  searchQuerySelector,
  itemToString,
  dataSelector = "result",
  extraParams,
  disabled
}: ISelectHookAsync<T, D> | ISelectHookOptions<T, D>) => {
  const [items, setItems] = useState<IList<T>["items"]>([]);
  const [totalPages, setTotalpages] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const _asyncFunc = (params: D): Promise<IAxiosResponse<IPagination<T>>> => {
    if (asyncFunc) return asyncFunc(params);
    else {
      return new Promise((resolve) => {
        resolve({
          status: 200,
          statusText: "200",
          data: { result: [], [dataSelector]: options, totalPages: 1 }
        });
      });
    }
  };
  const { page, resetPage, handleScroll } = useInfiniteScroll({
    loading,
    totalPages
  });

  const _handleFunc = useCallback(
    async ({ search, prev: _prev, params }: handleParams<D>) => {
      setLoading(true);
      const res = await _asyncFunc({
        ...(params && params),
        pageSize: pageSize || 40,
        pageIndex: page,
        ...(search?.length
          ? {
              [searchQuerySelector || "searchKeyword"]:
                encodeURIComponent(search)
            }
          : {})
      } as D);
      if (res?.status === 200) {
        setItems((prev) => [
          ...(emptyItem && page === 1 ? [emptyItem] : []),
          ...(_prev && page !== 1 ? prev : ([] as IList<T>["items"])),
          ...(res?.data[dataSelector as "result"] || [])
        ]);
      } else setItems([]);
      setLoading(false);
      setTotalpages(res.data.totalPages);
    },
    [page]
  );
  useEffect(() => {
    if (!disabled) {
      if (extraParams) resetPage();
      void _handleFunc({ prev: true, params: extraParams });
    }
  }, [_handleFunc, disabled, JSON.stringify(extraParams)]);

  const debounceHandleFunc = useMemo(
    () => debounce((params: handleParams<D>) => _handleFunc(params), 1000),
    []
  );
  const _searchFunc = (search?: string) => {
    let condition: boolean = false;
    setItems(() => [
      ...(emptyItem ? [emptyItem] : []),
      ...(options?.filter((o) => {
        if (instanceOfBaseType(o, "label"))
          condition = o.label
            .toLocaleLowerCase()
            .includes(search?.toLocaleLowerCase() || "");
        if (searchFunc) condition = searchFunc(o, search);
        return !search || condition;
      }) || [])
    ]);
  };
  const onInputValueChange = ({
    inputValue,
    ...props
  }: UseComboboxStateChange<T>) => {
    if (props.type === useCombobox.stateChangeTypes.InputChange) {
      if (asyncFunc) {
        setLoading(true);
        resetPage();
        void debounceHandleFunc({
          search: inputValue || "",
          prev: false,
          params: extraParams
        });
      } else if (options?.length) _searchFunc(inputValue);
    }
  };

  const _itemToString = (o: T | null) => {
    if (instanceOfBaseType(o, "label")) return o.label || "";
    if (itemToString) return itemToString(o) || "";
    return "";
  };

  return {
    items,
    setItems,
    onInputValueChange,
    handleScroll,
    loading,
    itemToString: _itemToString
  };
};
export default useSelect;
