/* eslint-disable max-lines */
import { format, parse } from "date-fns";
import { ReactNode, forwardRef, useEffect, useRef, useState } from "react";
import useDashboards from "../../hooks/useDashboards";
import useFetch from "../../hooks/useFetch";
import { OperatorFilteredAnalyticsListModel } from "../../interfaces/endpoints/IAnalytics.types";
import { getOperatorFilteredAnalytics } from "../../services/Analytics.service";
import { cx } from "../../utils/classnames";
import LinearGraph from "./LinearGraph";

const FilteredAnalytics = () => {
  const parentRef = useRef<HTMLDivElement>(null);
  const firstRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState({ left: 0, right: 0 });
  const [style, setStyle] = useState<"type-right" | "type-left">("type-right");
  const [active, setActive] =
    useState<keyof OperatorFilteredAnalyticsListModel>("sales");

  const { line, from, to } = useDashboards();

  const { response, loading, handleFunc } = useFetch(
    getOperatorFilteredAnalytics,
    { loading: true,resetResponseOnError: true  }
  );

  useEffect(() => {
    if (from && to) {
      void handleFunc({
        startDate: format(parse(from, "dd/MM/yyyy", new Date()), "MM-dd-yyyy"),
        endDate: format(parse(to, "dd/MM/yyyy", new Date()), "MM-dd-yyyy"),
        lineId: line?.value || undefined
      });
    }
  }, [from, to, line]);

  useEffect(() => {
    if (parentRef.current && firstRef.current) {
      const left =
        firstRef.current.getBoundingClientRect().left -
        parentRef.current?.getBoundingClientRect().left;
      const right = -(
        firstRef.current.getBoundingClientRect().right -
        parentRef.current.getBoundingClientRect().right
      );

      if (state.left > left) {
        setStyle("type-left");
      } else setStyle("type-right");

      setState({
        left,
        right
      });
    }
  }, []);

  const onClick = (
    e: React.MouseEvent<HTMLDivElement>,
    type: keyof OperatorFilteredAnalyticsListModel
  ) => {
    if (parentRef.current) {
      const left =
        e.currentTarget.getBoundingClientRect().left -
        parentRef.current?.getBoundingClientRect().left;
      const right = -(
        e.currentTarget.getBoundingClientRect().right -
        parentRef.current?.getBoundingClientRect().right
      );

      if (state.left > left) {
        setStyle("type-left");
      } else setStyle("type-right");
      setState({
        left,
        right
      });
      setActive(type);
    }
  };

  return (
    <>
      <style>{`
    .type-left {

      transition: right 0.4s ease 85ms, left 0.4s ease;
    }
    .type-right {

      transition: left 0.4s ease 85ms, right 0.4s ease;
    }
  `}</style>
      <div className="rounded-lg border border-gray-200 flex-1">
        <div className="flex gap-0.5 p-0.5 relative" ref={parentRef}>
          <Item
            loading={loading}
            onClick={onClick}
            value={<> {response?.data.sales.amount} €</>}
            ref={firstRef}
            title="Totali i shitjeve"
            type="sales"
          />
          <div className="border-l border-gray-100 my-4" />
          <Item
            loading={loading}
            onClick={onClick}
            title="Numri i biletave"
            value={response?.data.nrOfTickets.amount}
            type="nrOfTickets"
          />
          <div className="border-l border-gray-100 my-4" />
          <Item
            loading={loading}
            onClick={onClick}
            title="Numri i rezervimeve"
            value={response?.data.nrOfReservations.amount}
            type="nrOfReservations"
          />
          <div className="border-l border-gray-100 my-4" />
          <Item
            loading={loading}
            onClick={onClick}
            title="BILETAT E ANULUARA"
            value={response?.data.nrOfCanceledTickets.amount}
            type="nrOfCanceledTickets"
          />
        </div>
        <div className="border-t border-gray-100 relative">
          <span
            className={cx([
              "absolute w-auto  h-1 bg-primary rounded-full -top-1 transition-all duration-300",
              style
            ])}
            style={{ left: `${state.left}px`, right: `${state.right}px` }}
          />
        </div>
        <div className="mx-6 mt-6 mb-10">
          <p className="text-sm font-medium flex items-center gap-2 mb-4">
            <span className="h-1.5 w-1.5 bg-primary rounded-full" />
            Totali i shitjeve
          </p>
          <div className="w-full h-[165px]">
            <LinearGraph
              loading={loading}
              type={active}
              data={response?.data[active].distributionPerDay}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default FilteredAnalytics;

const Item = forwardRef<
  HTMLDivElement,
  {
    onClick: (
      e: React.MouseEvent<HTMLDivElement>,
      type: keyof OperatorFilteredAnalyticsListModel
    ) => void;
    loading: boolean;
    value: ReactNode;
    title: string;
    type: keyof OperatorFilteredAnalyticsListModel;
  }
>(({ loading, onClick, value, title, type }, ref) => (
  <div
    className="px-5 py-4 flex flex-col gap-3.5 duration-300 rounded-lg
 hover:bg-gray-50 cursor-pointer w-full justify-between"
    onClick={(e) => onClick(e, type)}
    ref={ref}
  >
    <p className="text-2xs text-neutral font-medium uppercase">{title}</p>
    {loading ? (
      <span className="bg-gray-200 h-8 w-full rounded-lg" />
    ) : (
      <span className="text-2xl font-medium whitespace-nowrap">{value}</span>
    )}
  </div>
));
