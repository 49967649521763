import HeaderPage from "../../../components/lines-managements/header/HeaderPage";
import OperatorsTable from "../../../components/operators";
import useCan from "../../../components/users/permissions/useCan";

const Operators: React.FC = () => {
  const can = useCan("operators.operator.create");
  return (
    <>
      <HeaderPage
        {...(can && {
          link: {
            to: "create",
            children: "Shto operator të ri",
            state: ["Operatorët", "Shto operator të ri"]
          }
        })}
      />
      <OperatorsTable />
    </>
  );
};

export default Operators;
