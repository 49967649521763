import { number, object, ref, string } from "yup";

const AnnouncementSchema = object().shape({
  title: string().required("Vendos titullin"),
  description: string().required("Vendos përshkrimin"),
  stationFrom: number()
    .required("Zgjedh stacionin")
    .notOneOf([ref("stationTo")], "Stacioni duhet te jete ndryshe"),
  stationTo: number()
    .required("Zgjedh stacionin")
    .notOneOf([ref("stationFrom")], "Stacioni duhet te jete ndryshe"),
  languageId: string().required("Zgjedh gjuhën"),
  // languageId: string().required("required laungaue"),
  announcementDate: string().required("Vendos datat")
});
export default AnnouncementSchema;
