import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Checkbox from "../../../components/ui/input/Checkbox";
import { Row } from "../../../components/ui/table";
import useSuperAdmin from "../../../components/users/permissions/useSuperAdmin";
import { PermissionType } from "../../../interfaces/endpoints/IPermission.types";
import { IUserRoleSchema } from "../../../interfaces/endpoints/IUserRoles.types";

interface IAccessControlRow {
  row: PermissionType;
  disabled: boolean;
  displaySystemName: boolean;
  userRoles: IUserRoleSchema[];
}

const AccessControlRow: FC<IAccessControlRow> = ({
  row,
  disabled,
  displaySystemName,
  userRoles
}) => {
  const { control } = useFormContext();
  const canSuperAdmin = useSuperAdmin();
  let roles = {};
  row.permissionRoles.forEach(
    (pR) =>
      (roles = {
        ...roles,
        [`${row.name}_${pR.userRoleId}_${row.id}`]: (
          <div className="flex justify-center">
            <Controller
              name={`role_${row.id}_${pR.userRoleId}.selected`}
              control={control}
              render={({ field: { value, name, onChange } }) => {
                return (
                  <Checkbox
                    disabled={
                      disabled ||
                      (!canSuperAdmin &&
                        !!userRoles.find((role) => role.id === pR.userRoleId)
                          ?.isSystemRole)
                    }
                    name={name}
                    onChange={onChange}
                    checked={value}
                  />
                );
              }}
            />
          </div>
        )
      })
  );
  return (
    <>
      <Row
        key={`${row.id}_${row.name}_${row.id}`}
        title={
          <div className="w-64 truncate">
            {displaySystemName ? row.systemName : row.name}
          </div>
        }
        {...roles}
      />
    </>
  );
};

export default AccessControlRow;
