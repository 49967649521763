/* eslint-disable max-len */
/* eslint-disable max-lines */
import dayjs from "dayjs";
import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { IReservationV2 } from "../../interfaces/reservations/Reservations.types";
import { cx } from "../../utils/classnames";
import Radio from "../shared/svgs/Radio";
import useCan from "../users/permissions/useCan";
import PaymentInfo from "./PaymentInfo";
import UsersInfo from "./UsersInfo";

const Ticket: FC<IReservationV2> = ({
  insertDate,
  journeyGuid,
  oneWayTicketDetails,
  paymentMethodId,
  paymentStatus,
  price,
  roundWayTicketDetails,
  uniqueJourneyId,
  clientNames
}) => {
  const { state } = useLocation();
  const can = useCan("reservation.old.ticket.update");
  return (
    <Link
      to={can ? `edit/${uniqueJourneyId}` : ""}
      state={state}
      className={cx([!can && "cursor-not-allowed"])}
    >
      <div className="ticket">
        <div className="p-4">
          <div className="h-full w-full grid grid-cols-10 gap-2">
            <div className="col-span-full w-full row-start-2">
              <div
                className="col-start-1 row-start-2 col-span-full bg-white px-4 py-3
            flex rounded-lg gap-6"
              >
                <div className="text-sm flex items-center justify-center flex-none">
                  <div className="flex flex-col">
                    {oneWayTicketDetails[0].operatorName}
                    <div className="text-sm items-center font-light">
                      {dayjs(oneWayTicketDetails[0].journeyDate).format(
                        "DD, MMMM YYYY"
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center text-sm w-full">
                  <span className="flex items-center mr-4">
                    {oneWayTicketDetails[0].departureStationName.replace(
                      "Stacioni",
                      ""
                    )}
                  </span>
                  <Radio className="w-3 h-3 text-primary" />
                  <span className="h-[1px] bg-[#eee] flex-1 relative flex justify-center">
                    <div className="flex justify-center absolute -top-3">
                      <span
                        className={`
                      rounded-md  border border-gray-200 bg-white text-xs flex
                      ${
                        oneWayTicketDetails.length > 1 ||
                        roundWayTicketDetails.length > 1
                          ? "py-0.5 px-1"
                          : ""
                      }`}
                      >
                        {oneWayTicketDetails.length > 1 ? (
                          <span>{oneWayTicketDetails.length - 1} Transfer</span>
                        ) : null}
                        {roundWayTicketDetails.length ? (
                          <div className="mx-2">
                            {oneWayTicketDetails.length > 1 && (
                              <span className="mr-2 bg-gray p-[0.5px]" />
                            )}
                            Vajtje - Ardhje
                          </div>
                        ) : null}
                      </span>
                    </div>
                  </span>
                  <Radio className="w-3 h-3 text-gray" />
                  <span className="flex items-center ml-4">
                    {oneWayTicketDetails[
                      oneWayTicketDetails.length - 1
                    ].arrivalStationName.replace("Stacioni", "")}
                  </span>
                </div>
              </div>
            </div>
            <UsersInfo
              clientNames={clientNames}
              journeyGuid={journeyGuid}
              paymentStatus={paymentStatus}
            />
            <PaymentInfo
              paymentMethodId={paymentMethodId}
              paymentStatus={paymentStatus}
              price={price}
              clientNames={clientNames}
              insertDate={insertDate}
            />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Ticket;
