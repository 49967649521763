import {
  CheckCircleIcon,
  PencilIcon,
  XCircleIcon
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import usePageIndex from "../../../hooks/usePageIndex";
import { useSearch } from "../../../hooks/useSearch";
import {
  deleteUserRoleById,
  getAllUserRoles
} from "../../../services/UserRole.service";
import DeleteModal from "../../ui/DeleteModal";
import IconBtn from "../../ui/IconBtn";
import Table, { Row } from "../../ui/table";
import Pagination from "../../ui/table/Pagination";
import Can from "../../users/permissions/Can";

const columns: (string | number)[] = ["Emri i Rolit", "Statusi", "Veprimet"];

const RolesTable = () => {
  const { response, loading, handlePageChange, page, setResponse } =
    useSearch(getAllUserRoles);

    const { pageIndex, state }: any = usePageIndex();
  return (
    <div className="flex flex-col" style={{ height: "calc(100vh - 11rem)" }}>
      <Table titles={columns} style="hover" loading={loading}>
        {response?.data.result?.length ? (
          response?.data.result?.map((row) => {
            const highlighted = +state?.params?.id === row.id;
            return (
            <Row
              key={row.id}
              title={row.roleName}
              className={`${highlighted && "highlighted"}`}
              status={
                <div>
                  {row.status ? (
                    <IconBtn className="w-4 h-4">
                      <CheckCircleIcon className="w-6 h-6 text-blue" />
                    </IconBtn>
                  ) : (
                    <IconBtn className="w-4 h-4">
                      <XCircleIcon className="w-6 h-6 text-red" />
                    </IconBtn>
                  )}
                </div>
              }
              actions={
                <span className="flex">
                  <Can permissions="userroles.userrole.update">
                    <Link
                      to={`/users/roles/edit/${row.id}`}
                      state={{ crumbs: ["Menaxhimi i roleve", "Edito rolin"], page: pageIndex }}
                    >
                      <IconBtn className="w-6 h-6">
                        <PencilIcon className="w-4 h-4 text-neutral" />
                      </IconBtn>
                    </Link>
                  </Can>
                  <Can permissions="userroles.userrole.delete">
                    <DeleteModal
                      params={row.id}
                      title="Konfirmo fshirjen e rolit."
                      handleDelete={deleteUserRoleById}
                      successMessage="Roli është fshirë me sukses."
                      errorMessage="Roli nuk është fshirë me sukses."
                      loadingMessage="Roli është duke u fshirë..."
                      onSuccess={() => {
                        setResponse((prev) =>
                          prev
                            ? {
                                ...prev,
                                data: {
                                  ...prev.data,
                                  result: prev.data.result.filter(
                                    (el) => el.id !== row.id
                                  )
                                }
                              }
                            : null
                        );
                      }}
                    />
                  </Can>
                </span>
              }
            />
          );
})
        ) : (
          <></>
        )}
      </Table>
      <Pagination
        onPageChange={handlePageChange}
        totalCount={response?.data?.totalCount || 0}
        length={response?.data?.result.length}
        page={page}
        pageSize={10}
      />
    </div>
  );
};

export default RolesTable;
