import axios from "axios";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import { IPagination } from "../interfaces/IPagination";
import { ICalculatePrice } from "../interfaces/endpoints/officeSales/ICalculatePriceSchema";
import {
  ICreateReservationModel,
  IGetResrvationById,
  IJourneyTripModel
} from "../interfaces/endpoints/officeSales/ICreateReservationSchema";
import { IEditReservationModel } from "../interfaces/endpoints/officeSales/IEditReservationSchema";
import IGetJourneys from "../interfaces/endpoints/officeSales/IGetJorneys";
import Routes from "../interfaces/endpoints/officeSales/IRoutes";
import ITrip from "../interfaces/endpoints/officeSales/ITrip";
import { serialize } from "../utils/serializeToQueryString";

type IJourneyParams = {
  pageIndex: number;
  pageSize: number;
};

type IEditStatusParam = {
  uniqueJourneyId: string;
  ticketStatus: number;
  paymentMethodId: number;
  shouldNotifyUser?: boolean;
  refundAmount?: number;
};

type IRouteParams = {
  lineId: number;
  journeyDate: string;
  pageNumber: number;
  pageSize: number;
  reverse: boolean;
};

export const getJourneys = async (
  att: IJourneyParams
): Promise<IAxiosResponse<IPagination<IGetJourneys>>> => {
  return axios.get(`Reservation/JourneysV3${serialize(att)}`);
};

export const getRoutes = async (
  att: IRouteParams
): Promise<IAxiosResponse<IPagination<Routes>>> => {
  return axios.get(`v1/Route/Routes${serialize(att)}`);
};

export const editJourneyStatus = async (
  att: IEditStatusParam
): Promise<IAxiosResponse<boolean>> => {
  return axios.put(`v2/Reservation/JourneyStatus${serialize(att)}`);
};

export const getTripByTripId = async (
  id: number
): Promise<IAxiosResponse<ITrip>> => {
  return axios.get(`v1/Route/Trip/${id}`);
};

export const getJourneyGuid = async (
  data: IJourneyTripModel[]
): Promise<IAxiosResponse<string>> => {
  return axios.post("v1/Reservation/JourneyTripMapping", data);
};

export const postOfficeReservation = async (
  data: ICreateReservationModel
): Promise<IAxiosResponse<number>> => {
  return axios.post("v1/Reservation/OfflineJourneyReservation", data);
};

export const getOfficeReservationById = (
  id: string
): Promise<IAxiosResponse<IGetResrvationById>> => {
  return axios.get(`v1/Reservation/JourneyReservation/${id}`);
};

export const editOfficeReservation = (data: IEditReservationModel) => {
  return axios.put("v2/Reservation/JourneyReservation", data);
};

export const getCalculatedPrice = (
  data: ICalculatePrice
): Promise<IAxiosResponse<number>> => {
  return axios.post("v1/Reservation/CalculateJourneyPrice", data);
};
