import { format, parse } from "date-fns";
import { useEffect, useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";
import useDashboards from "../../hooks/useDashboards";
import useFetch from "../../hooks/useFetch";
import { getOperatorOfficeAndOnlineSalesAnalytics } from "../../services/Analytics.service";
import SpinnerLoader from "../ui/SpinnerLoader";

const colors = ["#2D87F1", "#F15B2D"];
const renderActiveShape = (props: any) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    value
  } = props;

  return (
    <g>
      <text x={cx} y={cy - 32} dy={10} textAnchor="middle" fill={fill} className="text-sm font-medium">
        Nga
      </text>
      <text x={cx} y={cy - 16} dy={10} textAnchor="middle" fill={fill} className="text-sm font-medium">
        {payload.name}
      </text>
      <text
        x={cx}
        y={cy + 12}
        dy={10}
        textAnchor="middle"
        className="text-2xl font-medium text-black"
      >
        {value} €
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        cornerRadius={100}
        forceCornerRadius={false}
        fill={fill}
      />
    </g>
  );
};

const OfficeAndOnlineSalesAnalytics = () => {
  const { line, from, to } = useDashboards();

  const { response, loading, handleFunc } = useFetch(
    getOperatorOfficeAndOnlineSalesAnalytics,
    { loading: true, resetResponseOnError: true }
  );

  useEffect(() => {
    if (from && to) {
      void handleFunc({
        startDate: format(parse(from, "dd/MM/yyyy", new Date()), "MM-dd-yyyy"),
        endDate: format(parse(to, "dd/MM/yyyy", new Date()), "MM-dd-yyyy"),
        lineId: line?.value || undefined
      });
    }
  }, [from, to, line]);

  const data = [
    { name: "Zyra", value: response?.data.officeSales },
    { name: "GjirafaTravel", value: response?.data.onlineSales }
  ];

  const [activeIndex, setActiveIndex] = useState(1);
  return (
    <div className="border border-gray-200 rounded-lg px-6 py-4 flex flex-col">
      <p className="text-xs uppercase leading-6 font-medium text-neutral">
        Shitjet Totale
      </p>
      {loading || response === null ? (
        <div className="h-6 w-20 bg-gray-200 rounded-md animate-pulse" />
      ) : (
        <p className="text-sm leading-6 font-bold">
          {response?.data.totalSales} €
        </p>
      )}
      <div className="flex-1 ">
        <div className="h-[165px] w-[165px] mx-auto -mt-4 relative">
         {loading || response === null ? <SpinnerLoader className="inset-0 h-max m-auto  absolute"/>:null}
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={150} height={150}>
              <Pie
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                data={data}
                innerRadius={73}
                outerRadius={80}
                paddingAngle={4}
                cornerRadius="100%"
                fill="#8884d8"
                dataKey="value"
                onClick={(_, index) => setActiveIndex(index)}
              >
                {data.map((_, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="flex gap-4">
        <p className="text-sm font-medium flex items-center gap-2 ">
          <div className="h-1.5 w-1.5 bg-blue rounded-full" />
          Shitjet nga zyret
        </p>
        <p className="text-sm font-medium flex items-center gap-2 ">
          <div className="h-1.5 w-1.5 bg-primary rounded-full" />
          Shitjet nga GjirafaTravel
        </p>
      </div>
    </div>
  );
};

export default OfficeAndOnlineSalesAnalytics;
