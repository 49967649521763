import RowItem from "./Item";

const PassagersBox: React.FC = () => {
  return (
    <div className="w-56">
     <RowItem firstText="I/E rritur" secondText="mbi 18 vjeç" registerName="adult" />
     <RowItem firstText="Fëmijë" secondText="3 - 17 vjeç" registerName="children" />
     <RowItem firstText="Bebe" secondText="0 - 2 vjeç" registerName="baby" />
    </div>
  );
};

export default PassagersBox;
