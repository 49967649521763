import { axios } from "../config/axios";

import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import { IPagination } from "../interfaces/IPagination";
import { SelectParamsType } from "../interfaces/ISelectParams";
import {
  BundleOrderPost,
  BundleOrderPut,
  BundlesOrderByOperatorType,
  BundlesOrderListType,
  BundlesOrderOperatorType
} from "../interfaces/bundles/BundlesOrder.types";
import { serialize } from "../utils/serializeToQueryString";

export const getPagedBundlesOrder = async (): Promise<
  IAxiosResponse<BundlesOrderListType[]>
> => {
  return axios.get("TravelBundle/TravelBundleDisplayOrders");
};

export const createBundleOrder = async (
  data: BundleOrderPost
): Promise<IAxiosResponse<BundlesOrderListType>> => {
  return axios.post("TravelBundle/TravelBundleDisplayOrder", data);
};

export const editBundleOrder = async (
  data: BundleOrderPut
): Promise<IAxiosResponse<BundlesOrderListType>> => {
  return axios.put("TravelBundle/TravelBundleDisplayOrder", data);
};

export const deleteBundleOrderById = async (
  id: number
): Promise<IAxiosResponse<{}>> => {
  return axios.delete(
    `/TravelBundle/TravelBundleDisplayOrder/${id}?travelBundleDisplayOrderId=${id}`
  );
};

export const getBundlesOrderOperators = async (
  params: SelectParamsType
): Promise<IAxiosResponse<IPagination<BundlesOrderOperatorType>>> => {
  return axios.get(
    `TravelBundle/DistinctTravelBundleOperators${serialize(params)}`
  );
};

export const getBundlesOrderByOperatorId = async ({
  operatorId,
  ...params
}: SelectParamsType & { operatorId: number }): Promise<
  IAxiosResponse<IPagination<BundlesOrderByOperatorType>>
> => {
  return axios.get(
    `TravelBundle/TravelBundleByOperatorId/${operatorId}${serialize(params)}`
  );
};
