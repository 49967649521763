import BundleReservations from "../../../components/bundles/reservations/BundleReservations";
import HeaderPage from "../../../components/lines-managements/header/HeaderPage";

const BundleReservationsPage = () => {
  return (
    <>
      <HeaderPage
        hasFilter
        filterName="Filtro Rezervimet"
        filterSelctor="paymentStatus"
        options={[
          { label: "I konfirmuar", id: "1" },
          { label: "I pa përfunduar", id: "2" },
          { label: "I anuluar", id: "3" },
          { label: "I rimbursuar", id: "4" }
        ]}
      />
      <BundleReservations />
    </>
  );
};

export default BundleReservationsPage;
