import { axios } from "../config/axios";
import {
  IAnnouncement,
  IAnnouncementSchema
} from "../interfaces/endpoints/IAnnouncements";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import { IAnnouncements } from "./../interfaces/endpoints/IAnnouncements";

interface params {
  pageIndex?: number;
  pageSize: number;
}
export const getAllAnnouncements = async ({
  pageIndex = 1,
  pageSize = 10
}: params): Promise<IAxiosResponse<IAnnouncements>> => {
  return axios.get<IAnnouncements>(
    `RouteAnnouncement/GetRouteAnnouncements?pageIndex=${pageIndex}&pageSize=${pageSize}`
  );
};
export const getCurrentAnnouncement = async (
  id: number
): Promise<IAxiosResponse<IAnnouncement>> => {
  return axios.get<IAnnouncement>(
    `RouteAnnouncement/GetRouteAnnouncement?routeAnnouncementId=${id}`
  );
};
export const postAnnouncement = async (
  body: IAnnouncementSchema
): Promise<number> => {
  return axios.post("RouteAnnouncement/PostRouteAnnouncement", body);
};
export const editAnnouncement = async (
  body: IAnnouncementSchema
): Promise<number> => {
  return axios.put("RouteAnnouncement/PutRouteAnnouncement", body);
};
export const deleteAnnouncement = async (id: number): Promise<boolean> => {
  return axios.delete(`RouteAnnouncement/DeleteRouteAnnouncement/${id}`);
};
