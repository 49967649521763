/* eslint-disable max-len */

import { FC } from "react";
import { IIcon } from "./IIcon";

const CalendarIcon: FC<IIcon> = ({ color ,className,size }) => {
  const currentColor = color || "currentColor";
  const _size = size || 16;
  return (
    <span className={className}>
      <svg
        width={_size}
        height={_size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="calendar/icon">
          <g id="Union">
            <path
              d="M4.66665 8.66667C4.29846 8.66667 3.99998 8.96514 3.99998 9.33333V10C3.99998 10.3682 4.29846 10.6667 4.66665 10.6667H5.33331C5.7015 10.6667 5.99998 10.3682 5.99998 10V9.33333C5.99998 8.96514 5.7015 8.66667 5.33331 8.66667H4.66665Z"
              fill={currentColor}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.16665 2C5.16665 1.72386 4.94279 1.5 4.66665 1.5C4.3905 1.5 4.16665 1.72386 4.16665 2V2.66667H3.33331C2.22874 2.66667 1.33331 3.5621 1.33331 4.66667V12.6667C1.33331 13.7712 2.22874 14.6667 3.33331 14.6667H12.6666C13.7712 14.6667 14.6666 13.7712 14.6666 12.6667V4.66667C14.6666 3.5621 13.7712 2.66667 12.6666 2.66667H11.8333V2C11.8333 1.72386 11.6095 1.5 11.3333 1.5C11.0572 1.5 10.8333 1.72386 10.8333 2V2.66667H5.16665V2ZM10.8333 4.66667V3.66667H5.16665V4.66667C5.16665 4.94281 4.94279 5.16667 4.66665 5.16667C4.3905 5.16667 4.16665 4.94281 4.16665 4.66667V3.66667H3.33331C2.78103 3.66667 2.33331 4.11438 2.33331 4.66667V6.16667H13.6666V4.66667C13.6666 4.11438 13.2189 3.66667 12.6666 3.66667H11.8333V4.66667C11.8333 4.94281 11.6095 5.16667 11.3333 5.16667C11.0572 5.16667 10.8333 4.94281 10.8333 4.66667ZM2.33331 12.6667V7.16667H13.6666V12.6667C13.6666 13.219 13.2189 13.6667 12.6666 13.6667H3.33331C2.78103 13.6667 2.33331 13.219 2.33331 12.6667Z"
              fill={currentColor}
            />
          </g>
        </g>
      </svg>
    </span>
  );
};

export default CalendarIcon;
