/* eslint-disable max-len */

import { FC } from "react";
import { IIcon } from "./IIcon";

const PdfIcon: FC<IIcon> = ({ color, className }) => {
  const currentColor = color || "currentColor";
  return (
    <span className={className}>
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="file-download">
          <g id="Union">
            <path d="M5.4798 9.64666C5.67506 9.4514 5.99164 9.4514 6.18691 9.64666L7.33335 10.7931V7.33371C7.33335 7.05757 7.5572 6.83371 7.83335 6.83371C8.10949 6.83371 8.33335 7.05757 8.33335 7.33371V10.7931L9.4798 9.64666C9.67506 9.4514 9.99164 9.4514 10.1869 9.64666C10.3822 9.84192 10.3822 10.1585 10.1869 10.3538L8.42261 12.1181C8.09717 12.4435 7.56953 12.4435 7.2441 12.1181L5.4798 10.3538C5.28454 10.1585 5.28454 9.84192 5.4798 9.64666Z" fill={currentColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.8334 12.6668C13.8334 13.7714 12.9379 14.6668 11.8334 14.6668H5.16669C4.06212 14.6668 3.16669 13.7714 3.16669 12.6668V3.3335C3.16669 2.22893 4.06212 1.3335 5.16669 1.3335H8.33826C8.86869 1.3335 9.3774 1.54421 9.75247 1.91928L13.2476 5.41438C13.6226 5.78945 13.8334 6.29816 13.8334 6.82859V12.6668ZM12.8334 12.6668C12.8334 13.2191 12.3856 13.6668 11.8334 13.6668H5.16669C4.6144 13.6668 4.16669 13.2191 4.16669 12.6668V3.3335C4.16669 2.78121 4.6144 2.3335 5.16669 2.3335H7.83335C8.01745 2.3335 8.16669 2.48273 8.16669 2.66683V4.66683C8.16669 5.95549 9.21136 7.00016 10.5 7.00016H12.5C12.6841 7.00016 12.8334 7.1494 12.8334 7.3335V12.6668ZM9.16669 2.74771L12.4191 6.00016H10.5C9.76364 6.00016 9.16669 5.40321 9.16669 4.66683V2.74771Z" fill={currentColor} />
          </g>
        </g>
      </svg>

    </span>
  );
};

export default PdfIcon;
