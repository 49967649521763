import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import FormWrapper from "../../../components/bundles/FormWrapper";
import BundleFeaturesForm from "../../../components/bundles/features/BundleFeaturesForm";
import useFetch from "../../../hooks/useFetch";
import {
    FeatureFormProps,
    IBundleFeature
} from "../../../interfaces/bundles/BundleFeatures";
import { getTravelBundleIncludeById } from "../../../services/BundleFeatures.service";
import { BundleReservationSchema } from "../../../utils/validations/BundleDeatures.schema";

const FeatureForm: React.FC<FeatureFormProps> = ({ mode }) => {
  const { id } = useParams();

  const methods = useForm<IBundleFeature>({
    resolver: yupResolver(BundleReservationSchema),
    shouldFocusError: false,
    defaultValues: {
      name: "",
      svgUrl: "",
      svg: "",
      locales: [
        { languageId: 1, name: "" },
        { languageId: 2, name: "" }
      ]
    }
  });

  const { response, error, loading } = useFetch(getTravelBundleIncludeById, {
    initRequest: mode === "edit",
    params: Number(id)
  });

  useEffect(() => {
    if (mode === "edit" && response && response.data) {
      methods.reset({
        id: response.data.id,
        name: response.data.name,
        svg: response.data.svg,
        svgUrl: response.data.svgUrl,
        locales: response.data.locales
      });
    }
  }, [response, id]);

  return (
    <FormProvider {...methods}>
      <FormWrapper
        showPrompt={
          !methods.formState.isSubmitSuccessful &&
          !!Object.keys(methods.formState.dirtyFields).length
        }
        backTitle="Kthehu tek lista"
        backTo={"/bundles/features"}
        backState={["Veçoritë e ofertave"]}
        error={!!error}
        mode={mode}
        response={response}
        loading={loading}
        notFoundMessage="Veçoria nuk u gjet"
        title={
          mode === "create"
            ? "Veçori e Re"
            : methods.watch("name") || "Edito Veçorinë"
        }
      >
        <BundleFeaturesForm mode={mode} />
      </FormWrapper>
    </FormProvider>
  );
};

export default FeatureForm;
