import React, { createContext, useState } from "react";
import { ILineSchema } from "../interfaces/endpoints/lines/ILineSchema";
import { ILines } from "../interfaces/endpoints/lines/ILines";

export const LinesContext = createContext<{
  lines: ILines;
  setLines: (lines: ILines) => void;
  addLine: (line: ILineSchema) => void;
  editLine: (line: ILineSchema, id: number) => void;
  deleteLine: (id: number) => void;
}>({
  lines: { result: [], totalPages: 1, currentPage: 1 },
  setLines: (lines: ILines) => lines,
  addLine: (line: ILineSchema) => line,
  editLine: (line: ILineSchema) => line,
  deleteLine: (id: number) => id
});

export const LinesProvider: React.FC<{
  children: React.ReactElement;
}> = ({ children }) => {

  const [lines, setLines] = useState<ILines>({ result: [], totalPages: 1, currentPage: 1 });

  const addLine = (line: ILineSchema) => {
    const singleLine = {
      ...line,
      arrivalStation: 0,
      arrivalStationName: line.arrivalStationName || "",
      count: 0,
      deleted: false,
      departureStation: 0,
      departureStationName: line.departureStationName || "",
      id: 999,
      operatorId: 0,
      success: true,
      auditAuthor: {
        createdById: 0,
        createdByName: "",
        createdByLastName: "",
        updatedById: 0,
        updatedByName: "",
        updatedByLastName: "",
        insertDate: "",
        updateDate: ""
      }
    };
    setLines({ ...lines, result: [singleLine, ...{ ...lines }.result] });
  };

  const editLine = (data: ILineSchema, id: number) => {
    setLines({
      ...lines,
      result: { ...lines }
        .result.map((line) => line.id === id ? Object.assign(line, { ...data, id }) : line)
    });
  };

  const deleteLine = (id: number) => {
    setLines({ ...lines, result: { ...lines }.result.filter((line) => line.id !== id) });
  };

  return (
    <LinesContext.Provider value={{ lines, addLine, editLine, deleteLine, setLines }}>
      {children}
    </LinesContext.Provider>
  );
};
