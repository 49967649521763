/* eslint-disable max-lines */
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import Button from "../../../components/ui/Button";
import Select from "../../../components/ui/input/Select";
import useFetch from "../../../hooks/useFetch";
import { getAllLines } from "../../../services/Lines.service";
import { getTripsByLine } from "../../../services/Trips.service";

interface SearchTripProps {
  onSubmit: any;
}

const SearchTrip: FC<SearchTripProps> = ({ onSubmit }) => {
  const { register, handleSubmit } = useFormContext();
  const { response } = useFetch(getAllLines, {
    initRequest: true,
    params: { pageindex: 1, pageSize: 999999 }
  });
  const { response: linesRes, handleFunc: getTrips } = useFetch(
    getTripsByLine,
    {
      initRequest: false
    }
  );
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has("lineId") && searchParams.has("lineName"))
      void getTrips(Number(searchParams.get("lineId")));
  }, [searchParams.get("lineId")]);

  useEffect(() => {
    if (
      searchParams.has("lineName") &&
      searchParams.get("lineId") &&
      searchParams.has("tripTime") &&
      searchParams.has("tripId")
    ) {
      void handleSubmit(onSubmit)();
    }
  }, [searchParams]);

  const handleTripsByLine = (e: any) => {
    void getTrips(e.target.value);
  };

  const lineId = register("lineId", { valueAsNumber: true });

  return (
    <>
      <div className="border-b md:-mx-7 mb-6 py-6 md:p-0">
        <h6 className="mb-6 text-xl md:px-7">Cakto çmimet për linjë</h6>
        <form onSubmit={handleSubmit(onSubmit)} className="md:px-7">
          <div className="md:flex items-center mb-6 w-full">
            <div className="w-full md:flex">
              <div className="md:mr-6 md:w-1/2">
                <Select
                  {...lineId}
                  onChange={(e) => {
                    handleTripsByLine(e);
                    lineId;
                  }}
                  {...(searchParams.has("lineName") &&
                  searchParams.has("lineId")
                    ? {
                        options: response?.data?.result.length
                          ? response?.data?.result.map((el: any) => {
                              return { name: el.lineName, id: el.id };
                            }) || []
                          : [
                              {
                                name: searchParams.get("lineName"),
                                id: searchParams.get("lineId")
                              }
                            ],
                        hasEmptyOption: false
                      }
                    : {
                        options:
                          response?.data?.result.map((el: any) => {
                            return { name: el.lineName, id: el.id };
                          }) || [],
                        hasEmptyOption: true
                      })}
                  label="Zgjedh linjën"
                  placeholder="Zgjedh"
                />
              </div>
              <div className="flex md:w-1/2 mr-0 md:mr-6 mt-6 md:mt-0">
                <div className="mr-6 w-1/2">
                  <Select
                    {...(searchParams.has("tripTime") &&
                    searchParams.has("tripId")
                      ? {
                          options: linesRes?.data?.length
                            ? linesRes?.data.map((el) => {
                                return {
                                  name: `${el.trip.departureTimeString} - ${el.trip.arrivalTimeString}`,
                                  id: el.trip.id
                                };
                              }) || []
                            : [
                                {
                                  name: searchParams.get("tripTime") || "",
                                  id: searchParams.get("tripId") || 0
                                }
                              ],
                          hasEmptyOption: false
                        }
                      : {
                          options:
                            linesRes?.data.map((el) => {
                              return {
                                name: `${el.trip.departureTimeString} - ${el.trip.arrivalTimeString}`,
                                id: el.trip.id
                              };
                            }) || [],
                          hasEmptyOption: true
                        })}
                    // options={
                    // linesRes?.data.map((el) => {
                    //   return {
                    //     name: `${el.trip.departureTimeString} - ${el.trip.arrivalTimeString}`,
                    //     id: el.trip.id
                    //   };
                    // }) || []
                    // }
                    label="Zgjedh orarin"
                    placeholder="Zgjedh"
                    {...register("tripId", { valueAsNumber: true })}
                  />
                </div>
                <div className="w-1/2">
                  <Select
                    hasEmptyOption={false}
                    options={[
                      {
                        name: "I/e rritur",
                        id: 1
                      }
                    ]}
                    label="Lloji i biletës"
                    placeholder="Zgjedh"
                    {...register("ageGroupId", { valueAsNumber: true })}
                  />
                </div>
              </div>
            </div>
            <div className="md:flex h-[67px] items-end justify-between">
              <div className="flex items-center w-full md:w-36 h-11 mt-4 md:mt-0">
                <input
                  type="checkbox"
                  id="returnTicket"
                  className="cursor-pointer text-primary bg-primary"
                  {...register("isTwoWayTrip")}
                />
                <label
                  htmlFor="returnTicket"
                  className="ml-3 text-sm text-neutral hover:text-primary cursor-pointer select-none"
                >
                  Biletë kthyese
                </label>
              </div>
              <div className="">
                <Button
                  type="submit"
                  className="w-full h-11 flex justify-center items-center"
                >
                  <MagnifyingGlassIcon className="w-5 h-5"/>
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default SearchTrip;
