import { PencilIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useSearch } from "../../../hooks/useSearch";
import { getPagedBundleIncludes } from "../../../services/BundleFeatures.service";
import AuditAuthor from "../../audit-author/AuditAuthor";
import IconBtn from "../../ui/IconBtn";
import Table, { Row } from "../../ui/table";
import Pagination from "../../ui/table/Pagination";
import Can from "../../users/permissions/Can";

interface FeaturesProps {
  asd?: string;
}

const columns: (string | number)[] = ["Titulli", "Imazhi", "Detajet", "Veprimet"];

const Features: React.FC<FeaturesProps> = () => {
  const { response, loading, handlePageChange, page } = useSearch(
    getPagedBundleIncludes,
    {
      withQuerySearch: true,
      withFilter: true
    }
  );

  return (
    <div className="flex flex-col" style={{ height: "calc(100vh - 11rem)" }}>
      <Table titles={columns} style="hover" loading={loading}>
        {response?.data.result?.length ? (
          response?.data.result?.map((row) => (
            <Row
              key={row.id}
              name={row.name}
              svg={
                <img
                  width={20}
                  height={20}
                  src={row.svgUrl}
                  alt="Veçoritê e pakove"
                  className="flex-none"
                />
              }
              auditAuthor={
                <AuditAuthor
                  createdByName={row.auditAuthor.createdByName}
                  createdByLastName={row.auditAuthor.createdByLastName}
                  updatedByName={row.auditAuthor.updatedByName}
                  updatedByLastName={row.auditAuthor.updatedByLastName}
                  insertDate={row.auditAuthor.insertDate}
                  updateDate={row.auditAuthor.updateDate}
                />}
              actions={
                <span className="flex">
                  <Can permissions="travelbundles.travelbundle.include.update">
                    <Link
                      to={`/bundles/features/edit/${row.id}`}
                      state={["Veçoritë e ofertave", "Modifiko veçorinë"]}
                    >
                      <IconBtn className="w-6 h-6">
                        <PencilIcon className="w-4 h-4 text-neutral" />
                      </IconBtn>
                    </Link>
                  </Can>
                </span>
              }
            />
          ))
        ) : (
          <></>
        )}
      </Table>
      <Pagination
        onPageChange={handlePageChange}
        totalCount={response?.data?.totalCount || 0}
        length={response?.data?.result.length}
        page={page}
        pageSize={10}
      />
    </div>
  );
};

export default Features;
