/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  MagnifyingGlassIcon,
  PencilSquareIcon,
  PlusIcon
} from "@heroicons/react/24/outline";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuditAuthor from "../../../components/audit-author/AuditAuthor";
import Bus from "../../../components/shared/svgs/Bus";
import Train from "../../../components/shared/svgs/Train";
import Button from "../../../components/ui/Button";
import VideoPopup from "../../../components/ui/OpenVideoTutModal";
import Input from "../../../components/ui/input/Input";
import Table, { Row } from "../../../components/ui/table";
import Pagination from "../../../components/ui/table/Pagination";
import Can from "../../../components/users/permissions/Can";
import useCan from "../../../components/users/permissions/useCan";
import { LinesContext } from "../../../context/LinesProvider";
import { getAllLines } from "../../../services/Lines.service";
import { cx } from "../../../utils/classnames";
import DeleteLine from "./DeleteLineModal";

export enum lineTypeEnum {
  "bus" = 1,
  "train" = 2
}
const ListOfLines: React.FC = () => {
  const navigate = useNavigate();
  const { lines, setLines } = useContext(LinesContext);
  const [searchLineValue, setSearchLineValue] = useState<string>("");
  useEffect(() => {
    void getLinesFromAPI(1);
  }, []);
  // eslint-disable-next-line @typescript-eslint/space-before-function-paren
  const getLinesFromAPI = async (page: number) => {
    try {
      const { data } = await getAllLines({ pageindex: page });
      setLines({ ...data, currentPage: page });
    } catch (error) {
      // toast.error(`${error} shaban` || "Failed to get lines");
    }
  };
  const can = useCan(["route.line.update", "route.line.delete"]);
  const columns = [
    "ID e linjës",
    "Emri i linjës",
    "Nisja",
    "Arritja",
    "Përshkrimi",
    "Lloji i transportit",
    "Detajet",
    ...(can ? ["Veprimet"] : [])
  ];
  const searchLine = async (val: string) => {
    setSearchLineValue(val);
    console.log(1);
    const { data } = await getAllLines({
      pageindex: 1,
      pageSize: 25,
      searchKeyword: val
    });
    setLines(data);
    console.log(data);
  };
  const linesTable = lines.result.map((line) => {
    return {
      id: line.id,
      lineName: line.lineName,
      departureStationName: line.departureStationName,
      arrivalStationName: line.arrivalStationName,
      description: line.description || "-",
      lineType: line.lineType === lineTypeEnum.bus ? <Bus /> : <Train />,
      // success: line.success,
      auditAuthor: (
        <AuditAuthor
          createdByName={line.auditAuthor.createdByName}
          createdByLastName={line.auditAuthor.createdByLastName}
          updatedByName={line.auditAuthor.updatedByName}
          updatedByLastName={line.auditAuthor.updatedByLastName}
          insertDate={line.auditAuthor.insertDate}
          updateDate={line.auditAuthor.updateDate}
        />
      ),
      actions: (
        <>
          <span className="flex items-center">
            <Can permissions="route.line.update">
              <Link
                className="p-2.5 md:p-1"
                to={`/lines/edit/${line.id}`}
                state={["Menaxhimi i linjave", "Linjat", "Edito"]}
              >
                <PencilSquareIcon className="w-5 h-5 md:w-4" />
              </Link>
            </Can>
            <Can permissions="route.line.delete">
              <DeleteLine index={line.id} />
            </Can>
          </span>
        </>
      )
    };
  });
  const handlePageChange = ({ selected }: { selected: number }) =>
    getLinesFromAPI(selected + 1);

  return (
    <>
      <div className="flex flex-wrap mb-4 justify-between">
        <div className="flex items-center">
          <Can permissions="route.line.create">
            <Button
              className={cx([
                "flex items-center",
                "group hover:shadow-xl hover:bg-opacity-100"
              ])}
              onClick={() =>
                navigate("/lines/add", {
                  state: ["Menaxhimi i Linjave", "Linjat", "Shto linjë të re"]
                })
              }
            >
              <span className="relative">
                <PlusIcon
                  strokeWidth={3}
                  className={cx([
                    "w-4 h-4",
                    "absolute group-hover:translate-y-4 translate-y-0 opacity-100 group-hover:opacity-0",
                    "transition-all duration-300"
                  ])}
                />
                <PlusIcon
                  strokeWidth={3}
                  className={cx([
                    "w-4 h-4",
                    "group-hover:translate-y-0 -translate-y-4 opacity-0 group-hover:opacity-100",
                    "transition-all duration-300"
                  ])}
                />
              </span>
              <span className="ml-1">Shto linjë të re</span>
            </Button>
            <VideoPopup
              title="Si të krijoni një linjë?"
              link="https://video.gjirafa.com/embed/deshironi-te-shtoni-nje-linje-te-re?autoplay=true&am=true"
            />
          </Can>
        </div>
        <Input
          className="w-auto md:ml-4 group"
          paddingType="fill"
          onChange={(e: any) => searchLine(e.target.value)}
          placeholder="Kërko linjat..."
          inputClassName={cx([
            "group",
            searchLineValue.length
              ? "w-56 pr-1"
              : "cursor-pointer sm:focus:w-56 focus:w-48 w-0 pr-1"
          ])}
          beforeClassName="text-neutral group-hover:text-black"
          before={<MagnifyingGlassIcon className="w-4 h-4" strokeWidth={2} />}
        />
      </div>
      <div className="flex flex-col" style={{ height: "calc(100vh - 11rem)" }}>
        <Table titles={columns} className="md:table" style="hover">
          {linesTable.map((row) => {
            return <Row key={row.id} {...row} />;
          })}
        </Table>
        <Pagination
          onPageChange={handlePageChange}
          totalCount={(lines?.totalPages || 0) * 10}
          page={lines.currentPage}
          pageSize={10}
          length={linesTable?.length}
        />
      </div>
    </>
  );
};
export default ListOfLines;
