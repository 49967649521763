import { EyeIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import Button from "../../../ui/Button";
import Modal from "../../../ui/Modal";
import PreviewDetails from "./PreviewDetails";

const Preview = () => {
  const [show, setShow] = useState(false);
  return (
    <>
      <Button
        btnType="custom"
        className="flex justify-center items-center text-primary group"
        fontWeight="font-medium"
        padding="py-3"
        onClick={() => setShow(true)}
      >
        <EyeIcon className="w-4 h-4 mr-2" strokeWidth={2} />
        <span
          className="duration-300 text-opacity-90 group-hover:text-opacity-100
                 relative justify-center items-center
  before:bg-primary before:absolute before:w-full before:h-[1px] before:rounded-sm
   before:bottom-0 before:left-0 before:origin-left before:scale-x-0
   before:duration-300 before:ease-in-out before:transform group-hover:before:origin-left
   group-hover:before:scale-x-100 transition-all"
        >
          Paraqitja e pakos
        </span>
      </Button>
      <Modal
        show={show}
        position="top"
        modalHeight="650px"
        onClose={() => setShow(false)}
        title="Paraqitja e pakos"
      >
        <PreviewDetails />
      </Modal>
    </>
  );
};

export default Preview;
