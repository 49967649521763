import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useContext } from "react";
import { SideBarContext } from "../../../context/SideBarContext";
import { SingleItem } from "./Item";

interface TextProps {
  name: SingleItem["name"];
  icon?: SingleItem["icon"];
  widget?: SingleItem["widget"];
  withArrow?: boolean;

}

const Text: React.FC<TextProps> = ({ name, icon, withArrow, widget }) => {
  const { isSideBarOpen } = useContext(SideBarContext);
  return (
    <div
      title={name}
      className="rounded-l-lg
  w-full h-11 px-5 flex items-center justify-between transition-colors hover:bg-gray-200
  cursor-pointer">
      <div className="flex items-center">
        {icon && <span className="mr-5 w-5 h-5 flex-none">{icon}</span>}
        <span className={`transition duration-300 whitespace-nowrap
      ${isSideBarOpen ? "opacity-100 translate-x-0" : "-translate-x-3 opacity-0"}`}>{name}{widget}</span>
      </div>
      {withArrow && <ChevronDownIcon className="w-4 h-4" />}
    </div>
  );
};
export default Text;
