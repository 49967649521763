import { Link, useLocation } from "react-router-dom";
import { cx } from "../../utils/classnames";

const UsersNavigate = () => {
  const { pathname } = useLocation();
  return (
    <div className="border-b border-gray-200 mb-4">
      <style>{`
      .type-left {
        left: 0;
        right: 67%;
        transition: right 0.4s ease 85ms, left 0.4s ease;
      }
      .type-right {
        right: 10px;
        left: 40%;
        transition: left 0.4s ease 85ms, right 0.4s ease;
      }
    `}</style>
      <div className="inline-flex relative">
        <Link
          to="/users/overview"
          state={["User overview", "Users"]}
          type="Link"
          className="flex items-center py-2  duration-500 transition-colors rounded-t-md"
        >
          <a className="text-sm text-neutral font-medium">Userat</a>
        </Link>
        <Link
          to="/users/overview/invite"
          type="button"
          state={["User overview", "Users", "Invite User"]}
          className="flex items-center py-2 px-3 duration-500 transition-colors rounded-t-md"
        >
          <a className="text-sm text-neutral font-medium">Invite user</a>
        </Link>
        <span
          className={cx([
            "absolute bottom-0 h-0.5 bg-primary",
            "transform-gpu",
            pathname === "/users/overview" ? "type-left" : "type-right"
          ])}
        />
      </div>
    </div>
  );
};

export default UsersNavigate;
