import useFetch from "../../hooks/useFetch";
import { getStaticAnalytics } from "../../services/Analytics.service";
import DashboardCard from "./DashboardCard";

const StaticAnalytics = () => {
  const { loading, response } = useFetch(getStaticAnalytics, {
    initRequest: true
  });
  return (
    <div className="flex w-full justify-between lg:flex-row flex-col">
      <DashboardCard
        title="Sot"
        delay={50}
        currentValue={response?.data.todaySales?.currentValue || "0"}
        ratePercentage={response?.data.todaySales?.ratePercentage || "0"}
        ratePercentageDesc="prej të njejtës ditë të javës së kaluar"
        loading={loading}
      />
      <DashboardCard
        delay={150}
        className="lg:mx-2.5 my-2.5 lg:my-0"
        title="7 ditë"
        currentValue={response?.data.lastWeekSales?.currentValue || "0"}
        ratePercentage={response?.data.lastWeekSales?.ratePercentage || "0"}
        ratePercentageDesc="prej periudhës 7 ditore të kaluar"
        loading={loading}
      />
      <DashboardCard
        title="30 ditë"
        delay={250}
        currentValue={response?.data.currentMonthSales?.currentValue || "0"}
        ratePercentage={response?.data.currentMonthSales?.ratePercentage || "0"}
        ratePercentageDesc="prej periudhës 30 ditore të kaluar"
        loading={loading}
      />
    </div>
  );
};

export default StaticAnalytics;
