import dayjs from "dayjs";
import { FC } from "react";
import { BundleReservationDetailType } from "../../../interfaces/bundles/BundleReservations.types";

const BundleReservationInfo: FC<
  Pick<
    BundleReservationDetailType,
    "travelBundleDetail" | "ticketCount" | "travelBundleReservationUserInfos"
  >
> = ({ ticketCount, travelBundleDetail, travelBundleReservationUserInfos }) => {
  return (
    <div className="px-5 flex gap-x-6">
      <div>
        <p className="text-sm text-neutral mb-3">Pako e udhëtimit:</p>
        <p className="text-sm text-neutral mb-3">Biznesi:</p>
        <p className="text-sm text-neutral mb-3">Datat e udhëtimit:</p>
        <p className="text-sm text-neutral mb-3">Numri i Pasagjerëve:</p>
        <p className="text-sm text-neutral mb-3">Pasagjeri kryesorë:</p>
        <p className="text-sm text-neutral mb-3">Numri i telefonit:</p>
        <p className="text-sm text-neutral">Email:</p>
      </div>
      <div>
        <p className="mb-3 text-black text-sm">
          {travelBundleDetail.travelBundleTitle}
        </p>
        <p className="mb-3 text-black text-sm">Biznesi</p>
        <p className="mb-3 text-black text-sm">
          {dayjs(travelBundleDetail.fromDate).format("DD/MM/YYYY")} -
          {" "}{dayjs(travelBundleDetail.toDate).format("DD/MM/YYYY")}
        </p>
        <p className="mb-3 text-black text-sm">{ticketCount}</p>
        <p className="mb-3 text-black text-sm">
          {travelBundleReservationUserInfos[0].firstName}{" "}
          {travelBundleReservationUserInfos[0].lastName}
        </p>
        <p className="mb-3 text-black text-sm">
          {travelBundleReservationUserInfos[0].phoneNumber}
        </p>
        <p className="mb-3 text-black text-sm">
          {travelBundleReservationUserInfos[0].email}
        </p>
      </div>
    </div>
  );
};

export default BundleReservationInfo;
