import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";

export const useInfiniteScroll = ({
  loading = false,
  totalPages = 1,
  makeRequestBefore = 40,
  debounceTime = 50,
  body = false
}: {
  loading: boolean;
  totalPages: number | undefined;
  makeRequestBefore?: number;
  debounceTime?: 50;
  body?: boolean;
}) => {
  const [page, setPage] = useState(1);

  const resetPage = () => setPage(1);

  const nextPage = debounce(() => setPage((prev) => prev + 1), debounceTime);

  const handleScroll = useCallback(
    (e: any) => {
      const hasMore = page < totalPages;
      if (!body) {
        const bottom =
          e.target.clientHeight + e.target.scrollTop + makeRequestBefore >=
          e.target.scrollHeight;
        if (bottom && !loading && hasMore) nextPage();
      } else {
        const bottom =
          window.innerHeight +
            e.target.documentElement.scrollTop +
            makeRequestBefore >=
          e.target.documentElement.scrollHeight;
        if (bottom && !loading && hasMore) nextPage();
      }
    },
    [loading, totalPages, body]
  );

  useEffect(() => {
    if (body) window.addEventListener("scroll", handleScroll);
    return () => {
      if (body) window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, totalPages, body]);

  return { page, resetPage, handleScroll };
};
