import dayjs from "dayjs";
import { PerLineGprah } from "../../interfaces/components/Dashboard.types";
import Box from "../ui/Box";

const CustomTooltipPerLineCharts = (data: {
  active?: boolean;
  payload?: { payload: PerLineGprah }[];
}) => {
  if (data.active && data.payload && data.payload.length) {
    return (
      <Box
        padding="px-2.5 py-2"
        className="flex flex-col items-start justify-start text-xs leading-3 text-black outline-none
       min-w-[170px]"
      >
        <span className="">
          <span className="font-bold">
            {data.payload[0].payload.nrOfSales}€
          </span>{" "}
          shitje për linja
        </span>
        <span className="mt-2 mb-3">
          <span className="font-bold">
            {data.payload[0].payload.nrOfReservations}
          </span>{" "}
          rezervimeve për linja
        </span>
        <span className="text-neutral">
          {dayjs(data.payload[0].payload.date).format("MMMM DD,")}
        </span>
        <span className="text-neutral mt-1">
          {dayjs(data.payload[0].payload.date).format("YYYY")}
        </span>
      </Box>
    );
  }
  return null;
};

export default CustomTooltipPerLineCharts;
