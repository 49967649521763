import { axios } from "../config/axios";
import { IDefaultStations } from "../interfaces/endpoints/lines/IDefaultStations";
import {
  ILines,
  ILinesSelectList,
  LineType
} from "../interfaces/endpoints/lines/ILines";
import { ILineModel } from "../interfaces/endpoints/lines/ILineSchema";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import { SelectParamsType } from "../interfaces/ISelectParams";
import { serialize } from "../utils/serializeToQueryString";

export const getAllLines = async ({
  pageindex,
  pageSize = 10,
  searchKeyword
}: {
  pageindex: number;
  pageSize?: number;
  searchKeyword?: string;
}): Promise<IAxiosResponse<ILines>> => {
  return axios.get<ILines>(
    `route/lines/?pageIndex=${pageindex}&pagesize=${pageSize}&searchKeyword=${
      searchKeyword || ""
    }`
  );
};

export const getCurrentLine = async (
  id: number
): Promise<IAxiosResponse<LineType>> => {
  return axios.get<LineType>(`route/line/${id}`);
};

export const postLine = async (body: ILineModel): Promise<IAxiosResponse<number>> => {
  return axios.post("route/postline", body);
};

export const editLine = async (body: ILineModel): Promise<number> => {
  return axios.post("route/editline", body);
};

export const deleteLine = async (id: number): Promise<boolean> => {
  return axios.delete(`route/DeleteLine/${id}`);
};

export const getDefaultStations = async (): Promise<
  IAxiosResponse<IDefaultStations[]>
> => {
  return axios.get<IDefaultStations[]>("location/defaultStations");
};

export const getLinesSelectList = async ({
  searchKeyword = "",
  ...att
}: SelectParamsType & { operatorId?: number }): Promise<
  IAxiosResponse<ILinesSelectList>
> => {
  return axios.get<ILinesSelectList>(
    `route/linesselectlist${serialize({ ...att, searchKeyword })}`
  );
};
