import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { IBundleFeature } from "../../../interfaces/bundles/BundleFeatures";
import { cx } from "../../../utils/classnames";
import Input from "../../ui/input/Input";
import Localizations from "../Localizations";

const MultpileLangFields = () => {
  const {
    register,
    formState: { errors },
    control
  } = useFormContext<IBundleFeature>();
  const { fields } = useFieldArray({
    control,
    name: "locales",
    keyName: "uuid"
  });

  const [langIdActive, setLangIdActive] = useState<1 | 2>(2);

  return (
    <>
      <Localizations
        activeId={langIdActive}
        onChange={setLangIdActive}
        alError={!!errors.locales?.length && !!errors.locales[1]}
        enError={!!errors.locales?.length && !!errors.locales[0]}
      />
      <div className="overflow-hidden mt-2 mb-6">
        <div
          className={`transform duration-500 transition-all w-full flex
    ${langIdActive === 1 ? "translate-x-0" : "-translate-x-full"}`}
        >
          {fields.map((field, index) => {
            return (
              <Input
                key={field.uuid}
                type="text"
                {...register(`locales.${index}.name`)}
                error={errors?.locales && errors?.locales[index]?.name?.message}
                label="Emri i veçorisë"
                className={cx([
                  "w-full flex-none p-[1px] duration-500",
                  langIdActive === field.languageId
                    ? "opacity-100"
                    : "opacity-0"
                ])}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default MultpileLangFields;
