import { Control, Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import { IBlog } from "../../../interfaces/endpoints/IBlogs";

interface BlogTextEditorTranslateProps {
  control: Control<IBlog>;
  name: any;
  // defaultValue: string | undefined;
  error: string | undefined;
  languageId: number;
}

const BlogTextEditorTranslate: React.FC<BlogTextEditorTranslateProps> = ({
  control,
  // defaultValue,
  name,
  error,
  languageId
}) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "blockquote"],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"]
    ]
  };

  return (
    <div>
      <Controller
        name={name}
        control={control}
        // defaultValue={defaultValue}
        render={({ field: { value, onChange } }) => (
            <ReactQuill
              theme="snow"
              className={`text-sm rounded-lg h-auto ${ error ? "border-primary" : "" }`}
              placeholder="Shkruaj përshkrimin për këtë blog këtu..."
              value={value}
              onChange={onChange}
              modules={modules}
              bounds={`.editor-container-${languageId}`}
            />
        )}
      />
      {error && <p className="text-red-500 text-xs mt-0.5">{error}</p>}
    </div>
  );
};

export default BlogTextEditorTranslate;
