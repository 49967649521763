import FilteredAnalytics from "../components/dashboard/FilteredAnalytics";
import FilteredAnalyticsPerLine from "../components/dashboard/FilteredAnalyticsPerLine";
import StaticAnalytics from "../components/dashboard/StaticAnalytics";

const Home: React.FC = () => {
  return (
    <>
      <StaticAnalytics />
      <FilteredAnalytics />
      <FilteredAnalyticsPerLine />
    </>
  );
};

export default Home;
