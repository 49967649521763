import { useContext } from "react";
import { BundlesOrderContext } from "../context/BundlesOrderContext";

const useBundlesOrder = () => {
  const { error, loading, response, onDelete, onCreate, onEdit } =
    useContext(BundlesOrderContext);
  return { error, loading, response, onDelete, onCreate, onEdit };
};

export default useBundlesOrder;
