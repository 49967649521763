import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import "react-tooltip/dist/react-tooltip.css";
import { IBundleSchema } from "../../../../interfaces/bundles/Bundles.types";
import { cx } from "../../../../utils/classnames";
import Input from "../../../ui/input/Input";
import TextArea from "../../../ui/input/Textarea";
import Localizations from "../../Localizations";

const MultipleLangFields = () => {
  const {
    register,
    watch,
    control,
    formState: { errors }
  } = useFormContext<IBundleSchema>();

  const { fields } = useFieldArray({
    control,
    name: "locales",
    keyName: "uuid"
  });

  const [langIdActive, setLangIdActive] = useState<1 | 2>(2);

  return (
    <>
      <Localizations
        activeId={langIdActive}
        onChange={(langId) => setLangIdActive(langId)}
        alError={!!errors.locales?.length && !!errors.locales[1]}
        enError={!!errors.locales?.length && !!errors.locales[0]}
      />
      <div className="overflow-hidden">
        <div
          className={`transform duration-500 transition-all w-full flex
          ${langIdActive === 1 ? "translate-x-0" : "-translate-x-full"}`}
        >
          {fields.map((field, index) => {
            return (
              <div
                key={field.uuid}
                className={cx([
                  "w-full flex-none p-[1px] duration-500",
                  langIdActive === field.languageId
                    ? "opacity-100"
                    : "opacity-0"
                ])}
              >
                <Input
                  {...register(`locales.${index}.title`)}
                  label={
                    <>
                      Titulli*
                      <span title="Numri total i rezervimeve që mund të bëhen.">
                        {watch(`locales.${index}.title`)?.length || 0}/100
                      </span>
                    </>
                  }
                  className="mt-6"
                  placeholder="Shkruaj titullin këtu..."
                  labelClassName="flex justify-between"
                  error={
                    errors?.locales && errors?.locales[index]?.title?.message
                  }
                />
                <TextArea
                  {...register(`locales.${index}.description`)}
                  label={
                    <>
                      Agjenda*{" "}
                      <span>
                        {watch(`locales.${index}.description`)?.length || 0}
                        /3000
                      </span>
                    </>
                  }
                  className="mt-6"
                  placeholder="Përshkruaj pakon këtu..."
                  inputClassName="min-h-[90px]"
                  labelClassName="flex justify-between"
                  error={
                    errors?.locales &&
                    errors?.locales[index]?.description?.message
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default MultipleLangFields;
