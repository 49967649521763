const FlagAL = () => {
  return (
    <span>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_7700_39484)">
          <path
            d="M8.99988 18C13.9704 18 17.9999 13.9706 17.9999 9C17.9999 4.02944 13.9704 0 8.99988 0C4.02931
             0 -0.00012207 4.02944 -0.00012207 9C-0.00012207 13.9706 4.02931 18 8.99988 18Z"
            fill="#D80027"
          />
          <path
            d="M14.087 6.67964H10.8271C11.0283 6.46891 11.1522 6.18362 11.1522 5.86922C11.1522 5.2209 10.6266
             4.69531 9.97828 4.69531C9.56983 4.69531 9.21029 4.904 9.00002 5.22044C8.78975 4.904 8.43017
             4.69531 8.02176 4.69531C7.37345 4.69531 6.84786 5.2209 6.84786 5.86922C6.84786 6.18362 6.97172
             6.46895 7.17295 6.67964H3.91309C3.91309 7.54409 4.66603 8.24483 5.53041 8.24483H5.47824C5.47824
             9.10932 6.17901 9.81009 7.0435 9.81009C7.0435 10.0901 7.1173 10.3526 7.24611 10.5798L5.94779
             11.8782L6.94391 12.8742L8.35691 11.4612C8.41224 11.4815 8.46951 11.4978 8.52864 11.5089L7.6748
             13.4367L9.00002 14.8692L10.3252 13.4366L9.4714 11.5088C9.53056 11.4977 9.5878 11.4815 9.64313
             11.4612L11.0561 12.8741L12.0523 11.8781L10.7539 10.5797C10.8827 10.3525 10.9565 10.09 10.9565
             9.80998C11.821 9.80998 12.5218 9.10925 12.5218 8.24472H12.4696C13.334 8.24479 14.087 7.54409
             14.087 6.67964Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_7700_39484">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export default FlagAL;
