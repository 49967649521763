import { useState } from "react";
import { cx } from "../../../../utils/classnames";
import Animation from "../../Animation";
import Box from "../../Box";
import ClickAwayListener from "../../Clickaway";
import { IExtendsList } from "../Select.types";
import Styles from "../style.module.css";
import Pill from "./Pill";

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const ExtendsList = <T, D extends unknown>({
  extendsList,
  disabled,
  getOptionLabel,
  onSelectedItemChange
}: IExtendsList<T, D>) => {
  const [showExtendsList, setShowExtendsList] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setShowExtendsList(false)}>
      <div className="relative">
        <div
          className="py-1 ring-1 ring-gray-300 rounded-full text-sm leading-[18px]
         whitespace-nowrap px-2 bg-white cursor-pointer hover:shadow-md duration-300"
          onClick={() => setShowExtendsList((prev) => !prev)}
          role="button"
        >
          +{extendsList?.length}
        </div>
        <Animation
          show={showExtendsList && !!extendsList?.length}
          type="fade-top"
          leaveInDom
          duration={300}
        >
          <div className="absolute right-0 mt-1">
            <Box
              padding="px-2.5 py-2"
              className={cx([
                "gap-y-2 bg-white flex flex-col justify-start items-start max-h-56 overflow-y-auto",
                Styles.scrollThin
              ])}
            >
              {extendsList?.map((el) => (
                <Pill
                  animationType="fade"
                  key={JSON.stringify(el)}
                  item={el}
                  disabled={disabled}
                  getOptionLabel={getOptionLabel}
                  onSelectedItemChange={onSelectedItemChange}
                />
              ))}
            </Box>
          </div>
        </Animation>
      </div>
    </ClickAwayListener>
  );
};

export default ExtendsList;
