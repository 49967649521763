import React, { createContext, useState } from "react";
import checkDevice from "../utils/isTouch";

export type SideBarType = {
  isSideBarOpen: boolean;
  setSideBar: (e: boolean) => void;
};

export const SideBarContext = createContext<SideBarType>({
  isSideBarOpen: true,
  setSideBar: () => true
});

export const SideBarProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {

  const isTouch = checkDevice();
  const [isSideBarOpen, setSideBar] = useState<boolean>(!isTouch);

  return (
    <SideBarContext.Provider value={{ isSideBarOpen, setSideBar }}>
      {children}
    </SideBarContext.Provider>
  );
};
