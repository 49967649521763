import { format, parse } from "date-fns";
import { DateRange } from "react-day-picker";
import useDashboards from "../../hooks/useDashboards";
import useUser from "../../hooks/useUser";
import { getLinesSelectList } from "../../services/Lines.service";
import RangePicker from "../ui/datepicker/range/RangePicker";
import Select from "../ui/select/Select";

const SelectLineAndDate = () => {
  const { user } = useUser();
  const { setLocalLine, line, defaultLocation, setLocalDates, from, to } =
    useDashboards();

  const onDateApply = (r: DateRange | undefined) => {
    if (r?.from && r?.to)
      setLocalDates({
        from: format(r.from, "dd/MM/yyyy"),
        to: format(r.to, "dd/MM/yyyy")
      });
  };

  return (
    <div className="flex justify-between gap-4">
      <Select
        label="Të dhënat për linjën:"
        className="w-max"
        selectedItem={line}
        emptyItem={defaultLocation}
        onSelectedItemChange={setLocalLine}
        asyncFunc={getLinesSelectList}
        extraParams={{ operatorId: user?.operator.id || 0 }}
      />
      <div className="w-max">
        <RangePicker
          hasHelpers
          label="Filtro datën:"
          range={
            from && to
              ? {
                  from: parse(from, "dd/MM/yyyy", new Date()),
                  to: parse(to, "dd/MM/yyyy", new Date())
                }
              : undefined
          }
          // pickerProps={{ toDate: new Date() }}
          onApply={onDateApply}
        />
      </div>
    </div>
  );
};

export default SelectLineAndDate;
