import * as Yup from "yup";
import ICreateReservationSchema, { passagerType } from "../../interfaces/endpoints/officeSales/ICreateReservationSchema";

const PassagerSchema: Yup.SchemaOf<Partial<passagerType>> = Yup.object().shape({
  id: Yup.number().optional(),
  type: Yup.number().typeError("Zgjedh tipin e pasagjerit").required("Zgjedh tipin e pasagjerit"),
  name: Yup.string().required("Shkuarj emrin"),
  lastName: Yup.string().required("Shkuarj mbiemrin"),
  passportId: Yup.string().nullable().optional()
});

const CreateOfficeReservation: Yup.SchemaOf<ICreateReservationSchema> = Yup.object().shape({
  email: Yup.string().email("Formati duhet të jetë e-mail ").required("Shkruani e-mail adresen"),
  phoneNumber: Yup.string().required("Shkruaj numrin e telefonit"),
  paymentMethod: Yup.number().typeError("Zgjedh metoden e pageses").required("Zgjedh metoden e pageses"),
  passagers: Yup.array().of(PassagerSchema).required()
});

export default CreateOfficeReservation;
