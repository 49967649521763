import { FC } from "react";
import { SubmitHandler, useFormContext } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Actions from "../../../components/bundles/Actions";
import useFetch from "../../../hooks/useFetch";
import {
  FeatureFormProps,
  IBundleFeature
} from "../../../interfaces/bundles/BundleFeatures";
import {
  createBundleFeature,
  editBundleFeature
} from "../../../services/BundleFeatures.service";
import MultpileLangFields from "./MultipleLangFields";
import UploadSvg from "./UploadSvg";

const BundleFeaturesForm: FC<FeatureFormProps> = ({ mode }) => {
  const navigate = useNavigate();

  const { handleSubmit } = useFormContext<IBundleFeature>();

  const { loading, handleFunc } = useFetch(
    mode === "create" ? createBundleFeature : editBundleFeature,
    {
      initRequest: false
    }
  );

  const onSubmit: SubmitHandler<IBundleFeature> = (data) => {
    void handleFunc({ data: { ...data, name: data.locales[1].name } })
      .then(() => {
        if (mode === "create")
          toast.success("Veçoria është shtuar me sukses!", {
            duration: 2000
          });
        else
          toast.success("Veçoria është ndryshuar me sukses!", {
            duration: 2000
          });
        navigate("/bundles/features");
      })
      .catch(() => {
        if (mode === "create")
          toast.error("Ndodhi një gabim gjatë krijimit.");
        else toast.error("Ndodhi një gabim gjatë ndryshimit.");
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="max-w-lg mx-auto h-full max-h-full w-full overflow-auto p-5">
        <MultpileLangFields />
        <UploadSvg />
      </div>
      <Actions
        loading={loading}
        buttonTitle={mode === "edit" ? "Edito Veçorinë" : "Shto Veçorinë"}
      />
    </form>
  );
};

export default BundleFeaturesForm;
