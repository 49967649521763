import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { IStations } from "../../../interfaces/endpoints/IStations";
import Localizations from "../../bundles/Localizations";
import Input from "../../ui/input/Input";

const MultpileLangFieldsCountry = () => {
  const {
    register,
    formState: { errors },
    control
  } = useFormContext<IStations>();
  const { fields } = useFieldArray({
    control,
    name: "country.locales",
    keyName: "uuid"
  });

  const [langIdActive, setLangIdActive] = useState<1 | 2>(2);

  return (
    <div className="rounded-xl bg-gray-100 p-3">
      <Localizations
        activeId={langIdActive}
        onChange={setLangIdActive}
        alError={
          !!errors.country?.locales?.length && !!errors.country.locales[1]
        }
        enError={
          !!errors.country?.locales?.length && !!errors.country.locales[0]
        }
      />
      <div className="overflow-hidden mt-2 -mx-2">
        <div
          className={`transform duration-500 transition-all w-full flex
    ${langIdActive === 1 ? "translate-x-0" : "-translate-x-full"}`}
        >
          {fields.map((field, index) => {
            return (
              <Input
                key={field.uuid}
                label="Shteti*"
                className="flex-none w-full px-2 mb-1"
                {...register(`country.locales.${index}.name`)}
                placeholder="Shkruaj emrin e shtetit këtu..."
                // error={
                //   errors.country?.locales &&
                //   errors?.country?.locales[index]?.name?.message
                // }
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MultpileLangFieldsCountry;
