import { MouseEvent } from "react";
import { cx } from "../../../../utils/classnames";
import { IMultiplePills } from "../Select.types";
import ExtendsList from "./ExtendsList";
import Pill from "./Pill";

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const MultiplePills = <T, D extends unknown>({
  extendsList,
  selected,
  disabled,
  getOptionLabel,
  onSelectedItemChange
}: IMultiplePills<T, D>) => {
  const onPrevent = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <div
      className={cx([
        "h-full w-full flex justify-center items-center gap-x-2 z-20",
        disabled ? "" : "pointer-events-auto"
      ])}
      onClick={onPrevent}
    >
      {selected?.map((el) => (
        <Pill
          item={el}
          key={JSON.stringify(el)}
          disabled={disabled}
          getOptionLabel={getOptionLabel}
          onSelectedItemChange={onSelectedItemChange}
        />
      ))}
      {extendsList.length ? (
        <ExtendsList
          extendsList={extendsList}
          disabled={disabled}
          getOptionLabel={getOptionLabel}
          onSelectedItemChange={onSelectedItemChange}
        />
      ) : null}
    </div>
  );
};

export default MultiplePills;
