const FlagUS = () => {
  return (
    <span>
      <svg
        width="20"
        height="20"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706
           4.02944 18 9 18Z"
          fill="#F0F0F0"
        />
        <path
          d="M8.60938 8.99627H18.0007C18.0007 8.18395 17.8924 7.39702 17.6906 6.64844H8.60938V8.99627Z"
          fill="#D80027"
        />
        <path
          d="M8.60938 4.30096H16.6797C16.1288 3.40195 15.4243 2.60731 14.603 1.95312H8.60938V4.30096Z"
          fill="#D80027"
        />
        <path
          d="M9.00104 17.9956C11.1192 17.9956 13.066 17.2635 14.6034 16.0391H3.39868C4.93606 17.2635 6.88291
           17.9956 9.00104 17.9956Z"
          fill="#D80027"
        />
        <path
          d="M1.32146 13.6916H16.6795C17.1218 12.9699 17.4649 12.181 17.6905 11.3438H0.310547C0.536145 12.181
           0.879164 12.9699 1.32146 13.6916Z"
          fill="#D80027"
        />
        <path
          d="M4.16897 1.40548H4.98913L4.22624 1.95971L4.51765 2.85652L3.75479 2.30228L2.99194 2.85652L3.24366
          2.08178C2.57196 2.64129 1.98323 3.29681 1.49808 4.02722H1.76087L1.27526 4.38001C1.1996 4.50622
           1.12704 4.63444 1.0575 4.76455L1.28939 5.47826L0.856758 5.16393C0.749215 5.39177 0.650848 5.62475
           0.56243 5.86259L0.81791 6.64896H1.76087L0.99798 7.2032L1.28939 8.1L0.526535 7.54576L0.0695742
            7.87778C0.0238359 8.24544 0 8.61993 0 9H9C9 4.02947 9 3.44348 9 0C7.22208 0 5.56471 0.515742
             4.16897 1.40548ZM4.51765 8.1L3.75479 7.54576L2.99194 8.1L3.28335 7.2032L2.52046
             6.64896H3.46342L3.75479 5.75216L4.04617 6.64896H4.98913L4.22624 7.2032L4.51765 8.1ZM4.22624
             4.58146L4.51765 5.47826L3.75479 4.92402L2.99194 5.47826L3.28335 4.58146L2.52046
              4.02722H3.46342L3.75479 3.13042L4.04617 4.02722H4.98913L4.22624 4.58146ZM7.74591 8.1L6.98305
              7.54576L6.22019 8.1L6.51161 7.2032L5.74871 6.64896H6.69168L6.98305 5.75216L7.27443
               6.64896H8.21739L7.4545 7.2032L7.74591 8.1ZM7.4545 4.58146L7.74591 5.47826L6.98305
               4.92402L6.22019 5.47826L6.51161 4.58146L5.74871 4.02722H6.69168L6.98305 3.13042L7.27443
                4.02722H8.21739L7.4545 4.58146ZM7.4545 1.95971L7.74591 2.85652L6.98305
                2.30228L6.22019 2.85652L6.51161 1.95971L5.74871 1.40548H6.69168L6.98305
                0.508676L7.27443 1.40548H8.21739L7.4545 1.95971Z"
          fill="#0052B4"
        />
      </svg>
    </span>
  );
};

export default FlagUS;
