import { useSearch } from "../../../hooks/useSearch";
import { getPagedBundleReservations } from "../../../services/BundleReservations.service";
import NotfoundTable from "../../ui/NotfoundTable";
import Table from "../../ui/table";
import Pagination from "../../ui/table/Pagination";
import BundleReservationRow from "./BundleReservationRow";

const columns: (string | number)[] = [
  "Emri Mbiemri",
  "Email",
  "Nr. i pasagjerëve",
  "Pako e udhëtimit",
  "Totali",
  "Data e rezervimit",
  "Statusi",
  "Veprimet"
];

const BundleReservations = () => {
  const { response, loading, handlePageChange, page } = useSearch(
    getPagedBundleReservations,
    {
      withQuerySearch: true,
      withFilter: true,
      filterSelector: "paymentStatus"
    }
  );
  return (
    <div className="flex flex-col" style={{ height: "calc(100vh - 11rem)" }}>
      <Table
        titles={columns}
        loading={loading}
        className="overflow-x-auto"
        style="hover"
      >
        {response?.data.result?.length ? (
          response?.data.result?.map((row, i: number) => (
            <BundleReservationRow key={i} row={row} />
          ))
        ) : (
          <></>
        )}
      </Table>
      {!response?.data.result?.length && !loading ? (
        <NotfoundTable
          header="Nuk ka pako te reservuara në list"
        // paragraph="Shto pako duke klikuar butonin lartë"
        />
      ) : null}
      <Pagination
        onPageChange={handlePageChange}
        totalCount={response?.data?.totalCount || 0}
        length={response?.data?.result.length}
        page={page}
        pageSize={10}
      />
    </div>
  );
};

export default BundleReservations;
