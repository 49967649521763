/* eslint-disable max-lines */
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { useContext, useState } from "react";
import { DateRange } from "react-day-picker";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ReactRouterPrompt from "react-router-prompt";
import UpperSearch from "../../components/office/UpperSearch";
import Lines from "../../components/office/lines";
import Button from "../../components/ui/Button";
import Modal from "../../components/ui/Modal";
import SpinnerLoader from "../../components/ui/SpinnerLoader";
import {
  OfficeSalesContext,
  passagersType,
  selectTripType,
  tripTypeData
} from "../../context/OfficeSalesContext";
import useFetch from "../../hooks/useFetch";
import { LineSelectList } from "../../interfaces/endpoints/lines/ILines";
import { getRoutes } from "../../services/OfficeSales.service";
import { serialize } from "../../utils/serializeToQueryString";
import OfficeSalesSchema from "../../utils/validations/OfficeSales.schema";

export type OfficeReservationSchema = {
  trip: LineSelectList;
  passagers: passagersType;
  date: DateRange | undefined;
};

const ChooseLine = () => {
  const {
    handleFunc: getDepartureRoutes,
    response: departureLines,
    setResponse: setDepartureLines,
    loading: loadingDepartureRes
  } = useFetch(getRoutes);

  const {
    handleFunc: getReturnRoutes,
    response: returnLines,
    setResponse: setReturnLines,
    loading: loadingReturnRes
  } = useFetch(getRoutes);
  const [showPrompt, setShowPrompt] = useState(false);
  const { data, setData, setSelectedTripId } = useContext(OfficeSalesContext);
  const navigate = useNavigate();
  const methods = useForm<OfficeReservationSchema>({
    defaultValues: {
      passagers: { adult: 1, baby: 0, children: 0 },
      date: { from: new Date() }
    },
    resolver: yupResolver(OfficeSalesSchema)
  });

  const resetData = () => {
    setData((prev) => ({
      ...prev,
      departure: {} as tripTypeData,
      return: {} as tripTypeData
    }));
    setSelectedTripId({} as selectTripType);
    setDepartureLines(null);
    setReturnLines(null);
  };

  const submit: SubmitHandler<OfficeReservationSchema> = (res) => {
    resetData();
    setShowPrompt(true);
    try {
      void getDepartureRoutes({
        journeyDate: dayjs(res.date?.from).format("YYYY/MM/DD"),
        lineId: res.trip.value,
        pageNumber: 1,
        reverse: false,
        pageSize: 999
      });
    } catch (err) {
      setShowPrompt(false);
      toast.error("Nuk ka linja per nisje");
    }
    if (res.date?.to) {
      try {
        void getReturnRoutes({
          journeyDate: dayjs(res.date?.to).format("YYYY/MM/DD"),
          lineId: res.trip.value,
          pageNumber: 1,
          reverse: true,
          pageSize: 999
        });
      } catch (error) {
        setShowPrompt(false);
        toast.error("Nuk ka linja per kthim");
      }
    } else {
      setReturnLines(null);
    }
  };

  const handleNext = () => {
    setShowPrompt(false);
    const att = {
      dd: dayjs(data.date?.from).format("YYYY-MM-DD"),
      dr: dayjs(data.date?.to).format("YYYY-MM-DD"),
      padult: data.passagers.adult,
      pbaby: data.passagers.baby,
      pchild: data.passagers.children,
      tripD: data.departure.id,
      tripR: data.return?.id || ""
    };
    setTimeout(() => {
      navigate(`/office/create${serialize(att)}`, {
        state: [
          "Menaxhimi i linjave",
          "Linjat ne zyre",
          "Shto nje linje te re"
        ]
      });
    }, 0);
  };
  return (
    <FormProvider {...methods}>
      <ReactRouterPrompt
        when={
          (!methods.formState.isSubmitSuccessful &&
            !!Object.keys(methods.formState.dirtyFields).length) ||
          showPrompt
        }
      >
        {({ isActive, onConfirm, onCancel }) => (
          <Modal
            hasHeader={false}
            show={isActive}
            position="top"
            modalWidth="372px"
          >
            <div className="p-6 flex flex-col items-center justify-between h-[175px]">
              <h1 className="text-lg font-medium">
                A jeni të sigurt për këtë veprim?
              </h1>
              <p className="text-sm text-neutral-300">
                Nëse vazhdoni, të dhënat tua do të humbasin.
              </p>
              <div className="w-full flex gap-4 text-sm">
                <Button
                  btnType="custom"
                  fontWeight="medium"
                  className="w-full border"
                  onClick={onCancel}
                >
                  Anulo
                </Button>
                <Button
                  fontWeight="medium"
                  className="w-full"
                  onClick={onConfirm}
                >
                  Vazhdo
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </ReactRouterPrompt>
      <div className="h-full flex flex-col justify-between">
        <form onSubmit={methods.handleSubmit(submit)}>
          <UpperSearch resetData={resetData} />
          <div
            className={`grid ${methods.watch("date.to") && "grid-cols-2"}
          grid-flow-col w-full gap-6 mt-6`}
          >
            {loadingDepartureRes ? (
              <EmptyBoxWrapper>
                <SpinnerLoader />
              </EmptyBoxWrapper>
            ) : departureLines ? (
              <Lines lineType="departure" lines={departureLines} />
            ) : (
              <EmptyBoxWrapper>Nuk ka linja per nisje</EmptyBoxWrapper>
            )}
            {methods.watch("date.to") ? (
              loadingReturnRes ? (
                <EmptyBoxWrapper>
                  <SpinnerLoader />
                </EmptyBoxWrapper>
              ) : returnLines ? (
                <Lines lineType="return" lines={returnLines} />
              ) : (
                <EmptyBoxWrapper>Nuk ka linja per kthim</EmptyBoxWrapper>
              )
            ) : null}
          </div>
        </form>
        {(
          methods.watch("date.from") && methods.watch("date.to")
            ? data.departure?.id && data.return?.id
            : data.departure?.id
        ) ? (
          <div className="flex justify-end border-t pt-5 bottom-7 sticky bg-white">
            <div>
              <Button
                className="border-primary border text-primary bg-white mr-3 w-32"
                type="button"
                btnType="custom"
                onClick={() => navigate(-1)}
              >
                <span>Anulo</span>
              </Button>
              <Button className="w-32" type="submit" onClick={handleNext}>
                Vazhdo
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </FormProvider>
  );
};

export default ChooseLine;

const EmptyBoxWrapper: React.FC<{ children: React.ReactNode }> = ({
  children
}) => (
  <div className="border flex justify-center items-center py-16 rounded-lg text-gray-500">
    {children}
  </div>
);
