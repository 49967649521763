/* eslint-disable max-lines */
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import {
  IReservationForm,
  IReservationFormSchemaV2
} from "../../../interfaces/reservations/Reservations.types";
import { editReservationsDetails } from "../../../services/Reservations.service";
import Actions from "../../bundles/Actions";
import { TicketStatusEnum } from "../../reservations/res.enum";
import PaymentInfo from "../PaymentInfo";
import UsersInfo from "../UsersInfo";
import GeneralInfo from "./GeneralInfo";
import TripInfo from "./TripInfo";

const ReservationForm: FC<IReservationForm> = ({ details }) => {
  const navigate = useNavigate();
  const { handleSubmit } = useFormContext<IReservationFormSchemaV2>();

  const { loading: editLoading, handleFunc } = useFetch(
    editReservationsDetails
  );

  const onSubmit = async (data: IReservationFormSchemaV2) => {
    const res = await handleFunc({
      email: data.email,
      paymentMethodId: data.paymentMethod.value,
      paymentStatus: data.paymentStatus.value,
      uniqueJourneyId: data.uniqueJourneyId || "",
      tickets: [
        ...data.oneWayTicketDetails.map((ow) => ({
          Id: ow.ticketId,
          tripId: ow.tripId,
          journeyDate: ow.journeyDate
        })),
        ...data.roundWayTicketDetails.map((rt) => ({
          Id: rt.ticketId,
          tripId: rt.tripId,
          journeyDate: rt.journeyDate
        }))
      ]
    });
    if (res?.status === 200) {
      toast.success("Rezervimi është ndryshuar me sukses.");
      navigate(-1);
    } else toast.error("Ndodhi një gabim gjatë ndryshimit.");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="h-full w-full flex flex-col gap-2 px-5 mb-6">
        <div className="flex justify-between">
          <UsersInfo
            // clientNames={details.clientNames}
            journeyGuid={details.journeyGuid}
            paymentStatus={details.paymentStatus}
            uniqueJourneyId={details.uniqueJourneyId}
          />
          <PaymentInfo
            paymentMethodId={details.paymentMethodId}
            paymentStatus={details.paymentStatus}
            price={details.price}
            clientNames={details.clientNames}
          />
        </div>
        <div className="col-start-1 lg:col-span-6 col-span-full ">
          <TripInfo ticketDetailsFor="oneWayTicketDetails" showReturn={false} />
          <TripInfo
            ticketDetailsFor="roundWayTicketDetails"
            showReturn={true}
          />
        </div>
      </div>

      <GeneralInfo initialPaymentStatus={details.paymentStatus} />
      <Actions
        loading={editLoading}
        buttonTitle="Modifiko Rezervimin"
        disabled={details.paymentStatus !== TicketStatusEnum.Confirmed &&
          details.paymentStatus !== TicketStatusEnum.Canceled}
      />
    </form>
  );
};

export default ReservationForm;
