import { number, object } from "yup";

export const BundleOrderCreateSchema = object().shape({
  operator: object().shape({
    id: number().typeError("Vendos operatorin!").required("Vendos operatorin!")
  }),
  bundle: object().shape({
    id: number().typeError("Vendos oferten!").required("Vendos oferten!")
  })
});

export const BundleOrderEditSchema = object().shape({
  displayOrder: number()
    .typeError("Vendos renditjen!")
    .required("Vendos renditjen!")
});
