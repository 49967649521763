import { ArrowUpOnSquareIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { ChangeEvent, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { LoaderIcon } from "react-hot-toast";
import { useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { IBlog } from "../../../interfaces/endpoints/IBlogs";
import { getCurrentBlog } from "../../../services/Blogs.service";
import { getGuid, uploadBlogPhoto } from "../../../services/Media.service";
import Input from "../../ui/input/Input";
import useCan from "../../users/permissions/useCan";
interface BlogImageProps {
  mode?: "create" | "edit";
}
const BlogImage: React.FC<BlogImageProps> = ({ mode }) => {
  const { id } = useParams();
  const [imageData, setImageData] = useState<string>();
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);
  const { response } = useFetch(getGuid, {
    initRequest: true
  });
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext<IBlog>();

  const { response: picsRes } = useFetch(getCurrentBlog, {
    initRequest: mode === "edit",
    params: Number(id)
  });

  useEffect(() => {
    if (mode === "edit" && picsRes && picsRes.data) {
      Object.entries(picsRes.data).forEach(([key, value]) => {
        const feature = key as keyof IBlog;
        setValue(feature, value);
        setImageData(picsRes.data.blogPictureMappings[0].pictureUrl);
      });
    }
  }, [picsRes, id]);

  const onChangeHandle = (e: ChangeEvent<HTMLInputElement>) => {
    setLoadingUpload(true);
    if (e.target && e.target.files) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      void uploadBlogPhoto({
        guid: response?.data.guid,
        body: formData
      }).then((res) => {
        if (res.statusCode === 200) {
          setImageData(res.message);
          setLoadingUpload(false);
        }
        setValue("blogPictureMappings",
          [{ isCoverPicture: true, pictureId: res.data.id, mimeType: res.data.mimeType }]);
        // setValue("blogPictureMappings.[0].pictureId", res.data.id);
      });
    }
  };
  const can = useCan("media.picture.create");
  return (
    <div className="mb-6">
      {!imageData ? (
        <>
          {can ? <label
            htmlFor="file-upload"
            className={`border border-dashed items-center text-sm text-gray-600
                  w-full h-52 justify-center flex flex-col rounded-xl
                  ${errors.blogPictureMappings?.message && "border-red-500"}
                  cursor-pointer hover:border-primary transition-colors duration-300`}
          >
            {loadingUpload ? (
              <span>
                <LoaderIcon className="w-8 h-8" />
              </span>
            ) : (
              <span className="flex flex-col justify-center items-center">
                <ArrowUpOnSquareIcon className="w-6 h-6 p-1" />
                Ngarko foton
                <span className="text-xs mt-1">
                  Formatet e lejuara për foto janë png dhe jpg
                </span>
              </span>
            )}
          </label>:<div
              className="border-2 border-dashed items-center text-sm text-gray-600
                          h-52 w-full justify-center flex flex-col mb-4 rounded-xl
                           bg-gray-200 cursor-not-allowed transition-colors duration-300 px-4"
            >Nuk keni leje per te shtuar foto!</div>}
          <Input
            {...register("blogPictureMappings")}
            type="file"
            className="hidden"
            id="file-upload"
            onChange={(e) => onChangeHandle(e)}
            error={errors.blogPictureMappings?.message}
          />
          {errors.blogPictureMappings && (
            <p className="text-red-500 text-xs mt-0.5">
              {errors.blogPictureMappings.message}
            </p>
          )}
        </>
      ) : (
        <div className="flex relative w-full h-52 rounded-lg">
          <div className="w-full h-full border items-center flex justify-center rounded-lg">
            <img
              src={imageData}
              alt="Ikona"
              className="object-contain rounded-lg h-44"
              {...register("blogPictureMappings")}
            />
          </div>
          <button
            type="button"
            className="text-primary font-bold hover:text-black absolute right-0"
            onClick={() => setImageData(null || undefined)}
          >
            <XCircleIcon className="w-8 h-8 p-1" />
          </button>
        </div>
      )}
    </div>
  );
};

export default BlogImage;
