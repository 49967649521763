import { PencilSquareIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import usePageIndex from "../../hooks/usePageIndex";
import { useSearch } from "../../hooks/useSearch";
import {
  deletePromotionCode,
  getAllPromotionCodes
} from "../../services/PromoCodes.service";
import IconBtn from "../ui/IconBtn";
import Table, { Row } from "../ui/table";
import Pagination from "../ui/table/Pagination";
import Can from "../users/permissions/Can";
import DeleteCode from "./DeleteCodeModal";
import SendMail from "./SendMailCode";
const columns: (string | number)[] = [
  "Kodi",
  "Lloji",
  "Emri",
  "Email",
  "Data e skadimit",
  "Përshkrimi",
  "Veprimet"
];

const PromotionCodes = () => {
  const { response, loading, handlePageChange, page, setResponse } = useSearch(
    getAllPromotionCodes,
    {
      withQuerySearch: true
    }
  );
  const { pageIndex, state }: any = usePageIndex();

  const handleDelete = (id: number) => {
    deletePromotionCode(id)
      .then(() => {
        toast.success("Kodi është fshirë me sukses.", {
          position: "top-right"
        });
        setResponse((prev) =>
          prev
            ? {
                ...prev,
                data: {
                  ...prev.data,
                  result: prev.data.result.filter((el) => el.id !== id)
                }
              }
            : null
        );
      })
      .catch(() => toast.error("Ndodhi një gabim gjatë fshirjes."));
  };

  return (
    <div className="flex flex-col" style={{ height: "calc(100vh - 11rem)" }}>
      <Table
        titles={columns}
        loading={loading}
        className="overflow-x-auto"
        style="hover"
      >
        {response?.data.result?.length ? (
          response?.data.result?.map((row, i: number) => {
            const highlighted = +state?.params?.id === row.id;
            return (
              <Row
                key={i}
                promoCode={row.promoCode}
                className={`${highlighted && "highlighted"}`}
                promotionCodeType={
                  row.promotionCodeType === 1
                    ? "Student"
                    : "Commercial Representative"
                }
                name={row.name}
                email={row.email}
                expirationDate={dayjs(row.expirationDate).format("DD-MM-YY")}
                description={row.description || "-"}
                actions={
                  <span className="flex items-center">
                    <Can permissions="promotioncodes.promotioncode.update">
                      <Link
                        to={`/promotioncodes/edit/${row.id}`}
                        state={{
                          crumbs: ["Kodet promocionale", "Modifiko kodin"],
                          page: pageIndex
                        }}
                      >
                        <IconBtn className="w-6 h-6">
                          <PencilSquareIcon className="w-4 h-4" />
                        </IconBtn>
                      </Link>
                    </Can>
                    <Can permissions="promotioncodes.promotioncode.delete">
                      <SendMail
                        id={row.id}
                        promoCode={row.promoCode}
                        email={row.email}
                        promotionCodeType={row.promotionCodeType}
                      />
                      <DeleteCode
                        index={row.id}
                        handleDelete={() => handleDelete(row.id)}
                      />
                    </Can>
                  </span>
                }
              />
            );
          })
        ) : (
          <></>
        )}
      </Table>
      <Pagination
        onPageChange={handlePageChange}
        totalCount={(response?.data.totalPages || 0) * 10}
        length={response?.data?.result.length}
        page={page}
        pageSize={10}
      />
    </div>
  );
};
export default PromotionCodes;
