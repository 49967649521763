import dayjs from "dayjs";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";
import useNavigateState from "../../../../hooks/useNavigateState";
import {
  IBundleForm,
  IBundleSchema
} from "../../../../interfaces/bundles/Bundles.types";
import { createBundle, editBundle } from "../../../../services/Bundles.service";
import Actions from "../../Actions";
import BasicDetails from "./BasicDetails";
import JourneyDetails from "./JourneyDetails";
import UploadPhoto from "./Photos";
import Stations from "./Stations";

const BundleDetails: FC<IBundleForm> = ({ mode }) => {
  const { handleSubmit } = useFormContext<IBundleSchema>();

  const { loading, handleFunc } = useFetch(
    mode === "create" ? createBundle : editBundle,
    { updateLoadingWhenSuccess: false }
  );
  const navigate = useNavigate();
  const stateCrumbs = useNavigateState([
    "Menaxhimi i ofertave",
    "Ofertat e udhëtimit"
  ]);
  const { state }: any = useLocation();

  const { id } = useParams();

  const onSubmit = (data: IBundleSchema) => {
    const _id = typeof id === "string" ? Number(id) : 0;
    void handleFunc({
      data: {
        ...(_id !== 0 ? { id: _id } : { bundleStatus: true }),
        title: data.locales[1].title,
        description: data.locales[1].description,
        fromLocationId: data.fromLocation.cityId,
        toLocationId: data.toLocation.cityId,
        travelBundleDetails: data.journeys.map((el) => ({
          maxReservationCount: el.maxReservationCount,
          travelBundlePrice: el.travelBundlePrice,
          fromDate: dayjs(el.range?.from).format("YYYY-MM-DD"),
          toDate: dayjs(el.range?.to).format("YYYY-MM-DD"),
          id: el.id
        })),
        travelBundlePictureMappings: data.photos
          .filter((e) => e.completed)
          .map((el) => ({
            displayOrder: el.displayOrder || 0,
            isCoverPicture: el.isCoverPicture,
            pictureId: el.pictureId
          })),
        travelBundleLocationMappings: data.locationIncludes.map((el) => ({
          locationId: el.id
        })),
        travelBundleIncludeMappings: data.packages.map((el) => ({
          travelBundleIncludeId: el.id
        })),
        locales: data.locales
      }
    })
      .then(() => {
        toast.success(
          mode === "edit"
            ? "Pako është ndryshuar  me sukses."
            : "Oferta është publikuar me sukses."
        );
        navigate(`/bundles/manage?pageindex=${state?.page ? state?.page : 1}`, {
          state: stateCrumbs
        });
      })
      .catch(() => {
        toast.error(
          `Ndodhi një gabim gjatë krijimit.${
            mode === "edit" ? "ndryshimit" : "krijimit"
          }.`
        );
      });
    // if (res?.status === 200) {
    //   toast.success(
    //     mode === "edit"
    //       ? "Pako është ndryshuar  me sukses."
    //       : "Oferta është publikuar me sukses."
    //   );
    //   navigate(`/bundles/manage?pageindex=${state?.page ? state?.page : 1}`, {
    //     state: stateCrumbs
    //   });
    // } else
      // toast.error(
      //   `Ndodhi një gabim gjatë krijimit.${
      //     mode === "edit" ? "ndryshimit" : "krijimit"
      //   }.`
      // );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="px-5">
        <UploadPhoto />
      </div>
      <span className="border-b border-gray-200 w-full block my-6" />
      <div className="px-5">
        <BasicDetails />
      </div>
      <span className="border-b border-gray-200 w-full block my-6" />
      <div className="px-5">
        <JourneyDetails />
        <Stations mode={mode} />
      </div>
      <Actions
        loading={loading}
        buttonTitle={mode === "edit" ? "Edito Pakon" : "Shto Pakon"}
      />
    </form>
  );
};

export default BundleDetails;
