/* eslint-disable max-len */
const NotfoundSvg = () => {
  return (
    <span className="container flex mx-auto flex-col justify-center">
      <svg
        width="970"
        height="400"
        viewBox="0 0 970 162"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.7"
          d="M107.276 39.8768C81.0138 35.8417 16.6092 88.2457 0 100.015C17.3908 117.991 101.062 123.837 198.138 122.906C295.214 121.975 423.828 107.257 476 100.015C423.632 70.9156 311.862 11.8642 283.724 8.44992C248.552 4.18207 230.379 31.3411 190.517 44.9206C150.655 58.5001 140.103 44.9206 107.276 39.8768Z"
          fill="url(#paint0_linear_6856_32429)"
        />
        <path
          d="M820.987 32.4195C858.952 32.4195 878.884 47.9458 909.256 65.8156C939.628 83.6853 970 108 970 108H261C309.089 87.4937 374.896 42.6727 425.2 42.6727C461.266 42.6727 479.774 65.8156 512.045 58.4919C536.988 52.8311 614.366 0 642.275 0C670.184 0 716.108 19.8228 736.989 26.5606C757.869 33.2984 796.469 32.4195 820.987 32.4195Z"
          fill="url(#paint1_linear_6856_32429)"
        />
        <path
          d="M478.458 139.006C484.645 143.974 493.385 143.974 499.572 139.006C511.052 129.79 524 115.256 524 97.5573C524 74.92 511.138 60 489.015 60C466.892 60 454.03 74.92 454.03 97.5573C454.03 115.256 466.979 129.79 478.458 139.006Z"
          fill="url(#paint2_linear_6856_32429)"
        />
        <rect
          width="12.3476"
          height="12.3476"
          rx="4.24449"
          transform="matrix(-1 0 0 1 495.188 149.516)"
          fill="url(#paint3_linear_6856_32429)"
        />
        <g clipPath="url(#clip0_6856_32429)">
          <path
            d="M409.814 59.1889C410.353 58.4029 411.208 57.8973 412.379 57.672C413.55 57.4467 415.058 57.4119 416.902 57.5677C418.748 57.7274 420.211 58.0473 421.291 58.5273C422.37 59.0074 423.108 59.6947 423.503 60.5892C423.897 61.4855 424.107 62.2901 424.135 63.003C424.15 64.0407 424.106 65.0785 424.004 66.1113L419.75 115.297C419.552 117.593 419.127 119.264 418.476 120.311C418.179 120.809 417.782 121.239 417.311 121.575C416.84 121.91 416.305 122.143 415.739 122.258C414.568 122.513 413.132 122.567 411.433 122.42C409.734 122.273 408.399 122.009 407.43 121.628C406.457 121.247 405.726 120.827 405.237 120.369C404.747 119.91 404.417 119.288 404.247 118.501C403.983 117.468 403.94 115.92 404.118 113.855L405.052 103.063L381.245 101.005C377.141 100.65 374.807 99.319 374.242 97.0129C374.026 96.045 373.987 94.764 374.125 93.1699C374.263 91.5758 374.452 90.4042 374.691 89.6552L384.546 60.6521C385.016 59.3263 385.415 58.321 385.744 57.6362C386.074 56.9514 386.657 56.3168 387.496 55.7324C388.342 55.158 389.38 54.9239 390.609 55.0303C391.976 55.1701 393.318 55.4962 394.597 55.9996C398.605 57.4758 400.494 59.5403 400.265 62.1932C400.183 63.1381 399.87 64.3601 399.327 65.859C399.273 65.9366 399.236 66.0245 399.217 66.1173L391.991 87.2379L406.308 88.476L408.397 64.3189C408.468 63.3129 408.601 62.3123 408.794 61.3225C408.934 60.6805 409.274 59.9693 409.814 59.1889Z"
            fill="url(#paint4_linear_6856_32429)"
          />
        </g>
        <g clipPath="url(#clip1_6856_32429)">
          <path
            d="M579.24 77.7493C579.648 76.8879 580.413 76.2533 581.533 75.8456C582.654 75.438 584.137 75.1652 585.983 75.0273C587.831 74.8929 589.326 74.9774 590.468 75.2807C591.61 75.584 592.447 76.146 592.978 76.9668C593.509 77.7895 593.844 78.5507 593.984 79.2502C594.164 80.2724 594.285 81.3041 594.346 82.3401L597.926 131.58C598.093 133.878 597.938 135.595 597.46 136.731C597.246 137.27 596.923 137.758 596.51 138.164C596.098 138.569 595.607 138.884 595.067 139.087C593.95 139.524 592.541 139.805 590.84 139.928C589.139 140.052 587.779 140.003 586.762 139.78C585.741 139.557 584.953 139.258 584.397 138.883C583.841 138.508 583.417 137.945 583.125 137.196C582.7 136.217 582.413 134.695 582.263 132.628L581.477 121.825L557.645 123.557C553.536 123.856 551.021 122.911 550.099 120.723C549.733 119.802 549.492 118.543 549.376 116.947C549.259 115.351 549.26 114.165 549.378 113.387L554.522 83.1904C554.776 81.807 555.011 80.7512 555.228 80.0229C555.445 79.2946 555.921 78.5757 556.656 77.8661C557.401 77.1651 558.389 76.7698 559.62 76.6804C560.992 76.6022 562.368 76.712 563.711 77.0067C567.901 77.8305 570.093 79.5703 570.286 82.226C570.355 83.172 570.24 84.4279 569.941 85.9939C569.9 86.0791 569.876 86.1718 569.872 86.2664L566.078 108.264L580.41 107.222L578.652 83.0387C578.564 82.0341 578.536 81.0251 578.57 80.0172C578.607 79.3612 578.83 78.6052 579.24 77.7493Z"
            fill="url(#paint5_linear_6856_32429)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_6856_32429"
            x1="238"
            y1="-3.5"
            x2="238"
            y2="123"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B1B1B1" />
            <stop offset="0.693559" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_6856_32429"
            x1="616"
            y1="-36.5"
            x2="603.5"
            y2="82"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B1B1B1" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_6856_32429"
            x1="487.955"
            y1="60"
            x2="487.955"
            y2="146.433"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F15B2D" />
            <stop offset="1" stopColor="#EE277B" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_6856_32429"
            x1="6.36088"
            y1="-1.84969e-07"
            x2="6.36088"
            y2="12.3476"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F15B2D" />
            <stop offset="1" stopColor="#EE277B" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_6856_32429"
            x1="401.83"
            y1="56.0007"
            x2="396.2"
            y2="121.103"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F15B2D" />
            <stop offset="1" stopColor="#EE277B" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_6856_32429"
            x1="570.853"
            y1="75.8638"
            x2="575.591"
            y2="141.037"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F15B2D" />
            <stop offset="1" stopColor="#EE277B" />
          </linearGradient>
          <clipPath id="clip0_6856_32429">
            <rect
              width="49"
              height="67"
              fill="white"
              transform="translate(376.772 53) rotate(4.94263)"
            />
          </clipPath>
          <clipPath id="clip1_6856_32429">
            <rect width="49" height="67"
              fill="white"
              transform="translate(545.636 76.8633) rotate(-4.15776)"
            />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};
export default NotfoundSvg;
