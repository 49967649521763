import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { cx } from "../../utils/classnames";
import Button from "../ui/Button";
import { SpinLoader } from "../ui/SpinLoader";

export interface IFormActions {
  loading: boolean;
  buttonTitle: string;
  disabled?: boolean;
  className?: string;
}

const Actions: FC<IFormActions> = ({
  loading,
  buttonTitle,
  disabled = false,
  className
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={cx([
        "py-4 px-5 flex justify-end items-center border-t border-gray-200",
        className
      ])}
    >
      <Button
        className="mr-4 border border-primary text-primary"
        padding="py-3 px-10"
        btnType="custom"
        onClick={() => navigate(-1)}
      >
        Anulo
      </Button>
      <Button
        padding="py-3 px-10"
        className="flex"
        type="submit"
        disabled={loading || disabled}
      >
        {loading ? <SpinLoader className="text-white mr-3" /> : null}
        {buttonTitle}
      </Button>
    </div>
  );
};

export default Actions;
