/* eslint-disable max-lines */

import { format, parse } from "date-fns";
import { useEffect } from "react";
import useDashboards from "../../hooks/useDashboards";
import useFetch from "../../hooks/useFetch";
import { getOperatorBundleAnalytics } from "../../services/Analytics.service";
import AccountProfileUsersIcon from "../shared/svgs/AccountProfileUsersIcon";
import FileImageListIcon from "../shared/svgs/FileImageListIcon";

const BundleAnalytics = () => {
  const { from, to } = useDashboards();

  const { response, loading, handleFunc } = useFetch(
    getOperatorBundleAnalytics,
    { loading: true }
  );

  useEffect(() => {
    if (from && to) {
      void handleFunc({
        startDate: format(parse(from, "dd/MM/yyyy", new Date()), "MM-dd-yyyy"),
        endDate: format(parse(to, "dd/MM/yyyy", new Date()), "MM-dd-yyyy")
      });
    }
  }, [from, to]);

  if (!response?.data.travelBundleDetails.length) return null;

  return (
    <>
      <div className="border border-gray-200 rounded-lg pl-6 py-4">
        <p className="text-neutral font-bold text-xs uppercase leading-6">
          Pakot
        </p>
        <div className="flex gap-20">
          <div className="flex flex-col justify-between min-w-[150px]">
            <div>
              <p className="text-neutral font-medium uppercase text-xs">
                Qarkullimi Total
              </p>
              <p className="text-4xl font-medium whitespace-nowrap">
                {loading ? (
                  <span className="bg-gray-200 w-24 h-8 rounded-lg inline-block animate-pulse" />
                ) : (
                  `${response?.data.totalSales} €`
                )}
              </p>
            </div>
            <div className="flex flex-col gap-3">
              {loading ? (
                <>
                  <span className="bg-gray-200 w-24 h-4 rounded-lg inline-block animate-pulse" />
                  <span className="bg-gray-200 w-28 h-4 rounded-lg inline-block animate-pulse" />
                </>
              ) : (
                <>
                  <p className="text-neutral font-medium uppercase text-xs flex items-center">
                    <FileImageListIcon className="mr-1.5" />
                    {response?.data.nrOfBundles} Pako Total
                  </p>
                  <p className="text-neutral font-medium uppercase text-xs  flex items-center">
                    <AccountProfileUsersIcon className="mr-1.5" size={16} />
                    {response?.data.nrOfTickets} Pasagjerë
                  </p>
                </>
              )}
            </div>
          </div>
          <div
            className="w-full h-[200px] flex flex-col gap-6 tableDiv overflow-auto border-l
           border-gray-200 mr-1.5 py-1"
          >
            {!loading && response?.data.travelBundleDetails.map((bundle) => (
              <div className="flex items-center justify-between mx-9">
                <div className="flex gap-3">
                  <img
                    src={bundle.coverPhotoUrl}
                    className="w-[44px] h-[44px] rounded-md object-cover"
                    style={{ boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.25)" }}
                  />
                  <div className="flex flex-col gap-2.5">
                    <p className="text-sm font-semibold leading-4">
                      {bundle.travelBundleTitle}
                    </p>
                    <div className="flex gap-1 items-center">
                      <span
                        className="text-2xs font-semibold text-green px-2 leading-[18px]
                    rounded-full bg-green-100 bg-opacity-20"
                      >
                        Activ
                      </span>
                      <p className="text-neutral font-medium text-xs flex items-center gap-2">
                        <span className="w-1 h-1 bg-neutral rounded-full block" />
                        <AccountProfileUsersIcon size={16} />
                        <span>{bundle.nrOfTickets} Pasagjerë</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="text-lg font-semibold text-right">
                    {bundle.totalSales} €
                  </p>
                  <p className="text-xs text-neutral font-medium uppercase">
                    Shitje Totale
                  </p>
                </div>
              </div>
            ))}
            {loading ? (
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default BundleAnalytics;

const Skeleton = () => (
  <div className="flex items-center justify-between mx-9">
    <div className="flex gap-3">
      <div
        className="w-[44px] h-[44px] rounded-md bg-gray-200 animate-pulse"
        style={{ boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.25)" }}
      ></div>
      <div className="flex flex-col gap-2.5">
        <div className="text-sm font-semibold leading-4 w-36 bg-gray-200 h-4 rounded-lg animate-pulse" />
        <div className="flex gap-1 items-center">
          <span
            className="px-2 w-10 h-4
    rounded-full bg-gray-200 animate-pulse"
          />
          <span className="bg-gray-200 w-32 h-4 rounded-lg animate-pulse" />
        </div>
      </div>
    </div>
    <div className="flex flex-col gap-1.5 items-end justify-between">
      <p className="bg-gray-200 h-5  rounded-lg animate-pulse w-28" />
      <p className="bg-gray-200 h-4  rounded-lg animate-pulse w-36" />
    </div>
  </div>
);
