/* eslint-disable max-len */

import { FC } from "react";
import { IIcon } from "./IIcon";

const Location: FC<IIcon> = ({ color, size }) => {
  const currentColor = color || "currentColor";
  const currentSize = size || 16;
  return (
    <span>
      <svg
        width={currentSize}
        height={currentSize}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-auto h-auto"
      >
        <path
          d="M9.00026 17.07C7.89025 17.07 6.77275 16.65 5.90275 15.8175C3.69025 13.6875 1.24525 10.29 2.16775 6.2475C3.00025 2.58 6.20275 0.9375 9.00026 0.9375C9.00026 0.9375 9.00026 0.9375 9.00776 0.9375C11.8053 0.9375 15.0078 2.58 15.8403 6.255C16.7553 10.2975 14.3103 13.6875 12.0978 15.8175C11.2278 16.65 10.1103 17.07 9.00026 17.07ZM9.00026 2.0625C6.81775 2.0625 4.01275 3.225 3.27025 6.495C2.46025 10.0275 4.68025 13.0725 6.69025 15C7.98775 16.2525 10.0203 16.2525 11.3178 15C13.3203 13.0725 15.5403 10.0275 14.7453 6.495C13.9953 3.225 11.1828 2.0625 9.00026 2.0625Z"
          fill={currentColor}
        />
        <path
          d="M10.5004 10.2826C10.3579 10.2826 10.2154 10.2301 10.1029 10.1176L7.13285 7.14758C6.91535 6.93008 6.91535 6.57008 7.13285 6.35258C7.35035 6.13508 7.71035 6.13508 7.92785 6.35258L10.8979 9.32258C11.1154 9.54008 11.1154 9.90008 10.8979 10.1176C10.7854 10.2226 10.6429 10.2826 10.5004 10.2826Z"
          fill={currentColor}
        />
        <path
          d="M7.50008 10.3119C7.35758 10.3119 7.21508 10.2594 7.10258 10.1469C6.88508 9.92937 6.88508 9.56937 7.10258 9.35187L10.0726 6.38187C10.2901 6.16438 10.6501 6.16438 10.8676 6.38187C11.0851 6.59937 11.0851 6.95937 10.8676 7.17687L7.89758 10.1469C7.78508 10.2594 7.64258 10.3119 7.50008 10.3119Z"
          fill={currentColor}
        />
      </svg>
    </span>
  );
};

export default Location;
