import { CheckIcon } from "@heroicons/react/24/outline";
import { forwardRef } from "react";
import { cx } from "../../../utils/classnames";
import { InputProps } from "./Input.types";

const Checkbox = forwardRef<
  HTMLInputElement,
  Omit<InputProps, "after" | "before" | "type" | "paddingType" | "border">
>(({ label, className, error, inputClassName, ...props }, ref) => {
  return (
    <div className={cx([className])}>
      <label
        htmlFor={props.id || props.name || "random_checkbox"}
        className={cx([
          "text-sm font-medium text-black select-none flex justify-start items-center group cursor-pointer",
          "gap-2",
          props.disabled && "cursor-not-allowed opacity-70",
          inputClassName
        ])}
      >
        <input
          {...props}
          type="checkbox"
          id={props.id || props.name || "random_checkbox"}
          ref={ref}
          autoComplete="off"
          className="hidden"
        />
        <div
          className={cx([
            "h-4 w-4 flex items-center justify-center text-white ring-1 rounded transform duration-300",
            props.checked
              ? "bg-primary ring-primary"
              : "bg-white ring-neutral-300 group-hover:ring-neutral"
          ])}
          style={{ willChange: "transform" }}
        >
          <CheckIcon
            stroke="currentColor"
            className={`h-3 w-3 ${props.checked ? "" : "hidden"}`}
          />
        </div>
        {label && <span className="text-sm whitespace-nowrap">{label}</span>}
      </label>
      {typeof error === "string" && (
        <div className="text-red-500 text-xs mt-0.5">{error}</div>
      )}
    </div>
  );
});

export default Checkbox;
