/* eslint-disable max-lines */
import {
  endOfWeek,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYesterday,
  sub
} from "date-fns";
import { FC, useEffect, useState } from "react";
import { DateRange, SelectRangeEventHandler } from "react-day-picker";
import { cx } from "../../../utils/classnames";

interface IHelper {
  selected?: DateRange | undefined;
  onSelect?: SelectRangeEventHandler;
}

const Item: FC<{
  active: boolean;
  text: string;
  onClick: (e: any) => void;
}> = ({ active, text, onClick }) => (
  <div
    className={cx([
      "cursor-pointer duration-300 transition-all w-[105px] text-sm p-2",
      "rounded hover:bg-primary hover:bg-opacity-5 hover:text-primary",
      active && "text-primary bg-primary bg-opacity-5"
    ])}
    onClick={onClick}
  >
    {text}
  </div>
);

const Helper: FC<IHelper> = ({ selected, onSelect }) => {
  const [active, setActive] = useState<
    "today" | "yesterday" | "last_week" | "this_month" | "custom" | null
  >(null);
  const today = startOfDay(new Date());
  useEffect(() => {
    if (
      selected?.from && selected?.to
        ? isSameDay(selected?.from, today) && isSameDay(selected?.to, today)
        : false
    )
      setActive("today");
    else if (
      selected?.from && selected?.to
        ? isSameDay(selected?.from, startOfYesterday()) &&
          isSameDay(selected?.to, startOfYesterday())
        : false
    )
      setActive("yesterday");
    else if (
      selected?.from && selected?.to
        ? isSameDay(
            selected?.from,
            startOfWeek(sub(today, { weeks: 1 }), { weekStartsOn: 1 })
          ) &&
          isSameDay(
            selected?.to,
            endOfWeek(sub(today, { weeks: 1 }), { weekStartsOn: 1 })
          )
        : false
    )
      setActive("last_week");
    else if (
      selected?.from && selected?.to
        ? isSameDay(selected?.from, startOfMonth(today)) &&
          isSameDay(selected?.to, today)
        : false
    )
      setActive("this_month");
    else setActive("custom");
  }, []);

  return (
    <div
      className="mt-28 border-l border-blue-stroke pr-5 pl-3 flex flex-col gap-1 h-[244px]
     overflow-y-auto"
    >
      <Item
        text="Sot"
        active={active === "today"}
        onClick={(e) => {
          if (onSelect && active !== "today") {
            setActive("today");
            onSelect({ from: today, to: today }, today, {}, e);
          }
        }}
      />
      <Item
        text=" Dje"
        active={active === "yesterday"}
        onClick={(e) => {
          if (onSelect && active !== "yesterday") {
            setActive("yesterday");
            onSelect(
              { from: startOfYesterday(), to: startOfYesterday() },
              startOfYesterday(),
              {},
              e
            );
          }
        }}
      />
      <Item
        text=" Java e kaluar"
        active={active === "last_week"}
        onClick={(e) => {
          if (onSelect && active !== "last_week") {
            setActive("last_week");
            onSelect(
              {
                from: startOfWeek(sub(today, { weeks: 1 }), {
                  weekStartsOn: 1
                }),
                to: endOfWeek(sub(today, { weeks: 1 }), { weekStartsOn: 1 })
              },
              endOfWeek(today),
              {},
              e
            );
          }
        }}
      />
      <Item
        text=" Tërë muajin"
        active={active === "this_month"}
        onClick={(e) => {
          if (onSelect && active !== "this_month") {
            setActive("this_month");
            onSelect({ from: startOfMonth(today), to: today }, today, {}, e);
          }
        }}
      />
      <Item
        text=" Datë tjetër"
        active={active === "custom"}
        onClick={(e) => {
          if (onSelect && active !== "custom") {
            setActive("custom");
            onSelect(
              { from: startOfWeek(today, { weekStartsOn: 1 }), to: today },
              today,
              {},
              e
            );
          }
        }}
      />
    </div>
  );
};

export default Helper;
