/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/indent */
import { XMarkIcon } from "@heroicons/react/24/outline";
import { FC, ReactNode, useEffect } from "react";
import { createPortal } from "react-dom";
import checkDevice from "../../utils/isTouch";
import Animation, { AnimationProps } from "./Animation";
import Button from "./Button";
import IconBtn from "./IconBtn";

export interface PortalProps {
  onClose?: () => void;
  show: boolean;
  title?: ReactNode;
  position: "side" | "center" | "top";
  // fireEvent?: () => void;
  buttonElement?: ReactNode;
  children?: ReactNode;
  modalWidth?: string;
  modalHeight?: string;
  modalWidthTouch?: string;
  hasHeader?: false;
  mini?: boolean;
  calcHeight?: boolean;
}

export type Keys = {
  [key: string]: () => void;
};

export const Modal: FC<PortalProps> = ({
  children,
  show,
  onClose,
  title,
  position,
  buttonElement,
  modalWidth,
  modalHeight,
  modalWidthTouch = "100%",
  hasHeader = true,
  mini,
  calcHeight = true
}) => {
  const isTouch = checkDevice();
  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      const keys: Keys = {
        Escape: () => {
          e.preventDefault();
          window.removeEventListener("keyup", handleKeyUp, false);
          onClose && onClose();
        }
      };
      if (keys[e.key]) {
        keys[e.key]();
      }
    };

    window.addEventListener("keyup", handleKeyUp, false);
    show && document.body.classList.add("overflow-hidden");

    return () => {
      window.removeEventListener("keyup", handleKeyUp, false);
      show && document.body.classList.remove("overflow-hidden");
    };
  }, [show]);

  // let modalPosition;
  let modalPosition =
    "md:w-80 w-full h-full right-0 top-0 bottom-0 left-0 md:left-auto md:bottom:auto";
  let animationType: AnimationProps["type"] = "fade-right";

  if (position === "center") {
    modalPosition = `${mini
        ? "max-h-[175px] max-w-[350px]"
        : "md:min-h-fit md:max-h-[550px] md:w-[500px]"
      }
    m-auto inset-0  rounded-lg`;
    animationType = "fade-out";
  } else if (position === "top") {
    modalPosition = "m-auto w-max top-32 left-0 right-0 rounded-lg";
    animationType = "fade-in";
  }

  // if (position === "side")
  //   modalPosition =
  //     "md:w-80 w-full h-full right-0 top-0 bottom-0 left-0 md:left-auto md:bottom:auto";
  // else if (position === "center") {
  //   modalPosition =
  //     "md:w-[500px] md:min-h-[250px] md:max-h-[550px] md:m-auto inset-0 rounded-lg";
  // }
  return show
    ? createPortal(
      <div className="fixed inset-0 z-50">
        <Animation show={show} type="fade" duration={300}>
          <span
            onClick={onClose}
            className={"absolute bg-black bg-opacity-20 inset-0 z-40"}
          />
        </Animation>
        <Animation
          show={show}
          type={animationType}
          duration={300}
          translateShift={{ out: "translate-x-80" }}
        >
          <div
            style={{
              width: !isTouch ? modalWidth : modalWidthTouch, height: modalHeight
            }}
            className={`absolute bg-white overflow-hidden z-50 ${modalPosition}`}
          >
            {hasHeader ? (
              <div className="flex shadow items-center px-4 py-1 justify-between h-14">
                <div>{title}</div>
                <IconBtn onClick={onClose}>
                  <XMarkIcon className="w-5 h-5" />
                </IconBtn>
              </div>
            ) : null}
            <div
              className="overflow-y-auto overflow-x-hidden"
              style={{
                minHeight: `${calcHeight && buttonElement
                    ? "calc(100% - 7rem)"
                    : "calc(100% - 4rem)"
                  }`,
                height: `${calcHeight && buttonElement
                    ? "calc(100% - 7rem)"
                    : "calc(100% - 4rem)"
                  }`
              }}
            >
              {children}
            </div>
            {buttonElement && (
              <div
                className={`w-full  flex items-center ${position === "side"
                    ? "shadow-lg shadow-black-900 justify-center h-16"
                    : `${mini
                      ? "justify-center px-6 w-full"
                      : "justify-end px-4"
                    } h-16`
                  }`}
              >
                {position === "center" && (
                  <Button
                    btnType="custom"
                    className={`mr-4 border ${mini ? "w-auto" : ""}`}
                    onClick={onClose}
                  >
                    Anulo
                  </Button>
                )}
                {buttonElement}
              </div>
            )}
          </div>
        </Animation>
      </div>,
      document.body
    )
    : null;
};

export default Modal;
