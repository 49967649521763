import { PencilIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { FC } from "react";
import { Link } from "react-router-dom";
import usePageIndex from "../../../hooks/usePageIndex";
import { BundleListType } from "../../../interfaces/bundles/Bundles.types";
import { deleteBundleById } from "../../../services/Bundles.service";
import AuditAuthor from "../../audit-author/AuditAuthor";
import DeleteModal from "../../ui/DeleteModal";
import IconBtn from "../../ui/IconBtn";
import Status from "../../ui/Status";
import { Row } from "../../ui/table";
import Can from "../../users/permissions/Can";

interface IBundleRow {
  row: BundleListType;
  onDeleteSuccess: () => void;
}

const BundleRow: FC<IBundleRow> = ({ row, onDeleteSuccess }) => {
  const { pageIndex, state }: any = usePageIndex();
  const highlighted = +state?.params?.id === row.id;
  return (
    <Row
      id={row.id}
      className={`${highlighted && "highlighted"} group`}
      title={row.title.slice(0, 30) + (row.title.length > 30 ? "..." : "")}
      travelBundlePrice={<>{row.travelBundleDetails[0]?.travelBundlePrice}€</>}
      fromDate={
        <>
          {dayjs(row.travelBundleDetails[0]?.fromDate).format("DD/MM/YYYY")}
          <span className="mx-1">-</span>
          {dayjs(row.travelBundleDetails[0]?.toDate).format("DD/MM/YYYY")}
        </>
      }
      maxReservationCount={row.travelBundleDetails[0]?.maxReservationCount}
      bundleStatus={
        <Status
          name={row.bundleStatus ? "Aktive" : "Joaktive"}
          type={row.bundleStatus ? "green" : "gray"}
        />
      }
      auditAuthor={
        <AuditAuthor
          createdByName={row.auditAuthor.createdByName}
          createdByLastName={row.auditAuthor.createdByLastName}
          updatedByName={row.auditAuthor.updatedByName}
          updatedByLastName={row.auditAuthor.updatedByLastName}
          insertDate={row.auditAuthor.insertDate}
          updateDate={row.auditAuthor.updateDate}
        />}
      actions={
        <span className="flex items-center">
          <Can permissions="travelbundles.travelbundle.update">
            <Link
              to={`/bundles/manage/edit/${row.id}`}
              state={{ crumbs: ["Menaxhimi i ofertave", "Ofertat e udhëtimit", row.title],
              page: pageIndex }}
            >
              <IconBtn className="w-6 h-6">
                <PencilIcon className="w-4 h-4 text-neutral" />
              </IconBtn>
            </Link>
          </Can>
          <Can permissions="travelbundles.travelbundle.delete">
            <DeleteModal
              params={row.id}
              title="Konfirmo fshirjen e pakos."
              handleDelete={deleteBundleById}
              successMessage="Pako është fshire me sukses."
              errorMessage="Pako nuk është fshire me sukses."
              loadingMessage="Pako është duke u fshire..."
              onSuccess={onDeleteSuccess}
            />
          </Can>
        </span>
      }
    />
  );
};

export default BundleRow;
