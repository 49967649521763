/* eslint-disable max-lines */
import dayjs from "dayjs";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { ITripType } from "../../../interfaces/endpoints/trips/Trips.types";
import Input from "../../ui/input/Input";
import Select from "../../ui/input/Select";
import useTimeList from "../../ui/input/TimePicker";
// import { default as useTimeList } from "../../ui/input/TimePicker";
import { useSearchParams } from "react-router-dom";
import CreateTripWrapper from "./schedules/CreateTripWrapper";

interface NewTripProps {
  response: any;
  editMode: boolean;
}

const NewTrip: FC<NewTripProps> = ({ response, editMode }) => {
  const {
    register,
    getValues,
    setValue,
    formState: { errors }
  } = useFormContext<ITripType>();

  const [searchParams] = useSearchParams();

  const updateDuration = () => {
    const ft = dayjs(`2000-01-01 ${getValues("trip.departureTimeString")}`);
    const tt = dayjs(`2000-01-01 ${getValues("trip.arrivalTimeString")}`);
    const mins: number = Number(tt.diff(ft, "minutes", true));
    setValue("trip.duration", mins);
  };

  const times = useTimeList();

  return (
    <CreateTripWrapper title="Orari">
      <div
        className="absolute right-5 top-0 flex items-center first-letter flex-col
      "
      >
        <div
          className="flex items-center first-letter
        transitions hover:scale-[1.01] cursor-pointer transition-all duration-75 select-none"
        >
          <input
            type="checkbox"
            id="reserve"
            className="cursor-pointer text-primary bg-primary"
            {...register("trip.isReservable")}
          />
          <label
            htmlFor="reserve"
            className="ml-3 text-sm cursor-pointer  text-primary font-semibold
         "
          >
            Bëje të rezervueshme në GjirafaTravel
          </label>
        </div>
        {typeof errors.trip?.isReservable?.message === "string" && (
          <div className="text-red-500 text-xs mt-0.5">
            {errors.trip?.isReservable?.message}
          </div>
        )}
      </div>
      <div className="md:flex-row flex-col md:flex w-full">
        <div className="mb-6 w-full md:w-1/2 md:pr-4">
          {!editMode ? (
            <Select
              {...register("trip.lineId", { valueAsNumber: true })}
              {...(!editMode
                ? {
                    options: response?.data?.result.length
                      ? response?.data?.result.map((el: any) => {
                          return { name: el.label, id: el.value };
                        }) || []
                      : searchParams.has("lineName") &&
                        searchParams.has("lineId")
                      ? [
                          {
                            name: searchParams.get("lineName"),
                            id: searchParams.get("lineId")
                          }
                        ]
                      : [],
                    hasEmptyOption:
                      !searchParams.has("lineName") &&
                      !searchParams.has("lineId")
                  }
                : {
                    options:
                      response?.data?.result.map((el: any) => {
                        return { name: el.label, id: el.value };
                      }) || []
                  })}
              error={errors.trip?.lineId?.message}
              label="Zgjedh linjën"
              placeholder="Zgjedh"
            />
          ) : (
            <Input
              {...register("routeName")}
              label="Linja e zgjedhur"
              className="pointer-events-none"
              inputClassName="h-11"
            />
          )}
        </div>
        <div className="w-full md:w-1/2 flex">
          <div className="mb-6 w-2/5 pr-4">
            <Select
              label="Ora e nisjes"
              {...register("trip.departureTimeString")}
              onBlur={updateDuration}
              error={errors.trip?.arrivalTimeString?.message}
              options={times}
            />
          </div>
          <div className="mb-6 w-2/5 pr-4">
            <Select
              label="Ora e arritjes"
              {...register("trip.arrivalTimeString")}
              onBlur={updateDuration}
              error={errors.trip?.arrivalTimeString?.message}
              options={times}
            />
          </div>
          <Select
            {...register("trip.defaultCurrencyId", { valueAsNumber: true })}
            options={[
              {
                id: 1,
                name: "Euro"
              },
              {
                id: 2,
                name: "Lekë"
              }
            ]}
            error={errors.trip?.defaultCurrencyId?.message}
            label="Valuta"
            placeholder="Zgjedh"
            className="w-1/5"
            hasEmptyOption={false}
          />
        </div>
      </div>
    </CreateTripWrapper>
  );
};

export default NewTrip;
