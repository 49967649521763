export const noticePriority = [
  { label: "High", value: 1 },
  { label: "Medium", value: 2 },
  { label: "Low", value: 3 }
];
export const noticeType =[
  { label: "Service Disruption", value: 1 },
  { label: "Road Closure Or Diversions", value: 2 },
  { label: "Special Events Or Promotions", value: 3 },
  { label: "Seating Information", value: 4 },
  { label: "Luggage Restrictions", value: 5 },
  { label: "Health And Safety Guidelines", value: 6 },
  { label: "Payment Or Ticketing Notices", value: 7 },
  { label: "Amenities On Board", value: 8 },
  { label: "Weather Alerts", value: 9 },
  { label: "Emergency Information", value: 10 },
  { label: "Rules And Regulations", value: 11 },
  { label: "Bus Upgrades Or Changes", value: 12 },
  { label: "Other", value: 13 }
];
