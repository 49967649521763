import { FC } from "react";

export interface INotFoundForm {
  message?: string;
}

const NotFoundForm: FC<INotFoundForm> = ({ message }) => {
  return (
    <div
      className="bg-warning bg-opacity-60 px-4 py-20 rounded-lg ring
     ring-warning flex justify-center items-center flex-col text-black"
    >
      <h2 className="font-bold">404</h2>
      <p>{message || "Not found"}</p>
    </div>
  );
};

export default NotFoundForm;
