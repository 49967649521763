import { useLayoutEffect, useState } from "react";
export interface useWindowSizeProps {
  width?: number;
  height?: number;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  "2xl": boolean;
}
// Hook
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<useWindowSizeProps>({
    width: undefined,
    height: undefined,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    "2xl": false
  });

  useLayoutEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        sm: window.innerWidth < 540,
        md: window.innerWidth < 720 && window.innerWidth >= 540,
        lg: window.innerWidth < 960 && window.innerWidth >= 720,
        xl: window.innerWidth < 1140 && window.innerWidth >= 960,
        "2xl": window.innerWidth >= 1140
      });
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

export default useWindowSize;

// sm: "540px",
// md: "720px",
// lg: "960px",
// xl: "1140px",
// 2xl
