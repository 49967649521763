type resultType = { [key: string]: string };

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const parseQueryString = <T extends resultType>(search: string): T  => {
  const _search = search.replace("?","");
  const pairs = _search.split("&");

  const result: resultType = {};
  pairs.forEach((pair) => {
    const _pair = pair.split("=");
    result[_pair[0]] = decodeURIComponent(_pair[1] || "");
  });

  return JSON.parse(JSON.stringify(result));
};
