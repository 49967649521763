import { format } from "date-fns";
import { useEffect, useState } from "react";
import { LineSelectList } from "../interfaces/endpoints/lines/ILines";

const defaultLocation = {
  label: "All",
  oldValue: 0,
  parentValue: 0,
  selected: false,
  value: 0
};

const defaultDates = {
  from: format(
    new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000),
    "dd/MM/yyyy"
  ),
  to: format(new Date(), "dd/MM/yyyy")
};

type DatesType = {
  from: string;
  to: string;
};

const useDashboards = () => {
  const [line, setLine] = useState<LineSelectList | null>(null);
  const [dates, setDates] = useState<DatesType>();

  useEffect(() => {
    const storageDashboadLine = () => {
      const localLine = getLocalLine();
      if (localLine === null) setLocalLine(defaultLocation);
      else setLine(localLine);
    };
    const storageDashboadDates = () => {
      const localDates = getLocalDates();
      if (localDates === null) setLocalDates(defaultDates);
      else setDates(localDates);
    };

    window.addEventListener("storage:operators-dashboard-dates-change", storageDashboadDates);
    window.dispatchEvent(new Event("storage:operators-dashboard-dates-change"));

    window.addEventListener(
      "storage:operator-dashboard-line-change",
      storageDashboadLine
    );
    window.dispatchEvent(new Event("storage:operator-dashboard-line-change"));
    return () => {
      window.removeEventListener("storage:operators-dashboard-dates-change", storageDashboadDates);
      window.removeEventListener(
        "storage:operator-dashboard-line-change",
        storageDashboadLine
      );
    };
  }, []);

  const getLocalDates = () => {
    const localDates = sessionStorage.getItem("dashboard:operators-dates");
    try {
      const mainRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/\d{4}$/;
      const _dates: DatesType = JSON.parse(localDates || "");
      if (mainRegex.test(_dates.from) && mainRegex.test(_dates.to))
        return _dates;
      else return null;
    } catch (error) {
      return null;
    }
  };

  const setLocalDates = (d: DatesType) => {
    sessionStorage.setItem("dashboard:operators-dates", JSON.stringify(d));
    window.dispatchEvent(new Event("storage:operators-dashboard-dates-change"));
  };

  const getLocalLine = () => {
    const localLine = sessionStorage.getItem("dashboard:operators-line");
    try {
      const _line: LineSelectList = JSON.parse(localLine || "");
      if (_line.value !== undefined) return _line;
      else return null;
    } catch (error) {
      return null;
    }
  };

  const setLocalLine = (d: LineSelectList | null) => {
    sessionStorage.setItem("dashboard:operators-line", JSON.stringify(d));
    window.dispatchEvent(new Event("storage:operator-dashboard-line-change"));
  };

  return { line, setLocalLine, defaultLocation, ...dates, setLocalDates };
};

export default useDashboards;
