import axios from "axios";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import {
  EditPermissionType,
  IPermission
} from "../interfaces/endpoints/IPermission.types";

export const getAllPermissions = async (): Promise<
  IAxiosResponse<IPermission>
> => {
  return axios.get("Permission/Permissions");
};

export const editPermissions = async (
  data: EditPermissionType[]
): Promise<IAxiosResponse<unknown>> => {
  return axios.post("Permission", data);
};
