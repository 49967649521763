export const paymentStatus = [
  { label: "I konfirmuar", value: 1 },
  { label: "I pa përfunduar", value: 2 },
  { label: "I anuluar", value: 3 },
  { label: "I rimbursuar", value: 4 }
];
export const paymentMethods = [
  { label: "None", value: 0 },
  { label: "Raiffeisen", value: 1 },
  { label: "Stripe", value: 2 }
];
