import HeaderPage from "../../components/lines-managements/header/HeaderPage";
import Reservations from "../../components/reservation_v2/Reservations";

const ReservationsPage = () => {

  return (
    <>
      <HeaderPage
        hasFilter
        filterName="Filtro Rezervimet"
        filterSelctor="statusId"
        options={[
          { label: "I konfirmuar", id: "1" },
          { label: "I pa përfunduar", id: "2" },
          { label: "I anuluar", id: "3" },
          { label: "I rimbursuar", id: "4" }
        ]}
      />
      <Reservations />
    </>
  );
};

export default ReservationsPage;
