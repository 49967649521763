import { Dispatch, SetStateAction, createContext, useState } from "react";
import { DateRange } from "react-day-picker";
import { Outlet } from "react-router-dom";
import ICreateReservationSchema from "../interfaces/endpoints/officeSales/ICreateReservationSchema";

export type tripType = "departure" | "return";

export type selectTripType = Record<tripType, number>;

export type passagersType = {
  adult: number;
  children: number;
  baby: number;
};

export type tripTypeData = {
  id: number;
  departureLocation: string;
  departureTime: string;
  arrivalLocation: string;
  arrivalTime: string;
  fromStationId: number;
  toStationId: number;
  price: string;
};
export type Data = {
  departure: tripTypeData;
  return?: tripTypeData;
  passagers: passagersType;
  date: DateRange | undefined;
};
export interface SummaryData {
  phoneNumber: string;
  email: string;
  departure: tripTypeData;
  return?: tripTypeData;
  passagers: ICreateReservationSchema["passagers"];
  paymentMethod: number;
  departureDate: string;
  returnDate?: string;
  price: number;
}

interface OfficeSalesData {
  data: Data;
  setData: Dispatch<SetStateAction<Data>>;
  selectedTripId: selectTripType;
  setSelectedTripId: Dispatch<SetStateAction<selectTripType>>;
  summData: SummaryData;
  setSumData: Dispatch<SetStateAction<SummaryData>>;
}

export const OfficeSalesContext = createContext<OfficeSalesData>(
  {} as OfficeSalesData
);

export const OfficeSalesProvider = () => {
  const [selectedTripId, setSelectedTripId] = useState<selectTripType>(
    {} as selectTripType
  );
  const [data, setData] = useState<Data>({} as Data);
  const [summData, setSumData] = useState<SummaryData>({} as SummaryData);

  return (
    <OfficeSalesContext.Provider
      value={{
        data,
        setData,
        selectedTripId,
        setSelectedTripId,
        summData,
        setSumData
      }}
    >
      <Outlet />
    </OfficeSalesContext.Provider>
  );
};
