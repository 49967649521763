/* eslint-disable max-lines */
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm
} from "react-hook-form";
import toast from "react-hot-toast";
import "react-quill/dist/quill.snow.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import OperatorImage from "../../../components/operators/OperatorImage";
import FormWrapper from "../../../components/ui/FormWrapper";
import Input from "../../../components/ui/input/Input";
import Select from "../../../components/ui/select/Select";
import useFetch from "../../../hooks/useFetch";
import useNavigateState from "../../../hooks/useNavigateState";
import { IOperatorSchema } from "../../../interfaces/endpoints/IOperators";
import { getAllCountries } from "../../../services/Locations.service";
import {
  createOperators,
  editOperators,
  getCurrentOperators
} from "../../../services/Operators.service";
import { OperatorsSchema } from "../../../utils/validations/Operators.schema";

interface CreateOperatorProps {
  mode: "create" | "edit";
}

const CreateOperator: React.FC<CreateOperatorProps> = ({ mode }) => {
  const { id } = useParams();
  const stateCrumbs = useNavigateState(["Operatoret"]);
  const { state }: any = useLocation();
  const methods = useForm<IOperatorSchema>({
    resolver: yupResolver(OperatorsSchema),
    defaultValues: {
      operatorPictureMappings: [],
      // countryId: {},
      email: "",
      operatorName: "",
      phoneNumber: "",
      headQuarters: ""
    }
  });

  const {
    handleSubmit,
    register,
    control,
    formState: { errors }
  } = methods;
  const navigate = useNavigate();

  const { response, loading } = useFetch(getCurrentOperators, {
    initRequest: mode === "edit",
    params: Number(id)
  });

  useEffect(() => {
    if (mode === "edit" && response && response?.data?.id && !loading) {
      methods.reset({
        id: response.data.id,
        countryId: {
          id: response.data.country.id,
          countryName: response.data.country.countryName
        },
        email: response.data.email,
        headQuarters: response.data.headQuarters,
        operatorName: response.data.operatorName,
        phoneNumber: response.data.phoneNumber,
        bankAccountNumber: response.data.bankAccountNumber,
        fiscalNumber: response.data.fiscalNumber,
        operatorPictureMappings: response.data.operatorPictureMappings
      });
    }
  }, [response]);

  const onSubmit: SubmitHandler<IOperatorSchema> = async (data) => {
    if (mode === "edit") {
      await editOperators({
        ...data,
        id: data.id,
        countryId: data.countryId.id,
        email: data.email,
        headQuarters: data.headQuarters,
        operatorName: data.operatorName,
        phoneNumber: data.phoneNumber
      })
        .then(() => {
          toast.success("Operatori është ndryshuar me sukses.", {
            position: "top-right"
          });
          setTimeout(() => {
            navigate(`/operators?pageindex=${state?.page ? state?.page : 1}`, {
              state: stateCrumbs
            });
          }, 500);
        })
        .catch(() => toast.error("Ndodhi një gabim gjatë ndryshimit."));
    } else {
      await createOperators({
        ...data,
        countryId: data.countryId.id,
        email: data.email,
        headQuarters: data.headQuarters,
        operatorName: data.operatorName,
        phoneNumber: data.phoneNumber
      })
        .then(() => {
          toast.success("Operatori është shtuar me sukses.", {
            position: "top-right"
          });
          setTimeout(() => {
            navigate("/operators", { state: ["Operatorët"] });
          }, 500);
        })
        .catch(() =>
          toast.error("Ndodhi një gabim gjatë krijimit.", {
            position: "top-right"
          })
        );
    }
  };

  return (
    <FormProvider {...methods}>
      <Link
        className="flex items-center mb-4 text-sm font-semibold text-primary"
        to={"/operators"}
        state={["Operatorët"]}
      >
        <ChevronLeftIcon className="w-4 h-4 mr-2" />
        <span>Kthehu tek lista</span>
      </Link>
      <FormWrapper
        showPrompt={
          !methods.formState.isSubmitSuccessful &&
          !!Object.keys(methods.formState.dirtyFields).length
        }
        onSubmit={handleSubmit(onSubmit)}
        title={
          mode === "edit"
            ? "Modifiko të dhënat e operatorit"
            : "Shto operator të ri"
        }
        className="max-w-full mx-auto"
      >
        <OperatorImage mode={mode} />
        <div className="flex flex-row w-full gap-3 mb-6">
          <Input
            {...register("operatorName")}
            label="Emri i operatorit*"
            className="w-1/3"
            placeholder="Shkruaj emrin e operatorit këtu..."
            error={errors?.operatorName?.message}
          />
          <Controller
            control={control}
            name="countryId"
            rules={{ required: "Vendos shtetin e vendndodhjes së operatorit!" }}
            render={({ field: { value, onChange } }) => {
              return (
                <Select
                  className="w-1/3"
                  label="Shteti*"
                  placeholder="Zgjedh shtetit e vendndodhjes"
                  onSelectedItemChange={onChange}
                  selectedItem={value}
                  getOptionLabel={(o) => o.countryName}
                  getOptionValue={(o) => o?.id}
                  itemToString={(o) => o?.countryName || ""}
                  asyncFunc={getAllCountries}
                  error={errors?.countryId?.message}
                />
              );
            }}
          />
          <Input
            {...register("headQuarters")}
            label="Adresa e selisë kryesore*"
            className="w-1/3"
            placeholder="Shkruaj selinë kryesore të operatorit këtu..."
            error={errors?.headQuarters?.message}
          />
        </div>
        <div className="flex flex-row w-full gap-3 mb-6">
          <Input
            {...register("email")}
            label="Email adresa"
            className="w-1/2"
            placeholder="Shkruaj email adresën e operatorit këtu..."
          />
          <Input
            {...register("phoneNumber")}
            type="number"
            label="Numri kontaktues"
            className="w-1/2"
            placeholder="Shkruaj numrin kontaktues të operatorit këtu..."
          />
        </div>
        <div className="flex flex-row w-full gap-3 mb-6">
          <Input
            {...register("fiscalNumber")}
            // type="number"
            label="Numri i biznesit"
            className="w-1/2"
            placeholder="Shkruaj numrin e biznesit këtu..."
          />
          <Input
            {...register("bankAccountNumber")}
            type="number"
            label="Numri i llogarisë së bankës"
            className="w-1/2"
            placeholder="Shkruaj numrin e llogarisë së bankēs të operatorit këtu..."
          />
        </div>
      </FormWrapper>
    </FormProvider>
  );
};

export default CreateOperator;
