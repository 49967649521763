import { useEffect, useState } from "react";
import { OperatorsSelectList } from "../interfaces/endpoints/IOperators";

const defaultOperatorLocation = {
  operatorName: "All",
  id: 0
};

const useDashboardSelectOperator = () => {
  const [operator, setOperator] = useState<OperatorsSelectList | null>(null);

  useEffect(() => {
    const storageDashboard = () => {
      const localOperator = getLocalOperator();
      if (localOperator === null) setLocalOperator(defaultOperatorLocation);
      else setOperator(localOperator);
    };
    window.addEventListener(
      "storage:dashboard-operator-change",
      storageDashboard
    );
    window.dispatchEvent(new Event("storage:dashboard-operator-change"));
    return () => {
      window.removeEventListener(
        "storage:dashboard-operator-change",
        storageDashboard
      );
    };
  }, []);

  const getLocalOperator = () => {
    const localOperator = sessionStorage.getItem("dashboard:operator");
    try {
      const _operator: OperatorsSelectList = JSON.parse(localOperator || "");
      if (_operator.id !== undefined) return _operator;
      else return null;
    } catch (error) {
      return null;
    }
  };

  const setLocalOperator = (d: OperatorsSelectList | null) => {
    sessionStorage.setItem(
      "dashboard:operator",
      JSON.stringify({ operatorName: d?.operatorName, id: d?.id })
    );
    window.dispatchEvent(new Event("storage:dashboard-operator-change"));
  };

  return { operator, setLocalOperator, defaultOperatorLocation };
};

export default useDashboardSelectOperator;
