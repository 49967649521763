import { AxiosResponse } from "axios";
import { axios } from "../config/axios";
import { IAxiosResponse } from "../interfaces/IAxiosResponse";
import { IPagination } from "../interfaces/IPagination";
import { SelectParamsType } from "../interfaces/ISelectParams";
import {
  IAllStations,
  ISingleCity,
  IStationModel,
  // IStationModel,
  // IStationTypeSchema,
  IStationsSchema
} from "../interfaces/endpoints/IStations";
import { serialize } from "../utils/serializeToQueryString";
import {
  ICity,
  ICountry,
  ILocation
} from "./../interfaces/endpoints/ILocations";

export const getAllLocations = async (
  params: SelectParamsType
): Promise<IAxiosResponse<IPagination<ILocation>>> => {
  return axios.get(`Location/PagedStations${serialize(params)}`);
};

export const getAllCities = async (
  params: SelectParamsType
): Promise<IAxiosResponse<IPagination<ICity>>> => {
  return axios.get(`Location/PagedCities${serialize(params)}`);
};

export const getCitiesFromCountry = async (
  countryId: number
): Promise<AxiosResponse<ISingleCity>> => {
  return axios.get(`v2/Location/Cities/${countryId}`);
};

export const getCitiesTemp = async (
  params: SelectParamsType & { countryId: number }
): Promise<AxiosResponse<IPagination<ISingleCity>>> => {
  return axios.get(`v2/Location/Cities${serialize(params)}`);
};

export const getAllCountries = async (
  params: SelectParamsType
): Promise<IAxiosResponse<IPagination<ICountry>>> => {
  return axios.get(`v2/Location/Countries${serialize(params)}`);
};

export const getAllStations = async (
  params: SelectParamsType
): Promise<IAxiosResponse<IPagination<IAllStations>>> => {
  return axios.get(`v2/Location/PagedStations${serialize(params)}`);
};

export const getCurrentStation = async (
  id: number
): Promise<IAxiosResponse<IStationModel>> => {
  return axios.get<IStationModel>(`/v2/Location/Stations/${id}`);
};
export const getCurrentLocation = async (
  id: number
): Promise<IAxiosResponse<ISingleCity>> => {
  return axios.get<ISingleCity>(`/v2/Location/Cities/${id}`);
};
export const createStation = async (body: IStationsSchema): Promise<number> => {
  return axios.post("v2/Location/InsertStationWithDetails", body);
};

export const editStation = async (body: IStationModel): Promise<number> => {
  return axios.put("v2/Location/Stations", body);
};

export const deleteStation = async (id: number): Promise<IAxiosResponse<IAllStations>> => {
  return axios.delete(`v2/Location/Stations/${id}`);
};
