import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/ui/Button";
import Modal from "../../../components/ui/Modal";
import { SpinLoader } from "../../../components/ui/SpinLoader";
import Select from "../../../components/ui/select/Select";
import useBundlesOrder from "../../../hooks/useBundlesOrder";
import useFetch from "../../../hooks/useFetch";
import useUser from "../../../hooks/useUser";
import { BundleOrderCreateForm } from "../../../interfaces/bundles/BundlesOrder.types";
import {
  createBundleOrder,
  getBundlesOrderByOperatorId,
  getBundlesOrderOperators
} from "../../../services/BundlesOrder.service";
import { BundleOrderCreateSchema } from "../../../utils/validations/BundleOrderCreate.schema";

const BundlesOrderCreate = () => {
  const navigator = useNavigate();
  const { response, onCreate } = useBundlesOrder();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors }
  } = useForm<BundleOrderCreateForm>({
    resolver: yupResolver(BundleOrderCreateSchema)
  });
  const { handleFunc, loading } = useFetch(createBundleOrder);
  const { user } = useUser();
  const onClose = () => !loading && navigator(-1);
  const onSubmit = async (data: BundleOrderCreateForm) => {
    const res = await handleFunc({
      travelBundleId: data.bundle.id,
      displayOrder: response?.data
        ? response?.data[response.data.length - 1]?.displayOrder + 1 || 0
        : 0
    });
    if (res?.status === 200 && res?.data) {
      onCreate({
        ...res.data,
        auditAuthor: res.data.auditAuthor || {
          createdByLastName: user?.lastName,
          createdByName: user?.firstName,
          insertDate: dayjs().format("YYYY/MM/DD")
        },
        travelBundle: {
          id: data.bundle.id,
          operator: { id: data.operator.id, name: data.operator.name },
          operatorId: data.operator.id,
          title: data.bundle.title
        }
      });
      toast.success("Oferta është shtuar në faqen e parë.");
      onClose();
    } else toast.error("Ndodhi një gabim gjatë krijimit.");
  };
  return (
    <Modal
      show={true}
      position="center"
      onClose={onClose}
      title="Shto oferten në renditje"
      buttonElement={
        <Button
          form="bundleorder-create"
          className="flex justify-center items-center"
          type="submit"
          disabled={loading}
        >
          {loading ? <SpinLoader formatClassName="w-4 h-4 mr-2" /> : null}
          Konfirmo
        </Button>
      }
    >
      <form
        id="bundleorder-create"
        onSubmit={handleSubmit(onSubmit)}
        className="px-6 pt-8 pb-10 "
      >
        <Controller
          name="operator"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              maxHeightList="max-h-40"
              label="Operatori"
              isAbsolute={false}
              placeholder="Zgjedh operatorin"
              selectedItem={value}
              onSelectedItemChange={(item) => onChange(item)}
              getOptionLabel={(item) => item.name}
              itemToString={(item) => item?.name || ""}
              asyncFunc={getBundlesOrderOperators}
              error={errors.operator?.id?.message}
            />
          )}
        />
        <Controller
          name="bundle"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Select
              className="mt-2"
              maxHeightList="max-h-40"
              isAbsolute={false}
              disabled={!watch("operator")?.id}
              extraParams={{ operatorId: watch("operator")?.id }}
              label="Oferta e udhëtimit"
              placeholder="Zgjedh oferten e udhëtimit"
              selectedItem={value}
              onSelectedItemChange={(item) => onChange(item)}
              itemToString={(item) => item?.title || ""}
              getOptionLabel={(item) => item.title}
              asyncFunc={getBundlesOrderByOperatorId}
              error={errors.bundle?.id?.message}
            />
          )}
        />
      </form>
    </Modal>
  );
};
export default BundlesOrderCreate;
