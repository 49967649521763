import { Outlet } from "react-router-dom";
import BundlesOrder from "../../../components/bundles/order/BundlesOrder";
import HeaderPage from "../../../components/lines-managements/header/HeaderPage";
import useCan from "../../../components/users/permissions/useCan";
import { BundlesOrderProvider } from "../../../context/BundlesOrderContext";

const BundlesOrderPage = () => {
  const can = useCan("travelbundles.travelbundle.diplayorder.create");
  return (
    <>
      <HeaderPage
        {...(can && {
          link: {
            to: "create",
            children: "Shto oferte në renditje",
            state: [
              "Menaxhimi i ofertave",
              "Renditja e ofertave",
              "Shto oferte në renditje"
            ]
          }
        })}
      />
      <BundlesOrderProvider>
        <BundlesOrder />
        <Outlet />
      </BundlesOrderProvider>
    </>
  );
};

export default BundlesOrderPage;
