import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { debounce } from "lodash";
import { ChangeEvent, FC } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { defaultConfig } from "../../../hooks/useSearch";
import { IHeaderFilters } from "../../../interfaces/components/HeaderPage.types";
import { cx } from "../../../utils/classnames";
import Input from "../../ui/input/Input";
import Filter from "./Filter";

const HeaderFilters: FC<IHeaderFilters> = ({
  hasFilter,
  hasNoInput,
  filterName,
  options,
  querySelector = defaultConfig.querySelector,
  filterSelctor
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation();

  const onSearchInput = (event: ChangeEvent<HTMLInputElement>) =>
    debouceSearch(event.currentTarget.value);

  const debouceSearch = debounce((query) => {
    if (query.length) searchParams.set(querySelector, query);
    else searchParams.delete(querySelector);
    searchParams.delete(defaultConfig.paginationSelector);
    setSearchParams(searchParams, {
      replace: true,
      state
    });
  }, 700);

  return (
    <div className="flex justify-end items-center">
      {!hasNoInput && < Input
        className="w-auto md:ml-4 group"
        placeholder="Kërko..."
        paddingType="fill"
        defaultValue={searchParams.get(querySelector) || ""}
        onChange={onSearchInput}
        inputClassName={cx([
          "group",
          searchParams.get(querySelector)?.length
            ? "w-56 pr-1"
            : "cursor-pointer sm:focus:w-56 focus:w-48 w-0 pr-1"
        ])}
        beforeClassName="text-neutral group-hover:text-black"
        before={<MagnifyingGlassIcon className="w-4 h-4" strokeWidth={2} />} />}
      {hasFilter && (
        <Filter
          filterName={filterName}
          options={options}
          filterSelctor={filterSelctor}
        />
      )}
    </div>
  );
};

export default HeaderFilters;
