import { FC } from "react";
// import useWindowSize from "../../../hooks/useWindowResize";
import { cx } from "../../../utils/classnames";
import CalendarIcon from "../../shared/svgs/CalendarIcon";
import InputWrapper from "../input/InputWrapper";
import { IInputPickerWrapper } from "./Datepicker.types";

const InputPickerWrapper: FC<IInputPickerWrapper> = ({
  setShow,
  show,
  children,
  inputClassName,
  iconColor = "primary",
  iconSide = "left",
  disabled,
  ...labelProps
}) => {
  return (
    <div
      onClick={() => !disabled && setShow(!show)}
      role="timer"
      className="w-full"
    >
      <InputWrapper {...labelProps}>
        <div
          className={cx([
            "text-sm leading-[18px] w-full rounded-lg group",
            "ring-1 transition-shadow duration-300",
            "flex items-center justify-between px-3 ",
            disabled && "bg-gray-200 cursor-not-allowed",
            show ? "ring-primary" : "ring-blue-stroke hover:ring-gray-500",
            inputClassName,
            iconSide === "right" ? "flex-row-reverse" : ""
          ])}
          role="button"
        >
          <CalendarIcon
            className={cx([
              "w-4 h-4 duration-150",
              "transform-gpu transition-all duration-300",
              iconSide === "right" ? "ml-2" : "mr-2",
              iconColor === "primary"
                ? "text-primary"
                : `
              ${
                show
                  ? "text-primary"
                  : "text-neutral-300 group-hover:text-neutral"
              }`
            ])}
          />
          {children}
        </div>
      </InputWrapper>
    </div>
  );
};

export default InputPickerWrapper;
